import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class Layout extends Component {
  render() {
    return (
      <>
        <Header />
        <div>
          {this.props.children}
        </div>
        <Footer />
      </>
    );
  }
}

export default Layout;
