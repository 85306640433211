import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as AboutSinglePlank } from '../public/images/galleryPlank.svg'
import { config } from '../components/Constants'
const URL = config.url;

const Galleries = () => {
  const [locations, setLocations] = useState([]);
  const [welcome, setWelcome] = useState();

  useEffect(() => {
    fetch(`${URL}/api/about-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((data) => {
        setLocations(data.data)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (

    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign d-none d-sm-block d-sm-none d-md-block" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <h3 className="mainTitle text-uppercase mt-5">{welcome.data.attributes.aboutTitle}</h3> : null}

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="mb-5 mb-md-0 singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="galleryBackground">
        <div className="container">
          <AboutSinglePlank className="gallerySinglePlank d-none d-sm-block d-sm-none d-md-block" />
          <div className="galleryContent">
            <div className="row pb-5">
              <div className='col-md-9 text-end py-lg-5'>
                <Link to={`/about`} className='close text-uppercase galleryCloseBtn'>X</Link>
              </div>
            </div>
            <div className="row d-flex galleries">
              {locations && locations.map((location, locationIndex) => (
                <div key={location.id} className="col-sm-12 col-md-6 mb-5 text-center mb-5 mb-md-2 mb-lg-5 galleryItem">
                  <h3 className="text-center mt-4 mt-md-0 galleryTitle">{location.attributes.locationName}</h3>

                  <div id={`carouselGallery${location.id}`} className="carousel slide" >
                    <div className="carousel-inner">
                      {location.attributes.galleries.map((gallery, galleryIndex) => (
                        <div key={gallery.id} className={`carousel-item ${galleryIndex === 0 ? 'active' : ''}`}>
                          <img src={gallery.galleryImageOrVideoLink} className="img-fluid galleryImg" alt={gallery.galleryTitle} />
                          <div className="carousel-caption d-none d-md-block">
                          </div>
                        </div>
                      ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target={`#carouselGallery${location.id}`} data-bs-slide="prev">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                      </svg>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={`#carouselGallery${location.id}`} data-bs-slide="next">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                      </svg>
                    </button>

                    <div className="card-footer text-center mt-3">
                      <Link to={`/locations/${location.id}`} className="btn btn-danger text-capitalize">learn more</Link>
                    </div>
                  </div>


                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Galleries;
