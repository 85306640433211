
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as TopTriangle } from '../public/images/top_triangle.svg';
import { ReactComponent as FooterIcon } from '../public/images/footer_jones.svg';
import AllMont from '../public/images/allo_montgomery_logo.png';
import AllPrattville from '../public/images/allo_prattville_logo.png';
import '../public/styles/style.css';
import FraudModal from '../components/FraudModal.js';

const Footer = () => {

  const [locations, setLocations] = useState([]);
  const [welcome, setWelcome] = useState();
  const [showFraudModal, setShowFraudModal] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    fetch(`${URL}/api/locations-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  useEffect(() => {
    fetch(`${URL}/api/locations`)
      .then((res) => res.json())
      .then((locations) => {
        setLocations(locations);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(locations.data);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <footer className="footer container-fluid p-0">
        <div className="row pt-3">
          <div className="col-md-12 text-center mb-3">
            <div onClick={scrollToTop}>
              <TopTriangle className="triangle" />
              <p className="top text-uppercase">top</p>
            </div>
          </div>
        </div>
        <div className="row position-relative">
          <FooterIcon className="footerIcon" />
        </div>
        <div className="footerLinks">
          <div className="row">
            <div className="col-md-12 text-center p-0 pt-md-5">
              <Link to="/">Home</Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <Link to="/promotions">Promotions</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <Link to="/services">Services</Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <Link to="/about">About</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <Link to="/locations">Locations</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <Link to="/careers">Careers</Link>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
              </svg>
              <Link to="/contact">Contact</Link>
            </div>
          </div>

          <div className="row py-3">
            {locations.data &&
              locations.data.length > 0 &&
              locations.data.map((location) => (
                <div key={location.id} className="col-md-2 text-center text-uppercase">
                  <p className="locationNameFooter">{location.attributes.locationName}</p>
                  <p className="locationPhoneFooter">{location.attributes.locationPhone}</p>
                  <p>{location.attributes.locationAddress}</p>
                </div>
              ))}
          </div>

          {/* <div className="row">
            <div className="col-md-4 text-start footerIconLeft">
              <p className="text-uppercase">locally owned & operated</p>
              <img src={AllMont} alt="" className="img-fluid" />
            </div>



            <div class="col-md-4 text-center">
              <button className="btn text-white fraud-report" onClick={() => setShowFraudModal(true)}>Report Incident of Fraud, Waste, Abuse and Compliance Complaints</button>
              
            </div>

            <div className="col-md-4 text-end footerIconRight">
              <p className="text-uppercase">Proud Supporters of</p>
              <img src={AllPrattville} alt="" className="img-fluid" />
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4 footerIconLeft text-center text-md-start">
              <div className="row">
                <div className="col-12">
                  <p className="text-uppercase mb-0">locally owned & operated</p>
                </div>
                <div className="col-12">
                  <img src={AllMont} alt="" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center my-3 my-md-0">
              <button className="btn text-white fraud-report" onClick={() => setShowFraudModal(true)}>Report Incident of Fraud, Waste, Abuse and Compliance Complaints</button>
            </div>

            <div className="col-md-4 footerIconRight text-center text-md-end">
              <div className="row">
                <div className="col-12">
                  <p className="text-uppercase mb-0">Proud Supporters of</p>
                </div>
                <div className="col-12">
                  <img src={AllPrattville} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>




          <div className="row">
            <div className="col-md-12 text-center copy">
              <a href="https://whale-app-q9yuk.ondigitalocean.app/strapi/admin/" target="_blank" rel="noopener noreferrer" className="text-uppercase d-block">@ {currentYear} jones drugs</a>
              <p className="d-inline">This Site Was Designed and Brought To Life By&nbsp;
                <Link to="https://www.liquid-creative.com" >Liquid Creative</Link></p>
            </div>
          </div>
        </div>
      </footer>
      {showFraudModal && <FraudModal onClose={() => setShowFraudModal(false)} />}
    </>
  );
}

export default Footer;
