import React from 'react';
import { ReactComponent as WhereWeLive } from '../public/images/where_we_live.svg';
//import { ReactComponent as HomePageMap } from '../public/images/map_pins_new.svg';
//import { ReactComponent as HomePageMap} from './new_home_map.svg'
// import Map from './Map'
import '../public/styles/style.css'


const MapComponent = () => {


	return (
		<>
			<div className="container-fluid ">
				<div className="row">
					<div className="col-md-3">
						<WhereWeLive className="whereWeLive d-none d-sm-block d-sm-none d-md-block" />
					</div>
					<div className="col-md-8 singleAboutText">
					</div>
				</div>
			</div>
			<div className="container-fluid">
				{/* <HomePageMap className="homePageMap" /> */}
				{/* <Map/> */}
				<div className="row">
					<div className="mapLinks d-none d-sm-none d-md-block">
						<svg version="1.1" id="Isolation_Mode" x="0px"
							y="0px" viewBox="0 0 1403.6 653.8" enable-background="new 0 0 1403.6 653.8">
							<g>
								<path fill="#DDDDDD" d="M1061.7,581.1c-0.3-1.7-0.7-3.4-1-5.1c-0.1-0.9-0.3-1.8-0.4-2.7c-0.1-1.3-0.2-2.6-0.1-3.9
		c0.1-1.8,0.8-4.4-0.4-6c-1.2-1.5-4.2-1.5-6-1.6l-1.5-0.1l-0.4-1.5c-0.5-2.2-0.5-4.8-1.5-6.8c-1.3-2.3-3.5-3.4-6.1-3.5
		c-2.5-0.2-6.3-2.9-6.5-5.5c-0.2-1.5,0-1-1.3-1.6c-1-0.5-0.8-1-1.1-2.1c-0.6-2.9-0.3-7.1,0.5-10c1.1-3.8,3.6-7.3,7.4-8.8
		c3.5-1.4,7.2-1.4,10.8-1.5c8.1-0.3,16.2-0.4,24.3-0.5c44.2-0.9,88.4-3.5,132.6-5.4c25.9-1.1,49.3-2,75-2.9
		c-0.7-2.5-1.1-5.2-1.9-6.4c-0.6-1.3-3.7-2.7-5.4-5.2c-0.8-1.6-1.2-3.1-1.1-4.5c0.3-2.4,1.1-3.4,1.4-5.6c0-0.4,0-0.7,0.1-1
		c0.3-2.9-1-4.4-1.5-8.4c-0.3-2.2-0.3-4.3-0.1-6.4c0.1-1.9,0.4-3.7,0.5-5.5c0.1-2.2,0.2-4.2-0.2-5.9c-0.1-0.1-0.2-0.1-0.3-0.1
		c-0.8-0.2-3-1.3-3.1-3.2c-0.2-0.5-0.1-1-0.1-1.2c0.4-2.9,2.2-4.6,2.2-5.4c0.5-2,0.7-3.4,0.8-4.8c0.1-1,0.1-2.1-0.1-3.4
		c-0.3-5.8-5.3-7.2-5.4-15c0-0.5,0-0.9,0.1-1.3c0.4-5.6,1.6-12.8,3.2-18.2c1.2-4,3-6.9,3.2-9.8c0-0.4,0-0.8,0-1.2
		c0.1-1.7-2.1-3.8-2-7.6c0-0.3,0.1-0.5,0.1-0.5c1-5.1,6.5-9.7,10.1-12.6c-0.5-1.4-0.5-2.6-0.5-3.8c0.2-2.1,0.7-3.8,0.7-5.2
		c0.1-0.8-0.1-1.5-0.3-2.1c-0.4-1-1.7-1.8-3.6-2.8c-1.8-0.9-4.5-2.6-4.5-5.7c-0.1-0.5-0.1-0.8-0.1-0.9c0.3-3.3,2.7-4.8,4.2-5.9
		c1.6-1.2,2.3-1.8,2.3-2.6c0.4-1.7-2.6-1.9-5.9-5c-4.8-5.8-8.5-14.4-10.3-20.8c-1.6-6.6-5.8-12.9-8-20.7l1.7-0.5
		c-9.2-28.1-47.1-213.1-50.8-231.9c-76.4,2.4-158.4,3.5-230,4.2c4.1,4.3,7.3,6.3,7.3,6.3l1.1,0.7L958.4,424l11.8,166
		c2.9,0.6,5.9,2.7,8.3,3.6c1.7,0.6,4.7,1.3,6.3,2.3c1.8,1.1,2.1,3.8,4.6,3.4c1.1-3.5,4-6.9,4.5-10.4c0.6-4.6-2.7-8.5-0.9-13.2
		c0.7-1.8,2-3.1,2.5-4.9c0.5-2,0-4,0.4-5.9c0.7-4.4,2-8.6,2.5-13.1c0.4-3.3-0.8-13.4,2.5-14.3c0,4.6-0.3,9.2-0.3,13.8
		c2.4-0.7,0.5-3.8,1.6-5.7c3.3,0.5,3.1,4.5,2.8,7.2c1.6-1.8,2.8-2.5,3.8-4.7c0.9-2,0.8-4.1,1.2-6.3c0.9-4.7,1.6-8.6,1.5-13.7
		c2.8,4,1.1,9.6,0.5,14.5c-0.3,2.1-1.7,3.9-2,5.8c-0.5,2.2,2.3,6.5-0.8,6.5c-0.3,2.2-0.9,5.4,0,7.3c0.7,1.7,3.6,2.8,4.5,4.4
		c2.4,4-2.5,12.1,0.5,14.8l0.9,6.5c0,0-0.1-0.2-0.2-0.3c1.1,3.3,1.4,5.1,5.3,6.6c-0.5,1.5-1.3,3.1-1,4.9c2.5,0.6,8.3,1.6,7.3,5.3
		s-9.1,0.5-7.9,5.6c4.4-0.8,9.5-2.5,13.9-2.9c2.4-0.2,11.8,2.2,12.2-2.1c0.1-2-3.3-2.5-2.6-5.3c0.8-3.7,2.7-1.2,4.6-0.7
		c3.7,0.9,2-0.3,3.9-2.5c1.6-1.8,2.7-1.3,4.7-2.4c0.5-0.3,1.5-1.8,2.1-2.3s1.7-0.3,2.5-0.8c0.7-0.6,1.3-1.6,1.8-2.7
		c0.3-0.9,0.6-2.1,0.6-2.4C1062.2,584.1,1062,582.8,1061.7,581.1z"/>
								
								<g>
									<path fill="#DE1E25" d="M1270.6,309.7c0-5.6-4.5-10.3-10.3-10.3s-10.3,4.5-10.3,10.3c0,5.1,3.6,9.3,8.5,10.1l0.5,20.4l0,0l1.2,3.6
			c0,0.1,0.2,0.1,0.2,0l1.2-3.6l0,0l0.5-20.4C1267,319,1270.6,314.8,1270.6,309.7z"/>
								</g>
								<g>
									<path fill="#DE1E25" d="M1135.5,326.9c0-5.6-4.5-10.3-10.3-10.3c-5.6,0-10.3,4.5-10.3,10.3c0,5.1,3.6,9.3,8.5,10.1l0.5,20.4l0,0
			l1.2,3.6c0,0.1,0.2,0.1,0.2,0l1.2-3.6l0,0l0.5-20.4C1131.9,336.2,1135.5,332,1135.5,326.9z"/>
								</g>
								<g>
									<path fill="#DE1E25" d="M1156,312.9c0-5.6-4.5-10.3-10.3-10.3c-5.6,0-10.3,4.5-10.3,10.3c0,5.1,3.6,9.3,8.5,10.1l0.5,20.4l0,0
			l1.2,3.6c0,0.1,0.2,0.1,0.2,0l1.2-3.6l0,0l0.5-20.4C1152.3,322.1,1156,318,1156,312.9z"/>
								</g>
								<g>
									<path fill="#DE1E25" d="M1156,345.8c0-5.6-4.5-10.3-10.3-10.3c-5.6,0-10.3,4.5-10.3,10.3c0,5.1,3.6,9.3,8.5,10.1l0.5,20.4l0,0
			l1.2,3.6c0,0.1,0.2,0.1,0.2,0l1.2-3.6l0,0l0.5-20.4C1152.3,355.1,1156,350.9,1156,345.8z"/>
								</g>
								<g>
									<path fill="#DDDDDC" d="M46.4,357.6l-0.1-0.3c-0.4-0.2-0.7-0.1-1,0.2c0.2,0.3,0.6,0.4,0.9,0.7c0.2,0.2,0.3,0.7,0.6,0.7
			C47.9,358.9,46.6,357.8,46.4,357.6z"/>
									<path fill="#DDDDDC" d="M503.8,339.5c-0.1-0.4-0.2-0.5-0.3-0.4c0,0,0,0-0.1,0s-0.1,0-0.1,0l0,0c0,0.1,0.4,0.7,0.6,0.9
			C503.9,339.8,503.9,339.6,503.8,339.5z"/>
									<path fill="#DDDDDC" d="M509.7,456.2c-0.1-0.3-0.2-0.8-0.2-0.8c0-0.2-0.4-0.1-0.7-0.6c-0.1-0.2-0.2-0.4-0.2-0.6
			c0-0.5,0.3-0.7,0.2-0.9v-0.1c-0.1-0.2,0,0-0.6-0.3c-0.3-0.2-0.4-0.5-0.5-0.5c0-0.1-0.4,0.1-0.7-0.4c-0.1-0.2-0.1-0.4-0.1-0.5
			s0-0.2,0-0.2c0-0.1,0-0.1,0-0.1c-0.1-0.3-0.1-0.3-0.2-0.3l0,0c-0.1,0-0.5,0-0.8-0.4c-0.3-0.5-0.3-1.7-0.3-2c0-0.1,0-0.1,0-0.2
			c0-0.8,0.5-1,0.5-1.3c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.5c0-0.2,0.1-0.5,0.3-0.8c0.2-0.3,0.5-0.6,0.5-0.7s0-0.1,0-0.2s0-0.3,0-0.5
			s0-0.3,0.1-0.5l0.1-0.3l-30.3,1.8l-0.1-0.2l-0.2-0.6v-0.1c0.1,0-0.1-1,0.5-1.5c-0.2-0.4-0.3-0.8-0.3-1.4c0-0.2,0-0.3,0-0.5
			c0.2-0.7,0.8-1.2,0.7-1.5l0,0c0.1-0.2-0.5-0.6-0.5-1.3c0-0.2,0.1-0.4,0.2-0.6c0.2-0.4,0.7-0.5,0.9-0.4c0.1,0,0.2,0,0.2,0
			c0.1,0,0.1,0,0.1,0l0,0l0,0c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.4-0.3-0.7c0,0,0-0.1,0-0.2c0.2-0.7,0.8-0.7,0.7-0.8
			c0.1-0.2,0.1-0.3,0.1-0.3c0-0.1,0,0-0.1-0.1s-0.4-0.3-0.5-0.6c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.8,0.8-1,1.3-1.1
			c0.4-0.1,0.9-0.1,1.1-0.3c-0.2-0.4-0.3-0.7-0.3-1.1c0-0.9,0.7-1.7,1.7-1.8l0,0c0-0.3-0.1-0.7-0.1-1.1c0-0.5,0.1-1.2,0.7-1.7
			c0.3-0.2,0.6-0.2,0.7-0.3c0.2,0,0.2,0,0.2-0.1l0,0c0-0.2-0.1-0.6-0.2-1c0-0.1,0-0.1,0-0.2l0.2-0.6l0.4,0.4
			c0.2,0.2,0.3,0.2,0.4,0.2c0.2,0,0.5-0.3,0.5-0.6c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.2-0.1-0.5-0.2c-0.3,0-0.7-0.4-0.6-0.9v-0.1
			c0.1-0.7,0.8-1.2,1-1.5l0.2-0.2l0.3,0.1c0.3,0.2,0.5,0.4,0.7,0.5c0-0.4,0-0.7,0-1.1v-0.3l0.3-0.1c0.6-0.1,0.9-0.5,0.9-0.9
			c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.3-0.4-0.4-0.6-0.5c-0.3-0.2-0.5-0.6-0.5-1.1c-0.3-0.1-0.5-0.2-0.6-0.2l-0.3-0.1v-0.3
			c-0.1-1.1,0-1.3-0.5-1.9c-0.2-0.2-0.1-0.1-0.3-0.2c-0.3-0.1-0.4-0.4-0.4-0.8c0,0,0-0.1,0-0.2c0-0.3,0.1-0.6,0.2-0.8
			c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.7,0.8-1.8,0.8-2l0,0l0,0c0-0.3-0.2-0.7-0.4-1c-0.2-0.3-0.5-0.5-0.7-0.5
			h-0.1l-0.6,0.2l0.1-0.6c0.1-0.2,0.2-0.5,0.3-0.7l-44,1.1l0.7,18.5c0.2,0.6,0.4,1.1,0.8,1h0.3v0.3c0,0.2,0,0.4,0,0.7
			c0.4,0,0.8,0.2,1,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.2,0,0.2s0,0,0.1,0.1c0,0.1,0.6,0.1,0.9,0.7c0.1,0.2,0.1,0.3,0.1,0.5
			c0,0.3-0.1,0.5-0.1,0.7c0.2,0.3,0.3,0.5,0.3,0.8c0,0.6-0.4,1.1-0.3,1.5c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.5,0.6,0.7,0.8
			c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.4,0.5,0.8c0,0.1,0,0.2,0,0.3l-0.1,0.3h-0.3c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.1v0.3
			c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0.1,0.6c0.1,0.2,0.1,0.2,0.1,0.2l0.4,0.1l-0.1,0.4c0,0.1,0,0.1,0,0.2c-0.1,0.1,0.1,0.1,0.6,0.5
			c0.7,0.7,0.3,1.2,0.5,1.6c0.1,0.3,0.5,0.4,0.8,1.1c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.2,0.6-0.3,0.6c0,0,0,0.1-0.1,0.1
			c0.6,0.3,0.7,0.9,0.7,1.4c0,0.2,0,0.3,0,0.4v0.4l-0.4-0.1h-0.1c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.3
			c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.2v0.3h-0.3c0,0-0.1,0-0.3,0.1c0,0.1-0.1,0.3-0.1,0.5c0,0.3,0,0.7,0,1c0,0.1,0,0.2,0,0.3
			c0,0.2,0,0.5-0.1,0.8c-0.2,0.5-0.5,0.6-0.5,0.7c-0.2,0.3,0,1-0.5,1.7c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.1-0.2,0.2-0.2,0.2
			s0,0.1,0.1,0.2l0.3,0.3l-0.4,0.2c-0.4,0.2-1.1,0.6-1,0.8c0,0,0,0,0,0.1c0,0,0,0,0.1,0s0.4,0,0.6,0.3c0.1,0.2,0.1,0.4,0.1,0.6
			c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.3-0.3,0.3-0.3,0.3v0.1c0,0,0,0.1,0,0.2c0,0,0,0.3,0,0.5s0,0.3,0,0.4c0.1,0.5,0.6,0.8,0.7,1.8l0,0
			c0,0.6-0.1,1.3-0.1,1.9c-0.1,0.5,0.1,1.4-0.2,2.1c-0.4,0.7-0.9,0.8-1,1.1c0,0,0,0,0,0.1s0,0.3,0,0.5c0,0.1,0,0.2,0,0.3
			c-0.2,0.5-0.6,0.6-0.7,0.8c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.2-0.5,0.4-0.3,0.8c0.2,0.3,0.5,0.6,0.6,0.9c0.2,0.3,0.2,0.7,0.2,1
			c0.6-0.2,1.3-0.6,1.8-0.8c0.7-0.2,1.3-0.5,2-0.6c0.8-0.2,1.4-0.6,2.1-0.7c0.6-0.1,0.6,0,1,0.4c0.3,0.2,0.5,0.4,0.9,0
			c0.8-1-0.9-1-0.6-1.8c0.3,0,0.8-0.6,0.9-0.8c0.3-0.6-0.5-0.8-0.4-1.5c1,0.2,0.4-1.7,0.6-2.1c0.4,0.1,0.7,1.5,0.8,1.8
			c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0,0.4,0.1,0.5c0.1,0.2,0.3,0.2,0.4,0.3c0.6,1-0.3,1.1-1,1.1
			c-1.1,0.1-0.8,0.9,0.1,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c0.4,0.2,1.7,0.1,2.3,0.1c0.6,0,0.7,0.1,1.2,0.4c0.7,0.3,0.6-0.2,1.1-0.3
			c-0.3,1.1,1.6,1,2.3,1.3c0.4,0.2,0.7,0.5,1.2,0.6c0.4,0.1,1,0.1,1.4,0.2c0.4,0.2,0.8,0.6,1.3,0.8c0.4,0.1,0.8,0.2,1.3,0.3
			c1,0.4,2,0.2,3,0.4c0.5,0.1,0.8,0.1,1.3-0.1c0.4-0.1,0.9,0,1.4-0.2l0.4-0.2c0.4,0,0.8-0.5,1.1-0.7c0.4,0.5,1.1,0,1.6,0.3
			c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0,0.5,0c0.3,0.2,0.3,0.4,0.6,0.6c0.2,0.2,0.6,0.1,0.8,0.2c0.1,0,0.3,0.2,0.4,0.2
			c0.2,0,0.4-0.2,0.5-0.1c0.3,0.1,0.5,0.3,0.9,0.3c0.4-0.1,0.5-0.3,0.2-0.6c1.4-1-2.3-2.9-2.5-1.4c-0.7,0.1-1.3-0.4-1.7-0.9
			c-0.5,0.3-0.7,0.4-1.3,0.3c0.1-0.2,0.2-0.3,0.2-0.5c-0.6,0-2.3-0.7-2.6-1.2c0.5-0.3,1.7-0.6,2.1,0.1c0.5-0.3,0.9-0.5,1.5-0.7
			c0.5-0.2,0.7-0.5,1.1-0.8s0.9-0.4,1.4-0.1c0.3,0.2,0.9,0.6,0.8,1c-0.5-0.1-0.6,0.8,0.1,0.7c0.5-0.1,0.7-0.6,1.1-0.8
			c0.6-0.4,0.7,0.2,1.3,0.3c0.4,0.2,0.9,0,1.4,0c0,0.1,0,0.3,0,0.4c-0.9,0.2-0.1,0.9-0.3,1.2c0.5,0.3,1.2,0.4,1.5,0.9
			c0.1,0.2,0,0.8,0.3,1s0.6-0.4,1,0c0.2-0.5,0.5-0.5,0.6-1.1c0-0.3-0.3-1.2,0.2-1.2c1.2,0.1-0.5,2.1,0.1,2.5c0.2-0.7,1,0.3,1.5,0.1
			c0.4-0.2,0.7-1.3,0.7-1.7c-0.1-0.4-0.4-1.1-0.8-1.3c-0.3-0.2-0.7-0.1-0.6-0.5s0.6-0.2,0.9-0.3c0-0.5-0.3-0.5-0.6-0.7
			c-0.4-0.3-0.3-0.2-0.4-0.7c-0.1-0.6-0.6-1.1-1.2-1.3c-0.3-0.1-0.7,0-0.9-0.1c-0.3-0.1-0.4-0.5-0.6-0.7c-0.4-0.4-2.1-0.3-1.8-1.3
			c0.4-0.1,0.8,0.1,1.1,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0-0.2,0.1-0.4,0.4-0.5c0.5-0.2,0.5,0.1,0.5-0.5c0-0.4-0.2-0.8-0.2-1.2
			c0.1,0.3,0.4,0.3,0.5,0.6c0.2,0.3,0.2,0.7,0.4,0.9c0.3,0.5,0.7,0.8,0.9,1.4c0.1,0.4,0.1,0.9,0.5,1.3c0.4,0.3,0.8,0.3,1.1,0.7
			c0.9-0.7,1.3,1.8,1.7,0c0.1-0.6,0.3-0.9,0.1-1.5c-0.1-0.5-0.5-0.8-0.5-1.3c0.4,0.1,0.7,0.5,0.8,0.8c0.1,0.2,0.1,0.4,0.2,0.6
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.3,0.7,0.3,0.9c0,0.4-0.4,0.6-0.5,0.9c0.5,0,0.8,0.6,0.4,0.9c-0.2,0.1-0.4,0-0.6,0.1
			s-0.4,0.2-0.5,0.3c-0.3,0.1-0.8,0.2-0.8,0.7c-0.1,0.4,0.2,0.8,0.1,1.2c-0.5,0.1-0.2,0.9-0.5,1.1c-0.4,0.3-0.1,1.1-0.1,1.1
			c0.3-0.1,0.7-0.3,1,0c0.4,0.3,0.1,0.7,0.3,1.1c0.2,0.5,1.1,1.1,1.5,1.4c0.4-0.7,0.9-0.8,1.3,0.1c0.5,0.1,1.3-0.6,1.6-0.2
			c0.2,0.2,0,0.6-0.2,0.8c-0.3,0.2-0.3,0.1-0.5-0.1s0-0.3-0.4-0.4c-0.1,0-0.2,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.1
			c-0.3,0-0.5,0-0.8,0.2c-0.2,0.2-0.1,0.6-0.4,0.8c-0.6-0.2-1.1-1.2-1.4-1.7c-0.9-1.8-0.9,0.9-1.8,0.2c-0.2,0.5,0,1.3,0.2,1.8
			c0.7-0.4,1.5,0.2,2.2,0.4c-0.1,0.4,0.3,0.7,0.6,0.7c0.5,0.1,0.4-0.1,0.7-0.4c0.3-0.4,0.4-0.4,0.8-0.3s0.4,0.5,0.8,0
			c0.4-0.4,0.2-1,1.1-0.9c0.4,0,1.3,0.4,1.5,0.7c-0.4,0-0.3,0.3-0.6,0.4c-0.2,0.1-0.5-0.1-0.7-0.1c-0.4,0.2-0.7,1.3-0.3,1.6
			c0.3,0.3,0.7-0.3,1-0.5c0.2-0.1,0.4-0.4,0.7-0.1c0.2,0.3-0.1,0.6-0.1,0.9c0.2-0.2,0.2-0.4,0.6-0.5c0.3,0,0.5,0.4,0.7,0.4
			c0.4,0.1,1-0.7,1.4-1c0.7-0.5,0.5-0.6,0.5-1.4c0.1-0.6,0.7-1.1,1.2-1.1c0-0.2,0.1-0.5,0.2-0.7c0.3,0,0.9,0.1,1.1,0.2
			c0.5,0.2,0.2,0.4,0.2,0.9c0.3,0,0.4,0.1,0.4,0.2c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.4,0.1-0.7,0.4-0.4c0.3,0.3,0,0.6,0.5,0.7
			c0.2,0.1,0.7-0.3,0.9-0.2c0.5,0.3-0.3,2,0.7,1.7c0.2,0.4,0,1.1,0.2,1.4c0.3,0.5,0.9-0.1,0.5-0.5c0.2-0.2,0.3,0,0.6-0.1
			c0.2-0.1,0.2-0.2,0.4-0.3c0.4-0.3,0.8-0.2,1.1-0.5c1.2-1-0.9-2-0.7-2.8c0.3-0.1,0.5-0.1,0.8-0.2c0.5-0.2,0.4,0,0.4-0.5
			c0-0.2-0.2-0.4-0.1-0.6c0-0.2,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.2,0.1-0.4c-0.1-0.3-0.2,0-0.4-0.1c-0.1,0-0.3,0-0.4-0.1
			s-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.2-0.7-0.3c-0.5-0.2-1.7-0.4-0.8-1c-0.4-0.5-0.5-1.5-0.3-2.1c0.2-1-0.5-0.6-1.2-0.4
			c-0.8,0.3-1.6,0.1-1.4-1c0.2-0.7,0.9-1.3,1.6-1.3c1.1,0.1,0.5,1,0.2,1.6c0.3,0.1,1,0,1.2,0.3c0.1,0.1,0.1,1,0.1,1.2
			c-0.1,0.4-0.4,0.6-0.1,1c0.2,0.3,0.7,0.3,1,0.4c0.6,0.2,1.3,0.4,1.9,0.7c0.8,0.3,1.1,1.1,1.9,0.5c1.2-0.8,0.2,0.4,0.8,1
			c0.5-0.3,1,0.3,0.9,0.8c-0.1,0.3-0.5,0.6-0.4,1c0.1,0.3,0.5,0.1,0.7,0.2c0.5,0.1,0.8,0.3,1.2,0.1c0.2-0.1,0.4-0.5,0.7-0.5
			c0.4,0,0.1,0.1,0.2,0.4c0.1,0.5,0,0.7,0.5,1c0.3,0.2,0.9,0.3,1.2,0.4c0.4,0.1,0.9,0.2,1.3,0.5c0.5,0.4,0.3,0.8,0.5,1.4
			c0.3,0.7,0.6,0.6,1.2,0.6c0.6,0,0.6,0.5,1.1,0.6c0.2-0.9,0.6-0.2,1,0c0.7,0.4,0.6-0.1,1-0.5s0.7,0.1,1.1-0.1
			c0.5-0.3,0.2-1.2,0.1-1.5c-0.1-0.4-0.2-0.9-0.5-1.2c-0.1-0.1-0.3-0.1-0.4-0.3c-0.1-0.2-0.1-0.4-0.1-0.7c-0.1-0.4-0.3-1-0.9-0.9
			c-0.5,0.1-0.3,0.6-0.7,0.8c-0.5,0.2-0.2-0.6-0.5-0.9s-0.9,0.1-1.2,0.2c0.3-0.5,0.1-1-0.4-1.2c-0.2-0.1-0.7-0.1-0.9-0.1
			c-0.2,0.1-0.4,0.4-0.6,0.4c-0.3,0-0.5-0.3-0.8-0.4s-0.6,0.2-0.9-0.1c-0.3-0.3,0-0.5-0.1-0.8c-0.2-0.3-0.9-0.6-1.2-0.9
			c-0.4-0.4-0.7-0.6-1.3-0.8c-0.5-0.3-1.1-1.1-0.1-1c0-0.3-0.5-0.9-0.1-1.1c0.3-0.2,0.3,0.2,0.5,0.2c0.5,0,1.1-0.4,0.7-0.9
			c-0.2-0.3-0.9-0.4-0.3-1c0.5,0.1,0.7,0.3,1.1,0.1c0.3-0.1,0.4-0.5,0.7-0.7c0.7-0.5,1.2-0.3,1.3-1.2c0-0.4,0-0.7,0-1.1
			c0-0.5,0.1-0.5,0.4-0.8c0.2-0.2,0.6-0.7,0.3-1c-0.3-0.4-0.7,0.1-1,0.3c-0.6-0.9-1.1,0.4-1.4,0.8c-0.4,0.6-0.4,1-1.1,1.4
			c-0.5,0.2-0.3,0.2-0.8,0c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.3-0.1-0.7-0.4-0.8c-0.5-0.1-0.6,0.5-1,0.5c-0.3-1.2-1.3-0.2-2-1
			c0.2-0.4,0.7-0.4,1-0.6c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.5,0,0.6,0c0.3-0.1,0.5-0.5,0.7-0.7c0.4-0.4,0.9-0.8,1.2-1.3
			c-0.9-0.4-1.4,1-2.2,1c-1.5,0.1-0.7-1.3-0.6-2.1c0.3,0,0.6,0.3,0.8,0.3c0.3,0,0.5-0.4,0.7-0.6c0,0.9,0.9,0.6,1.5,0.5
			c-0.1-0.3-0.2-0.5-0.3-0.7C509.8,456.7,509.7,456.3,509.7,456.2z M505.6,459.6c-0.4,0.5-0.9,1.3-1.5,1.5c-0.7,0.3-1.8,0.1-2.6-0.1
			c-0.4-0.1-0.9,0-1.3-0.1c-0.5-0.2-0.3-0.7-0.6-0.9c-0.4-0.2-0.5,0.5-0.8,0.6c-0.3-0.2-0.3-0.3-0.6-0.4c-0.2-0.1-0.6,0.1-0.8-0.1
			c-0.3-0.3,0.2-1.2-0.6-1.2c-0.6,0.1-0.6,1.1-1.3,0.9c-0.6-0.1,0.1-0.9,0-1.4c-1.6,0.3-0.1-2.9,0.9-2.5c0.7,0.3,0.1,1.6,0,2.1
			c0.4-0.2,0.6-0.1,0.9-0.4c0.1-0.1,0.1-0.3,0.3-0.4c0.1-0.1,0.4,0,0.5-0.1c0.2-0.1,0.3-0.4,0.5-0.6s0.6-0.2,0.7-0.4
			c0.5-0.5,0.2-1.6,1.3-1.6c0.4,0,0.7,0.3,1,0.4s0.6,0,0.9,0c1.1,0,1,0.6,1.2,1.5c0.8,0,1.1,0.9,1.8,0.8c0.4,0,0.7-0.4,1.1-0.3
			c0.5,0.1,0.3,0.3,0.4,0.7C506.7,458.2,506.2,458.9,505.6,459.6z"/>
									<path fill="#DDDDDC" d="M484.4,409.3L484.4,409.3L484.4,409.3L484.4,409.3z" />
									<path fill="#DDDDDC" d="M142.5,308.1c-0.3,1.6-0.6,3.3-1,4.9c-0.3,1.3-0.5,2.5-0.7,3.8c-0.2,1-0.9,2.2-0.8,3v0.1
			c0,0,0.1,0.2,0.2,0.6v0.1c0,0.6-0.4,1.1-0.7,1.4c-0.3,0.5-0.7,1-1.1,1.4c-0.3,0.4-0.8,1.5-1.5,1.8c-0.3,0.1-0.5,0.2-0.8,0.2
			c-0.7,0-1.2-0.6-1.3-1.4c0-0.4,0-0.8-0.1-1s-0.1-0.2-0.5-0.3c-0.6-0.1-1.2-0.1-2-0.5c-0.6-0.3-1.2-0.5-1.8-0.5h-0.1
			c-0.7,0-1.1,0.2-1.3,0.8c-0.2,0.5-0.5,1.3-0.5,1.8c0,0.4,0.5,0.9,0.6,1.8c0,0.1,0,0.3-0.1,0.4c-0.2,0.4-0.4,0.6-0.4,0.6
			c0,0.1,0.1,0.3,0.2,0.7v0.1c-0.1,0.9-0.6,1.3-0.5,1.8v0.1c0,0.2,0,0.3,0,0.4c0,0.8-0.3,1.4-0.3,2.1c0,0.1,0,0.2,0,0.3
			c0,1.4,0.2,2.8,0.5,4.2c0,0.1,0,0.2,0,0.3c0,0.8-0.4,1.2-0.4,1.7c0,0.4,0.1,0.7,0.1,1.1s-0.4,0.8-1,1s-0.3,0.2-0.6,1.1
			c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0,0.9c0,0.2,0,0.4-0.1,0.6c-0.1,0.6-0.3,0.9-0.3,1s0,0.2,0.2,0.5c0.4,0.8,0.4,1.4,0.8,1.9
			c0.2,0.2,0.5,0.4,0.8,0.7l0.1,0.1v0.1c0.1,0.2,0.1,0.4,0.1,0.5c0,0.5-0.1,0.8-0.1,1.1l0,0c-0.1,0.4,0.5,0.5,0.6,1.4c0,0,0,0,0,0.1
			c0,0.6-0.3,1.2-0.3,1.6h-0.1h0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.2,0.1s0,0,0.1-0.1l0.2-0.3l0.3,0.2
			c0.8,0.5,0.5,1.4,0.7,1.8c0.1,0.2,0.1,0.2,0.1,0.2c0.1,0.1,0.3,0.2,0.5,0.4c0.2,0.3,1,0.9,1,1.7c0,0.1,0,0.2-0.1,0.4
			c-0.3,0.4-0.7,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-0.9,0.3c-0.1,0.1-0.2,0.4-0.6,0.8c-0.4,0.3-0.9,0.3-1.2,0.4c-0.4,0.1-1.1,0.3-1.4,0.5
			c-0.1,0.1-0.1,0.1-0.1,0.1v0.1c0,0.1,0.1,0.3,0.1,0.5s-0.1,0.5-0.3,0.7c-0.4,0.4-1,0.4-1.1,0.5c-0.3,0.2-0.4,0.3-0.5,0.5v0.1
			c0,0.1,0.1,0.3,0.2,0.8c0,0.1,0,0.1,0,0.2c-0.2,0.6-0.6,0.8-0.6,0.9c0,0,0,0,0,0.1h-0.3h0.3c-0.1,0.1,0.3,0.1,0.5,0.8
			c0,0.1,0,0.1,0,0.1c-0.1,0.7-0.6,0.9-0.7,1.1c-0.1,0.1-0.1,0.1-0.1,0.2l0,0c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.2,0,0.3
			c-0.1,0.6-0.5,0.7-0.6,1c-0.1,0.2-0.1,0.4-0.2,0.8c-0.1,0.3-0.2,0.6-0.4,0.7c-0.2,0.1-0.1,0-0.3,0.1c-0.2,0.1-0.1,0.5-0.6,1
			c-0.6,0.6-1.3,0.7-1.3,1.2v0.3H120h-0.1c-0.6-0.1-0.7-0.4-0.7-0.3l0,0l0,0c0,0.1,0.2,0.4,0.4,0.7l0.2,0.3l-0.3,0.2
			c-0.3,0.1-0.4,0.2-0.4,0.3l0,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.2,0.4,0.2,0.5c-0.1,0.8-0.7,0.9-0.6,1.2v0.1
			c-0.1,0.2,0.3,0.3,0.4,1c0,0.2,0,0.3-0.1,0.5s-0.3,0.4-0.3,0.5l0,0l0,0l0,0c0.1,0.1,0.3,0.1,0.4,0.4c0.2,0.2,0.2,0.4,0.2,0.5h0.1
			c0,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.6-0.1l0.4-0.2l0.1,0.4c0.2,0.5,0.2,0.7,0.4,1c0.2,0.3,0.4,0.7,0.5,1.1c0,0.1,0,0.2,0,0.3
			c0,0.8-0.6,1.1-0.8,1.6c0,0,0,0.3-0.3,0.6c-0.1,0.1-0.3,0.1-0.3,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.5-0.5,0.9
			c-0.3,0.2-0.6,0.2-0.9,0.2c-0.4,0-0.9-0.1-1.3-0.2c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.5-0.5-0.8-0.3c-0.2,0.2-0.1,0.4-0.5,0.4h-0.1
			c0,0,0,0,0.1,0l0,0c-0.3,0.3-0.2,0.7-0.4,0.9c-0.2,0.3-0.7,0.1-0.9,0.5c-0.1,0.3-0.1,1.1-0.1,1.4l0.1,0.8c0.1,0.7,5.2,3,5.8,3.4
			c3.9,2.3,7.8,4.6,11.7,7c1.5,0.9,3.2,1.6,4.6,2.6c0.6,0.4,1.1,1.1,1.8,1.5c0.8,0.5,1.6,0.5,2.4,0.9c1.5,0.7,2.7,1.7,4.2,2.6
			c7.7,4.4,15.4,8.8,23.1,13.3c0.6,0.3,1.1,0.6,1.7,1c0.4,0.3,1.4,0.2,1.9,0.3c1.7,0.2,3.5,0.5,5.2,0.7c2.4,0.3,4.9,0.7,7.3,1
			c2.4,0.3,4.9,0.3,7.2,0.8c1.6,0.3,3.1,0.5,4.7,0.8c1.2,0.2,2.4,0.3,3.6,0.4l14.9-104.4C189.5,316.8,163.6,312.8,142.5,308.1z"/>
									<path fill="#DDDDDC" d="M116.1,383.9c0.2-0.3,0.5-0.7,0.9-0.5c0.3,0.1,0.5,0.4,0.7,0.5s0.7,0.2,1.1,0.2c0.2,0,0.4-0.1,0.4-0.1
			c0.2-0.1,0-0.4,0.4-0.9c0.1-0.1,0.3-0.2,0.4-0.2h0.1c0-0.1,0-0.2,0.1-0.3c0.4-0.7,0.8-1,0.7-1.3c0-0.1,0-0.1,0-0.2
			c-0.1-0.3-0.2-0.6-0.4-0.9c-0.2-0.3-0.3-0.6-0.3-0.8c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.4,0c-0.3-0.1-0.6-0.3-0.6-0.5
			c-0.1-0.2,0-0.2-0.1-0.3c0.1-0.1-0.4-0.1-0.6-0.7c0-0.1,0-0.1,0-0.1c0.1-0.5,0.4-0.7,0.4-0.8c0-0.1,0.1-0.2,0.1-0.2
			c0.1-0.1-0.3-0.3-0.4-1c0-0.1,0-0.1,0-0.2c0.2-0.8,0.7-1,0.6-1.1c0,0,0-0.1-0.1-0.2c0,0,0,0-0.1-0.1s-0.3-0.3-0.3-0.5
			c0-0.1,0-0.2,0.1-0.3c0.1-0.3,0.3-0.4,0.4-0.5c-0.1-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.2
			c0.3,0,0.5,0.1,0.6,0.2c0.3-0.7,1.1-0.9,1.4-1.2c0.2-0.1,0.1-0.6,0.6-1.1c0.2-0.1,0.3-0.2,0.4-0.2c0,0,0-0.1,0.1-0.2
			c0.1-0.2,0.1-0.6,0.3-1c0.4-0.5,0.5-0.5,0.5-0.6v-0.1c0-0.2-0.2-0.1-0.3-0.6c0-0.2,0.1-0.4,0.2-0.6c0.3-0.4,0.6-0.6,0.6-0.7
			c0.1,0-0.4-0.2-0.5-0.8c0,0,0,0,0-0.1c0.1-0.8,0.7-0.9,0.6-1.1c0,0,0,0,0-0.1s-0.1-0.3-0.2-0.8c0-0.1,0-0.2,0-0.3
			c0.1-0.5,0.5-0.7,0.8-0.9c0.4-0.2,0.9-0.3,1-0.4s0.1-0.1,0.1-0.2s0-0.3-0.1-0.5c0-0.1,0-0.3,0.1-0.4c0.4-0.7,1.6-0.8,2-1
			c0.4-0.1,0.8-0.2,0.9-0.3c0.1-0.1,0.2-0.4,0.6-0.8c0.6-0.4,1.7-0.4,2-0.6c-0.1-0.2-0.6-1-0.8-1.1c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.2-0.1-0.4-0.3-0.4-0.7c-0.1-0.5-0.1-0.9-0.2-1.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.6-0.1-1-0.5-1.1-1.1v-0.1c0-0.6,0.3-1.2,0.3-1.6
			l0,0c0.1-0.4-0.5-0.5-0.6-1.4v-0.1c0-0.5,0.1-0.8,0.1-1.1c0-0.1,0-0.1,0-0.2c-0.2-0.2-0.5-0.4-0.8-0.7c-0.7-0.7-0.6-1.5-0.9-2
			c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.5,0.2-0.6,0.3-1.2c0-0.2,0-0.3,0-0.4c0-0.3,0-0.5,0-0.9c0-0.1,0-0.2,0-0.3L66,252.1l13.8-54.6
			c-19.4-4.9-38.4-10.3-55-15.9c0,0.6-0.1,1.3-0.4,1.9c-0.5,1-0.9,1-0.6,2.1c0.3,1,0.2,1.7,0.1,2.7c-0.1,1.1-0.1,2.1-0.3,3.2
			c-0.3,1.1-0.8,1.6-1.4,2.5c-0.5,0.7-0.5,1.7-1.1,2.4c-0.8,0.9-1.5,0.9-1,2.2c0.4,1.1-0.4,1.5-0.5,2.6c0,0.4,0.1,0.9,0.1,1.3
			c-0.4,0-0.8-0.1-1.1,0.1c-0.3,0.2-0.5,0.6-0.8,0.8c-0.4,0.2-0.5,0-0.8,0.4c-0.2,0.2-0.1,0.6-0.2,0.9c-0.6,0.1-0.9,0-1.3,0.4
			c-0.3,0.2-0.4,0.6-0.6,0.9c-0.4,0.4-0.8,0.8-1.1,1.3c-0.3,0.4-0.3,0.9-0.5,1.3c0.3,0.5,1.1,0.9,0.9,1.7c-0.2,0.7-1.3,0.9-1.1,1.7
			c0.2,0.5,1.1,0.8,1.4,1.3c0.3,0.6,0.1,1.2,0.5,1.7l0.4,0.6c0.6,0.6,0.1,1.2,0.4,1.8c0.1,0.2,0.5,0.2,0.6,0.4
			c0.2,0.3-0.1,0.8,0,1.2c0.1,0.3,0.4,0.5,0.5,0.8s0,0.7,0.1,1.1c0.2,0.7,0.4,1.2,0.3,2c-0.1,1.6,0.2,3.6-0.6,4.9
			c-0.7,1-1.6,2.4-1.7,3.7c-0.1,1.5,0.3,3.1,0.3,4.6c0,0.3,0.1,0.8,0,1.1c-0.2,0.4-0.5,0.3-0.8,0.6c-0.4,0.6,0.1,1,0.4,1.6
			c0.8,1.2,1.3,2.6,1.6,4c0.2,1.3,1.3,2.6,2,3.8c0.9,1.4,1.6,2.6,2,4.2c0.2,0.8,0.6,1.4,0.7,2.2c0.2,1.7,0.6,3.4,1.4,5
			c0.2,0.5,0.7,1,0.9,1.5c0.2,0.6,0.2,1.2,0.5,1.8c0.3,0.5,0.8,0.7,1,1.2c0.8,0,0.4-1.1,0.5-1.6c0.9-0.2,0.9-2.3,1.1-3
			c0.6-0.4,1.1,0,1.7-0.1c0.3,0,0.3-0.3,0.7-0.2c0.2,0,0.6,0.3,0.8,0.4c0.6,0.3,0.6,0.4,1,0.9s1.5,1,1.6-0.1c0.2,0,0.7,0.2,0.8,0.1
			c0.3-0.2,0.1-0.7,0.3-0.9c0.8-0.7,1.1,0.5,1.2,1c0.3,1.3,2.4,1,2.3,2.4c0.7,0,1.1-0.9,1.8-0.9c0,0.3-0.1,0.7,0.1,1
			c0.2,0.3,0.7,0.1,0.6,0.7c-0.7,0.1-1.2-0.7-1.9-0.7c-0.4,0-0.7,0-1,0.1s-0.4,0.4-0.8,0.4c-0.2-0.5-1.2-1.2-1.7-1.5
			c-1.1-0.6-1.3,0.3-2.3,0.2c-0.9-0.1-1.5-0.8-2.4-0.7c-0.4,0.1-0.7,0.3-1.1,0.4s-1,0-1.4,0.2c0.4,0.8,1.3,1,1.5,1.8
			s0.3,1.7,0.4,2.5c0,0.3-0.1,0.7,0,1c0.1,0.2,0.6,0.6,0.6,0.6c0.1,0.5-0.2,1.3-0.1,1.7c0.1,0.3,0.4,0.3,0.5,0.5s0.1,0.4,0.2,0.5
			s0,0.3,0,0.5c0.1,0.2,0.4,0.3,0.5,0.5s0.7,1,0.7,1.2c0,0.7-1.5-0.2-1.9-0.5c-0.7-0.5-1.3-1.5-1.7-2.1c-0.2-0.3-0.5-0.7-0.7-1.1
			c-0.3-0.7-0.5-1.6-0.4-2.4c0.1-0.7,0.4-1.3-0.2-1.9c-1-1-0.9,0.5-1.4,1.4c-0.6,1.2-0.9,2.4-1.6,3.5c0.8,0.8,1.7,0.5,1.1,1.9
			c-0.4,1.1-0.4,1.9-0.4,3c-0.1,1.1-0.5,1.6,0.3,2.7c0.6,0.8,0.8,2.8,1.8,3.3c0.4,0.2,0.8,0,1.2,0.3c0.4,0.2,0.5,0.6,0.9,0.7
			c0.6,0.2,0.7-0.1,1.2,0.4c0.4,0.4,0.5,0.9,0.5,1.4c0.1,0.6-0.1,0.8-0.2,1.3s-0.1,1-0.2,1.6c-0.2,0.8-0.4,1.9-1.2,2.2
			c-0.4,0.1-1.2-0.1-1.5,0.2c-0.4,0.5,0.1,1.2,0.1,1.6c0.2,1.5-1.4,3.4-0.1,4.8c0.6,0.7,1.1,0.5,1.5,1.4c0.6,1.5,1.4,2.9,2,4.4
			c0.4,0.9,0.2,1.5,0.3,2.4c0.1,0.7,0.6,1.1,0.9,1.7s0.6,1.1,0.7,1.8c0.1,1,0.3,1.1,0.9,1.8c0.5,0.6,0.9,1.1,1,1.8
			c0.1,0.9,0.2,1,0.8,1.7c0.2,0.2,0.2,0.4,0.5,0.6c0.3,0.3,0.8,0.3,1,0.6c0.3,0.4,0.4,1.7,0.2,2.2c-0.4,0.7-1.3,0.5-1,1.5
			c0.2,0.8,1,0.8,1.5,1.4c0.5,0.5,0.7,1.3,1.2,1.9s0.5,1.1,0,1.7c-0.6,0.8-1,1.3-0.8,2.4c0.2,1,0.4,1.3-0.1,2.1
			c-0.2,0.4-0.4,0.6-0.4,1.1c0,0.4,0.3,0.9,0.2,1.3c-0.2,0.6-1.3,0.7-1.2,1.4c0.1,0.7,1.2,0.7,1.5,1.3c0.5,0.9,0.1,1.2,1.1,1.5
			c1.4,0.5,3.1,0.2,4.4,0.9c1.4,0.7,2.7,1.8,4.1,2.3c0.7,0.3,1.4,0,2.2,0.2c0.7,0.2,1.6,0.6,2.3,1c0.7,0.5,0.9,1.2,1.7,1.7
			c0.7,0.5,1.2,1,1.8,1.6c0.2,0.2,0.5,0.3,0.6,0.6c0.1,0.5-0.3,0.7-0.4,1.1c-0.1,0.9,0.6,0.9,1.2,1.3c0.4,0.3,0.5,0.7,0.8,1
			c0.3,0.4,0.8,0.6,1.2,0.9c0.8,0.4,1.6,0.4,2.4,0.6c1.4,0.3,2.5,1.3,3.8,2c1.2,0.6,0.8,1.1,1.2,2.2c0.1,0.3,0.5,0.5,0.5,0.8
			c0.1,0.6-0.6,0.9-0.8,1.4c-0.4,1.1,0.4,0.9,1.1,1.3c0.4,0.2,1.2,1.5,1.3,0.2c0.5,0,1,0.1,1.5,0.3c1.1,0.4,0.7,0.7,0.7,0.7
			c0.2,0.9,0.8,1.3,1.4,2c0.6,0.6,1,1.3,1.6,1.8c0.7,0.6,1,1.2,1.5,2s1.3,1.1,1.8,1.9c0.9,1.5,2.2,3.2,2.5,5c0.2,1,0,1.8,0.3,2.8
			c0.3,0.9,0.3,1.7,0.3,2.6c0,1,0.4,1.9,0.4,3c0,1-0.3,2-0.2,3.1v1.1c0.1,1.2,16.1,1.8,17.2,2c2.1,0.4,4.3,0.9,6.4,1
			c2.7,0.2,5.4,0.4,8.1,0.7c1.3,0.2,2.8,0.5,4.1,0.5c0.3,0,0.4,0.1,0.5,0.2C115.9,384.1,116,384,116.1,383.9z"/>
									<path fill="#DDDDDC" d="M440.7,467.9c0-0.1,0-0.1,0-0.1c0.1-0.5,0.5-0.8,0.6-1s0.2-0.5,0.5-0.9c0.4-0.4,0.5-0.4,0.6-0.6
			c0,0,0,0,0-0.1s0-0.3,0-0.5c0-0.1,0-0.2,0.1-0.4c0.4-0.7,0.9-0.8,1-1.2c0.2-0.3,0.1-1.3,0.2-1.9s0.1-1.2,0.1-1.8
			c0-0.1,0-0.1,0-0.1c0-0.5-0.4-0.7-0.6-1.6c0-0.2,0-0.3,0-0.5c0-0.3,0-0.5,0-0.7c0,0,0-0.1,0-0.2s0-0.1,0-0.2
			c0.1-0.3,0.3-0.2,0.3-0.3c0,0,0,0,0.1,0c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0,0-0.1,0-0.2,0s-0.4-0.1-0.6-0.4
			c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.7,0.6-1,1-1.2c0-0.1-0.1-0.2-0.1-0.3c0.1-0.8,0.9-0.9,1-1.1c0.3-0.3,0.1-0.8,0.4-1.5
			c0.3-0.5,0.5-0.5,0.5-0.7s0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.3,0-0.7,0-1s0-0.7,0.2-1l0.1-0.1h0.1c0.1,0,0.1,0,0.2,0
			c0,0,0.1,0,0.1-0.1s0-0.2-0.1-0.2c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2,0-0.4-0.4c0-0.1,0-0.1,0-0.2c0-0.5,0.4-0.9,0.8-0.9l0,0
			c0-0.5-0.1-0.7-0.4-0.7h-0.3v-0.3c0-0.1,0-0.1,0-0.1c0-0.4,0.2-0.6,0.3-0.7c0.1-0.1,0.1-0.1,0.1-0.1s0-0.1,0-0.2
			c-0.1-0.4-0.5-0.4-0.8-1.1c-0.3-0.9,0-1-0.4-1.3c-0.2-0.2-0.8-0.3-0.8-1.1v-0.1c-0.4-0.4-0.4-1-0.4-1.4c-0.4-0.1-0.8-0.4-0.8-1
			c0-0.1,0-0.1,0-0.1v-0.3h0.3h0.1c0,0,0.1,0,0.2-0.1c0-0.1,0,0-0.1-0.1s-0.4-0.1-0.6-0.3c-0.4-0.2-0.8-0.6-1-1.1
			c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.7,0.3-1.1,0.3-1.5c0-0.2,0-0.3-0.2-0.4l-0.2-0.2l0.1-0.2c0.1-0.3,0.2-0.5,0.2-0.7
			c0-0.1,0-0.2,0-0.2c0-0.2-0.2-0.1-0.8-0.5c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.1,0-0.2,0-0.2s0-0.1-0.1-0.2c0-0.1-0.3-0.2-0.5-0.2
			c-0.1,0-0.1,0-0.2,0h-0.4v-0.4c0-0.3,0-0.5,0-0.7c-0.7-0.3-0.9-1.1-1.1-1.5v-0.1l-1.1-28.5h-0.1c-0.2,0-0.5-0.1-0.6-0.1
			s-0.2,0-0.2,0c-0.4,0-0.8,0.1-1.3,0.1h-0.2c-0.2,0-0.3,0-0.4,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1s-0.1,0.3-0.3,0.5
			c-0.3,0.2-0.6,0.3-1,0.3c-0.2,0-0.4,0-0.6-0.1l-0.3-0.1l0.1-0.3c0-0.2,0.1-0.3,0.1-0.4c-0.1-0.3,0.1-0.2-0.5-0.3
			c-0.1,0-0.1,0-0.2,0h-0.2l-0.1-0.2c0-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.7-0.1-1.1-0.6-1.3-1.2
			c-0.2,0-0.6,0.2-1,0.2c-0.1,0-0.2,0-0.3,0l-0.3-0.1V396c-0.1-0.7,0.1-0.7-0.3-0.9c-0.2-0.2-0.8-0.1-1.3-0.5
			c-0.6-0.5-0.5-0.6-1-0.8c-0.2-0.1-0.9-0.1-1.2-0.7c-0.1,0-0.2,0-0.3,0c-0.9-0.1-1.1-0.8-1.7-1.1c-0.2-0.1-0.3-0.1-0.3-0.1
			s-0.1,0.1-0.5,0.1c0,0-0.1,0-0.2,0c-0.4,0-0.7-0.3-0.8-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.2-0.1
			c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.1,0.7-0.5,1.1c-0.1,0.1-0.2,0.1-0.2,0.1l0,0H419c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.6,0.1H418
			c-0.4,0-0.8-0.1-1.1-0.1c-1-0.1-1.4-0.7-1.9-0.7c-0.2,0.4-0.5,0.6-0.8,0.6c-0.2,0-0.4-0.1-0.5-0.1c-0.5-0.2-0.6-0.3-0.8-0.3
			c0,0-0.1,0-0.2,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.2,0.5s-0.3,0.1-0.5,0.1s-0.3,0-0.5,0c-0.5-0.1-1-0.2-1.4-0.3
			c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.8,0.4-1.2,0.4c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7-0.2-1-0.2
			c-0.1,0-0.2,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0.1,0.1,0.2,0.1,0.5c0,0.2-0.1,0.4-0.2,0.6s-0.4,0.4-0.6,0.4
			c-0.5-0.1-0.6-0.3-0.8-0.3h-0.1c-0.3,0-0.2,0-0.2,0.1s-0.1,0.4-0.2,0.8c-0.2,0.3-0.5,0.6-0.8,0.6c-0.8-0.1-1.2-0.8-1.5-1.5H402
			c-0.6,0-0.9-0.3-1.2-0.5c-0.4-0.2-0.6,0-1.3-0.3c-0.9-0.5-0.6-1.4-0.7-1.6c0-0.1-0.1-0.1-0.1-0.1v0.1c-0.1,0.2-0.2,0.4-0.4,0.5
			c-0.1,0.5-0.5,0.8-1,1l-0.2,0.1l-0.2-0.2c-0.4-0.5-0.6-1-0.9-1.1c-0.1,0.4-0.3,0.6-0.7,0.7c-0.7-0.1-0.9-0.7-1.1-1.2
			c-0.2-0.6-0.1-0.7-0.4-0.8c-0.1-0.1-0.2-0.1-0.2-0.1s0,0-0.1,0.2s-0.1,0.4-0.2,0.7c-0.1,0.2-0.4,0.7-0.7,1.1
			c-0.1,0.1-0.1,0.2-0.5,0.3c-0.1,0-0.2,0-0.3-0.1c0,0.6-0.2,0.9-0.2,1.5v0.2l-0.1,0.1c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.7-0.1-1.5-0.5-1.7-1.2v-0.1c0-0.4,0.2-0.5,0.4-0.8c0.1-0.2,0.2-0.4,0.2-0.5c-0.1,0-0.1,0-0.3,0l-0.4,0.1v-0.4
			c0,0,0-0.1-0.2-0.1c-0.1,0.5-0.4,1.1-1.1,1.1c-0.1,0-0.2,0-0.4-0.1c-0.5-0.2-0.7-0.5-0.9-0.5l0,0c-0.1,0-0.4,0.2-0.7,0.2h-0.1
			h-0.3v-0.3v-0.1c0-0.3-0.2-0.5-0.5-0.7s-0.7-0.3-1.1-0.3c-0.1,0-0.1,0-0.2,0h-0.4v-0.4V391c0-0.4-0.2-0.5-0.6-0.6
			c-0.3,0-0.6,0.1-0.7,0.4c-0.2,0.2-0.3,0.6-0.4,1s-0.3,0.9-0.9,0.9c-0.3,0-0.6-0.2-0.9-0.5c-0.3-0.4-0.3-0.5-0.4-0.5c0,0,0,0-0.1,0
			s-0.4,0.1-0.7,0.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.7-0.4-0.7-0.8c0-0.2,0-0.4,0.1-0.6c0-0.2,0-0.3,0-0.4c0-0.2,0-0.3-0.2-0.4
			c-0.1-0.1-0.1-0.1-0.4-0.1c-0.2,0-0.4-0.2-0.6-0.5c-0.3,0-0.5,0-0.7-0.1s-0.4-0.3-0.4-0.6c0.1-0.5,0.4-0.9,0.6-1.3
			c-0.6,0.2-1.2,0.8-2.2,1c-0.5,0-0.8-0.4-1.2-0.6c-0.3-0.3-0.7-0.5-0.8-0.5c0,0,0,0-0.1,0.1c0,0.1,0.1,0.3,0.1,0.7
			c0,0.2-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.2-0.7,0.2c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.2-0.7-0.4-1-0.5s-0.8-0.4-0.9-1
			c-0.1-0.1-0.3-0.1-0.3-0.1v-0.1v0.1c0,0,0,0-0.1,0c0,0-0.2,0.2-0.4,0.3c-0.3,0.1-0.6,0.2-1,0.2c-0.1,0-0.2,0-0.4,0
			c-0.6-0.1-0.8-0.1-1.3-0.4c-0.3-0.2-0.8-0.3-1.2-0.6c-0.3-0.2-0.4-0.5-0.4-0.6c-0.1-0.1,0-0.1-0.3-0.2c-0.2-0.1-0.6-0.2-0.9-0.2
			c-0.1,0-0.2,0-0.2,0c-0.3,0.1-0.2,0.2-0.8,0.3c-0.2,0-0.4-0.1-0.7-0.3c-0.5-0.3-0.5-0.5-0.8-0.6l-0.3-0.1V384c0-0.6,0-0.5-0.4-1
			c-0.3-0.4-0.4-0.5-0.5-1c0-0.2-0.1-0.3-0.1-0.4c0,0-0.2,0-0.5-0.2s-0.7-0.6-0.9-0.8c0,0.1,0.1,0.3,0.1,0.6c0,0.1,0,0.4-0.2,0.5
			c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5,0-0.9-0.4-1.1-0.4c-0.5-0.2-1-0.6-1.3-0.6c-0.1,0-0.2,0-0.3,0.4l-0.1,0.3h-0.3c-0.1,0-0.1,0-0.1,0
			s-0.1,0-0.2,0.1l-0.2,0.1l-0.2-0.1c-1.2-0.6-1.7-1.7-2.5-2.5c-0.3-0.3-0.8-0.6-1.2-0.8c-0.2-0.1-0.3-0.2-0.3-0.2s-0.2,0.2-0.9,0.2
			h-0.3v-0.3c0-0.1,0-0.1,0-0.1c0-0.3,0-0.5-0.2-0.7l-0.1-0.1l0.1-0.1l0.1-0.2l1.3-35l-44-3.1l-7.1,84.7h-0.4
			c-1.7-0.2-3.4-0.3-5.1-0.5c-4.1-0.4-8.3-0.8-12.4-1.2c-5.1-0.5-10.2-1-15.3-1.5c-4.6-0.4-9.2-0.9-13.8-1.3c-2.7-0.2-5.4-0.7-8-0.8
			c-0.1,0-0.5-0.1-0.8-0.1c0,0.1,0.1,0.3,0.1,0.6v0.1c-0.1,0.5-0.4,0.8-0.4,1c0,0,0.2,0.2,0.2,0.5c0,0.1,0,0.1,0,0.2
			c0.2,0.2,0.5,0.5,0.6,0.8c0,0.1,0.1,0.2,0.1,0.3c0.2,0.1,0.4,0.4,0.5,0.6c0.2,0.3,0.3,0.4,0.6,0.5c0.4,0.2,0.9,0.1,1.2,0.4
			c0.6,0.6,1.1,1.7,1.3,2.5c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.5,0,0.8,0.3,1.2c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.3-0.1,0.2,0.3,0.3
			c0.4,0.1,0.6,0.5,1,0.7s0.9,0.2,1.3,0.6c0.3,0.3,0.4,0.5,0.5,0.9c0.2,0.6,0.8,0.3,1.1,0.8c0.1,0.3,0.1,0.6,0.3,0.8
			c0.1,0.2,0.3,0.2,0.4,0.4c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.4,0.1,0.6,0.2c0.5,0.2,0.7,0.7,1,1
			c0.3,0.2,0.8,0.3,0.9,0.8c0.1,0.4-0.1,0.8,0.2,1.2c0.2,0.3,0.6,0.5,0.8,0.9s0.3,0.6,0.7,0.9c0.3,0.2,0.7,0.4,0.9,0.7
			c0.1,0.2,0.2,0.4,0.1,0.6c0.1,0,0-0.1,0-0.1l0.1,0.5c0.3-0.2,1.1,0.6,1.5,0.7s0.9,0.2,0.9,0.7c0.8-0.2,1.3,0.4,1.8,0.9
			c0.2,0.2,0.5,0.3,0.6,0.6c0.2,0.4,0.2,0.5,0.7,0.6s0.5,0,0.4,0.5c-0.1,0.4,0.1,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.6,0.5
			c0.3,0.3,0.1,0.4,0,0.8c-0.1,0.5,0.3,0.8,0.5,1.2c0.1,0.4,0.1,0.7,0.1,1.1c0,0.6-0.1,1.2,0.3,1.7s0.9,1,1.3,1.5
			c0.4,0.6,0.4,1.5,0.5,2.2c0.1,0.5-0.1,0.9,0,1.3c0,0.2,0.1,0.4,0,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.4-0.1,0.4,0,0.8
			s-0.2,0.6-0.3,1c0.7,0.3,1,1.2,1.4,1.8c0.3,0.4,0.5,0.8,0.6,1.2c0.1,0.5,0.4,0.8,0.6,1.3s0,1,0.2,1.5c0.2,0.4,0.7,0.4,0.8,0.9
			c0.1,0,0.2,0,0.4,0c0,0.7,0.3,0.3,0.7,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.4,0.1,0.6,0.2c0.4,0.2,0.6,0.6,0.9,0.9
			c0.5,0.3,0.7,0.3,0.8,0.9c0.7,0.1,0.1,1.1,0.7,1.4c0.2,0.1,0.5,0.1,0.7,0.3c0.2,0.2,0.4,0.3,0.7,0.5c0.3,0.1,0.5,0.3,0.7,0.5
			c0.2,0.1,0.1,0.1,0.3,0.2c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.3,0.3,0.5,0.4s0.3,0.1,0.6,0.1c0.3,0.1,0.5,0.2,0.8,0.3
			c0.2,0.1,0.5,0.3,0.8,0.3l0.6,0.1c0.1,0.2,0.1,0.5,0.3,0.6c0.2,0.1,0.4,0.1,0.6,0.3c0.3,0.2,0.1,0.5,0.3,0.8
			c0.1,0.2,0.5,0.2,0.7,0.2c0.4,0,1.1,0,1.4,0.2c0.2,0.1,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.2,0.5,0.3c0.4,0.2,0.5,0.6,0.8,0.9
			s0.7,0.5,1,0.8c0.2,0.2,0.4,0.3,0.6,0.3c0.3-0.1,0.2-0.4,0.5-0.2c0.3,0.1,0.4,0.5,0.6,0.8c0.1,0.2,0.4,0.3,0.6,0.4
			c0.4,0.2,1,0.3,1.4,0.1c0.2-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.5-0.1,0.7-0.1c0.2-0.4,0.2-0.9,0.4-1.2c0.2-0.3,0.8-0.2,0.9-0.5
			c0.1-0.2-0.1-0.4,0-0.6s0.3-0.3,0.5-0.4c0.4-0.2,0.6-0.2,1.1-0.2c0.3,0,0.7-0.1,0.8-0.4c0.2-0.3,0-0.5-0.1-0.8
			c0-0.2,0.1-0.4,0-0.6c0-0.2-0.1-0.3-0.1-0.4c0-0.5,0.4-0.7,0.8-0.8c0.4-0.2,0.4-0.3,0.5-0.7c0.1-0.4,0.3-0.6,0.3-1
			c0.1-0.4-0.1-0.7,0-1.1c0.1-0.3,0.3-0.5,0.5-0.7c0.5-0.5,0.6-1.1,1.1-1.5c0.4-0.3,0.5-0.3,0.5-0.8c0-0.4-0.1-0.8,0.4-0.9
			c0.2-0.1,0.4,0.1,0.6,0.1c0.1,0,0.1-0.2,0.3-0.3c0.4-0.2,0.7,0.2,1.1,0c0.2-0.1,0.2-0.3,0.4-0.3c0.1,0,0.4,0,0.5,0
			c0.5,0,1,0.2,1.5,0.4l0.4-0.2c0.3-0.4,0.5-1.1,0.9-1.5c0.4-0.3,1.1-0.1,1.5,0.2c0.3,0.2,0.3,0.3,0.6,0.3c0.3,0,0.4,0.1,0.6,0.3
			c0.4,0.4,0.8,0.8,1.5,0.9c0.3,0,0.7,0.1,1,0s0.5-0.5,0.8-0.6c0.2,0.2,0.4,0.4,0.7,0.6c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0.1,0.5,0.2
			c0.3,0.1,0.6-0.1,0.9,0c0.1,0,0.3,0.1,0.5,0.1s0.3,0.1,0.5,0.1h0.6c0.1,0.2,2,0,2.5,0c0.2,0,0.5-0.1,0.7,0s0.2,0.5,0.4,0.6
			c0.4,0.2,0.9-0.3,1.3,0c0.2,0.1,0.3,0.9,0.4,1.1c0.2,0.4,0.5,0.8,0.8,1.2c0.5,0.9,1.1,0.9,1.1,2.1c0.3,0.1,0.9,0.2,1.1,0.4
			c0.4,0.3,0.3,0.6,0.6,0.9c0.3,0.3,0.7,0.3,1,0.6c0.2,0.2,0.3,0.6,0.5,0.8c0.6,0,1.6,0.8,2,1.2c0.2,0.3,0.3,0.7,0.5,0.9
			c0.3,0.3,0.6,0.3,0.9,0.5c0.7,0.5,0.3,1,0.5,1.7c0.3,0.9,0.8,0.8,1.4,1.2c1.2,0.8,0.3,2.4,0.9,3.5c0.3,0.6,0.7,1,1,1.6
			c0.1,0.2,0.3,0.2,0.3,0.3c0.1,0.1-0.1,0.3,0,0.5c0.1,0.3,0.5,0.5,0.5,0.6c0.2,0.5-0.2,1.2-0.1,1.7s0.8,0.8,0.9,1.2l1,1.2
			c0.4,0.5,0.5,0.8,0.5,1.4c0,0.1,0,0.3-0.1,0.4c0.8,0.7,0.8,1.4,0.9,2.6c0,0.3,0,0.7,0.1,1c0.2,0.5,0.1,0.3,0.5,0.6
			c0.4,0.2,0.5-0.1,0.7,0.4c0.2,0.3,0,0.6,0.3,0.9c0.3,0.4,1.1,0.7,1.6,0.3c0.3,0.3,1,1,1.1,1.4c0.2,0.6,0.2,0.7,0.7,1.2
			c0.6,0.6,0.4,0.7,0.6,1.5c0.1,0.4,0.4,0.7,0.6,1.1c0.3,0.7,0.3,0.6,0.8,1.1c0.5,0.3,0.5,0.8,1,1.1c0.4,0.3,0.8,0.2,1.1,0.7
			c0.3,0.5,0,0.8,0.5,1.1c0.5,0.3,1,0.1,1.5,0.5c0.5,0.5,0.3,0.7,0.3,1.3c0,0.8,0.1,0.5,0.7,0.8c1.7,0.8-0.4,3.3,0.3,4.5
			c0.3,0.6,0.8,1,1,1.7c0.2,1-0.5,1.1-0.5,1.9c0,0.5,0.7,1.4,1,1.9c0.3,0.6,0.8,1.1,1.2,1.6c0.2,0.3,0.6,0.6,0.7,0.9
			c0.2,0.3,0,0.6,0.1,0.9c0.2,0.7,0.9,0.6,1.1,1.3c0.1,0.4-0.1,0.8,0,1.2c0.1,0.3,0.3,0.6,0.3,0.9c0.1,0.4,0,0.7,0.1,1.1
			c0.2,0.4,0.6,0.6,0.7,1c0.3,0.6-0.1,1.3,0.2,1.8c0.4,0.7,1.1,0.3,1.7,0.6c0.3,0.1,0.5,0.4,0.9,0.5c0.3,0.1,0.7,0,1,0.1
			c0.5,0.1,1.6,0.7,2,1c0.6,0.5,0.4,1.1,1.3,1.1c0.3,0,0.6-0.3,0.9-0.1s0.1,0.8,0.2,1.1c0.3,0.6,0.9,0.4,1.5,0.3
			c0.8-0.1,1.2,0.1,2,0.4c0.5,0.2,1.2,0.3,1.6,0.7c0.6,0.6,0.6,1.1,1.5,1.3c1.2,0.3,2.2,0.5,3.5,0.6c0.5,0,1-0.1,1.6-0.1
			c0.7,0,0.9,0.3,1.5,0.5c0.6,0.3,1.1,0,1.8,0.1c0.5,0.1,1.1,0.3,1.5,0.5c0.5,0.3,0.6,0.7,0.9,1.1c0.3,0.5,0.7,0.6,1.2,1
			c0.4,0.3,0.7,0.7,1.2,1c0.7,0.4,0.7,0.2,1.3-0.1c0.5-0.3,0.8-0.3,1.4-0.3c0.7,0,1.2-0.3,1.9-0.5l0.5-0.5c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.5-0.1-1.1-0.2-1.6l0.1-0.5c-0.6-0.2-1.3-0.4-1.7-0.7c-0.5-0.4-0.6-1.2-0.8-1.7c-0.2-0.4-0.2-0.4-0.2-0.8c0-0.4,0-0.7-0.3-1
			s-0.4-0.3-0.5-0.7c-0.1-0.3-0.1-0.6-0.2-1c-0.9,0.1-0.7-0.6-0.6-1.2c0.1-0.6-0.1-1.2-0.2-1.8s0.1-1.3-0.2-1.8
			c-0.3-0.6-0.5-0.6-0.4-1.3s0.1-0.7-0.4-1.1c-0.4-0.2-0.9-0.7-1-1.1c-0.1-0.5,0.4-1.1,0.2-1.6c-0.2-0.4-1.1-1-0.2-1.4
			c0.5-0.2,1,0.1,1.4-0.4s-0.1-1.1,0-1.7c0.5-0.1,1.1-1.3,0.3-1.4c0.1-0.4,0.3-0.6,0.1-1c-0.4,0-0.4,0.2-0.7,0.4
			c-0.5,0.3-0.3,0.1-0.8-0.1c-0.6-0.2-1-0.2-1.5-0.6c-0.4-0.3-0.2-0.3-0.7-0.3c-0.4,0-0.6,0.1-0.9,0.3c-0.6,0.2-1.2,0.2-1.9,0.2
			c-0.5,0-1.1,0.5-1.7,0.3c0.3-0.5,1.2-0.3,1.7-0.3c0.8-1.2,2-0.8,3.2-0.8c-0.1-0.3-0.5-0.6-0.6-0.9c-0.2-0.5-0.3-0.5-0.7-0.8
			c-0.6-0.5-1.1-1.8-1.2-2.6c0.3,0.4,0.6,1,1,1.3c0.2,0.2,0.5,0.1,0.7,0.3s0.2,0.5,0.4,0.6c0.1,0.2,0.4,0.2,0.5,0.3
			c0.2,0.3,0.1,0.4,0.2,0.7c0.2,0.5,0.8,0.6,0.9,1.2c0.2-0.4,0.6-0.5,0.9-0.8s0.5-0.8,0.9-1.1c1.1-0.9,0.1,1.3,0.2,1.7
			c0.3,0,0.3-0.3,0.6-0.3c0.3-0.1,0.4,0.2,0.6-0.1c0.2-0.2,0.3-0.9,0.4-1.1c0.1-0.4,0.1-0.9,0.2-1.3c0.2-0.6,0.6-1.2,0.9-1.7
			l0.5-0.4c-0.1-0.9,0.8-1.4,0.7-2.2c-1-0.1-1-0.4-1.6-1.1c-0.3-0.4-1.2-0.9-1.7-1c-0.3-0.1-1.8,0.1-1.1-0.8c0.3-0.4,1.2,0.1,1.7,0
			c0.9-0.2,2.4-1.2,3-0.1c0.2,0.3,0.1,1.6,0.7,1c0.2-0.2,0.2-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.6-0.5,0.7-0.8,1.1-1.6
			c0.3-0.6,0.7-1,0.7-1.8c-0.3-0.1-0.5-0.1-0.8,0.1c-0.2,0.1-0.4,0.6-0.5,0.7c-0.5,0.2-0.3-0.3-0.6-0.5c-0.2-0.1-0.6,0-0.9,0
			c-0.2-0.9,1.2-0.9,1.6-1.3c0.5-0.4,0.9-1.2,1.6-1c0,0.6-0.3,1.5,0.7,1.2c0.1-0.3,0.2-1.7,0.4-1.9c0.5-0.4,0.6,1.2,0.2,1.6
			c0.4,0.4,0.8-0.3,1-0.6c0.2-0.4,0.7-0.6,0.9-1c0.2-0.4,0-0.8,0.3-1.3s0.2-0.7,0.3-1.3c0.1-0.5,0.2-0.7,0.7-0.8
			c0.7-0.1,0.6,0.3,0.8,0.8c0.2,0.5,0.3,0.4,0.9,0.3c0.4-0.1,0.7-0.2,1.1-0.4c0.8-0.4,1.6-0.7,2.3-1c0.1-0.5,0-0.5-0.2-0.8
			c-0.3-0.4-0.3-0.5-0.5-1c-0.8,0.1-0.5-0.1-0.7-0.7c-0.8,0.2-0.6,0.1-1.1-0.5c-0.2-0.2-0.4-0.3-0.5-0.5s-0.1-0.5-0.3-0.7
			c-0.2-0.3-0.6-0.3-0.5-0.7c0.1-0.3,0.4-0.2,0.6-0.4c0.4-0.3,0.3-0.8,0.6-1.3c0,0.3,0,0.5,0.1,0.7c0.8-0.1,0.1,1.2,0.1,1.5
			c0.3,0,0.6,0.2,0.7,0.5c0.4,0,0.6-0.5,1-0.4c0,0.3,0,0.6,0.1,0.9c0.5-0.7,0.8-1,0.9-2c0.6,0.4,0.3,0.9,0.4,1.5
			c0.1,0,0.3,0,0.4-0.1c0.1-0.5,0.9-0.4,1.2-0.5c0.5-0.2,0.9-1.1,1.5-1.1c-0.3,0.3-0.1,0.6-0.2,1c-0.2,0.4-0.8,0.4-0.7,0.9
			c0.2,0,0.2,0.1,0.3,0.2c-0.2,0.2-0.4,0.5-0.5,0.8c0.7,0,0.9-0.1,1.4-0.6c0.5-0.4,1.1-0.2,1.5-0.7h0.4c0.5-0.1,0.9-0.2,1.3-0.5
			s0.6-0.6,1-0.7s0.8,0,1.2-0.2c0.5-0.2,0.8-0.5,1.2-0.8c0.3-0.1,0.8-0.4,1.1-0.3c0.4,0.1,0.4,0.7,0.9,0.6c0.3,0,0.6-0.5,0.9-0.7
			c0.5-0.3,1-0.8,1.5-1c0.9-0.4,1.7-0.8,2.4-1.4c0.3-0.2,0.5-0.3,0.8-0.5c0.3-0.3,0.4-0.6,0.7-0.9c0.4-0.4,0.6-0.4,0.7-0.9
			c0.1-0.4,0-0.8,0.2-1.2c0.2-0.4,0.8-0.5,0.9-0.8c0.2-0.3-0.2-0.7-0.1-1c0.2-0.7,0.5-0.5,0.9-0.4s0.7,0,1.1,0
			c0.8,0,1.5,0.7,1.6-0.4c0.2,0.4,1.8-0.2,2.1-0.5c0.9-1.1-0.9-0.2-1.3-0.3c-0.3,0-0.8-0.2-0.5-0.5s0.8,0.1,1.1-0.1
			c0.4-0.3,0-0.5-0.1-0.8c-0.1-0.1-0.2-0.1-0.3-0.3c-0.1-0.1,0.1-0.3,0-0.5c-0.3-0.7-1.3,0-1.1-1.2c0.1-0.3,0.3-0.6,0.1-0.9
			c-0.2-0.2-0.7-0.1-1-0.2c-0.9-0.5,0.3-1.3,0-2c-0.2-0.4-0.6-0.4-0.8-0.8c-0.1-0.3,0.1-0.6-0.1-1c-0.2-0.3-0.5-0.3-0.6-0.8
			c-0.1-0.3,0-0.6-0.2-1c-0.3-0.6-0.6-1-0.7-1.8c-0.2,0-0.5,0-0.7-0.1c0-0.3,0.2-0.5,0.3-0.8c0-0.3,0-0.6,0.1-0.9
			c0.1-0.2,0.3-0.4,0.3-0.7c0-0.2-0.2-0.5-0.3-0.7c0.7,0.6,0.3,1,0.2,1.8c-0.2,0.9,0.8,0.6,0.9,1.5c0.1,0.4-0.1,0.7-0.1,1
			s0.2,0.4,0.3,0.7c0.2,0.6,0.3,1.2,0.7,1.7c0.5,0.5,1.1,0.2,1.6,0.7c0.2,0.2,0.2,0.6,0.6,0.2c0.2-0.2,0.2-0.5,0.3-0.8
			c0.3-0.7,0.6-1.4,1.4-1.2c0.6,0.2,1.1,0.8,0.9,1.5c-0.1,0.4-0.3,0.4-0.1,0.8c0.2,0.4,0.5,0.1,0.2,0.7c-0.2,0.5-1.4,1.2-1,1.7
			c0.7-0.1,1.5,0,2.1-0.3c0.4-0.2,0.6-0.6,1.1-0.5s0.4,0.4,0.8,0.6c0.2,0.1,1.9,0.1,0.7,0.7c-0.4,0.2-0.5-0.1-0.8-0.1
			c-0.4,0-0.4,0.2-0.7,0.4c-0.6,0.5-1.1,0.2-1.7,0.5c0.5,0.5-1,1.4-1.2,1.9c0.9-0.5,1.8-1.1,2.7-1.7c0.9-0.6,1.9-0.8,2.7-1.4
			c0.5-0.3,0.9-0.5,1.4-0.6s0.7-0.4,1.2-0.6c1.2-0.6,2.3-1.1,3.6-1.5c0.4-0.2,1.1-0.5,1.5-0.4c0.2,0,0.5,0.2,0.7,0.2
			c0.1,0,0.1,0,0.2,0c0-0.4-0.1-0.8-0.3-1.2C441,468.6,440.7,468.3,440.7,467.9z"/>
									<path fill="#DDDDDC" d="M239.9,420.7l-0.4-0.2l0.2-0.3c0-0.1,0.1-0.1,0.1-0.1s0,0,0-0.1c-0.1-0.1-0.2-0.3-0.2-0.5c0,0,0,0,0-0.1
			c0.1-0.5,0.4-0.8,0.4-1c0-0.1-0.1-0.3-0.2-0.7c0-0.1,0-0.3,0.1-0.4c0.2-0.2,0.3-0.2,0.4-0.2h0.1c0.3,0,0.9,0.1,0.9,0.1
			c2.7,0.1,5.4,0.5,8.1,0.8c4.6,0.4,9.2,0.9,13.8,1.3c5.1,0.5,10.2,1,15.3,1.5c4.1,0.4,8.3,0.8,12.4,1.2c1.6,0.1,3.2,0.3,4.8,0.4
			l7.1-84.6h0.6l0.6-8c-18.8-1.6-53.1-4.9-88.5-9.5l-14.9,104.4c0.2,0,0.5,0,0.7,0.1c1.6,0.2,2.8,0.7,4.5,0.7c0.8,0,1.3,0.2,2.2,0.5
			c0.4,0.1,5.6,0.8,5.6,0.8l1.2-8.2c0-0.1,8.2,0.9,8.8,1.1c0.6,0.1,1.2,0.5,1.8,0.5c0.7,0.1,1.3-0.1,2-0.1c1.3,0,2.6,0.2,3.8,0.6
			c1.9,0.5,4.2,0.4,6.2,0.6c0.9,0.1,1.8,0.2,2.7,0.3C240.3,421.1,240.1,420.8,239.9,420.7z"/>
									<path fill="#DDDDDC" d="M638.4,434.3c-0.9,0.1-2.1-0.2-3-0.5c-0.8-0.3-1.9-0.2-2.9-0.5c0,0.2,0,0.5,0,0.7v0.2l-0.2,0.1
			c-0.5,0.2-1,0.3-1.5,0.4c0,0.1,0.3,0.2,0.5,0.8v0.1c0,0.5-0.2,0.9-0.2,1l0,0c0.1,0.4,0.2,0.4,0.5,0.5c0.2,0.1,0.7,0.4,0.7,1
			c0,0.2,0,0.4-0.1,0.6c0,0.2-0.1,0.9-0.1,1.5c-0.1,0.7,0,1.2-0.6,1.6c-0.1,0.1-0.2,0.1-0.4,0.1c-0.6-0.1-0.8-0.5-0.9-0.5
			c-0.1,0-0.4,0.3-1,0.3c-0.1,0-0.2,0-0.3,0l-0.3-0.1v-0.3c0-0.1,0-0.1,0-0.1c0-0.6-0.1-0.4-0.4-1c-0.2-0.3-0.2-0.3-0.2-0.3
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.5,0.2-0.6c0,0,0,0-0.1,0c-0.2-0.2-0.7-0.3-1.3-0.3
			c-0.7,0-1.6,0.2-2.2,0.2c-1.4,0.1-2.9,0.2-4.3,0.3c-7.3,0.4-14.6,1-21.9,1.5c-4.3,0.3-8.7,0.6-13,0.9H585l-0.1-0.1
			c-0.7-0.9-1-1.5-1.4-2.6c-0.3-0.7-0.8-1.4-1.3-2.1c-4.4,0.4-8.3,0.8-12.7,1.3c-7.3,0.7-14.6,1.6-22,2.2c-1.4,0.1-2.7,0.2-4,0.3
			c-1.4,0.2-2.1,0.1-2.3,1.7c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.2s0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.4
			c0.3,0.1,0.8,0,1.4,0.4c0.7,0.5,0.8,1.1,0.9,1.6c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.6,0.4,0.9c0,0.3-0.1,0.7-0.1,1.1
			c0,0.1,0,0.2,0,0.4c0,0.5,0.1,0.9,0.2,1.3c0,0.1,0,0.3,0,0.4s-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.4-0.6,0.8c0,0.2,0.2,0.3,0.1,0.7
			c-0.1,0.3-0.4,0.4-0.4,0.7c0.9-0.2,1.4-0.2,2.2-0.8c0.6-0.5,0.9-0.8,1.3-1.4c0.3-0.5,0.2-0.2,0.7-0.4c0.4-0.2,0.4-0.5,0.3-0.9
			c-0.2-0.6-0.9-0.8-1.2-1.4c-0.4-0.9-1.1-1.8-1.3-2.7c0.3,0.4,0.7,0.7,1,1.1c0.3,0.3,0.4,0.8,0.7,1.1c0.3,0.3,0.8,0.4,1.1,0.7
			c0.3,0.3,0.3,0.8,0.8,0.8c0-0.1,0.1-0.3,0-0.5c0.3,0.1,0.4,0.3,0.6,0.6c0.8-0.5,0.4-1.9,1.6-2.2c0.1,0.4-0.3,0.5-0.5,0.8
			c-0.1,0.1-0.3,0.6-0.3,0.7c-0.2,1.1,0.7,0.3,1.2,0.5c0,1.1,1.6,0.9,2.4,0.8c0.5-0.1,1-0.2,1.5-0.2c0.6,0,1.1,0.3,1.7,0.1
			c-0.2-0.6,0.5-0.5,0.8-0.8c-0.2-0.4,0.2-0.8,0.5-0.9c0.7-0.3,0.6,0.2,1,0.4c0.3,0.1,0.7-0.1,1-0.1c0.4,0,0.8,0.2,1.1,0
			c0.5-0.2,1.2-1.2,1.9-0.5c0.2,0.2,0.2,0.8,0.4,1c0.2,0.3,0.6,0.3,0.7,0.7c-0.4,0.1-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.1-0.7-0.1
			c-0.5,0-1.1-0.1-1.5,0.4c0.2,0.2,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.6,0.2,1.4,0,2,0.2s1,0.7,1.7,0.9
			c0.6,0.2,1.4,0.4,1.8,0.8l0.5,0.3c0.4,0.2,0.9,0.6,1.3,0.7c0.4,0.1,0.3,0.1,0.6-0.1c0.2-0.1,0.6-0.6,0.7-0.8
			c0.2-0.7-0.6-1-1.1-0.9c-0.3,0.1-2,0.1-1.4-0.7c0.6-0.2,1.5-0.2,1.9,0.3c0.5-0.3,1-0.7,1.6-0.5c0,0,0,0.1-0.1,0.1
			c-0.8-0.2-1.2,0.7-0.5,1.2c0.5,0.4,1,0.6,1.7,0.7c-0.2,0,0.8,0.5,0.8,0.5c0.3,0.3,0.4,0.9,0.8,1c0.2,0.1,0.4-0.3,0.6-0.1
			c0.1,0.1,0.2,0.5,0.2,0.7c0.2-0.1,0.4-0.1,0.4,0.2c-0.5,0.1-0.6-0.2-1-0.3c-0.4,0-0.6,0.3-1-0.1c-0.3-0.3-0.3-0.6-0.7-0.8
			c-0.2-0.1-0.9-0.2-1.1-0.2c-0.4,0.1,0.6,0.9,0.7,0.9c0.5,0.3,1,0.8,1.5,1c0.7,0.3,1.1,0.4,1.6,1c0.8,0.9,1.7,1.8,2.4,2.8
			c0.3,0.5-0.3,1.2,0.4,1.5c0.8,0.2,0.8-0.8,1.6-0.8c-0.1,0.4-0.4,0.5-0.3,0.9c0.4,0.1,0.7,0.2,1.1,0.4c0-0.1,0-0.2,0-0.3
			c-0.2,0-0.3-0.1-0.5-0.1c0.2-0.6,0.8-0.6,1.2-0.6c0.6,0,0.8-0.1,1.3-0.3c0.4-0.2,0.8-0.1,1-0.7c0.2-0.5,0-1,0.4-1.4
			c0.7-0.5,0.4,0.3,0.3,0.7c-0.2,0.5-0.4,1.1,0.4,0.8c0.8-0.3,1.6-1.2,2.2-1.7c0.5-0.4,1-0.6,1.4-1s1.4-1.4,2-1.5
			c0.2,0,0.5,0.2,0.8,0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0.2,0.3,0.3c1,0.4,1-0.9,0.4-1.3c-0.5-0.3-1.8-0.3-0.7-1.1
			c0.4-0.3,0.6-0.3,0.9-0.8c0.4-0.6,0.6-0.7,1.2-1c0.7-0.5,0.6,0,1.1,0.4c0.4,0.4,0.8-0.2,1.4-0.5c0.2-0.1,0.5-0.1,0.7-0.3
			c0.3-0.1,0.4-0.5,0.7-0.6c0,0.3,0,0.9,0.2,1.1s0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.3,0.4,0.4c0.4,0.3,0.6,0.1,1.1,0.2
			c0.8,0.1,2,0.5,2.6,1.1c0.1,0.1,0.2,0.4,0.4,0.5c0.3,0.2,0.5,0,0.8,0.1c0.9,0.2,0.7,0.9,1.1,1.5c0.4,0.6,1.1,1.7,1.7,2
			c0.9,0.3,1.7-0.3,1.7,1.1c0.1,1,0,1.4,0.9,1.9c0,0.6,0.2,0.8,0.7,1.1c0.5,0.3,0.4,0,1-0.1c0.2,0,0.8,0.1,1,0.3
			c0.2,0.2,0,0.6,0.2,0.7c0.3,0.4,0.6-0.3,0.9,0.2c0.2,0.3-0.1,1,0.2,1.4c0.3,0.2,0.7-0.2,1.1,0c0.4,0.2,0.3,0.6,0.5,0.9
			c0.1,0.2,0.4,1,0.6,1.1c0.6,0.4,1.8-0.5,2.5-0.2c0,0,0.8,0.3,0.9,0.1c0.8,0.2,0.6,1.6,1.7,1.6c0.1,0.3,0.2,0.6,0.3,0.9
			c0.5,1.3,1.8,1.7,2,3.2c0.2,1.4-0.3,2.6,0.3,4s0,2.7-0.4,4c-0.2,0.7,0,1.5-0.1,2.2c-0.1,0.6-0.5,1-0.6,1.6c-0.2,0.6,0,1.3,0.1,1.9
			c0.1,0.5-0.1,1-0.1,1.5c-0.1,0.7,0.1,1.3,0.2,1.9c0.1,0.6-0.1,1.3,0.2,1.8c0.4,0.7,1,0.3,1.5,0.5c0.4,0.2,0.8,1.5,1.2,1.4
			s0.2-1.6,0.2-1.9c-0.1-0.7-0.3-1.2-0.7-1.8c-0.4-0.5-0.8-0.8-0.7-1.5c0-0.8,0.4-0.9,1-0.6c1.4,0.5,2.2,0.8,2.3,2.5
			c0.8-0.5,0.3-1.1,0.6-1.8c0.1-0.3,0.7-0.6,1-0.4c0.2,0.2,0,0.8,0.1,1.1c0.2,0.4,0.4,0.5,0.4,0.9c0,0.2,0.1,0.6,0.1,0.8
			c0,0.6-0.8,0.8-1.2,1.1c0.1,0.3,0.3,0.7,0.2,1c-0.2,0.3-0.6,0.2-0.9,0.3c-0.5,0.4-0.4,1.5-0.5,2c-0.8-0.2-0.3,1-0.3,1.4
			c-0.4,0.1-0.7-0.2-1-0.1c-0.4,1.4,1.7,1.3,2.1,2.3c0.3,0.7,0.5,1.2,0.9,1.9c0.3,0.5,0.6,1.1,0.7,1.7l0.5,0.4l0.1,0.5
			c-0.1-0.1,0.2,0.6,0.6,1.2c0.4,0.5,1,0.5,1.3,0.9c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.3,0.6,0.5,0.8,0.8c0.4,0.3,0.6,0.8,1,1.2
			c0.6-0.3,1.7,0.7,1.7-0.5c0-0.7-0.8-1-1.1-1.4c-0.5-0.6-0.2-1.3,0.7-1.3c0.2,0,0.8,0.2,0.9,0.3c0.4,0.2,0.4,0.6,0.6,0.9
			c0.3-0.3,0.8-0.2,1-0.6c0.4-0.5-0.1-1-0.1-1.5c0-0.3,0.1-0.5,0.1-0.7s0-0.5,0.1-0.7c0.1-0.4,0.6-0.8,0.9-1c0.1,0.6-0.1,0.7-0.5,1
			s-0.4,0.3-0.3,0.9c0.2,0.8,0.5,1.2,0.1,1.9c-0.4,0.7-0.3,1-0.3,1.9c0,0.7-0.3,1.5-0.1,2.1c0.1,0.5,0.3,0.6,0.2,1.1
			c-0.1,0.4-0.3,0.6-0.2,1c0.6,0.1,0.7,1.5,1,1.9c1-0.3,1.5-1,1.8-2c0.2-0.6,0.8-2.2,1.8-2c0.3,0.1,0,0.2-0.2,0.3
			c-0.3,0.3-0.7,0.4-0.9,0.8s-0.2,0.8-0.3,1.1c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-0.9,1.7,0.2,1.7c0.4,0,0.8-0.4,1.2-0.2
			s0.4,0.8,0.4,1.1c0.1,0.9,0.6,1.4,1,2.2s1,1.4,0.9,2.4c0,0.5-0.2,1,0.1,1.4c0.3,0.3,0.8,0.2,1.1,0.5c0.3,0.4,0,0.9,0,1.3
			c0,0.6,0.3,0.9,0.8,1.2c-0.1-0.4,0.3-0.5,0.6-0.5c0,0,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.3-0.1s0.3,0,0.4,0c0.2,0,0.2,0.1,0.4,0.2
			c0.4,0.1,0.8,0.1,1.2,0.2c0.6,0.1,0.8,0.4,1.4,0.2c0.1,0,0.1-0.3,0.3-0.3c0.1,0,0.2,0.2,0.3,0.2c0.5,0,0.5-0.3,0.8,0.1
			c0.4,0.4,0.2,1.1,0.5,1.6c0.6,0.9,0.6,0.3,0.9-0.3c0.2-0.3,0.5-0.7,0.7-0.2c0.1,0.3-0.3,0.6-0.3,0.9c0,0.3,0.2,0.4,0.3,0.6
			c0.1,0.4,0,0.7,0.3,1c0.2,0.2,0.5,0.3,0.6,0.6c0.2,0.3,0.2,0.5,0.4,0.8c0.2,0.4,0.3,0.3,0.6,0.5c0.3,0.3,0.2,0.4,0.3,0.8
			c0.1,0.3,0.1,0.6,0.4,0.8c0.3,0.2,0.6-0.1,0.9,0c0.5,0.1,0.4,0.9,0.9,1.2c0.4-0.8,0.8-0.1,1.2,0.3c0.3,0.2,1.2,0.7,0.6,1.2
			c-0.3,0.2-1.3-0.2-1.6-0.3c-0.5-0.2-0.6-0.7-1.2-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c-0.5,0.9-0.3,1.5,0.1,2.4c0.3,0.7,0.6,1.6,1.5,1.5
			c0.8-0.1,1.4-0.2,2-0.6c0.6-0.4,1.2-0.9,2-0.7c0.9,0.2,1.1,0.5,1.8-0.2c0.3-0.3,0.4-0.4,0.8-0.3c0.3,0,0.7,0.3,1,0
			s0.1-0.8,0.4-1.1c0.4-0.4,0.5,0,0.9,0.1c0.5,0.1,1.3-0.4,1.6-0.9s0.4-1.1,0.7-1.7c0.2-0.4,0.3-0.6,0.3-1c0-0.7-0.6-1.3-0.7-2
			c-0.1-0.7,0.3-1.7,0.5-2.3c0.1-0.4,0.2-0.6,0.3-1c0-0.4-0.1-0.9,0.1-1.2c0.2,0.1,0.4,0.1,0.6,0.1c0.8-0.3,0.4-2.3,0.6-3
			c0.4-1.3,0-2.3,0-3.6c0-2.6,0-5.1-0.3-7.6c-0.1-1.2,0.1-2.4,0-3.5s-0.2-2.5-0.6-3.5c-0.4-1-1-2.1-1.5-3c-0.6-1-1.6-1.7-2.2-2.9
			c-0.6-1.2-1.6-2-2-3.3c-0.3-1-1-1.9-1.6-2.8c-0.5-0.8-0.9-1.7-1.4-2.4c-0.2-0.3-0.6-0.6-0.8-0.9c-0.3-0.4-0.6-0.9-0.9-1.4
			c-0.5-0.6-0.8-1.3-1.2-2c-0.5-0.7-1.2-1.3-1.7-1.9c-0.5-0.7-0.8-1.5-1.1-2.3c-0.3-0.9-0.7-1.7-1.1-2.5c-0.3-0.7-0.5-1.5-1-2.2
			s-1.2-1.3-1.4-2.1c-0.3-0.8-0.5-1.6-0.6-2.5c-0.1-0.8-0.3-1.6-0.4-2.5c-0.1-0.7-0.8-1.3-1.2-1.9c-0.5-0.6-0.9-1.1-1.2-1.8
			c-0.3-0.7-0.6-1.6-1.1-2.1c-1.1-1.1-1.9-2.7-2.5-4.1s-1.6-2.5-2.1-4c-0.3-0.8-0.4-1.7-0.8-2.4c-0.4-0.9-0.8-1.6-1.2-2.5
			c-0.2-0.4-0.4-0.8-0.5-1.3c0-0.4,0-0.8-0.1-1.2c-0.2-0.7-0.8-1.2-1.1-1.8c-0.3-0.6-0.2-1.2-0.8-1.7c-0.1-0.1-0.3,0.1-0.4,0
			c-0.2-0.1-0.1-0.3-0.2-0.4c-0.1-0.3,0-0.5-0.4-0.7c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0.3-0.1,0.2-0.6,0c-0.1,0-0.1-0.2-0.2-0.3
			c-0.1,0-0.1,0.2-0.2,0.2c-0.4,0.1-0.6,0.2-0.9,0.5c-0.5,0.6-0.1,0.7-0.1,1.3c-0.2,0.1-0.4,0.1-0.5,0.3c0.2,0.4,0.7,0.6,0.7,1.1
			c0,0.4-0.3,0.5-0.1,0.9c0,0.1,0.3,0.3,0.3,0.4c0,0.2-0.2,0.2-0.2,0.3c-0.1,0.6-0.2,0.4,0.4,0.6c-0.1,0,0.6,1.3,0.7,1.3
			c0.2,0.5,0.3,1.1,0.5,1.6c0.2,0.4,0.1,0.9,0.2,1.3c0.2,0.4,0.4,0.4,0.5,0.9c0,0.4-0.1,0.8,0.4,1c0.1,0.5-0.4,0.3-0.6,0.6
			c-0.2,0.3,0.1,0.8,0.4,0.9c0.2,0.6-0.3,0.7,0.1,1.3c0.4,0.4,0.5,0.6,0.7,1.1c0.2,0.4,0.5,0.7,0.8,1.1c0.2,0.2,0.5,0.4,0.5,0.7
			c0,0.4-0.2,0.5-0.5,0.1c-0.2,0.2-0.8-0.2-1-0.4c-0.3-0.3-0.4-0.8-0.7-1.1c-0.7-0.7-1.2,0.2-1.1,0.9c0.2,1,0.5,1.1,1.2,1.6
			c0.5,0.4,0.9,1.3,1.2,1.9c0.4,0.8,0,1.4,0.5,2c-0.2-0.1-0.5,0-0.7-0.2c-0.3-0.2-0.3-0.5-0.6-0.8c-0.7-0.5-0.9-0.8-1-1.7
			c-0.1-0.7-0.4-1-0.7-1.7c-0.5-1.2-0.8-2.4,0.7-3c-0.1-0.1-0.5-0.6-0.6-0.8c-0.1-0.4,0.2-0.6,0.1-1c-0.1-0.4-0.6-0.6-0.4-1
			c0.2-0.5,0.7-0.2,0.7-0.9c0-0.5-0.6-0.8-0.6-1.3c-0.1-0.6,0.3-0.5-0.1-1.1c-0.3-0.3-0.5-0.7-0.8-1.1s-0.5-0.4-0.9-0.6
			c-0.5-0.2-0.4-0.2-0.3-0.8c0.1-0.6-0.1-0.6-0.2-1.2c-0.1-0.8,0.4-1.6-0.1-2.4c-0.3-0.4-0.6-0.5-0.2-1.1c0.2-0.4,0.5-0.5,0.7-0.9
			c0.1-0.4,0.1-0.9,0.3-1.3c0.2-0.5,0.6-0.4,1.1-0.4c0.2,0,1.2,0.1,1.3-0.1c0.1-0.1-0.3-0.8-0.4-1c-0.1-0.5,0-0.9-0.1-1.4
			c0-0.1-0.2-0.4-0.3-0.5s-0.4-0.2-0.4-0.2c-0.5-0.6,0.2-0.3,0.4-0.7c0.2-0.3-0.5-0.5,0-0.8c-0.3-0.5-0.5-0.3-0.8-0.5
			c-0.1,0-0.2-0.1-0.2-0.1C638.6,434.3,638.5,434.3,638.4,434.3z"/>
									<path fill="#DDDDDC" d="M226.4,244.9l-10.6,74.6c33.1,4.3,65.3,7.4,84.7,9.2c9.9,0.9,16.4,1.4,18,1.5l4.5-75
			C300.5,254.9,255.7,249,226.4,244.9z"/>
									<polygon fill="#DDDDDC" points="580.1,427.1 580.1,427.1 580,427.1 		" />
									<path fill="#DDDDDC" d="M643.7,407.8c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.7,0.2c-0.3,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0-0.3-0.2
			c-0.1-0.1-0.1-0.2-0.1-0.2s-0.1,0-0.1-0.1c-0.4-0.3-0.6-0.3-0.9-0.8c-0.1-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.1,0-0.2,0
			c0,0-0.2,0-0.3-0.1c-0.2-0.2-0.4-0.3-0.4-0.7c0-0.2,0-0.3,0.1-0.5c0-0.3,0.1-0.6,0.1-0.8c0-0.3,0-0.5-0.2-1s-0.6-1.1-1-1.7
			c-0.4-0.7-0.4-1.2-0.9-1.6s-0.6-0.4-1-0.4c-0.2,0-0.4,0-0.8,0c-1.1-0.1-1.3-1.1-1.4-1.7c-0.1-0.7,0-1.5-0.2-1.9
			c-0.1-0.3-0.6-0.5-0.9-1.2c-0.1-0.3-0.1-0.6-0.2-0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.4-0.2-0.6c0-0.4,0.2-0.8,0.2-1.1
			c0-0.1,0-0.1,0-0.2c-0.1-0.4-0.2-0.1-1-0.5c-0.5-0.4-0.6-0.7-0.7-0.7c-0.5-0.2-1.3-0.4-2-0.8c-0.9-0.5-1.3-1-2.1-1h-0.3v-0.3
			c0-0.3,0-0.5,0-0.8c-1.1-0.2-1.6-1.4-2-1.7c-0.3-0.3-0.5-0.4-0.5-0.8c0.1-0.4,0.2-0.4,0.3-0.8c0-0.1,0.1-0.2,0.1-0.3
			c0,0,0-0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0c-0.9-0.1-1.3-0.8-1.5-1.4c-0.8-0.1-0.8-0.8-1-0.7c-0.1,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0
			c-0.4-0.1-0.4-0.2-0.5-0.2l0,0h-0.3l-0.1-0.3l0,0c-0.5-0.2-0.6-0.8-0.7-1.1c-0.2-0.5-0.2-0.7-0.4-0.6c0,0,0,0-0.1,0h-0.3l-0.1-0.3
			l-0.5-1c-0.3-0.6-1.7-1-2.6-1.4c-0.9-0.4-1.9-0.9-2.6-1.8c-0.6-0.7-0.5-1.1-1.1-1.3c-0.1-0.1-0.1,0-0.3-0.1
			c-0.2,0-0.5-0.2-0.6-0.4c-0.2-0.4-0.2-0.8-0.2-0.9c-0.1-0.4-0.3-0.7-0.6-1.1c-0.5-0.7-0.5-1.1-0.8-1.8c-0.2-0.4-0.6-0.9-1-1.2
			c-0.3-0.2-0.9-0.5-0.9-1.3l0,0c-0.1-0.1-0.2,0.4-1,0.6l0,0c0,0,0,0-0.1,0c-0.6-0.1-1-0.6-1.2-0.7c-0.3-0.2-0.7-0.3-1.1-0.5
			c-0.5-0.3-0.7-0.7-1-0.9c-0.5-0.5-1.3-1.2-1.8-1.4c-0.2-0.1-0.4,0-0.7-0.1c-0.4-0.1-0.6-0.5-0.5-0.9c0-0.5,0.3-1,0.3-1.3
			c0.1-0.7,0.4-0.8,0.6-1.1c0.2-0.2,0.1-0.7,0.5-1.2l0.1-0.1h0.2c0.8-0.1,1.4-0.8,1.5-1.3l0,0c-7.5,1.7-21.5,3.4-37.1,4.9
			c0.5,1.7,2.5,9.1,4.6,17c2.5,9,5.2,18.5,6.1,20.8l-0.3,0.1l0,0c0.4,1.1,1.1,2.1,1.5,3.4c0.3,0.9,1,2.3,1.7,3.1
			c0.1,0.2,1.1,0.2,1.2,1.2v0.1c0,0.5-0.4,0.8-0.6,1c-0.3,0.2-0.4,0.3-0.4,0.4h-0.1h0.1c0,0.1,0.1,0.2,0.4,0.4
			c0.3,0.1,0.7,0.3,1,0.8c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.3,0.1,0.5l0.2,0.3l-0.3,0.2
			c-0.5,0.4-1.5,1.5-1.5,1.9c0,0.2,0.3,0.5,0.4,1.2c0,0.1,0,0.2,0,0.3c0,0.7-0.3,1.3-0.4,1.8c-0.2,0.8-0.3,2-0.3,2.9
			c0,0.1,0,0.1,0,0.2c0,0.8,0.8,1,1,2.3c0,0.3,0.1,0.5,0.1,0.7c0,0.3,0,0.6-0.1,0.9c-0.1,0.4-0.3,0.6-0.3,0.8c0.1,0,0.4,0,0.5,0.4
			s0.2,0.9,0.2,1.3c0,0.3,0,0.6,0,0.9s0,0.6,0.1,0.9c0.1,0.5,0.4,0.8,0.4,1.5c0,0.1,0,0.1,0,0.2c0,0.5-0.2,0.8-0.2,0.9
			s0,0.2,0.1,0.3c0,0,0.6,0.4,0.9,0.8c0.3,0.5,0.4,1.1,0.5,1.4c0.4,0.8,1.2,1.6,1.6,2.6c0.4,0.9,0.6,1.5,1.2,2.2
			c4.3-0.3,8.5-0.6,12.8-0.9c7.3-0.5,14.6-1.1,21.9-1.5c1.4-0.1,2.9-0.2,4.3-0.3c0.5,0,1.4-0.2,2.2-0.2c0.6,0,1.3,0.1,1.8,0.5
			c0.2,0.2,0.3,0.3,0.3,0.6c0,0.3-0.2,0.4-0.3,0.5c0,0.1,0,0,0,0.1s0,0.2,0,0.3c0,0.1,0,0.1,0,0.1c0.1,0.1,0.2,0.2,0.3,0.5
			s0.4,0.5,0.5,1.1c0.2-0.1,0.4-0.2,0.9-0.3h0.1c0.6,0.2,0.8,0.6,0.8,0.5c0,0,0.1-0.1,0.1-0.2c0-0.2,0.1-0.5,0.1-0.8
			c0.1-0.6,0.1-1.3,0.1-1.6c0-0.2,0.1-0.4,0.1-0.5c0-0.3,0-0.2-0.3-0.4c-0.2-0.1-0.7-0.4-0.9-1c0-0.1,0-0.2,0-0.2
			c0-0.5,0.2-0.8,0.2-1c0,0,0-0.1,0,0c0.1-0.1-0.4-0.2-0.5-0.9c0-0.1,0-0.3,0.1-0.4l0.1-0.2h0.2c0.5-0.1,0.9-0.2,1.4-0.4
			c0-0.3,0-0.7,0-1v-0.5l0.5,0.2c0.9,0.4,2.1,0.2,3.4,0.6c0.5,0.2,1,0.2,1.4,0.3c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0.1,0.5,0.1,0.8,0.2
			c0.1,0,0.2,0,0.5,0.1c0.6-0.7-0.5-1.4-0.2-2.1c0.8,0.7,0.7-1.3,0.7-1.6c-0.7,0-0.3,1.2-0.9,1.2c-0.1-0.5,0.5-1,0.3-1.5
			s-1.2-0.3-1-0.9c0.9,0.5,1-0.4,0.6-1c-0.2-0.4-0.3-0.4-0.7-0.7c-0.3-0.1-0.6-0.3-0.7-0.6c-0.4-1.3,0.9,0,1.1,0
			c0.6-0.1-0.8-1.2,0-1.6c0.6-0.3,0.7,1.1,1,1.4c0.1-0.6,0.5-0.5,0.8-1c0.2-0.4,0.3-0.9,0.6-1.4s0.3-0.9-0.2-1.3s-0.9,0.1-1.4-0.2
			c0-1.1,0.9-0.5,1.5-0.6c0.1-0.5-0.1-0.6-0.3-1c-0.2-0.3-0.5-0.7-0.1-0.9c0.6,0.8,0.8,0.5,0.9-0.4c0.1-0.7,0.6-0.8,0.4-1.7
			c-0.5,0.1-0.7,0.4-1.3,0.3s-0.7-0.5-0.1-0.8c0.2-0.1,0.4,0.1,0.6-0.1c0.2-0.2-0.1-0.5,0.2-0.7c0.5-0.2,0.4,0.7,0.9,0.7
			c0.2-0.3,0.6-1.6,0.4-1.9c-0.4-0.6-1.1,0.4-1.6,0c0.1-0.4,0.5-0.1,0.8-0.3c-0.3-0.1-0.6-0.3-0.9-0.4c-0.4-0.1-0.8,0-0.9-0.6
			c0.5-0.1,0.8,0.2,1.2,0.2c0.3,0,0.6-0.4,0.7,0.3c0.3-0.2,0.3-0.4,0.4-0.7c0.2-0.4,0.2-0.4,0.5-0.6s0.4,0,0.5-0.4
			c0.1-0.3-0.1-0.6-0.1-0.9c-0.2,0-0.8,0.1-1-0.1s0-0.6,0.2-0.7c0.1,0,0.7,0,0.9,0c0.3,0,0.6-0.1,0.8,0.1c0.3-0.3,0.7-0.3,0.6-0.7
			c-0.1-0.3-0.6-0.4-0.7-0.7c-0.4-1,0.8-0.4,1.1-0.3c0.7,0.2,1-0.2,1.1-0.9c0.1-0.4,0-0.5-0.2-0.6c-0.1,0-0.3,0.1-0.3,0.1
			C644.8,408.3,644.3,407.9,643.7,407.8z"/>
									<path fill="#DDDDDC" d="M484.3,409C484.2,409,484.2,409,484.3,409C484.2,409,484.2,409,484.3,409L484.3,409z" />
									<path fill="#DDDDDC" d="M412.9,270.1L412.9,270.1c-0.1,0.1-0.1,0.1-0.2,0c-0.3-0.2-0.3-0.4-0.4-0.5l0,0l0.3-0.2h-0.4l0,0l0,0
			c0-0.1,0-0.3,0-0.4s0-0.1,0-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.2-0.6,0.2-0.6
			s0-0.2-0.1-0.4l0,0c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.1
			c-0.1-0.1-0.2-0.2-0.5-0.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0h-0.3l-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-0.8
			c-0.6-0.2-0.8-0.8-0.8-1.3c0-1.1,0.5-2.6,0.5-3.1l0,0c0-0.5-0.4-0.7-0.4-1.5c0-0.1,0-0.3,0.1-0.5c0.2-0.6,0.5-1.2,0.4-1.5
			c0-0.2,0-0.2-0.3-0.4c-0.1-0.1-1,0-1.4-0.6c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.6,0.4-0.5,0.4-0.7c0.2-0.3,0.3-0.5,0.3-0.6
			c0,0,0-0.1,0-0.2c0,0,0,0-0.1,0c-0.2,0-0.5-0.2-0.5-0.6c0-0.1,0-0.1,0-0.2c0.1-0.8,0.6-1.1,0.5-1.4c0-0.1,0-0.1,0-0.2
			c-0.1-0.3-0.1-0.2-0.3-0.5c-0.1-0.2-0.4-0.7-0.5-0.9l0,0c0,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7-0.3c-0.1-0.1-0.1-0.3-0.1-0.4
			c0-0.4,0.2-0.7,0.2-0.8s0-0.2,0-0.3s0-0.2,0-0.2l0,0c-0.1-0.1-0.4-0.2-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.7c0.1-0.8,0.9-1,0.9-1.3
			c0-0.1,0-0.1,0-0.2c0.1-0.3-0.6-0.8-0.7-1.7c0,0,0,0,0-0.1c0-0.3,0.1-0.6,0.1-0.8v-0.1c0-0.2-0.2-0.3-0.5-0.7
			c-0.4-0.9-0.1-1.5-0.5-1.9c-0.2-0.2-0.2-0.2-0.3-0.3c-0.2-0.1-0.3-0.3-0.3-0.7c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.6v-0.1
			c-0.1-0.5-0.1-0.4-0.4-0.5s-0.8-0.1-1.1-0.7c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.3,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.2
			c0-0.2-0.3-0.5-0.4-0.9c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.1c0.1-0.1-0.3,0-0.7-0.5
			c-0.1-0.2-0.1-0.3-0.1-0.5c0.1-0.8,0.7-1.3,0.6-1.6V230c0,0,0,0-0.1-0.1s-0.2-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.4-0.2l0,0
			c-0.4-0.2-1.6,0-2.3-0.5c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1l0,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0
			h-0.3v-0.3c0,0,0-0.1,0-0.2c0-0.4,0.1-0.6,0.1-0.8v-0.1c0.1-0.1-0.4-0.2-0.7-0.7c-0.7-0.1-0.9-0.5-1.3-0.6
			c-0.3-0.1-0.9-0.2-1.3-0.4c-0.5-0.2-0.2-0.1-0.5-0.1c0,0,0,0-0.1,0c0,0-0.2,0.2-0.7,0.2c-0.1,0-0.1,0-0.1,0
			c-0.4,0-0.8-0.5-0.7-0.8c0-0.2,0-0.3,0.1-0.5c-0.2,0-0.5,0.1-0.7,0.1c-0.1,0-0.3,0-0.4-0.1c-0.2-0.1-0.4-0.3-0.4-0.6
			s0.1-0.3,0.1-0.3s0,0,0-0.1c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.7,0.1-1.1,0.1v-0.2v0.2c-0.5,0-0.8-0.3-0.9-0.2c0,0,0,0-0.1,0
			c-0.1-0.1-0.2,0.5-0.9,0.6l0,0c-0.6-0.1-0.9-0.5-0.9-0.4l0,0l0,0c0,0.1-0.2,0.3-0.5,0.4c-0.1,0-0.2,0-0.3,0
			c-0.3,0-0.5-0.1-0.5-0.2c-0.1-0.1-0.1-0.1-0.2-0.1l0,0c-0.1,0-0.4,0.3-1,0.4c-0.1,0-0.1,0-0.1,0c-0.6-0.1-0.8-0.6-0.9-0.6
			c0,0-0.1,0-0.2,0.1c-0.2,0.2-0.2,0.4-0.3,0.7c0,0.4-0.5,0.8-1.2,0.8c-0.1,0-0.2,0-0.2,0c-0.6,0-1.2-0.1-1.7-0.5s-0.7-0.9-0.9-0.9
			l0,0c-0.1,0-0.3,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.3-0.3-0.6-0.3-0.8c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0-0.6-0.3-0.7-0.5
			s-0.2-0.4-0.4-0.6l-0.1-0.1c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.7-0.7-0.8-1.2c0-0.3,0-0.5-0.1-0.7
			c-5-0.1-62.1-0.8-74.6-3.9L296,252c10.6,1,20.2,1.7,27.3,1.7h0.4l-1.1,18.7l93.7,3.3c-0.3-0.1-0.6-0.3-0.9-0.6l-0.2-0.3l0.3-0.2
			c0.5-0.4,0.8-0.7,0.7-0.8c0,0,0-0.1-0.3-0.4c-0.4-0.3-1.2-0.5-1.2-1.6c0-0.1-0.1-0.2-0.1-0.5c0,0,0,0,0-0.1l0,0l0,0
			c0.1-0.2,0.2-0.3,0.2-0.4c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.2-0.5-0.3c-0.4-0.3-0.9-0.6-1.3-0.8l0.2-0.4L412.9,270.1L412.9,270.1z
			"/>
									<path fill="#DDDDDC" d="M41.7,342.4c0.3-0.7-0.6-0.7-1-0.6l0.1-0.3c-0.3,0-1.3-0.3-1.5-0.1c-0.4,0.3,0.5,0.9,0.6,1.2
			c0.1,0.2-0.1,0.4,0.1,0.7c0.2,0.2,0.6,0.2,0.8,0.2c0.6,0.1,1.1,0.1,1.7,0.1C42.4,342.9,42.5,342.5,41.7,342.4z"/>
									<path fill="#DDDDDC" d="M66.8,251.9l59.5,89.5c0.1-0.5,0.3-0.9,1-1.1c0.5-0.2,0.4-0.2,0.4-0.3c0-0.2-0.1-0.6-0.1-1.1
			c0,0,0-0.1,0,0c0-0.8,0.5-1.2,0.4-1.7c0-0.1,0-0.1,0-0.2c-0.3-1.4-0.5-2.9-0.5-4.3c0-0.1,0-0.2,0-0.3c0-0.8,0.3-1.5,0.3-2.1
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.9,0.6-1.3,0.5-1.8l0,0c0-0.1-0.2-0.3-0.2-0.7v-0.1c0.1-0.5,0.4-0.7,0.4-0.8
			c0-0.1,0-0.2,0-0.2c0-0.4-0.5-0.9-0.6-1.8v-0.1c0-0.6,0.4-1.4,0.6-2c0.3-0.9,1.2-1.3,1.9-1.3c0.1,0,0.1,0,0.2,0
			c0.8,0,1.4,0.3,2.1,0.5c0.6,0.2,1.1,0.2,1.9,0.4c0.5,0.1,0.9,0.5,1,0.9s0.1,0.7,0.1,1.1c0.1,0.6,0.3,0.7,0.6,0.7
			c0.1,0,0.3,0,0.5-0.1c0.3-0.1,0.9-1.2,1.3-1.6c0.4-0.5,0.7-0.9,1-1.4c0.3-0.4,0.6-0.8,0.5-1l0,0c0,0-0.2-0.2-0.2-0.5
			c0-0.1,0-0.1,0-0.2c0-1.1,0.7-2.2,0.9-3.1c0.2-1.3,0.3-2.6,0.7-3.9c0.4-1.6,0.8-3.4,1-5.1c0.3-1.5,0.7-3.3,1.2-5.8
			c0.4-2.1,0.8-4.2,1.2-6.4c0.5-2.3,1-4.7,1.4-7c0.5-2.5,1-4.9,1.5-7.4s1-5,1.5-7.6s1.1-5.2,1.6-7.7c0.5-2.6,1-5.1,1.5-7.7
			c0.5-2.5,1-5,1.5-7.4s1-4.8,1.4-7.2c0.4-2.2,0.9-4.4,1.3-6.6c0.4-2,0.8-4,1.2-6c0.3-1.8,0.7-3.5,1.1-5.3c0.3-1.4,0.6-2.9,0.9-4.3
			c0.2-1.1,0.4-2.2,0.7-3.3c0.1-0.7,0.3-1.4,0.4-2.1c0-0.2,0.1-0.4,0.1-0.6c-23.3-4.5-51.9-10.6-79.7-17.7L66.8,251.9z"/>
									<path fill="#DDDDDC" d="M319.2,330.3c9.7,0.6,36.7,1.2,61.4,1.6c23.2,0.4,44.4,0.6,47.4,0.6l-0.2-39l-0.4-0.5v-0.1
			c-0.2-0.5-0.3-0.5-0.5-0.6c-0.3-0.1-0.7-0.2-1.2-0.6l-0.2-0.2l0.2-0.3l0,0l0,0c-0.2,0-0.4,0.1-0.7,0.1c-0.3,0-0.7-0.2-0.9-0.6
			c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.3,0-0.4s0-0.1,0-0.1c0-0.2-0.4-0.4-0.7-0.6L423,289v-0.2c0.1-0.4,0.3-0.7,0.5-0.8
			c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0-0.2,0s-0.3,0-0.4-0.1c-0.6-0.2-0.8-0.6-1-0.7l-0.1-0.1v-0.2
			c-0.1-0.5-0.1-0.7-0.3-1.1c0-0.1-0.5-0.3-0.7-0.8c0-0.1,0-0.2,0-0.3c0-0.3,0.3-0.6,0.4-0.6c0.1-0.1,0.2-0.1,0.2-0.1
			c0.1-0.2,0.1-0.1,0.1-0.2c0,0,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0.1-0.6,0.5-0.9,0.8-1c0.3-0.2,0.5-0.3,0.6-0.5c0,0,0-0.1,0-0.3
			c0-0.1,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.2,0.5-0.2s0.2,0,0.3,0l0,0h0.1h-0.1c-0.2-0.1-0.5,0-0.7-0.4c0-0.1-0.1-0.2-0.1-0.2
			c0-0.1,0.1-0.2,0.1-0.3l-0.3,0.2l-0.1-0.7c0-0.2,0-0.4-0.1-0.5l-0.1,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.3,0.2-0.5,0.3-0.8,0.3
			c-0.6-0.1-0.7-0.6-0.9-0.9c-0.2-0.3-0.2-0.4-0.4-0.4c0,0,0,0-0.1,0l-0.4,0.1V278c0-0.1,0-0.1,0-0.1s-0.3,0-0.5-0.2
			c-0.1-0.1-0.3-0.3-0.4-0.4l-95.4-3.3L319.2,330.3z"/>
									<path fill="#DDDDDC" d="M59.2,365.6l-0.2,0.1c-0.1,0.9,0.4,1.8,0.8,2.5c0.3,0.5,0.7,1.5,1.2,1.9c0-0.1,0.1-0.3,0-0.4
			c0.1,0,0.2-0.1,0.3-0.1c-0.5-0.6-0.9-1.2-1.4-1.8C59.4,367,59.5,366.4,59.2,365.6z"/>
									<path fill="#DDDDDC" d="M46.7,343.2c-0.3-0.2-0.3-0.7-0.7-0.8c-0.4-0.2-0.7-0.2-1.1-0.1c-0.1-0.4-0.7-0.5-1-0.6
			c0.1,0.4,0.5,0.7,0.6,1c0.1,0.4-0.1,0.8,0.1,1.1c0.3,0.4,1.7,0.7,2.2,0.7c0.4,0,0.6-0.2,1-0.3c0.5-0.2,0.5-0.1,1-0.1
			c0.4,0,0.8,0.3,0.9-0.3C50,342.5,47.3,343.5,46.7,343.2z"/>
									<path fill="#DDDDDC" d="M506.8,329.3v0.2l-0.3-0.1c-0.5,0-0.9-0.2-1.1-0.7c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.3,0-0.5,0-0.6
			s0-0.1,0-0.2c0,0-0.1-0.1-0.3-0.1c-0.2,0-0.6-0.1-0.8-0.5c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.4,0.2-0.7,0.2-0.8c0-0.1,0-0.1,0-0.1
			c-0.1-0.1-0.1-0.1-0.1-0.1l0,0c0,0-0.1,0-0.2,0s-0.3,0-0.5-0.1c-0.4-0.2-0.7-0.5-0.7-0.8c0.1-0.8,0.9-1.3,1-1.6
			c0.1-0.2,0.2-0.5,0.2-0.7c0-0.4-0.2-0.7-0.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.4-0.4-0.7v-0.1c0.1-0.7,0.6-0.9,0.5-1l0,0
			c0.1-0.1-0.5-0.1-0.8-0.7c0-0.1-0.1-0.2-0.1-0.3c0-0.4,0.2-0.6,0.1-0.8c0-0.1,0-0.1,0-0.1c-0.1-0.1-0.1,0-0.1,0
			c-0.1,0-0.3,0.1-0.7,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.6-0.4-0.5-0.7c0-0.2,0-0.3,0-0.4v-0.1c-0.1-0.5-1.2-1.1-1.6-1.1
			c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0c-0.1,0-0.3-0.1-0.4-0.2s-0.2-0.2-0.3-0.4c-0.3-0.4-0.3-0.4-0.3-0.4s0,0-0.1,0s-0.2,0-0.4,0
			c-0.3,0-0.3,0.1-0.6,0.1c-0.4,0-0.6-0.3-0.7-0.6c-0.4-0.7-0.2-0.7-0.7-1c-0.3-0.2-0.5-0.5-0.7-0.6c-0.2-0.2-0.5-0.1-1.1-0.3
			c-0.8-0.3-1.7-1-2-1.8c-0.3-0.6-0.3-0.9-0.6-1.2c-0.2-0.2-0.6-0.4-0.9-0.8s-0.5-0.8-0.5-1.3c0-0.6,0.3-1.2,0.6-1.7
			c0.4-0.5,0.8-0.8,0.8-1v-0.1c0-0.2-0.2-0.5-0.3-1c0-0.1,0-0.2,0-0.3c0.2-0.7,0.9-1,1-1.3c0.1-0.1,0.1-0.2,0.2-0.2
			c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0-0.3c0-0.6,0.3-0.7,0.3-1c0-0.1,0-0.1,0-0.2s0-0.2,0-0.4c0-0.2,0-0.4,0.2-0.7
			c0.3-0.6,0.9-1.3,0.8-1.7c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5-0.1
			c-0.5-0.2-0.7-0.6-1-0.7c-0.4-0.2-0.9-0.4-1.3-0.4c-0.1,0-0.2,0-0.4,0c-0.3,0-0.4,0-0.5,0.1c0,0-0.2,0.1-0.2,0.2v0.1v0.1
			c0,0.1-0.2,0.3-0.3,0.3l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0.3-0.3,0.7-0.6,0.7c-0.8-0.1-1.4-1.1-1.6-1.2
			c-0.4-0.4-0.5-0.7-0.6-1.2c-0.1-0.3-0.1-0.5-0.1-0.6c0,0,0.1,0-0.2-0.1H484v-0.3c0-0.3,0.1-0.6,0.1-0.9c-0.1-0.3,0-0.2-0.2-0.3
			h-0.4v-0.4c0-0.2,0-0.4,0-0.6c0-1-0.2-2.2-0.6-2.7c-0.2-0.2-0.1-0.1-0.3-0.1c-0.1,0-0.4,0-0.7-0.2c-0.6-0.5-0.4-1.2-0.5-1.1
			c-0.1-0.2-0.6-0.2-1.1-0.4c-0.5-0.4-0.8-0.8-1.1-0.9c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.4,0.1c-0.3,0-0.5-0.2-0.7-0.5
			c-0.2-0.4-0.3-0.8-0.3-1.1c-0.1,0-0.2,0-0.3,0c-0.9,0-1.1-0.8-1.5-1.2c-0.5-0.6-1.3-0.6-1.7-1.8c-0.2-0.8-0.2-1.6-0.6-2
			c-0.1-0.2-0.9-0.2-1.1-1.1c0-0.1,0-0.1,0-0.1c0.1-0.7,0.7-0.9,0.5-0.9c0-0.1,0-0.1,0-0.1c0.1-0.3-0.7-0.9-0.9-1.7
			c-0.2-0.7-0.4-1.5-0.5-2.2c0-0.1,0-0.2,0-0.3c0-0.9,0.5-1.5,0.5-2.1c0,0,0,0,0-0.1c0-0.4-0.2-0.7-0.2-1.2c0-0.2,0.1-0.4,0.2-0.6
			c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.4-0.1-0.8-0.6c-0.3-0.5-0.1-0.9-0.2-0.8l0,0c0,0-0.1,0-0.2,0s-0.3,0-0.6-0.2
			c-0.3-0.2-0.3-0.6-0.3-0.7c0-0.2,0-0.2,0-0.2s0,0-0.1,0s-0.2,0-0.3,0c-0.1,0-0.4,0-0.6-0.2c-0.3-0.5-0.2-0.9-0.3-1
			c-0.1-0.2-0.3-0.2-0.7-0.2c-0.9,0-2.2,0.5-3.1,0.6c-1.9,0.1-3.7,0.4-5.7,0.4c-4.8,0.2-9.6,0.5-14.4,0.6c-5,0.1-10.1,0.2-15.1,0.3
			c-4,0-8,0-12,0.1c-0.1,0-0.2,0-0.3,0c-1.3,0-2.7,0-4,0c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.4-0.2,0.5-0.3,0.9c-0.1,0.2-0.1,0.3-0.1,0.4l0,0c0,0,0.1,0,0.2,0l0.4-0.1v0.4c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3l0,0v0.1
			v0.1c0.2,0.1,0.4,0.4,0.4,0.6c0.3,0.2,0.8,0.5,1.2,0.8c0.1,0.2,0.7,0.2,1,0.9v0.1c0,0.3-0.2,0.4-0.3,0.5c0,0.1,0.1,0.2,0.1,0.4
			c0.1,0.8,0.1,0.5,0.9,1c0.4,0.3,0.6,0.6,0.6,0.9c0,0.5-0.4,0.8-0.7,1.1c0.4,0.2,1,0.2,1.6,0.6c0.5,0.3,0.7,0.8,0.8,0.9
			c0.1,0,0.1,0.1,0.4,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.9,0.2,1,1.3,1.1,1.3l0,0c0,0,0.1,0,0.3-0.2c0.5-0.3,0.7-0.8,1.3-0.9
			c0.3,0,0.5,0.2,0.6,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0.1l0.2,0.1v0.2l0,0c0,0.5-0.3,0.7-0.5,0.8h0.1
			c0.2,0.1,0.5,0,0.8,0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.4-0.3,0.5-0.5,0.5c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0
			c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.3c-0.3,0.5-0.7,0.7-1,0.8c-0.3,0.2-0.4,0.2-0.4,0.5c0,0.1,0,0.1,0,0.2
			c0,0.2,0,0.2,0,0.4c0,0.2-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.5,0.3v0.1c0,0,0.3,0.3,0.6,0.6c0.3,0.4,0.3,0.8,0.4,1.2
			c0.3,0.2,0.5,0.5,0.7,0.5c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0s0.3,0,0.5,0.1c0.4,0.2,0.7,0.2,0.7,0.7c0,0.4-0.3,0.5-0.4,0.6
			s-0.1,0.1-0.1,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0,0.4s0,0.1,0,0.2c0.2,0.2,0.1,0.2,0.3,0.2
			c0.1,0,0.4-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0.1l0.3,0.2l-0.2,0.3l0,0v0.1v0.1c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.8,0.4,0.9,1l0.5,0.6
			l0.2,39.6l0.2,9.3l0,0c2.1-0.1,15.5-0.5,29.7-1c15.5-0.6,32.1-1.2,36.1-1.5h0.2l0.1,0.2l0.1,0.1c0.2,0.4,0.3,0.7,0.3,1
			c0,0.2,0,0.4-0.1,0.6c0.3,0.1,0.5,0.3,0.6,0.6c0.1,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.5
			c0,0.1,0,0.1,0,0.2c0,0.2-0.2,0.4-0.3,0.4l0,0c-0.2,0.4-0.3,0.5-0.3,0.8v0.4l-0.4-0.1H495c-0.1-0.1-0.2,0.4-0.5,1
			c-0.2,0.3-0.5,0.4-0.6,0.7c-0.1,0,0.1,0.3-0.3,0.8c-0.2,0.2-0.4,0.1-0.5,0.1s-0.1,0-0.1,0h-0.1c0,0,0,0,0,0.2c0,0.1,0,0.3-0.1,0.5
			c-0.1,0.3-0.3,0.5-0.3,0.7l0,0l9.2-0.6c0-0.6,0.4-0.8,0.9-1.3c0.3-0.3,0.3-0.4,0.3-0.5s-0.1-0.3-0.3-0.6c-0.1-0.3-0.7-0.9-0.8-1.6
			c0-0.3,0.2-0.7,0.6-0.8c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.7,0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.6-0.4-0.6-1.1
			c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.2,0s0.1,0,0.1,0c-0.1-0.1-0.2-0.2-0.2-0.4V342c0-0.5,0.4-0.8,0.5-0.8c0-0.1-0.1-0.1-0.2-0.3
			c-0.2-0.2-0.8-0.8-0.9-1.5c0-0.3,0.3-0.7,0.6-0.7c0.1,0,0.2,0,0.2,0c0.6,0,0.8,0.6,1,0.9c0,0.2,0.1,0.3,0.1,0.5
			c0.6-0.5,0.9-1.1,1-1.8c0.1-0.7,0.1-1.3,0.8-1.4c0.5,0.1,0.7,0.4,1,0.7c0.1,0.1,0.3,0.3,0.4,0.3c0.3-0.8,0.9-0.9,1-1.4
			c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4-0.6-0.7-0.7-1.6c0-0.1,0-0.1,0-0.2l0.1-0.3h0.3l0,0c0.4,0,0.3-0.1,0.3-0.2c0,0,0-0.1,0-0.2
			c-0.4-0.2-0.6-0.4-0.6-0.8c0-0.6,0.5-0.9,0.6-1.3c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.4-0.6c-0.1-0.1-0.3-0.3-0.5-0.5
			c0,0-0.1,0-0.1-0.1C507.6,329.7,507.2,329.4,506.8,329.3z"/>
									<path fill="#DDDDDC" d="M302.5,178.3v0.4l0,0l-3.2,36.3c6.1,1.6,24.4,2.6,41.3,3.2c17.5,0.6,33.5,0.8,33.5,0.8h0.3v0.3
			c0,0.2,0,0.6,0.1,1s0.2,0.6,0.2,0.6h0.1c0.1,0-0.1-0.1,0.5-0.2c0.3,0,0.4,0.2,0.6,0.4c0.4,0.4,0.5,0.8,0.6,0.9
			c0.1,0,0.3-0.1,0.7-0.2l0.4-0.2l0.1,0.4c0.1,0.3,0.1,0.5,0.1,0.7s0,0.3,0,0.3s0,0,0.1,0s0.3-0.1,0.6-0.1c0.1,0,0.1,0,0.2,0
			c0.7,0.2,0.9,0.9,1.1,1c0.3,0.2,0.9,0.3,1.4,0.3c0.1,0,0.1,0,0.2,0c0.6-0.1,0.4-0.1,0.5-0.3s0.1-0.7,0.6-1.1
			c0.2-0.2,0.4-0.3,0.7-0.3c0.4,0,0.6,0.3,0.7,0.4c0.1,0.1,0.2,0.2,0.3,0.2l0,0c0.1,0.1,0.4-0.3,1-0.4h0.1c0.3,0,0.5,0.2,0.5,0.2
			s0,0,0.1,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1s0.3-0.4,0.7-0.3c0.1,0,0.1,0,0.1,0l0,0c0.6,0.1,0.8,0.5,0.9,0.4l0,0
			c0.1,0.1,0.1-0.2,0.7-0.6c0.1,0,0.2-0.1,0.3-0.1c0.5,0,0.7,0.3,0.9,0.3h0.1c0.2,0,0.6-0.1,1.1-0.1c0.4,0,0.9,0.1,1.2,0.7
			c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3l0,0l0,0c0,0,0,0,0.1,0c0.2,0,0.6-0.1,1-0.2h0.1h0.3v0.3c0,0.1,0,0.1,0,0.2
			c0,0.3-0.1,0.6-0.1,0.7l0,0c0,0,0,0,0.1,0l0,0c0,0,0.2-0.2,0.6-0.2h0.1c0.4,0,0.5,0.1,0.7,0.2c0.3,0.1,0.9,0.2,1.4,0.4
			c0.7,0.3,0.7,0.6,1.1,0.6h0.1h0.3l0.1,0.3c0,0.3,0.4,0.2,0.8,0.8c0.1,0.2,0.1,0.3,0.1,0.4c0,0.2,0,0.4-0.1,0.5
			c0.3,0,0.7,0,1.1,0.3c0.1,0.2,0.2,0.4,0.2,0.5s0,0.2,0,0.3s0,0.1,0.1,0.2c0.1,0.1,0.7,0.2,1.2,0.2l-0.1-0.1
			c-0.6-0.5-0.4-1-0.5-1.3c-0.1-0.5-0.1-0.3-0.4-0.8c-0.1-0.3-0.2-0.5-0.2-0.7s0-0.4,0-0.6l0,0c0-0.4-0.1-0.4-0.3-0.9
			c-0.1-0.3-0.2-0.5-0.2-0.8c0.1-0.7,0.5-0.8,0.6-1.3c0.1-0.3,0.1-0.7,0.2-1.2c0.2-0.5,0.1-0.4,0.2-0.9c0-0.5,0-0.9,0.4-1.3
			c0.5-0.4,0.6-0.2,0.7-0.5c0.1-0.1,0.2-0.4,0.1-0.6c0-0.1,0-0.1,0-0.1c0-0.2-0.2,0-0.8-0.4c-0.2-0.2-0.3-0.3-0.3-0.6
			c0-0.4,0.1-0.3,0.2-0.6c0-0.2,0.1-0.3,0.1-0.3l0,0c0,0-0.1-0.1-0.2-0.3s-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.4-0.2-0.6
			c0-0.1,0-0.2,0-0.3c0.1-0.5,0.3-0.6,0.3-0.8l0,0c0,0-0.1-0.2-0.1-0.6c0-0.1,0-0.1,0-0.2l0,0c0.1-0.2,0.2-0.4,0.3-0.4
			c0,0,0,0,0-0.1c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.2,0.1-0.5,0.2-0.6c0.2-0.2,0.4-0.3,0.7-0.3
			c0.4,0,0.8,0,1.2,0l0.6-33.3l-0.2-0.5l-2.9-1.7v-0.1l-0.4-0.8l-0.7-1.2l-1.6-1.6l0.2-0.3l0.4-0.5c0-0.2,0-0.5,0.1-0.9
			s0.5-0.7,0.7-0.7c0.3-0.1,0.6-0.2,1-0.3c0.6-0.3,1-0.6,1.1-0.7l0.4-1.9c-5.1-0.3-28.7-1.5-51.3-2.6c-21.7-1.1-42.5-2.1-45.3-2.3
			L302.5,178.3L302.5,178.3L302.5,178.3z"/>
									<path fill="#DDDDDC" d="M481.4,151.5c0.2,0,0.3,0,0.4,0c0.4,0.1,0.6,0.5,0.6,0.5c0.6,0.3,1.2,0.3,1.6,1.3c0.2,0.5,0.4,1.1,0.6,1.6
			l13.6,3l3.3,1.8l0,0c0.5,0,0.7,0.3,0.9,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.5-0.3l0.5-0.3l0.1,0.6c0,0.2,0.1,0.3,0.1,0.4
			c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.3c0.2-0.2,0.5-0.5,0.9-0.5h0.1c0.4,0,0.8,0.3,0.8,0.5c0.1,0.2,0.2,0.3,0.2,0.3l0,0
			c0,0,0.1-0.1,0.5-0.1h0.1c0.4,0.1,0.5,0.3,0.5,0.3h0.1c0.1,0,0.3,0,0.5,0h0.1c0.3,0,0.5,0.1,0.7,0.1c0.4,0.1,1.2,0,1.9,0.4
			c0.3,0.2,0.5,0.5,0.5,0.8s-0.1,0.5-0.1,0.7s-0.1,0.4-0.1,0.5s-0.1,0.1,0.3,0.3c0.1,0,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.1,0l0,0
			c0.1,0,0.3-0.1,0.7-0.2h0.1c0.6,0.1,0.9,0.5,1,1.1c0.5,0,0.8,0.2,1.2,0.5c0.4,0.4,0.4,0.5,0.9,0.6l0.3,0.1v0.3
			c0,0.4-0.3,0.7-0.4,0.9c0.2,0.1,0.3,0.3,0.3,0.6c-0.1,0.5-0.4,0.8-0.8,0.9c0.1,0.1,0.4,0.2,0.5,0.5c0,0.1,0,0.1,0,0.2
			c-0.1,0.6-0.4,0.9-0.4,1l0,0c0,0.2,0.2,0.8,0.3,1c0,0,0,0,0,0.1c0.1,0,0.1-0.1,0.3-0.1c0.3-0.2,0.4-0.3,0.8-0.6
			c0.2-0.1,0.1-0.3,0.6-0.4c0.4,0,0.4,0.2,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.7c0,0.8-0.7,1.5-0.7,1.9c0,0.1,0,0.2,0,0.3
			c0,0.2,0.1,0.5,0.2,0.9c0,0.4,0,0.7,0.1,0.7c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0l0.4-0.1l0.1,0.4c0,0.2,0.3,0.4,0.5,0.5l0,0
			c0-0.7,0.4-1.2,0.7-1.8c0.2-0.3,0.2-0.7,0.3-1c0.2-0.4,0.5-0.5,0.7-0.8c0.9-1.2,0.8-3.1,1.7-4.3c0.4-0.6,0.7-1.1,0.9-1.9
			c0.1-0.4,0-0.7,0.3-1c0.2-0.2,0.6-0.2,0.9-0.5c0.4-0.4,0.2-0.3,0.2-0.8c0-0.4,0.3-0.4,0.5-0.8c0.2-0.5-0.5-1.6,0.3-1.9
			c0.1,0.5,0.5,0.9,0.6,1.4s-0.2,1.4,0.2,1.8c0.1-0.4,0.4-0.3,0.7-0.5c0.2-0.1,0.4-0.5,0.7-0.5c0.2-0.5,0.6-0.9,0.8-1.3
			c0.2-0.4-0.3-1.2,0.3-1.3c0.3-0.1,0.5,0.3,0.8,0.3s0.5-0.3,0.6-0.4c0.4-0.4,0.7-1.1,1-0.2c0.2-0.1,0.6-0.1,0.7,0.1
			c-0.3,0.4-0.1,0.6-0.3,1c-0.2,0.5-0.5,0.1-0.8,0.3c-0.2,0.2,0,0.3,0,0.5c-0.1,0.2-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.7-0.7,0.7
			c-0.1,0.2-0.4,0.5-0.4,0.7s0.3,0.3,0.3,0.5s-0.2,0.4-0.2,0.6c0,0.1,0.2,0.6,0.2,0.8c0.2-0.2,0.4-0.7,0.7-0.4
			c0.1-0.2,0.5-0.2,0.6-0.4s-0.1-0.5-0.1-0.7c0.1-0.3,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.3-0.6c0.4-0.4,0.9-0.1,1.2-0.7
			c0.1-0.2-0.1-0.5,0.2-0.7c0.4-0.2,0.7,0.3,1.1,0c0.3-0.3,0.3-1.1,0.3-1.5c0.1-0.9,0.1-1.1,0.8-1.5c1-0.6,1.6-0.5,2.7-0.1
			c0.6,0.2,0.9,0.4,1.4-0.1c0.3-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0.5-0.3,0.8-0.2,1.3-0.2c0.7-0.1,0.8-0.4,1.2-0.9
			c0.7-0.9,0.9-1.7,2.2-1.8c0-0.1,0-0.1,0-0.2c0.8-0.3,1.7,0.1,2.5,0.3c1.3,0.4,2.8,0.9,3.9,1.6c0.5,0.3,0.9,0.5,1.3,0.9
			c0.3,0.3,0.5,0.2,0.9,0.4c0.3,0.1,0.5,0.5,0.9,0.4c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.3-0.1-0.4-0.2-0.7c-0.1-0.4,0-1.1,0.1-1.5
			c0.1-0.8,0.3-1,1-0.9c0.3,0.1,0.4,0,0.7,0.2c0.2,0.2,0.2,0.5,0.6,0.5c0-0.2,0-0.4,0-0.5c0.3-0.1,0.8,0.1,1,0.3
			c0.3,0.3,0.2,1.1,0.7,0.8c0-0.8,1-0.5,1.5-0.6s0.9-0.5,1.5-0.6s0.9,0.2,1.4,0.4c0.6,0.1,0.9-0.2,1.4-0.4c0.6-0.2,0.7,0,1.2,0.1
			s1,0.2,1.5,0.2c0.9,0.1,0.4-0.5,0.9-0.8s1,0.4,1.3,0.6c0.5,0.3,0.7-0.1,0.7-0.7c0-0.6-0.3-0.7-0.9-0.8c0-0.5-0.2-0.9-0.4-1.4
			c-0.4,0-1.4-0.3-1.7-0.1c-0.7,0.5,0.5,0.6,0.2,1.1c-0.2,0.4-0.9,0.6-1.3,0.7c-0.2,0.1-0.7,0.3-0.9,0.1c-0.3-0.1-0.1-0.5-0.5-0.5
			c-0.2,0.6-0.9-0.3-1.1-0.4c-0.4-0.3-0.8-0.5-1.1-0.9c-0.3-0.3-0.4-0.8-0.9-0.9c-0.4-0.1-0.3,0.2-0.6,0.3h-0.6
			c-0.7-0.1-0.5-0.4-0.1-0.9c0.6-0.7,0-0.6,0.2-1.3c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0.1,0.4,0,0.6,0c0-0.2,0.1-0.8-0.1-1
			c-0.2-0.2-0.6,0.2-0.8,0c-0.5-0.4,0.5-0.3,0.5-0.4c0.3-0.4-0.4-0.3-0.6-0.3c0-0.4,0.5-0.4,0.3-0.9c-0.2,0-0.6,0.2-0.8,0
			s0.1-0.4,0.3-0.3c0.3-0.2,0.8-0.5,0.9-0.9c0.1-0.4-0.2-0.6-0.2-1c-0.3-0.3-0.9-0.1-1.2,0.2c-0.2,0.2-0.2,0.6-0.5,0.5
			c-0.2,0-0.3-0.3-0.3-0.6c-0.7-0.1-1.4,0.4-1.5,1c-0.7-0.1-1,0.6-1.6,0.8c-0.7,0.2-1.1-0.5-1.1-1.1c-0.7,0.2-1,0.6-1.6,1
			c-0.5,0.4-0.8,0.2-1.3,0c-0.4-0.1-1-0.2-1.5-0.3c-0.8-0.1-0.9,0.1-0.9-0.8c-0.2-0.1-0.4-0.1-0.6-0.1c0-0.3,0.5-0.5,0.6-0.9
			c0.1-0.3-0.1-0.7-0.1-1.1c0-0.4,0-0.7,0.1-1.1c0.1-0.3,0.4-0.7,0.3-1c-0.5-0.1-0.9,0.2-1.4,0.1c-0.6-0.1-0.9-0.2-1.4,0.1
			c-0.8,0.5-1.5,1-2.5,1.3c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1.1,0.4-1.6,0.4c-0.4,0.1-0.9-0.1-1.3,0c-0.6,0.2-0.9,0.4-1.6,0.4
			c-0.5,0-0.9-0.1-1.3,0c-0.6,0.2-1.1,0.5-1.7,0.5c-0.6,0-0.6-0.1-0.9,0.4c-0.3,0.4-0.8,0.7-1.1,1c-0.4,0.3-0.9,0.6-1.3,0.9
			c-0.5,0.2-1.1,0.4-1.5,0.6c-0.3,0.2-0.5,0.7-0.7,1c-0.1,0.2-0.4,1-0.6,1.1c-0.7,0.3-0.3-0.8-0.3-1.1s-0.1-1.3-0.3-1.4
			c-0.5-0.3-0.6,0.5-0.5,0.8c0.2,0.5,0.7,0.7,0.1,1.3c-0.4-0.8-1-0.1-1.4,0.2c-0.2,0.2-0.3,0.3-0.7,0.1c-0.2-0.1-0.4-0.5-0.7-0.6
			c0,0.1,0,0.2,0,0.3c-0.6,0.2-0.4-0.3-0.6-0.6c-0.3-0.4-0.4,0-0.8-0.2c-0.3-0.1-0.2-0.6-0.6-0.3c0,0,0,0.4-0.1,0.4
			c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1.2,0.5-1.9,0.5c-0.6,0-1.8,0-1.7-0.8c0-0.3,0.4-0.7,0.2-1c-0.2-0.5-0.3,0-0.7,0
			c-0.2-0.3-0.3-0.5-0.6-0.7c-0.2-0.1-0.4-0.1-0.6-0.2c-0.3-0.2-0.1-0.3-0.3-0.6c-0.2-0.4-0.1-0.1-0.5-0.4c-0.4-0.3-0.5-0.9-0.9-1.2
			c-0.3-0.2-0.3-0.1-0.5-0.4c-0.1-0.2,0-0.4-0.3-0.7c-0.3,0.1-0.5,0.5-0.8,0.3s0-0.6-0.2-0.9c-0.4-0.6-1.6,0.3-1.8-0.3
			c-0.4-0.1-0.9-0.2-1.4-0.1c-0.5,0-1,0.4-1.3-0.2c-0.3,0.1-0.6,0.4-1,0.3c0-0.4,0.1-0.7,0.1-1.1c-0.5-0.2-0.6,0.6-0.8,0.9
			c-0.1,0.1-1.6,1.6-1.7,1.3c-0.1-0.4-0.4-0.5-0.5-0.1l0.1,0.5c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.1-0.4,0.6-0.4,0.7c0-0.3,0.1-0.7,0-1
			s-0.4-0.5-0.5-0.7c-0.1-0.6,0.4-1.1,0.2-1.8c-0.1-0.5-0.6-0.9-0.6-1.3c0-1.1,0.7,0.1,0.9,0.3c-0.1-0.6,0.3-0.7,0.6-1.1
			c0.3-0.4,0.4-0.8,0.6-1.2c0.1-0.3,0.3-0.9,0.5-1.1c0.3-0.3,0.6-0.3,1-0.6c0.8-0.6,1.2-1.6,1.9-2.1c0.3-0.2,0.9-0.4,1-0.7
			c0.1-0.5-0.5-0.6-0.1-1s2-0.2,2.6-0.2c-0.1-0.6-0.7-0.6-1.2-0.7c-0.8-0.3-1.5-0.3-2.4,0c-0.8,0.3-1.6,0.4-2.5,0.6
			c-0.7,0.2-1.5,0.4-2.1,0.7c-0.9,0.3-0.8,1.1-1.3,1.9c-0.4,0.7-1,1.1-1.6,1.6c-0.7,0.7-0.8,1.1-1.7,1.5c-0.7,0.3-1.2,0.7-1.6,1.4
			c-0.3,0.6-0.3,1.5-1,1.7c-1,0.3-1.1-0.2-1.8,0.7c-0.4,0.5-0.9,1.1-1.4,1.5c-0.5,0.5-1.2,0.6-1.9,0.7c-1,0.2-1.7,0.3-2.7,0.6
			c-0.8,0.3-1.5,0.4-2.3,0.6c-1.1,0.3-1.4,1-1.9,1.9c-0.9,1.7-2.5,1.7-4.2,2.2c-0.5,0.2-0.6,0.1-0.9,0.6c-0.1,0.1-0.1,0.3-0.2,0.4
			c0,0,0,0,0.1,0C481.1,151.5,481.3,151.5,481.4,151.5z"/>
									<path fill="#DDDDDC" d="M63.4,360c0.9-0.2,0.1-0.9-0.3-1.1c-0.3-0.2-0.5-0.3-0.7-0.5c-0.2-0.3,0-0.6-0.5-0.8h-0.4
			c-0.3-0.1-0.6-0.2-0.8,0c0.2,0.6,1.1,0.5,1.4,1c0.4,0.6-0.2,0.8,0,1.4c0.2,0.5,1.9,1.3,2.3,1.3C64.5,360.6,63.7,360.4,63.4,360z"
									/>
									<path fill="#DDDDDC" d="M428.1,342.3l-0.2-9c-6.1-0.1-91.1-1-109.2-2.2h-0.4V331c-1.4-0.1-6.2-0.5-13.5-1.1l-0.6,8l44.2,3.1
			l-1.3,35.8v0.1v0.1c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0,0.2-0.1,0.5-0.2c0.2,0,0.4,0.1,0.7,0.3c0.4,0.3,0.9,0.6,1.3,1
			c0.8,0.8,1.3,1.7,2.1,2.3c0.1,0,0.1-0.1,0.2-0.1c0.2-0.4,0.6-0.7,0.9-0.7c0.7,0,1.2,0.5,1.6,0.7c0.2,0.1,0.5,0.3,0.7,0.4
			c0-0.1-0.1-0.3-0.1-0.6c0-0.1,0-0.3,0.2-0.5c0.1-0.2,0.3-0.2,0.5-0.2l0,0c0.4,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.5,0.4,0.5,0.5
			c0.2,0.1,0.3,0.1,0.5,0.2c0.3,0.2,0.3,0.4,0.4,0.8c0.2,0.7,0,0.2,0.4,0.8c0.3,0.4,0.5,0.7,0.5,1.2c0.4,0.2,0.5,0.5,0.7,0.6
			c0.1,0.1,0.2,0.1,0.2,0.1s0,0,0.1,0c0.1-0.1,0.3-0.2,0.6-0.3c0.1,0,0.3-0.1,0.4-0.1c0.4,0,0.8,0.1,1.1,0.2
			c0.3,0.1,0.6,0.4,0.6,0.5c0.1,0.2,0.1,0.2,0.3,0.4c0.1,0.2,0.6,0.3,1,0.5c0.5,0.3,0.5,0.3,1,0.3c0.1,0,0.2,0,0.3,0
			c0.3,0,0.4-0.1,0.8-0.2l0,0c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0,0,0.1,0c0.3,0,0.6,0.2,0.8,0.2l0.2,0.1v0.3c0,0.4,0.1,0.3,0.6,0.6
			c0.4,0.2,0.7,0.4,1,0.5c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.2s-0.1-0.4-0.1-0.7
			c0-0.1,0-0.3,0.1-0.4c0.2-0.3,0.5-0.5,0.7-0.5c0.5,0,0.9,0.4,1.2,0.6c0.3,0.3,0.7,0.5,0.7,0.5l0,0c0.6,0,1.3-1,2.4-1
			c0.1,0,0.2,0,0.3,0l0.3,0.1v0.3c0,0,0,0,0,0.1c0,0.7-0.6,1.3-0.7,1.5c0.1,0,0.2,0,0.5,0h0.1h0.3l0.1,0.3c0.1,0.3,0.1,0.2,0.1,0.2
			c0.1,0,0.4,0,0.7,0.3c0.3,0.3,0.4,0.7,0.4,1c0,0.2,0,0.3,0,0.5c0,0.3-0.1,0.5-0.1,0.6c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0
			s0.4-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0.1c0.5,0.3,0.5,0.6,0.6,0.6c0.2,0.2,0.4,0.3,0.4,0.3l0,0c0-0.1,0.1-0.2,0.2-0.4
			c0.1-0.4,0.2-0.8,0.5-1.2c0.3-0.4,0.8-0.6,1.3-0.6s1.1,0.3,1.3,0.9c0.8,0,1.9,0.4,2.2,1.4c0.1,0,0.3-0.1,0.6-0.1c0,0,0.1,0,0.2,0
			c0.5,0.1,0.7,0.5,0.9,0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.2,0.5-0.8l0.1-0.3h0.3c0.3,0,0.8,0,1.1,0.4h0.1c0.2,0,0.4,0,0.5,0.2
			c0.2,0.1,0.2,0.3,0.2,0.5c0,0.6-0.6,1.2-0.6,1.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.2,0.5,0.3,0.7,0.3c0.1-0.5,0.2-0.9,0.2-1.2
			c0-0.2,0-0.4-0.1-0.6l-0.2-0.4l0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.5,0.1,0.7,0.3c0,0,0,0,0-0.1c0.2-0.2,0.4-0.5,0.4-0.6
			c0.1-0.3,0.1-1.2,1-1.3c0.2,0,0.3,0.1,0.5,0.1c0.7,0.3,0.6,0.9,0.7,1.2c0.1,0.3,0.3,0.6,0.4,0.7c0-0.1,0.1-0.2,0.1-0.4l0.1-0.2
			h0.3c0.1,0,0.1,0,0.2,0c0.9,0.1,1.1,0.8,1.4,1.2c0.1-0.1,0.3-0.3,0.3-0.5V392l0.2-0.1l0,0c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.2-0.5,0.7-0.6c0.4,0,0.5,0.3,0.7,0.6c0.3,0.7,0.1,1.2,0.4,1.3c0.3,0.2,0.5,0,1.3,0.3c0.5,0.2,0.7,0.4,0.9,0.4
			c0.1,0,0.1,0,0.2-0.1l0.4-0.2v0.5c0,0.2,0.2,0.6,0.4,0.8c0.2,0.3,0.4,0.4,0.5,0.4c0,0,0,0,0.1-0.2s0.1-0.4,0.2-0.7
			c0.1-0.3,0.5-0.6,0.9-0.6c0.1,0,0.2,0,0.2,0c0.3,0,0.5,0.1,0.6,0.2c0.1,0,0.1,0.1,0.1,0.1s0-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.2
			s-0.1-0.1-0.1-0.5c0-0.3,0.2-0.5,0.5-0.7s0.5-0.2,0.8-0.2c0.4,0,0.7,0.1,1,0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.8-0.2l0,0
			c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.4-0.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.4,0.1c0.6,0,1.1,0.2,1.4,0.3
			c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.3l-0.1-0.3l0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.5,0,0.6,0.2,1.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1
			c0.1,0,0.1,0.1,0.2-0.3l0.1-0.3h0.2c0.1,0,0.2,0,0.3,0c1,0,1.4,0.7,2,0.7c0.4,0,0.8,0.1,1.1,0.1h0.1c0.1,0,0.7-0.1,0.9-0.1
			c0.1-0.1,0.1-0.3,0.2-0.6c0.1-0.3,0.1-0.8,0.7-0.9c0.3,0,0.5,0.1,0.7,0.3c0.3,0.2,0.4,0.5,0.5,0.6s0,0.1,0.3,0.2
			c0.1,0,0.1,0,0.1,0c0.2,0,0.2-0.1,0.5-0.1c0.2,0,0.4,0.1,0.7,0.3c0.8,0.6,1,1,1.3,1c0.1,0,0.3,0,0.5-0.1l0.6-0.2l-0.1,0.6l0,0
			c0,0.1,0,0.1,0.2,0.2s0.4,0.1,0.7,0.2c0.7,0.3,0.8,0.6,1.2,1c0.2,0.2,0.7,0.2,1.2,0.5s0.6,0.7,0.6,1.2c0.2,0,0.6-0.2,1-0.2
			c0.1,0,0.1,0,0.2,0v0.4h0.3c0,0.1,0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.3,0.4,0.3l0,0l0.8-32.8L428.1,342.3z"/>
									<path fill="#DDDDDC" d="M208.7,177.2l-9.9,62.9c3,0.5,24.2,3.7,49.1,7c15.5,2.1,32.5,4.1,47.4,5.5l6.5-74.1
			c-5.5-0.6-77.2-9.1-91.4-11.3L208.7,177.2C208.7,177.1,208.7,177.1,208.7,177.2L208.7,177.2z"/>
									<path fill="#DDDDDC" d="M225.7,244.8c-16.1-2.3-27.4-4-27.4-4l-0.4-0.1l2.9-18.5c-9-1.1-23.1-3.5-40-6.7c0,0.2-0.1,0.5-0.1,0.6
			c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1.1-0.4,2.2-0.7,3.3c-0.3,1.4-0.6,2.9-0.8,4.3c-0.4,1.8-0.7,3.5-1.1,5.3c-0.4,2-0.8,4-1.2,6
			c-0.5,2.2-0.9,4.4-1.3,6.6c-0.5,2.4-1,4.8-1.4,7.2c-0.5,2.5-1,5-1.5,7.5s-1,5.1-1.5,7.6c-0.5,2.6-1.1,5.2-1.6,7.7s-0.9,5-1.5,7.6
			c-0.5,2.5-1,4.9-1.5,7.4c-0.4,2.4-0.9,4.7-1.4,7c-0.4,2.1-0.8,4.2-1.2,6.4c-0.4,2.2-0.8,3.9-1.1,5.3c21.1,4.7,47,8.8,72.4,12.1
			L225.7,244.8z"/>
									<path fill="#DDDDDC" d="M400.1,494.7c0,0.3-0.1,0.7-0.4,0.8c-0.2-0.2-0.5-0.1-0.8-0.1c0,0.1,0,0.3,0,0.4c-0.5,0.1-0.7,0.5-1.1,0.8
			c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.1-0.3,0.1-0.5,0.3c-0.2,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0-0.5,0.1c-0.3,0.1-0.6,0.7-0.9,0.8
			c-0.3,0.1-0.5-0.5-0.8-0.4c-0.5,0.2,0,1.1-0.2,1.3c-0.2,0.2-0.3-0.2-0.5-0.2c-0.4,0-0.2,0.2-0.4,0.3c-0.1,0.1,0,0.3-0.1,0.3
			c-0.1,0.1-0.2-0.1-0.2-0.1c-0.2,0.1-0.1-0.2-0.4,0c-0.4,0.3-1.3,1.8-1,2.3c0.5-0.2,1-0.7,1.4-1.1c0.3-0.3,0.4-0.7,0.7-0.9
			c0.3-0.2,0.7-0.2,1-0.4c0.8-0.4,1.5-1.2,2.2-1.7c0.6-0.5,1.2-0.8,1.8-1.2c0.5-0.3,1.1-0.6,1.5-1c0.2-0.2,0.5-0.4,0.6-0.6
			C400.7,494.5,400.1,494.5,400.1,494.7z"/>
									<path fill="#DDDDDC" d="M491.6,349.8c0-0.1,0-0.4,0.1-0.6c0.1-0.4,0.3-0.5,0.3-0.7c0.1,0-0.1-0.5,0.3-0.9c0.2-0.2,0.5-0.2,0.6-0.2
			s0.1,0,0.1,0l0,0c0,0,0-0.1,0.1-0.5c0.2-0.4,0.5-0.6,0.6-0.7c0.2-0.1,0.1-1.1,1-1.3c0.1-0.3,0.2-0.5,0.3-0.8
			c0.1-0.1,0.2-0.2,0.3-0.3l0,0c0,0-0.1-0.1-0.1-0.4c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1c0-0.2,0-0.5-0.1-0.6s0-0.1-0.2-0.1h-0.1
			l-0.6,0.1l0.2-0.5c0.1-0.4,0.2-0.6,0.2-0.8c0-0.2,0-0.3-0.1-0.5C486,341,434,342.8,429,343l2.9,20.7l-0.8,32.9
			c0.1,0.1,0.3,0.2,0.4,0.4h0.1c0.3,0,0.6,0,0.8,0.2c0.3,0.2,0.4,0.5,0.4,0.8c0,0.1,0,0.2,0,0.2h0.1c0.2,0,0.3-0.1,0.6-0.2
			c0.1,0.1,0-0.3,0.4-0.6c0.2-0.1,0.3-0.1,0.4-0.1c0.2,0,0.5,0,0.6,0c0,0,0,0,0.1,0c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.2,0,0.3,0
			c0.3,0.1,1,0,1.5,0.4l0.2,0.2l-0.2,0.2l-0.1,0.1l0.4,9l44.5-1.1c0.1-0.1,0.1-0.2,0.1-0.3l0,0c0-0.1-0.1-0.2-0.3-0.3
			c-0.2-0.1-0.7-0.3-0.7-0.9v-0.1c0.1-0.5,0.5-0.7,0.8-0.8c0.3-0.1,0.5-0.2,0.5-0.2c0.1-0.1,0.2-0.5,0.2-0.8c0-0.2,0-0.4-0.1-0.6
			c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0-0.7-0.3-0.8-0.7c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.8,0.8-1.1,0.7-1.3c0.1-0.2,0.1-0.3,0.1-0.4
			c0-0.5-0.6-0.7-1.5-1l-0.2-0.1V398c0-0.1,0-0.2,0-0.3c0-0.7,0.4-1,0.3-1.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.4-1-0.8-1.1-1.7
			c0-0.4,0.2-0.7,0.6-1l0.2-0.2l0.2,0.2c0.2,0.2,0.8,0.4,1.1,0.4c0.2,0,0.2,0,0.3-0.1c-0.5-0.2-0.8-0.7-0.8-1.2
			c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.2,0.7-0.2s0.3,0,0.4,0c-0.2-0.2-0.3-0.4-0.3-0.7c0.1-0.7,0.6-1.1,0.6-1.5
			c-0.6-0.1-1-0.2-1.1-0.8c0.1-0.6,0.6-0.7,1-1c0.3-0.2,0.7-0.3,1-0.5c-0.2-0.3-0.5-0.8-0.5-1.2c0-0.2,0.1-0.5,0.2-0.7
			c0.2-0.3,0.6-0.4,0.8-0.4c0.4,0,0.8,0.2,1,0.2c0.1,0,0.1,0,0.1,0l0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7-0.2-0.9-0.7v-0.1
			c0.1-0.7,0.7-1.2,1.2-1.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.2v-0.1c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.4-0.4,0.7-0.4c0.2,0,0.4,0.1,0.6,0.2c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.5-0.2,0.6-0.3c0.1,0,0.1,0,0.1,0
			s0-0.1,0.1-0.2c0.1-0.3-0.1-0.8,0.2-1.4c0.1-0.3,0.1-0.4,0.1-0.4s-0.1-0.2-0.1-0.5c0-0.2-0.1-0.5-0.1-0.7s0-0.4,0.1-0.6
			c0.1-0.3,0-0.5,0.2-0.7s0.2-0.1,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.3c0,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.4-0.6-0.7
			c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0.1,0.8,0.1c0.1,0,0.1,0,0.1,0l0,0
			c0-0.2-0.5-0.9-0.5-1.7c0-0.3,0.1-0.6,0.4-0.8c0.2-0.1,0.3-0.2,0.5-0.2c0.7,0.1,0.6,0.7,0.7,0.8c0,0.1,0,0.1,0,0.1
			c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.4-0.1c0.3-0.1,0.7-0.3,0.9-0.4c0.3-0.1,0.4-0.3,0.3-0.3c0,0,0-0.1-0.1-0.2
			c-0.2-0.4-0.9-0.6-1-1.4c0-0.3,0.1-0.5,0.4-0.8s0.7-0.4,0.8-0.5c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.3
			c-0.1-0.2-0.1-0.4-0.1-0.6s0-0.4,0.2-0.6c0.2-0.3,0.3-0.5,0.7-0.6c0.5,0.1,0.5,0.2,0.7,0.2c0,0,0,0,0.1,0s0.2,0,0.2,0
			c0-0.2-0.5-0.9-0.7-1.3c-0.1-0.2-0.1-0.4-0.1-0.6c0,0,0,0,0-0.1c0-0.2,0-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.2,0-0.3c0.1-0.5,0.5-0.8,0.9-0.8c0.2,0,0.5,0.1,0.6,0.1c0-0.1,0-0.1-0.1-0.2
			c-0.1-0.3-0.3-0.5-0.4-1c0-0.2,0.1-0.5,0.3-0.8c0.2-0.3,0.4-0.5,0.8-0.5c0.2,0,0.4,0.1,0.5,0.2c0.3-0.6,1.1-0.7,1.4-0.9
			c0.1-0.1,0.1-0.1,0.1-0.1s0-0.1-0.2-0.3c-0.1-0.1-0.4-0.3-0.7-0.5c-0.3-0.3-0.7-0.5-0.7-1s0.4-0.7,0.9-0.8c0.1,0,0.2,0,0.3,0
			c0.3,0,0.5,0.1,0.5,0.1l0,0c0,0,0,0,0.1-0.1s0.1-0.2,0.1-0.4c0-0.2,0.3-0.6,0.7-0.7c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.4,0l0,0
			c0.1,0-0.4-0.2-0.5-0.9v-0.1c0-0.2,0-0.4,0.1-0.6c0-0.1,0.2-0.4,0.5-0.4c0.4,0.1,0.5,0.3,0.6,0.3s0.1,0,0.2,0l0,0
			c-0.4-0.2-0.5-0.6-0.5-0.9c0-0.4,0.1-0.8,0.1-1V350l0,0l-10.1,0.7L491.6,349.8z"/>
									<path fill="#DDDDDC" d="M60.8,109.6c0.1,0,0.1,0,0.2,0c0.8,0,1.1,0.6,1.2,1c0.2,0.4,0.1,0.4,0.6,0.5c0,0,0,0,0.1,0H63l0.1,0.2
			c0.4,0.9,0.9,1.5,0.9,2.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6c0,0.5,0,1,0,1.6c0,0.2,0,0.4,0,0.6c-0.1,1.2-0.7,2.2-0.8,3.1v0.1
			c0,0.5,0.8,1.2,1.5,1.7s1.6,0.9,2.5,1.5s1.1,0.8,2,0.8c0.1,0,0.2,0,0.4,0c0.4,0,0.7-0.2,1.2-0.3c0.1,0,0.3-0.1,0.4-0.1
			c0.5,0,0.8,0.1,1.1,0.1c0.1,0,0.1,0,0.2,0c0.4,0,0.4-0.5,1.1-0.9c0.2-0.1,0.4-0.2,0.7-0.2c0.5,0,0.8,0.3,1.2,0.4
			c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0,0.9,0h0.1c0.9,0,1.2,0.5,1.7,0.8c0.4,0.3,0.9,0.4,1.4,0.8c0.2,0.1,0.3,0.3,0.4,0.3
			c0,0,0.1-0.1,0.2-0.3l0.1-0.1h0.2c0.1,0,0.2,0,0.2,0c0.9,0.1,1,0.9,1.2,1.1c0,0,0.2,0.1,0.4,0.4c0.1,0.3,0.1,0.5,0.1,0.6l0,0
			c0.1,0.3,0.2,0.2,0.2,0.2s0.2,0,0.4-0.2c0.4-0.2,0.4-0.4,0.9-0.5c0.2,0,0.3,0.1,0.6,0.1c0.6,0.2,0.9,0.6,1.2,0.7
			c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.7-0.4c0.9-0.3,2-0.4,2.8-0.6c0.3-0.1,0.3-0.1,0.6-0.1c0.2,0,0.4,0.2,0.5,0.3
			c0.1,0.1,0.1,0.3,0.2,0.5c0.3,0.7,0.1,0.7,0.5,0.8c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.1,0,0.2,0
			c0.6,0.1,1,0.2,1.4,0.3c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.1,1.1-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0v-0.1c0-0.1,0-0.3,0.2-0.5
			c0.3-0.4,0.7-0.6,1.1-0.6c0.8,0,1.3,0.6,1.7,0.5c0.9,0,1.9,0.2,2.8,0.2c0.2,0,0.4,0,0.6,0h0.1c0-0.1,0-0.2,0.1-0.4
			c0.1-0.2,0.3-0.3,0.5-0.3l0.4-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.4,0,0.5,0.4,0.6,0.5c0.1,0.2,0.1,0.2,0.1,0.2c0.1,0,0.1,0,0.1,0
			c0.1,0,0.3-0.1,0.7-0.2c0.3,0,0.7-0.1,1.1-0.1c0.5,0,1.1,0,1.5,0.3c0.3,0.2,0.4,0.4,0.4,0.5c0.1,0.1,0,0.1,0.1,0.1l0,0
			c0.1,0.1,0.1-0.3,0.8-0.6c0.1,0,0.1,0,0.2,0c0.6,0,1.1,0.4,1.4,0.3c0.1,0,0.1,0,0.2-0.1v-0.1l0.1-0.1l0.5-0.4l25.5,6.2
			c0-0.2,0-0.3,0-0.4v-0.1c0,0-0.3-0.3-0.3-0.7v-0.1c0-0.5,0.3-0.9,0.8-1.1c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.1,0-0.3,0.2-0.5l0.1-0.1
			c-0.1-0.2-0.3-0.6-0.4-1v-0.1c0-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.1,0.1-0.1l0,0l0,0c0-0.1-0.3-0.3-0.4-0.9c0-0.1,0-0.2,0-0.3
			c-0.1-0.4,0.3-0.7,0.5-0.7c0.2-0.1,0.2,0,0.3-0.1c0,0,0.1-0.2,0-0.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2c0.2-2.1,0.9-4.2,1.4-6.2
			c0.9-3.7,1.7-7.4,2.6-11.2c1-4.2,1.9-8.5,2.9-12.7c0.8-3.5,1.6-6.9,2.4-10.4c0.3-1.4,0.7-3,1-4.4c-26.8-5.7-50.3-11.5-68.3-17
			c0,0.2,0.3,0.5,0,0.6c-0.2,0.1-0.4-0.3-0.7-0.3c-0.1,0.4,0.4,0.4,0.4,0.7c0.1,0.3-0.4,0.5-0.6,0.6c0.1,0.2,0.1,0.4,0.3,0.6
			c0.2,0.2,0.4,0.1,0.3,0.5c0,0.3-0.3,0.3-0.3,0.6c0.1,0.4,0.4,0.4,0.6,0.4c-0.1,0.3,0.3,0.9,0,1.1s-1-0.4-0.8,0.4
			c0,0.2,0.2,1.2,0.5,1.2c0.5,0.1,0.2-0.8,0.3-1c0.1-0.3,0.2-0.2,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.1,0.9,0.1
			c0.5,0.4,0,0.3-0.2,0.6c-0.1,0.2-0.1,0.8-0.1,1c0,0.5,0.3,0.6,0.5,1c0.1,0.3-0.1,1-0.3,1.2c-0.3,0.3-0.7-0.1-1,0
			c-0.1,0.4,0.1,0.7,0.1,1.1c0.1,0.4,0.1,0.7-0.4,0.7c-0.7,0-0.7-1-1.2-1.5c0,0-0.1,0-0.2,0c0.2-0.3,0.6-0.6,0.4-0.9
			c-0.2,0-0.4,0.3-0.6,0.4v-0.1c-0.1,0.2-0.2,0.4,0,0.6c-0.5,0.3-0.8,0.5-0.6,1.2c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.1,0.4,0,0.7,0
			c0.2,0.4,0,0.8,0.2,1.1c0.1,0.3,0.4,0.5,0.5,0.8c0.2,0.4,0.3,0.6,0.6,0.8c0.2,0.3,0.3,0.7-0.1,1c-0.3,0.2-0.7,0.1-1-0.1
			c-0.4-0.2-0.4-0.2-0.7,0.2c-0.4,0.4-0.2,0.4-0.3,0.9c0,0.4-0.3,0.6,0.1,0.9s0.3,0.2,0.4,0.7c0.1,0.2,0.2,0.8,0.5,0.7
			c-0.1-0.3,0-0.5-0.2-0.8c-0.1-0.3-0.3-0.5-0.4-0.8c0-0.1-0.1-0.7,0-0.8c0.2-0.4,0.6-0.1,0.5-0.6c0.4,0,1.1,0.1,1.2,0.6
			c0.1,0.2-0.1,0.4-0.2,0.6C81,78.6,81,78.6,81,78.9c0,0.9,0.7,1.4,0.9,2.2c0.3,0.1,0.4-0.3,0.7-0.1c0.3,0.2,0,0.4,0.1,0.7
			c0.2,0,0.3,0.4,0,0.4c-0.1,0-0.3-0.2-0.4-0.3c-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.2-0.7,0.2-1,0.2c-0.1,0.3-0.5,0.5-0.6,0.8
			c-0.2,0.3-0.1,0.7-0.5,0.9c-0.4,0.2-0.5,0.3-0.7,0.7c-0.1,0.3-0.2,0.7-0.2,1h-0.1c0,0.5,0.1,0.7-0.3,0.9c-0.3,0.2-0.7,0.2-0.5,0.6
			c0,0.1,0.3,0.2,0.3,0.2c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.1,0.8-0.2,0.9c0-0.2,0-0.4-0.1-0.5c-0.2,0.4-0.6,0.8-0.5,1.3
			s0.3,0.6,0,1.1c-0.4,0.6,0.6,1.8-0.1,2.3c-0.3,0.2-0.9,0.1-1.3,0.2c-0.6,0.2-0.1,0.4,0.1,0.8c0.3,0.5-0.2,0.6-0.5,0.3
			c-0.3-0.2-0.5-1-0.8-1c-0.3-0.1-0.3,0.7-0.5,0.9c-0.3,0.3-0.6,0.6-0.9,0.9L73,95.6c-0.1,0.2-0.6,0.2-0.9,0.5
			c-0.2,0.1-0.3,0.7-0.6,0.7c-0.5,0-0.5-1.2-1-1.3c0.3,0.8-0.3,0.8-0.8,0.4c0-0.4-0.2-1.5-0.7-0.7c-0.2,0.3,0.1,0.9,0,1.4
			c-0.5,0-0.4-0.7-0.5-1.1c-0.2,0.2-0.8,1.1-0.7,0.3c0-0.3,0.5-0.5,0.6-0.9c-0.6-0.1-1.1,0.8-1.7,0.2c0.1-0.2,0.5-0.7,0.7-0.8
			c0.4-0.2,0.6,0.1,1.1,0c0-0.2-0.1-0.5-0.3-0.6c-0.3,0.2-0.6,0.2-0.9,0.2c-0.2-0.9,0.3-0.9,1-0.8c0.8,0,1-0.1,1.1-0.9
			c0.3,0.8,0.7,0,1.1-0.1c0.1,0.4-0.3,0.9-0.2,1.5c0.1-0.3,0.4-0.5,0.7-0.5c0-0.3-0.1-1.2,0.1-1.4c0.5-0.5,0.7,0.3,0.6,0.7
			c-0.2,0.6-0.4,0.5-0.4,1.1c0,0.5,0.4,1,0.8,0.4c0.2-0.4-0.4-0.5,0-1c0.2-0.2,0.5,0,0.5,0c0.2-0.2,0.1-0.4,0.2-0.6
			c0.2-0.2,0.4-0.9,0.9-0.4c0.4,0.5-0.2,0.9-0.6,1c0.1,0.3,0.6,0.5,0.9,0.7c0.5-0.6,1-3.3,1.8-3.3c0-0.4,0-0.9,0-1.3
			c0-0.6,0.1-0.4,0.4-0.9c0.2-0.4,0.4-1-0.2-0.6c-0.3,0.2-0.3,0.9-0.3,1.2c-0.5,0-0.8,0.4-1.3,0.4c-0.1-0.4,0.3-0.7,0.3-1.1
			c-0.2,0-0.3-0.1-0.5-0.1c-0.1-0.3,0.1-1.1,0.6-0.9c0.4,0.2-0.1,0.9,0.5,1c0-0.5,0.6-0.8,0.6-1.3c0-0.3-0.7-1.3,0-1.3
			c0.1,0.3,0.2,0.4,0.5,0.4c0.1-0.7,0.8-0.5,1.2-0.8c-0.1-0.7,0.3-1.6-0.3-2.2c-0.5,0.3-0.2,0.9-0.4,1.4c-0.1-0.1-1.3,0.3-1.5,0.4
			c-0.5,0.2-0.8,0.8-0.9,1.3c-0.4,0-0.9,0.6-1.3,0.7S72.4,86.8,72,87s-1,0.4-1.3,0.6c-0.1,0.1-0.3,0.3-0.4,0.4
			c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0.6-0.6,1.2-1.1,1.5c0.6,0,0.9,0.9,0.8,1.4c-0.4-0.9-1.6-0.7-1.7-1.6c0-0.7,1.1-1.1,1.5-1.6
			c0.3-0.3,0.6-0.7,1-1c0.7-0.6,0.5,0,1.2-0.1c0.5-0.1,1.3-1.1,1.6-1.5c0.3-0.3,0.5-0.6,0.4-1c-0.1-0.5-0.6-0.5-0.3-1.1
			c0.3,0,0.5,0.3,0.8,0.5C73.2,83,74,81.8,74.3,83c0.2,0.9-0.6,1.6-0.3,2.4c0.2-0.6,0.7-0.9,1-1.4c0.1-0.2,0.1-0.6,0.3-0.8
			c0.3-0.3,0.6-0.2,0.9-0.4c0.7-0.4,0.2-0.3,0.1-1c-0.1-0.6,0.3-1,0.1-1.6c-0.2-0.5-0.6-0.7-0.4-1.3c0.1-0.4,1.1-1.3-0.1-1.1
			c-0.3,0-1.1,0.6-1.1,0.9c-0.1,0.4,0.8,0.9,0.3,1.2c-0.7,0.5-0.4-0.9-0.5-1.1c-0.2-0.5-0.7-0.8-1.2-0.9c0.2,1-0.4,1.5-0.8,0.4
			c-0.3-0.8,0-1.6-1-2c-0.7-0.3-1.5-0.1-2.2-0.4c-0.6-0.3-1.2-0.8-1.7-1.3c-0.7,0.8-1.2,0-1.8-0.2c-0.6-0.2-1.1-0.1-1.7-0.4
			s-0.9-0.8-1.5-1.2c-0.6-0.4-1,0-1.6-0.3c-1-0.5-0.5-2.1-1.9-2.4c-0.7-0.1-0.9-0.1-1.3-0.7c-0.4-0.6-0.5-1.1-1.1-1.5
			c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.2-0.4-0.5-0.5c-0.1-0.1-0.3,0.1-0.6,0c-0.1-0.1-0.3-0.5-0.4-0.7c-0.7,0.4-0.2,1.3-0.5,2
			c-0.1,0.4-0.5,0.3-0.6,0.7c-0.1,0.3,0.1,0.7,0.1,1.1c-0.9-0.2-0.4,0.4-0.4,0.9c0,1.6-0.9,3.2-0.1,4.8c0.4,0.7,0.9,0.8,0.9,1.7
			c0,0.4-0.2,0.7-0.1,1c0.1,0.3,0.4,0.5,0.5,0.8c0.2,0.6-0.1,1.5-0.1,2.1c0,1.3-1.3,2.8-0.9,4.1c0.1,0.4,0.6,0.5,0.7,0.8
			c0.1,0.4-0.1,0.9-0.2,1.3c-0.2,1,0.4,1.1,0.3,2c-0.1,1.2-1,4.1,1.1,2.8c0.1,0.3,0.3,0.8,0.6,0.9c0.4,0.2,0.8-0.1,1.2,0
			c0.3,0.1,0.8,0.3,0.5,0.7c-0.6,0-1.3-0.1-1.6-0.1c-0.4,0-0.8,0-1.2,0s-0.8,0.3-1.2,0.3c-1.1,0-0.4-1.3-1.4-1.4
			c-0.1,0.6,0.2,1,0.3,1.5c0.1,0.6-0.2,1-0.2,1.5s0.4,0.9,0.6,1.3c0.2,0.3,0,0.5,0.4,0.6c0.3,0,0.5-0.5,0.7-0.5
			c0.5,0,0.7,0.8,0.3,1.2c-0.5,0.5-1.1-0.2-1,0.8c0,0.5,0.5,1.1,0,1.6s-0.8,0-1,0.9c-0.1,0.6,0.1,1.4-0.7,1.6
			c-0.2,0.1-0.5-0.2-0.8-0.1s-0.6,0.5-0.8,0.7c0.4,0.3,0.9,0.2,1.2,0.5c0.2,0.2,0.1,0.8,0.3,1.1c0.3,0.4,0.5,0.4,0.9,0.4
			c0.2,0,0.8,0.1,0.9,0.1c0.3-0.1,0.5-0.3,0.8-0.3c0.6-0.1,0.7,0.5,0.9,0.7c0.6,0.4,1.3,0.7,1.8,1.2c0.3,0.4,0.2,0.8,0.5,1.2
			c0.1,0.2,0.4,0.3,0.5,0.5c0.4,0.1,0.9,0.5,1.2,0.5C59.7,109.9,60.3,109.7,60.8,109.6z"/>
									<path fill="#DDDDDC" d="M765.4,202.5l-0.1-0.3c0,0.3,0,1-0.1,1.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.1,0.3-0.1,0.4
			c-0.3,0.3-0.3-0.3-0.7-0.2c-0.2,0.1-0.5,0.6-0.5,0.8c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.4-0.2,0.6-0.2c0.3-0.1,0.6,0,1-0.1
			c0.2-0.1,0.9,0,1-0.3c0-0.1-0.4-0.7-0.5-0.9C766.1,203.1,765.7,202.4,765.4,202.5z"/>
									<path fill="#DDDDDC" d="M146.1,94.3c-1,4.2-2,8.5-2.9,12.7c-0.8,3.7-1.7,7.5-2.6,11.2c-0.5,2-1.1,4.1-1.4,6.1v0.1
			c0,0.2,0,0.5,0,0.7c0,0.3,0,0.6-0.2,0.9c-0.3,0.3-0.6,0.4-0.7,0.4c0,0,0,0,0,0.1c0,0,0,0.1,0,0.2s0.3,0.3,0.4,0.9v0.1
			c-0.1,0.4-0.2,0.5-0.2,0.6l0,0l0,0h-0.1h0.1c0,0.3,0.4,0.7,0.5,1.4l0,0v0.4h-0.4l0,0c0,0.2,0.2,0.6,0.3,1v0.5l-0.5-0.1h-0.1
			c-0.2,0-0.4,0.2-0.4,0.4h-0.2h0.2c0,0,0.2,0.2,0.3,0.6c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.5v0.1c0,0.3,0.5,0.5,0.6,1.3l0,0
			c0,0.4-0.2,0.7-0.2,0.8l0,0c0,0,0,0,0.1,0h0.2l0.1,0.2c0.2,0.4,0.2,0.7,0.3,0.8l0,0c0.1,0,0.2,0,0.5-0.1l0.4-0.1v0.4v0.1
			c0,0.2,0,0.4,0,0.5s0,0.2,0,0.2l0,0h0.3v0.3c0,0.1,0,0.1,0.1,0.3c0.8,0.1,1.1,0.8,1.1,1.4v0.1c0,0.3-0.1,0.5-0.1,0.6
			c0,0,0,0,0,0.1s0.3,0.3,0.5,0.8c0,0.2,0.1,0.3,0.1,0.4c0,0.7-0.5,0.9-0.8,1.2c-0.4,0.2-0.4,0.1-0.5,0.2c-0.1,0-0.1,0.3-0.3,0.6
			c-0.5,1.1-1.4,1.6-1.7,2.5c-0.2,0.5-0.1,0.9-0.5,1.5s-0.7,0.9-1,1.4c-0.2,0.6-0.5,1-0.9,1.4c-0.5,0.5-0.4,0.5-0.7,1.3l0.2,0.4
			h-0.2l0,0c-0.3,0-0.5,0-0.6,0l0,0c0,0-0.1,0.1-0.1,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.6-0.2,0.9
			c-0.4,0.6-1.1,0.6-1.1,0.8c-0.2,0.2-0.1,0.6-0.4,1.1c-0.3,0.4-0.8,0.5-1.1,0.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.4,0-0.6,0.4
			c-0.2,0.4-0.4,0.9-0.6,1.4c-0.2,0.3-0.2,0.9-0.6,1.4c-0.2,0.2-0.3,0.2-0.4,0.2l0,0v0.1l-0.2,0.1c0,0.1,0,0.3-0.2,0.5
			c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0-0.2,0c-0.2,0.1-0.3,0.2-0.3,0.2l0,0c0,0,0,0.2,0.1,0.5l0.1,0.6l-0.6-0.1h-0.1l0,0c0,0,0,0,0,0.1
			c0,0.3,0.5,1.2,0.6,1.3l0.4,0.3l-0.4,0.3c-0.2,0.2-0.4,0.3-0.5,0.3c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.5,0.5,0.6,0.8V165l0.3-0.1
			c0,0,0,0,0.1,0c0.7,0.1,1.1,0.6,1.3,1.2c0.1,0,0.1,0,0.1,0l0.6-0.3l-0.1,0.7c0,0,0,0,0,0.1c0,0.3,0.2,0.5,0.3,1.1
			c0,0.3-0.2,0.6-0.5,0.9c-0.2,0.2-0.4,0.3-0.5,0.3c-0.1,0.1-0.2,0.1-0.2,0.1l0,0c0,0.1,0,0.2,0,0.4h-0.4v0.1l0.2,0.2l0,0
			c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.1,0,0.2c-0.3,0.7-0.8,0.8-1,1c0,0.1,0.1,0.1,0.2,0.4
			l0,0l0,0l0,0c0,0.2-0.2,0.3-0.3,0.4h-0.1c-0.1,0.3-0.2,0.6-0.3,0.9l-0.1,0.2l-0.2,0.1c-0.2,0.1-0.3,0.2-0.3,0.3l0.1,0.1l-7.3,32.5
			c34.2,7.6,65,13.1,80.5,15l7-44.3c-0.1-0.3-0.7-0.8-1-1.5c-0.1-0.4-0.2-0.8-0.3-1s-0.3-0.3-0.4-0.7c-0.2,0-0.3,0-0.5,0h-0.3
			l-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.8,0.2-1c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.3-0.3,0.5-0.3,0.5
			c-0.1,0,0.5,0.3,0.5,1.2c0,0.1,0,0.2,0,0.3v0.3h-0.3c0,0,0,0-0.1,0c-0.7,0-1.1-0.5-1.4-0.5c-0.2,0-0.6-0.1-0.9-0.1h-0.1l0.1,0.4
			h-0.5c-0.1,0-0.1,0-0.1,0c-0.5,0-0.9,0.2-1.5,0.2c-0.3,0-0.6-0.1-1-0.3s-0.2-0.2-0.5-0.3l0,0c-0.1,0-0.3,0-0.5,0l0,0
			c-0.6,0-0.9-0.3-1.2-0.3l0,0c-0.1,0-0.2,0.1-0.4,0.3s-0.4,0.6-0.9,0.6h-0.1H195c-0.9-0.2-0.9-1.1-1.1-1.3
			c-0.3-0.5-0.5-0.5-0.6-0.5s-0.4,0.1-0.8,0.3c0,0.2,0,0.5-0.1,0.8l-0.2,0.3l-0.3-0.1c-0.3-0.1-0.5-0.4-0.6-0.4l0,0h-0.1
			c-0.1,0-0.1,0.1-0.6,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.6,0-0.8-0.3-1-0.3c-0.1,0.2-0.4,0.4-0.6,0.4c-0.7,0-1.4-0.6-1.6-0.5l0,0
			c-0.4,0.1-0.5,0.3-0.7,0.7c-0.2,0.3-0.3,0.9-0.8,1.2l-0.3,0.2l-0.2-0.3c-0.2-0.4-0.4-0.7-0.6-1c-0.2-0.4-0.5-0.6-0.8-1
			c-0.6-0.9-0.6-1.9-0.7-2.8c0-0.9,0-2.1-0.4-2.6c-0.1-0.2-0.5-0.2-0.8-0.7c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1,0-0.3,0.1-0.5,0.1
			c0,0-0.1,0-0.2,0c-0.5-0.2-0.7-0.5-0.8-0.5c-0.4-0.1-0.4,0-0.8-0.2c-0.4-0.3-0.2-0.5-0.3-1c-0.1-0.8,0.1-0.3-0.5-0.9
			c-0.3-0.2-0.6-0.3-0.7-0.8c0-0.3,0.2-0.5,0.4-0.7s0.5-0.4,0.6-0.4c0.1-0.1,0.1,0,0.1-0.1s0-0.2-0.1-0.4c-0.1-0.3-0.3-0.7-0.4-1.2
			c-0.1-0.3-0.4-0.6-0.6-1.1c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3-0.1-0.4c-0.1-0.3-0.7-0.2-1-1.1
			c-0.2-0.7-0.2-1.2-0.2-1.8c0-0.4,0-0.7,0-1.1c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0-0.2,0-0.3s0-0.3,0-0.3c0.1-0.3,0.2-0.6,0.3-0.8
			c-0.1,0-0.2,0-0.4-0.1c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.1,0-0.2,0-0.4c0.1-0.4,0.2-0.6,0.2-0.8c-0.2,0.1-0.4,0.2-0.7,0.2
			c0,0,0,0-0.1,0h-0.4l0.1-0.4c0.1-0.6,0.4-1,0.3-1.2c0-0.1,0,0-0.1-0.1c0,0,0,0-0.1,0c-0.3,0-0.9,0.2-1,0.2c-0.2,0.1-0.5,1-1.3,1.2
			c-0.1,0-0.2,0-0.3,0c-0.5,0-0.8-0.2-1-0.4c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.1,0,0.1v0.7l-0.6-0.3c-0.3-0.2-0.3-0.2-0.3-0.2
			s-0.1,0-0.3,0l0,0c0,0.1-0.2,0.3-0.5,0.3c-0.1,0-0.1,0-0.2,0c-0.4-0.2-0.4-0.4-0.5-0.5c-0.3-0.2-0.6-0.3-0.9-0.6
			c-0.2-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.1-0.5,0.1c-0.5,0-1-0.4-1-1v-0.1c0-0.6,0.4-1,0.6-1.3c0.4-0.5,0.2-0.3,0.3-1
			c0.1-0.5,0.3-0.9,0.3-1.1v-0.1l0,0c-0.1-0.1-0.3-0.2-0.3-0.5c0-0.1,0-0.2,0-0.2c0-0.3,0.1-0.5,0.2-0.7c0.1-0.4,0.5-0.8,0.9-0.8
			c0.2,0,0.3,0,0.5,0.1c0.4,0.1,0.6,0.3,0.7,0.3l0,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3s0-0.3-0.1-0.3c0-0.1,0,0,0,0h-0.3v-0.3
			c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c-0.2,0-0.3-0.1-0.4-0.1h-0.2l-0.1-0.2c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3l0.1-0.2
			h0.2c0.6-0.1,1-0.4,0.9-0.6c0,0,0-0.1,0-0.2l0,0c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.3-0.8
			c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0,0,0-0.1l0,0l-0.1-0.5l0.5,0.1l0,0l0,0l0,0c0-0.1,0-0.1,0-0.2
			c0-0.4,0-0.7,0.2-1.1c0.2-0.4,0.4-0.6,0.4-0.7c0-0.1,0-0.1,0-0.1s0-0.1,0-0.3c0-0.1,0-0.2,0-0.3c0.1-0.4,0.4-0.7,0.6-0.8
			c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.1-0.2,0.1-0.2s-0.1,0-0.2,0h-0.2l-0.1-0.2c0-0.1,0-0.2,0-0.3c0.1-0.7,0.6-0.8,0.9-1l0,0
			c0-0.6,0.4-0.8,0.7-1.1c0.2-0.2,0.3-0.3,0.4-0.4h-0.1c-0.4,0-0.5-0.1-0.6-0.1l0,0c-0.1,0,0,0.1-0.2,0.2c0,0-0.1,0-0.2,0l0,0l0,0
			l0,0c-0.4,0-0.6-0.3-0.6-0.3c-0.3-0.1-0.7,0.1-1.3-0.3c-0.3-0.2-0.4-0.5-0.4-0.5s-0.1,0-0.2,0c0,0,0,0-0.1,0h-0.6l0.2-0.5
			c0.1-0.2,0.3-0.7,0.3-0.9l0,0c0,0-0.1,0.1-0.2,0.2c-0.2,0.1-0.4,0.4-0.8,0.4c-0.1,0-0.2,0-0.3,0l-0.3-0.1v-0.3c0-0.3,0-0.5,0-0.6
			c-0.1-0.5,0.1-0.4-0.8-0.8l-0.3-0.1v-0.3c0-0.3,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.2c0-0.2,0-0.1-0.1-0.2c0,0-0.3-0.2-0.4-0.4
			c-0.2-0.6-0.1-1.2-0.2-1.7c-0.5,0-0.8-0.5-0.8-0.9c-0.1-0.1-0.2-0.3-0.2-0.4s0-0.1,0-0.1c0-0.1-0.3-0.1-0.6-0.4
			c-0.4-0.5-0.4-1-0.5-1.5c-0.1-0.6,0-0.5-0.3-0.8c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.4-0.5-0.4-0.8c0-0.1,0-0.1,0-0.2
			c0-0.4,0.1-0.7,0.1-0.7s0,0-0.2-0.2c-0.1-0.1-0.8-0.6-0.9-0.7c-0.1,0-0.1,0-0.1,0s0,0-0.2,0c-0.3,0-0.5-0.3-0.7-0.6
			c-0.2-0.5-0.2-0.9-0.4-1.1c-0.3-0.3-0.3-0.3-0.8-0.5c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.1-0.4-0.1-0.5-0.6c0,0,0,0,0-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.4,0.3-0.7,0.5-1s0.4-0.7,0.4-0.7l0,0l0,0l0,0c0,0-0.1-0.1-0.3-0.2s-0.5-0.3-0.6-0.7l0,0l0,0
			c0-0.5,0.4-0.6,0.6-0.9c0.2-0.2,0.4-0.4,0.4-0.4s0-0.4,0-0.7c0-0.2,0-0.5,0-0.6l0,0c0-0.1,0,0,0-0.1c0,0-0.2-0.1-0.3-0.3
			c-0.1-0.1-0.2-0.5-0.3-0.5c-0.3-0.4-0.4-0.7-0.6-1.1c-0.2-0.5-0.3-1.4-0.3-1.7l-1.3-2.5l4.3-18.2c-3.8-0.8-7.6-1.6-11.3-2.3
			c-0.3,1.4-0.7,3.1-1,4.5C147.7,87.4,146.9,90.9,146.1,94.3z"/>
									<path fill="#DDDDDC" d="M127,173.9c0-0.1,0-0.1,0-0.2c0-0.4,0.3-0.7,0.5-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.1,0.1-0.2,0.2-0.3
			c0-0.1-0.1-0.1-0.1-0.3c0-0.1,0-0.1,0-0.1c0.4-0.8,1.1-0.8,1-1.1l0,0c0,0-0.2-0.2-0.2-0.6c0-0.1,0-0.1,0-0.1
			c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.1,0.1-0.2c0-0.4,0.1-0.7,0.4-0.8c0.3-0.2,0.4-0.1,0.6-0.3c0.2-0.2,0.2-0.2,0.2-0.3
			s-0.1-0.3-0.2-0.7c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0h-0.5l0.1-0.5c0,0,0,0,0-0.1s-0.1-0.3-0.2-0.5c0-0.1-0.1-0.1-0.1-0.1
			c-0.1,0.2-0.4,0.3-0.6,0.3h-0.1H128l-0.1-0.2c-0.2-0.6-0.3-0.6-0.8-1.1c-0.2-0.3-0.3-0.4-0.3-0.7c0-0.4,0.3-0.5,0.4-0.5h0.1
			c-0.3-0.4-0.5-1-0.6-1.5c0-0.2,0-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.2c0-0.1,0-0.1,0-0.2c0-0.5,0.4-0.7,0.7-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0v-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1l0,0l0,0l0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2
			c0.2-0.2,0.3-0.2,0.3-0.2l0,0l0,0c0.2-0.3,0.2-0.7,0.4-1.2c0.2-0.4,0.4-1,0.6-1.4c0.3-0.7,0.9-0.9,1.3-0.8c0.2,0,0.3,0,0.5,0
			c0.3,0,0.4,0,0.6-0.2c0.2-0.2,0.1-0.6,0.4-1.1c0.5-0.5,1-0.5,1.1-0.8c0.1-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.4s0-0.4,0.1-0.7
			c0.1-0.5,0.4-0.8,0.8-0.8c0.1,0,0.3,0,0.4,0c0.2-0.5,0.2-0.8,0.8-1.3c0.4-0.4,0.5-0.6,0.8-1.1c0.3-0.7,0.7-1.1,1-1.6
			c0.3-0.3,0.2-0.7,0.4-1.3c0.5-1.2,1.4-1.7,1.7-2.5c0.1-0.2,0.1-0.5,0.4-0.8c0.3-0.3,0.6-0.3,0.6-0.3c0.4-0.3,0.5-0.3,0.5-0.6
			c0-0.1,0-0.2,0-0.3s-0.3-0.3-0.5-0.8c0-0.1,0-0.2,0-0.2c0-0.3,0.1-0.5,0.1-0.6l0,0c-0.1-0.5-0.2-0.7-0.5-0.7h-0.1l-0.5,0.1v-0.5
			l0,0c0-0.1,0-0.1-0.1-0.3c-0.4-0.2-0.5-0.6-0.4-0.9c0-0.1,0-0.1,0-0.2h-0.1c-0.3,0-0.7-0.3-0.7-0.5c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.3-0.1-0.6-0.4-0.6-0.7c0-0.4,0.2-0.8,0.2-0.8c0-0.3-0.2-0.4-0.4-0.8l-25.6-6.2l-0.2,0.1c-0.2,0.3-0.5,0.5-0.8,0.5
			c-0.6,0-1.2-0.4-1.4-0.3l0,0c-0.1-0.1-0.2,0.5-1,0.7c0,0,0,0-0.1,0c-0.4,0-0.6-0.3-0.7-0.5s-0.2-0.3-0.2-0.3
			c-0.1-0.1-0.6-0.2-1.1-0.2c-0.4,0-0.8,0-1,0.1c-0.2,0-0.4,0.2-0.8,0.2c-0.2,0-0.3,0-0.5-0.1c-0.3-0.2-0.4-0.4-0.4-0.5l0,0l0,0
			l-0.5,0.2h-0.1c0,0.1,0,0.3-0.2,0.5c-0.2,0.2-0.4,0.2-0.6,0.2s-0.4,0-0.7,0c-0.9,0-1.9-0.1-2.8-0.2c-0.8-0.1-1.3-0.6-1.6-0.5
			c-0.1,0-0.3,0-0.5,0.3l0,0v0.1c0,0.1,0,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.2,0l0,0c-0.4,0.1-0.8,0.2-1.3,0.2
			c-0.1,0-0.2,0-0.4,0c-0.6-0.1-1-0.2-1.4-0.3h-0.1c-0.2,0-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.6-0.1c-0.7-0.2-0.8-0.8-1-1.2
			c0-0.1-0.1-0.2-0.1-0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.9,0.2-2,0.4-2.8,0.6c-0.3,0.1-0.4,0.3-1,0.4c-0.2,0-0.4,0-0.6-0.1
			c-0.5-0.2-0.8-0.6-1.2-0.7c-0.2-0.1-0.3-0.1-0.4-0.1c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.5,0.2-0.8,0.2
			c-0.4,0-0.7-0.3-0.9-0.6c-0.1-0.4-0.1-0.6-0.1-0.7l0,0c0,0,0,0-0.1-0.1s-0.2-0.1-0.2-0.3c-0.2-0.4-0.4-0.7-0.5-0.8
			c-0.2,0.3-0.4,0.5-0.7,0.5c-0.5,0-0.6-0.3-0.9-0.5c-0.4-0.3-0.8-0.4-1.3-0.8c-0.6-0.4-0.8-0.7-1.4-0.7l0,0c-0.3,0-0.6,0.1-0.9,0.1
			c-0.2,0-0.5,0-0.8-0.2c-0.4-0.2-0.7-0.3-0.9-0.3c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.4,0.8-1.3,1c-0.1,0-0.2,0-0.3,0
			c-0.5,0-0.8-0.1-1.1-0.1c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.8,0.3-1.4,0.4c-0.1,0-0.3,0-0.4,0c-1,0-1.6-0.3-2.5-0.9
			c-0.7-0.5-1.6-0.9-2.5-1.5c-0.6-0.5-1.7-1.2-1.8-2.3c0-0.1,0-0.1,0-0.2c0.2-1.1,0.7-2.1,0.8-3c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0-1.6
			c0-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0-0.8-0.3-1.3-0.7-2.2c-0.7-0.1-1-0.6-1.1-1c-0.2-0.4-0.1-0.4-0.5-0.5h-0.1
			c-0.4,0-0.9,0.1-1.5,0.1c-1-0.3-1.2-0.7-1.2-0.7l0,0c-0.5-0.4-1.1-0.8-1.5-1.3c-0.5-0.7-0.5-1.2-1.2-0.7c-0.6,0.4-0.5,0.7-1.5,0.4
			c-0.7-0.2-0.9-0.8-1.6-1c-0.5-0.2-1.7-0.1-1.9,0.4c-0.2,0.4,0.2,1.4,0.1,1.9c-0.2,0.9-0.5,0.8-1.1,1.3c-0.7,0.5-0.5,1.1-0.4,1.8
			c0.2,1.6-1.8,2.6,0.4,3.4c-0.6,0.2-1.4,0-1.8,0.6c-0.2,0.4,0.1,0.7,0,1c-0.1,0.5-0.5,0.7-0.4,1.2c0.4,0.1,1,0.8,0.7,1.3
			c-0.3,0.4-1.2,0.2-1.6,0c-0.1,0.6-0.2,1.1-0.4,1.6c-0.2,0.6-0.1,1.1-0.3,1.6c-0.1,0.4-0.5,0.6-0.7,1c-0.2,0.5-0.3,1-0.5,1.5
			c-0.3,0.7-1.2,1.6-1.2,1.6c-0.4,1.3-1.2,2.5-1.6,3.7c-0.2,0.7-0.8,1.3-0.8,2c0,0.3,0.4,0.4,0.3,0.8c-0.1,0.5-0.3,0.3-0.5,0.5
			c-0.9,0.7-0.9,2-1.4,3.1c-0.3,0.7-0.4,1.4-0.7,2.1c-0.2,0.6-0.6,1-0.8,1.5c-0.3,0.8-0.7,1.6-0.9,2.4c-0.2,0.8-0.3,1.4-0.8,2.1
			c0.2,0.1,0.4,0.2,0.7,0.3c0,0.7-1.2,1.1-1.5,1.9c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2,0.1,0.4,0.1,0.5
			c-0.1,1.1-0.6,0.8-1.3,1.5c-0.3,0.4-0.3,0.8-0.5,1.2s-0.4,0.5-0.6,0.8c-0.5,0.9,0,1.6-0.4,2.4c-1.2-0.6-1.4,0-1.9,0.9
			c-0.4,0.8-1.1,1.5-1.6,2.2c-0.3,0.4-0.5,0.4-0.3,0.9c0.2,0.3,0.9,0.5,0.6,1c-0.3,0.4-1-0.1-1.4,0.1c-0.5,0.2-0.4,0.7-0.6,1.1
			c-0.6,1.5-2.8,2.4-1.9,4.1c0.8,1.4,0.8,4.2-0.3,5.5c-0.6,0.7-1.1,1-1.1,2.1c0,0.9-0.2,1.6,0,2.6s-0.2,1.7-0.2,2.6
			c0,0.7,0.8,1.3,1,2.1c28,9.4,63,18.3,95.2,25.4L127,173.9z"/>
									<path fill="#DDDDDC" d="M158.7,102.3L158.7,102.3c0,0.1,0.1,1.4,0.2,1.6c0.2,0.4,0.3,0.6,0.6,1c0.1,0.2,0.2,0.5,0.3,0.6
			c0,0,0,0,0.1,0.1s0.3,0.2,0.3,0.5c0,0.2,0,0.4,0,0.8s0,0.6,0,0.8c-0.2,0.6-0.9,1-1,1.1c0,0,0,0,0.2,0.1s0.4,0.2,0.6,0.5
			c0.1,0.1,0.1,0.3,0.1,0.4c0,0.7-0.6,1.2-0.8,1.6l-0.1,0.1v0.3c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.2,0.8,0.3,1.1,0.8
			c0.4,0.5,0.3,1,0.5,1.3c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1c0.3,0.2,0.8,0.6,1,0.8
			c0.3,0.2,0.4,0.5,0.4,0.7c0,0.3-0.1,0.4-0.1,0.7v0.1c0,0.2,0,0.2,0,0.2l0,0l0,0c0.1,0,0.3,0.1,0.6,0.3c0.5,0.4,0.6,0.8,0.6,1.3
			c0.1,0.6,0,0.7,0.3,1.1l0,0l0.1,0.1c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.2,0.2,0.4,0.2,0.4l0,0l0.3,0.1v0.3v0.1c0,0.3,0.1,0.3,0.2,0.3
			h0.1l0.3-0.1l0.1,0.3c0.3,0.8,0,1.5,0.2,1.9c0,0,0,0,0.1,0.1s0.3,0.3,0.4,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.5
			c0.7,0.3,1.1,0.7,1,1.4c0,0.1,0,0.1,0,0.2l0.1-0.1c0.2-0.1,0.3-0.3,0.7-0.4c0.1,0,0.2,0,0.2,0c0.4,0.1,0.5,0.5,0.5,0.8
			s-0.1,0.5-0.2,0.7c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.2,0.1,0.2,0.2,0.3c0.3,0.2,0.4,0.1,1,0.2c0.2,0,0.3,0.2,0.4,0.3
			c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0,0,0.1,0c0.4,0,0.6,0.2,0.7,0.1l0,0c0,0,0,0,0.2-0.1l0.4-0.4l0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.5
			c0,0.6-0.4,0.9-0.7,1.2s-0.5,0.5-0.4,0.5c0,0,0,0,0,0.1l0.2,0.3l-0.3,0.2c-0.2,0.1-0.5,0.2-0.6,0.3c0,0-0.1,0-0.1,0.1
			c0.3,0.1,0.5,0.4,0.5,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.4-0.5,0.5-0.7,0.6s-0.2,0.1-0.3,0.4v0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.4,0.6-0.4,0.7c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4v0.4l-0.4-0.1l0,0
			c0,0.1,0,0.1,0,0.1l0.1,0.6l-0.6-0.1l0,0c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.1,0.1,0.3,0.1,0.4c0,0.6-0.5,0.9-1,1.1l0,0c0,0,0,0,0.1,0
			h0.3v0.3c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0.4,0.2,0.4,0.7,0.4,1c0,0.2,0,0.4-0.1,0.5c-0.2,0.4-0.4,0.7-0.8,0.7
			c-0.4,0-0.6-0.2-0.9-0.3c-0.2,0-0.3-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4l0,0c0,0,0,0,0.1,0.1
			c0,0.1,0.2,0,0.3,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,0.9-0.3,1.2c-0.1,0.5,0,0.8-0.5,1.3c-0.3,0.4-0.5,0.6-0.5,0.8l0,0l0,0
			c0,0.2,0.2,0.4,0.3,0.4s0.1,0,0.2-0.2l0.2-0.3l0.3,0.3c0.4,0.4,0.4,0.8,0.6,0.9c0.2,0.2,0.4,0.3,0.7,0.5c0.2,0.1,0.2,0.2,0.3,0.3
			c0.1-0.1,0.2-0.1,0.3-0.2c0.2,0,0.3,0,0.4,0h0.1c0-0.4,0.1-0.7,0.1-1v-0.4l0.4,0.1c0.7,0.1,1.1,0.6,1.3,0.5h0.1
			c0.3,0,0.6-0.7,1.1-1.1c0.3-0.2,0.9-0.3,1.4-0.4c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.5,0.5,0.5,0.8c0,0.2-0.1,0.4-0.1,0.6
			c0.1-0.1,0.3-0.1,0.5-0.1h0.1l0.2,0.1v0.3c0,0.1,0,0.1,0,0.2c0,0.6-0.2,0.9-0.3,1.3c0,0.1,0,0.2,0,0.3l0,0c0.1,0,0.3,0,0.5,0h0.3
			v0.3v0.1c0,0.4-0.2,0.7-0.3,1l0.1-0.1v0.7c0,0.5,0,0.9,0,1.4c0,0.6,0,1,0.2,1.5c0.1,0.5,0.4,0.3,0.9,0.9c0.2,0.3,0.2,0.5,0.2,0.8
			c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0,0.3c0.1,0.3,0.4,0.6,0.6,1.1c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.2,0.1,0.4,0.1,0.6
			c0,0.3-0.2,0.6-0.4,0.7c-0.2,0.1-0.3,0.2-0.5,0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0.1,0.2,0.1,0.4,0.3c0.6,0.3,0.9,0.7,0.9,1.5
			c0,0.2,0,0.3,0,0.4c0.1,0,0.3,0,0.5,0.1c0.5,0.2,0.7,0.5,0.7,0.5l0,0c0.1,0,0.3-0.1,0.7-0.2l0.3-0.1l0.1,0.3
			c0.1,0.4,0.2,0.9,0.4,1.1c0.1,0.2,0.4,0.3,0.8,0.7c0.7,0.9,0.5,2.2,0.6,3c0,0.9,0.1,1.8,0.5,2.4c0.2,0.3,0.6,0.5,0.9,1.1
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.5-0.9,1.1-1.1c0.1,0,0.1,0,0.2,0c0.6,0,1.4,0.6,1.6,0.5l0,0
			c0,0,0,0,0-0.1V173h0.3c0.8,0,1,0.4,1.3,0.4c0.1,0,0.2,0,0.3-0.1c0.2,0,0.3-0.3,0.8-0.3c0.1,0,0.1,0,0.2,0
			c0.2,0.1,0.3,0.1,0.4,0.2c0-0.1,0-0.2,0-0.3v-0.2l0.2-0.1c0.5-0.2,0.8-0.5,1.3-0.5s0.8,0.3,1.2,0.9c0.2,0.3,0.2,0.5,0.4,0.7
			c0.1,0.2,0.2,0.2,0.2,0.2l0,0l0,0c0.1,0,0.2-0.1,0.3-0.3c0.2-0.2,0.4-0.6,0.9-0.6c0,0,0,0,0.1,0c0.5,0,0.9,0.3,1.1,0.3l0,0
			c0.1,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.4,0.1,0.5,0.3,0.7,0.3c0.3,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.2,1.2-0.2l-0.2-0.3l0.5-0.1
			c0.2,0,0.4,0,0.6-0.1c0.4,0,0.7,0,1.1,0.1c0.4,0.1,0.7,0.3,0.9,0.4c-0.1-0.2-0.4-0.3-0.5-0.9c0-0.3,0.2-0.6,0.5-1
			c0.4-0.4,0.8-0.6,1.3-0.6s1,0.2,1.4,0.5l0.2,0.1v0.2l0,0c0,0.5-0.2,0.8-0.2,1.1c0.2,0,0.3,0,0.5,0h0.4v0.4v0.1
			c0,0.2,0.1,0.2,0.3,0.7c0.2,0.4,0.2,0.8,0.3,1.1c0.1,0.2,0.2,0.4,0.4,0.7l1.5-9.6l0.4,0.1c6.9,1.1,29.9,4,51.2,6.5
			c19.6,2.4,37.7,4.5,40.5,4.8l6-74C257.2,99,205.7,90.8,161.6,82l-4.2,17.9L158.7,102.3z"/>
									<path fill="#DDDDDC" d="M768.9,145.4L768.9,145.4c-0.5-0.2-0.9,0.2-0.9,0.7c0,0.4,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.3,0.6
			c0.7,0.1,0-0.7,0-0.8C768.8,146.2,769.4,145.4,768.9,145.4z"/>
									<path fill="#DDDDDC" d="M394.9,114.7C394.9,114.8,394.9,114.8,394.9,114.7c-0.1,0.7-0.6,0.9-0.9,1.2c-0.3,0.2-0.5,0.4-0.5,0.6l0,0
			c0,0,0,0,0.1,0.1s0.3,0.2,0.4,0.5c0.1,0.4,0.1,0.7,0.2,1.1c0,0.1,0,0.3,0,0.4c0,0.7-0.1,1.3-0.2,1.8l0,0v0.1c0,1.4,0,3.1,0.2,4.5
			c0.1,1.2,0.8,2.7,1.3,3.9c0.6,1.4,0.7,2.9,1.3,4.2c0.2,0.5,0.8,1,1,1.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.5-0.2,0.9-0.2,1.4
			c0,0.6,0.3,1.1,0.4,1.9c0,0.1,0,0.2,0,0.2c0,1-0.5,1.9-0.4,2.7l0,0c0,0.6,0.3,1.4,0.3,2.1c0,0.1,0,0.2,0,0.2
			c-0.1,0.8-0.4,1.3-0.4,1.8c0,0.2,0,0.3,0.1,0.5c0.1,0.5,0.9,0.9,1,1.8c0,0.1,0,0.1,0,0.2c0,1-0.6,1.6-0.6,2.2l0,0h0.2v0.2
			c0,0.1,0,0.2,0,0.3c0,0.6-0.3,0.9-0.3,1.2l0,0l0,0c0,0.3,0.2,0.9,0.3,1.3c0.2,0.8,0.5,2.2,1.1,2.3h0.2l0.1,0.2
			c0.3,0.9,0.1,1.8,0.4,2.3c0.1,0.2,0.6,0.4,0.9,1.1c0,0.1,0.1,0.2,0.1,0.4c0,0.7-0.5,1-0.5,1.3v0.1c0,0.6,0.4,1.1,0.4,2
			c0,0.1,0,0.1,0,0.3c0,0.1,0,0.5,0,1.1c0,0.2,0,0.3,0,0.5h0.1l-0.1,0.4v0.1l0,0l-0.6,2.5l-0.1,0.1c0,0-0.5,0.5-1.4,0.9
			c-0.5,0.2-0.9,0.3-1.1,0.4c-0.2,0.1-0.2,0.1-0.3,0.2c-0.1,0.5-0.1,0.9-0.1,0.9v0.1l-0.1,0.1l-0.2,0.3l1.2,1.2l0.7,1.3l0.4,0.7
			l3,1.7v0.1l0.3,0.8l-0.6,33.4c1,0,2,0,2.9,0c14.3,0,21.5,0.8,31.4,0.8c8,0,17.9-0.5,34.8-2.3c0-0.4,0.3-0.7,0.2-0.8
			c0,0,0,0-0.2-0.2l-0.1-0.1l-0.1-0.1l-0.1-0.1c-0.2-0.1-0.2-0.2-0.2-0.2s0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0-0.4,0.1-0.8,0.2-1.1
			c0-0.1,0-0.2,0-0.3c0-0.2,0-0.2-0.1-0.3s-0.3-0.4-0.4-0.8c0-0.1,0-0.2,0-0.3c0-0.6,0.4-1,0.3-1.2l0,0c0-0.4,0-0.2-0.5-0.7
			c-0.7-0.8-0.7-1-1.5-1.2c-1.1-0.3-1.3-1-1.8-1.5c-1.1-1.1-2.8-1.4-4.1-2.7c-0.6-0.7-0.7-1.5-1.1-2c-0.6-0.8-0.5-0.5-1.7-0.8
			c-1.3-0.5-1.2-1.6-2.1-1.7h-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.5,0-0.8-0.3c-0.4-0.6-0.2-1-0.4-1c-0.1-0.1-0.2-0.1-0.4-0.1
			c-0.3,0-0.7,0.1-1.1,0.1c-0.3,0-0.6-0.1-0.9-0.3c-0.6-0.4-1.3-1.2-1.7-1.7c-0.3-0.3-0.5-0.7-0.8-0.9c-0.1-0.1-0.6-0.1-1-0.5
			c-0.4-0.3-0.5-0.8-0.5-1.2s0.1-0.8,0.1-1.2s0-0.9,0-1.3c0-0.4,0-0.8,0-1.3c0-0.6-0.4-1.1-0.5-2c0-0.1,0-0.2,0-0.3
			c0.1-0.5,0.3-0.8,0.3-1c0-0.1,0-0.1,0-0.1c0-0.2-0.5-0.6-0.5-1.3c0-0.1,0-0.1,0-0.1c0.1-0.6,0.5-0.9,0.5-1.1c0-0.1,0-0.1,0-0.2
			c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2,0-0.2c0.3-1,1.2-1.2,1.2-1.7c0-0.1,0-0.1,0-0.1c0-0.5-0.5-0.5-1.3-0.9l-0.4-0.2l0.2-0.3
			c0.1-0.1,0.1-0.2,0.1-0.3l0,0c0,0-0.2-0.1-0.4-0.1c-0.3-0.1-0.6,0-1-0.2c-0.5-0.4-0.7-1.1-0.7-1.7c0-0.5,0.1-1,0.4-1.4
			c0.7-1,1.4-1.2,1.4-2.1c0-0.3-0.1-1,0.3-1.5c0.2-0.3,0.4-0.5,0.7-0.5c0.3,0,0.4,0.1,0.5,0.2c0-0.2,0-0.3,0.1-0.5
			c0.1-0.3,0.4-0.7,0.9-0.8c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.5,0.1c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.4-0.3,0.5-0.3
			c0.1-0.1,0.3-0.1,0.3-0.1c0-0.1,0-0.1,0.1-0.2l0,0c0,0,0,0,0-0.1c0-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.3-0.1,0.4-0.1s0.1,0,0.2,0
			c0-0.1,0-0.1,0-0.2c0-0.6-0.1-1.4-0.2-2c-0.1-0.7-0.1-1.5-0.1-2.2c0-0.1,0-0.2,0-0.2v-0.1c0-1,0.1-1.9,0.2-2.8
			c0-0.5,0.1-0.9,0.1-1.4c0-0.2,0-0.4,0-0.5c0-0.2-0.1-0.6-0.1-0.9c0-0.3,0.1-0.6,0.3-0.8l0.2-0.2l0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.6,0.3-0.9l0.2-0.3l0.3,0.3c0.1,0.1,0.1,0.1,0.1,0.1l0,0c0.1-0.2,0.1-0.5,0.2-0.8
			c0.3-0.6,1-1.1,1.3-1.6l0.4,0.4c0.4-0.5,0.9-0.9,1.2-1.2c1.4-1.1,2.9-2,4.1-3.2s2.1-2.6,3.2-3.9c2.9-3.6,5.9-7.4,10.1-9.4
			c1.8-0.8,3.7-1.3,5.5-2.2c1.8-0.8,3.4-1.9,5-3.1c-0.1-0.8-1.5-1.2-2.2-0.9c-0.6,0.3-0.7,1-1.4,0.7c-0.4-0.1-0.8-0.7-1.1-1
			c-1.2-0.9-2.4-0.2-3.7-0.1c-0.3,0-0.9,0.1-1.2,0c-0.3-0.1-0.3-0.3-0.6-0.4c-0.6-0.3-1-0.2-1.7-0.2c-0.6,0-1,0.3-1.6,0.3
			c-1,0.1-0.6-0.4-0.7-1.1c-0.2,0-0.5-0.1-0.7,0c0.1-0.4,0.4-1,0.3-1.5c-0.7-0.1-0.8,0.3-1.3,0.6c-0.4,0.2-0.9,0-1.3,0.2
			c-0.8,0.5-1.4,1.4-2.2,1.9c-0.3,0.2-0.8,0.3-1.1,0.5c-0.4,0.3-0.4,0.7-1,0.8c-0.5,0-0.7-0.4-1.2-0.4c-0.6,0-0.9,0.5-1.5,0.4
			c0-0.2-0.1-0.3-0.1-0.5c-0.5-0.1-1.5-0.4-1.7-0.9c-0.2-0.4,0-0.8-0.4-1c-0.3-0.2-0.9,0.1-1.2,0c-0.4-0.1-0.6-0.5-0.9-0.7
			s-0.6-0.1-1-0.3c-0.7-0.4,0.1-1.8-1.3-1.7l-0.4-0.4c-0.7,0-1.6,0.9-2.6,0.8c0.2,0.4,0.4,1,0.5,1.4c-0.4,0.1-0.8,0.5-1.2,0.6
			c-0.2-0.3-0.1-0.7-0.1-1.1c-0.3,0-0.6,0-0.8,0c0-0.2,0-0.6-0.1-0.8c-0.1-0.3-0.4-0.3-0.5-0.5c-0.3-0.4-0.4-1.1-0.5-1.6
			c-0.7,0-1.8,0.2-2.4,0.1c-1-0.3,0.4-1,0.1-1.9c-0.4,0-0.9,0.2-1.4,0.1c-0.4-0.1-0.6-0.3-0.9-0.5c-0.4-0.1-0.9,0-1.3-0.2
			c-0.4-0.1-0.6-0.4-1-0.5c-0.2-0.1-0.3,0.2-0.5,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.2-0.3-0.4-0.9-0.4c-0.7,0.1-1.7,0.5-2.4,0.7
			c-0.6,0.3-0.9,0.7-1.4,1c-0.6,0.4-1.3,0.4-2,0.5c-1,0.2-1.5,0.5-1.9-0.6c-0.3-0.8-0.2-0.9-1.1-1c-0.7-0.1-1.4-0.1-2.2-0.2
			c-0.4-0.1-0.7-0.2-1.1-0.2s-0.7,0.2-1.2,0.2c0.9-2.7-2.6-0.8-3.5-1.1c-0.6-0.2-1-0.8-1.5-1.2c-0.7-0.5-0.8-0.8-0.5-1.6
			c0.3-0.8,0.4-1.1,0-2c-0.3-0.7-0.7-1.3-0.8-2c-0.3-1.4,0-3.2-1-4.3c-0.3-0.4-0.7-1.1-1.3-1.2c-0.8-0.2-1.2,0.3-2-0.2l-0.6,0.4v6.5
			c-8.2,0.3-16.8,0.4-25.5,0.3c0.1,1.2,0.7,2.7,0.9,4.1C394.4,113.1,394.9,114.1,394.9,114.7z"/>
									<path fill="#DDDDDC" d="M501.3,160.6L501.3,160.6L501.3,160.6z" />
									<path fill="#DDDDDC" d="M400.5,165.2c0-0.2,0-0.3,0-0.5c0-0.6,0-0.9,0-1.1v-0.1c0-0.1,0-0.1,0-0.2c0-0.6-0.4-1.1-0.4-2
			c0-0.1,0-0.1,0-0.2c0.1-0.7,0.5-1,0.5-1.2v-0.1c0-0.2-0.5-0.4-0.8-0.9c-0.4-0.8-0.3-1.7-0.4-2.3c-1.1-0.5-1.2-2.1-1.4-2.7
			c-0.1-0.4-0.3-1-0.3-1.5v-0.1c0-0.5,0.2-0.8,0.3-1h-0.2l-0.1-0.2c0-0.1,0-0.3,0-0.4c0-1,0.6-1.6,0.6-2.2v-0.1
			c0-0.5-0.6-0.8-0.9-1.6c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.8,0.4-1.2,0.4-1.8c0-0.1,0-0.1,0-0.2c0-0.6-0.3-1.3-0.3-2.1c0,0,0,0,0-0.1
			c0-1,0.5-1.9,0.4-2.7c0-0.1,0-0.1,0-0.2c0-0.6-0.4-1.1-0.4-2c0,0,0-0.1,0,0c0-0.6,0.2-1,0.2-1.3c0-0.1,0-0.3-0.1-0.4
			c-0.2-0.5-0.7-1-1.1-1.7c-0.6-1.4-0.7-2.9-1.3-4.2c-0.5-1.2-1.2-2.7-1.4-4.1c-0.2-1.4-0.2-3.2-0.2-4.5c0,0,0,0,0-0.1
			c0-0.7,0.2-1.3,0.2-1.9c0-0.1,0-0.2,0-0.3c0-0.4,0-0.6-0.1-1l0,0l-0.1-0.1c-0.1-0.1-0.4-0.2-0.4-0.6c0-0.1,0-0.1,0-0.2
			c0-0.6,0.5-1,0.8-1.2c0.4-0.2,0.6-0.4,0.6-0.5v-0.1c0-0.3-0.5-1.3-0.6-1.9c-0.2-1.2-0.8-2.8-0.9-4.2c-26.7-0.2-55.3-1.9-84.3-4.7
			l-4.5,56.3C309.8,160.5,390.9,164.7,400.5,165.2z"/>
									<path fill="#DDDDDC" d="M758,203.3c-0.2-0.3-0.4-0.3-0.7-0.5v-0.1c-0.5,0-0.9,0.7-1.1,1.2c-0.1,0.4-0.4,0.5-0.6,0.9
			c-0.1,0.3,0,0.6-0.1,0.9c-0.3,0-0.6-0.2-0.7,0.1c-0.2,0.3,0.2,0.5,0.4,0.6c0.5,0.2,0.9-0.5,1.4-0.7s0.9-0.6,1.4-0.7
			c0.4-0.1,1.3-0.5,0.8-1C758.5,203.6,758.2,203.6,758,203.3z"/>
									<path fill="#DDDDDC" d="M405.4,211.1c-1.5,0-3.1,0-4.9,0c-0.2,0-0.2,0-0.2,0.2s0.1,0.5,0.2,0.6s0.1,0.1,0.2,0.1
			c0,0,0.4,0.1,0.5,0.3c0.1,0.1,0.1,0.3,0.1,0.5v0.3h-0.1c-0.1,0.1-0.2,0.2-0.2,0.2l0,0l0,0c0,0.1,0.1,0.2,0.1,0.5l0,0
			c0,0.5-0.3,0.8-0.3,0.8c0,0.1,0,0.1,0,0.2l0,0c0.1,0,0.2,0.2,0.4,0.4c0.2,0.3,0.3,0.3,0.4,0.7c0,0.2-0.1,0.3-0.2,0.6
			c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0.1,0.1c0,0.1,0.5,0,0.9,0.6c0.1,0.2,0.1,0.3,0.1,0.4c0,0.4-0.1,0.7-0.2,1
			c-0.3,0.6-0.8,0.5-0.8,0.6c-0.2,0.3-0.2,0.2-0.2,0.8c0,0.5-0.1,0.7-0.2,1.1c-0.1,0.3-0.1,0.7-0.2,1.2c-0.3,0.8-0.6,0.9-0.6,1.1
			c0,0.1,0,0.2,0.1,0.5s0.4,0.6,0.4,1.2l0,0c0,0.3,0,0.5,0,0.6s0,0.2,0.1,0.4c0.1,0.3,0.4,0.4,0.5,1s0,0.7,0.3,0.9l0.5,0.6l-0.1,0.2
			l0,0l0,0c0.1,0,0.2,0.1,0.4,0.2c0.3,0.1,0.7,0.2,1,0.7c0,0.1,0,0.2,0,0.3c-0.1,0.8-0.7,1.3-0.6,1.6v0.1c-0.1,0.1,0.3,0,0.7,0.5
			c0.1,0.2,0.1,0.3,0.1,0.4c0,0.3-0.1,0.6-0.1,0.7c0,0,0,0,0,0.1c0,0.2,0.2,0.4,0.3,0.8c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,0.7
			c0,0.1,0,0.1,0,0.1c0.1,0.2,0.2,0.2,0.6,0.3c0.4,0,0.9,0.4,1,1.1c0,0,0,0.1,0,0.2c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.1
			c0,0.1,0.1,0.2,0.1,0.3c0,0,0.2,0.1,0.4,0.4c0.6,0.8,0.3,1.5,0.6,2c0,0.2,0.4,0.3,0.5,0.9c0,0.1,0,0.2,0,0.2
			c0,0.4-0.1,0.6-0.1,0.8v0.1l0,0c0,0.4,0.6,0.8,0.7,1.6c0,0.1,0,0.3-0.1,0.4c-0.3,0.6-0.9,0.9-0.8,1c0,0,0,0.1,0.1,0.3
			c0.2,0.2,0.3,0.2,0.5,0.3s0.5,0.5,0.5,0.9c0,0.1,0,0.2,0,0.4c0,0.3-0.2,0.6-0.2,0.7h0.1c0.1,0,0.4,0,0.6,0.2
			c0.3,0.3,0.5,0.9,0.6,1.1c0.1,0.2,0.3,0.3,0.4,0.7c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.8-0.6,1.2-0.5,1.4c0,0,0,0,0,0.1l0,0l0,0
			c0.1,0,0.4,0.1,0.5,0.4c0.1,0.2,0.1,0.3,0.1,0.5c0,0.5-0.3,0.7-0.4,1c-0.1,0.1-0.2,0.2-0.3,0.3l0,0l0,0c0,0.1,0.6,0.1,1.1,0.3
			s0.8,0.6,0.8,1c0,0.6-0.4,1.1-0.5,1.6c0,0.1,0,0.2,0,0.3c0,0.4,0.3,0.6,0.4,1.5l0,0c0,0.7-0.5,2.2-0.5,3.1c0,0.6,0.1,0.6,0.3,0.7
			l0,0h0.3v0.3c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.8,0.1,1.1,0.5c0.1,0.1,0.1,0.2,0.1,0.3
			c0.2,0,0.3,0,0.5,0c1.3,0,2.7,0,4,0c0.1,0,0.2,0,0.3,0c4,0,8,0,11.9-0.1c5,0,10.1-0.2,15.1-0.3c4.8-0.1,9.6-0.4,14.3-0.6
			c1.9-0.1,3.7-0.4,5.7-0.4c0.6,0,2-0.6,3.1-0.6c0.5,0,1,0.1,1.3,0.5s0.3,0.8,0.3,0.9l0,0c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0.3
			s0.2,0.6,0.2,0.7s0,0.1,0,0.2c0,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0.2c0.4,0.5,0.2,0.9,0.3,0.9c0.1,0.2,0.4,0.1,0.8,0.5
			c0.1,0.1,0.2,0.3,0.3,0.5c0.2-0.1,0.3-0.1,0.3-0.2c0.1,0,0,0,0.1-0.1c0-0.1,0-0.1,0-0.1s-0.2-0.3-0.2-0.7v-0.1
			c0.1-0.6,0.4-0.7,0.4-0.8l0,0c0.1-0.1-0.4-0.3-0.6-0.9c0-0.1,0-0.2,0-0.2c0.1-1,1-1.6,1.6-1.8c0.2-0.1,0.4-0.1,0.6-0.1
			c0.3,0,0.6,0.1,0.8,0.1c0.2,0,0.3,0,0.4-0.1c0.2-0.1,0.7-1.4,0.9-1.9c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3-0.4-0.6-0.5-1.5V259
			c0.1-1.1,1.1-1.5,1.5-1.9c0.4-0.3,0.4-0.5,0.4-0.6c0-0.2-0.1-0.4-0.1-0.8c-0.1-0.7-0.2-1.3-0.5-1.8c-0.3-0.4-0.8-0.6-1.3-1.3
			c-0.3-0.4-0.5-0.8-0.5-1.2s0.2-0.7,0.4-1.1c0.1-0.1,0.2-0.7,0.3-1.3c0.1-0.5,0.1-1.1,0.7-1.4c0.1-0.1,0.3-0.1,0.4-0.1
			c0.5,0,0.9,0.3,1.1,0.2l0,0c0.2,0,0.3,0,0.4,0c0.2,0,0.1,0,0.5-0.4c0.1-0.2,0.1-0.2,0.2-0.4s0.4-0.3,0.6-0.3c0.1,0,0.2,0,0.2,0
			c0.6,0,1,0.3,1.3,0.3l0,0c0.4,0,0.4-0.1,0.6-0.5c0.1-0.3,0.4-0.9,1.1-0.9c0.1,0,0.1,0,0.1,0c0.3,0,0.5,0.1,0.6,0.2
			c0,0,0.1,0,0.2-0.1c0.2-0.1,0.6-0.4,0.7-0.7c0.2-0.3,0.3-0.7,0.4-1.1c0.1-0.3,0.5-0.7,1-0.8c0-0.3,0-0.5,0-0.8
			c0-0.4,0-0.7,0.1-1.1l0.1-0.3h0.3c0.1,0,0.1,0,0.2,0c0.7,0,1-0.3,1-0.6c0-0.1,0-0.3-0.1-0.5c-0.2-0.4-0.3-0.6-0.3-0.9
			c0.1-0.7,0.5-0.8,0.8-1.4c0.1-0.3,0.2-0.4,0.2-0.4s0-0.1-0.2-0.5c-0.3-0.6-0.2-0.8-0.2-1.3c-0.2-1.5-1.8-2.2-3.3-2.5l-0.3-0.1
			v-0.3c0-0.4,0-0.8,0-1c0-0.8-0.1-1.2-1-1.6c-1-0.4-1-1-1.3-1.5c0-0.1-0.2-0.1-0.5-0.2c-0.2-0.1-0.6-0.1-0.8-0.5
			c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.5,0.2-0.8,0.2-1c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.8-0.4-1.3-0.8c-0.6-0.5-0.3-0.3-1.1-0.5
			c-0.9-0.2-1.1-0.8-1.6-1c-0.1,0-0.1,0-0.2,0s-0.4,0.1-0.8,0.2c-0.1,0-0.2,0-0.4-0.1c-0.4-0.1-0.6-0.6-0.6-0.9
			c0-0.2,0.1-0.5,0.1-0.6v-0.1c-0.1-0.8-0.8-1.6-1-2.6c-0.1-0.2-0.1-0.5-0.1-0.7s0-0.4,0-0.6c0-0.1,0-0.2,0-0.4
			c-0.1-0.4-0.5-0.7-0.6-1.4c0-0.2-0.1-0.3-0.1-0.4c0-0.7,0.4-1.1,0.8-1.3c0.3-0.3,0.6-0.5,0.6-0.9c0-0.1,0-0.2,0-0.2
			c0-0.3-0.1-0.2-0.6-0.7c-0.6-0.5-0.5-1-0.8-1.3c-0.1-0.2-0.9-0.2-1.1-1c0-0.1,0-0.2,0-0.2c0-0.5,0.3-0.9,0.4-1.1
			c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.2c-17,1.8-26.9,2.3-35,2.3C426.8,211.9,419.7,211.1,405.4,211.1z"/>
									<path fill="#DDDDDC" d="M739,216.4c-0.8,0.3-1.7,0.7-2.4,1.3c-0.1,0.1-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
			c-0.1,0.1-0.2,0-0.3,0.1s0,0.2-0.1,0.2c-0.3,0.4-0.6,0.7-0.8,1.2c-0.1,0.3-0.3,0.6-0.7,0.5c-0.2-0.1-0.2-0.2-0.4-0.2s-0.4,0-0.5,0
			c-0.4-0.1-0.7,0-1.1-0.1c0.2-0.4,0.7-0.6,1-0.9c0.3-0.3,0.3-0.7,0.5-1c0.4-0.6,1.2-1.4,1.2-2.1c-0.6,0.2-0.9,0.9-1.2,1.3
			c-0.3,0.3-0.6,0.7-1,1c-0.8,0.5-1.5,1.1-2.4,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6,0c-0.1,0.1-0.1,0.2-0.2,0.2
			c-0.1,0.1-0.1,0.1-0.3,0.2c-0.7,0.3-1.4,0.4-2.1,0.5c-0.4,0-0.9-0.1-1.2,0.1c-0.3,0.3-0.2,0.7-0.2,1.1c-0.3,0-0.3,0.4-0.5,0.5
			c-0.3,0.2-0.9,0.3-1.3,0.4c-0.4,0.1-0.7,0.4-1.1,0.4c-0.5,0-0.6-0.1-1,0.3c-0.4,0.4-0.4,0.2-0.9,0.2c-0.7-0.1-1.6,0.3-1.6,1.1
			c0,0.6,0.1,0.7-0.5,1c-0.2,0.1-0.5,0.2-0.7,0.3s-0.3,0.3-0.5,0.4c-0.2,0.1-0.5-0.1-0.6,0c-0.1,0-0.1,0.2-0.2,0.3
			c-0.1,0.1-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.4,0.1,0.7-0.2,1.1c-0.3,0.3-0.7,0.4-0.9,0.8c-0.1,0.3,0.1,1,0.3,1.3
			c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0.1-0.3c0.3-0.3,0.5,0.2,0.8-0.3c0.2-0.3,1.2-2,1.7-1.4
			c0.2,0.2-0.1,0.8,0.1,1.2c0.3,0.5,0.7,0.4,0.6-0.2c0-0.3-0.2-0.4,0-0.6c0.2-0.2,0.6-0.3,0.8-0.3c0.2,0.1,0.4,0.3,0.6,0.3
			c0.3-0.1,0.3-0.4,0.3-0.7c0.5-0.1,0.9,1,1.3,0.2c0.1-0.3,0.1-0.5,0.4-0.7c0.4-0.3,1-0.5,1.4-0.8c0.6-0.4,1.1-0.7,1.7-1
			c1.2-0.6,2.5-0.9,3.5-1.8c1-0.9,2.3-1.3,3.6-1.8c1.1-0.5,1.9-1.3,2.8-2c1-0.8,1.7-1.8,2.7-2.6c0.8-0.6,1.6-0.7,2.2-1.5
			c0.1-0.2,0.9-0.6,1-0.8C740.5,215.1,739.6,216.2,739,216.4z"/>
									<path fill="#DDDDDC" d="M773.6,141.7c-0.1-0.2,0-0.5,0-0.7c-0.2-0.1-0.4-0.2-0.7-0.1l-0.2-0.1c-0.3,0.1-0.4-0.1-0.6-0.1
			c-0.3,0-0.4,0.3-0.4,0.6c0,0.2,0.1,0.3,0,0.6c-0.1,0.2-0.3,0.3-0.3,0.5c-0.1,0.4,0.2,2.4,0.9,1.5c0.1,0.2,0.2,0.5,0.4,0.5
			s0.3-0.4,0.2-0.6c-0.1-0.4-0.7-0.8,0-0.9c0.4-0.1,1.1,0.1,0.9-0.6C773.8,142,773.6,141.9,773.6,141.7z"/>
									<path fill="#DDDDDC" d="M705.1,149.5c0,0.3,0,0.5,0.1,0.6c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.5,0.1,0.8,0.3,1.2
			c0.3,0.9,0.3,1.9,0.5,2.6c0.2,0.7,1.1,1,1.2,2.2c0,0.1,0,0.3,0,0.5v0.2l-0.1,0.1c-0.4,0.3-0.5,0.4-0.5,0.6c-0.1,0.5,1,1.5,1.6,2
			l0.3,0.2l-0.2,0.3c-0.2,0.2-0.2,0.3-0.2,0.6c0,0.1,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c-0.2,0.8-0.5,1.3-0.6,1.8c0,0,0,0,0,0.1
			c-0.1,0.9,1.1,1.2,1.5,2.5c0,0.1,0,0.2,0,0.3c0,0.5-0.2,0.8-0.2,1.1c0,0.1,0,0.2,0.1,0.3c0.1,0.4,0.7,0.6,0.8,1.6c0,0,0,0,0,0.1
			c0,0.4-0.2,0.9-0.2,1.1l0,0c0,0.3,0.1,0.8,0.1,1.2c0.3-0.2,0.6-0.4,1-0.5l0,0c0.5,0,0.9,0.3,1,0.7c0.1,0.3,0.2,0.6,0.2,1l0.3-0.1
			l3.2,14.5l9.8-2.2c-0.1-0.6-0.8-0.9-1.2-1.6c-0.3-0.6-0.5-0.9-0.5-1.4c0-0.2,0-0.5,0.1-0.8c0-0.3,0.1-0.5,0.1-0.8
			c0-1-0.2-2.1-0.2-3.1c0-0.3,0-0.5,0.1-0.8c0.1-0.5,0.1-0.8,0.1-0.9c0-0.2-0.1-0.4-0.3-1s-0.4-1.3-0.4-1.9
			c-0.1-0.7-0.3-1.7-0.3-2.7c0-0.6,0.1-1.1,0.2-1.6c0.3-0.9,0.5-1,0.5-1.8c0-0.7,0.1-1.4,0.4-2c0.2-0.6,0.3-1.1,0.3-1.7
			c0-0.5-0.1-1-0.1-1.6c0-0.5-0.1-0.7-0.2-1c0-0.2,0.1-0.5,0.2-0.8c0.3-0.6,0.6-0.8,0.5-1.2c0-0.1,0-0.1,0-0.1
			c-0.1-0.4-0.1-0.4-0.3-0.5s-0.5-0.2-0.8-0.6c-0.3-0.4-0.4-0.6-0.4-0.9c0-0.5,0.4-0.6,0.6-0.7c0.2-0.1,0.4-0.2,0.5-0.3
			c0,0,0-0.1,0-0.2c0-0.2,0-0.4,0-0.7s0.1-0.7,0.4-0.9c0.2-0.1,0.4-0.2,0.5-0.2c0.6,0.1,0.9,0.5,1.1,0.4c0,0,0,0,0.1,0
			c1.1-0.1,0.8-2.1,1.2-3.3l0.1-0.2h0.2c0.6-0.1,0.7-0.7,0.7-1.5c0-0.1,0-0.1,0-0.2c0-0.8-0.2-0.5-0.6-1.3c-0.1-0.1-0.3-0.3-0.4-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.3,0.2-0.4,0.2-0.4c-0.1-0.3-0.5-0.7-0.5-1.2c0-0.1,0-0.1,0-0.1c0-0.9,0.7-1,0.7-1.3
			c0.1-0.2,0.1-0.3,0.1-0.4c0-0.4-0.6-1.2-0.7-1.9c-1.2,0.5-4,1.1-4.9,1.4c-1,0.3-2,0.5-3,0.8c-1.2,0.4-2.5,0.7-3.8,1
			c-1,0.3-2.2,0.5-3.2,0.8c-1.1,0.2-2.1,0.6-3.2,0.8c-1.4,0.3-2.8,0.6-4.2,0.9c0.1,0.3,0.1,0.7,0.1,0.9
			C705.1,148.9,705.1,149.2,705.1,149.5z"/>
									<path fill="#DDDDDC" d="M786.1,128.4c-0.3,0.1-0.5,0.1-0.7,0.3c-0.5,0.5-0.6,0.4-1-0.1c-0.2-0.3-0.5-0.8-0.6-1.1
			c-0.2-0.7,0.7-0.6,1-0.9c0.3-0.4-0.1-1.2-0.4-1.5c-0.3-0.3-0.6-0.5-0.9-0.9c-0.2-0.3-0.5-0.5-0.7-0.8c-0.4,0.1-0.9-0.2-1.3,0
			c-0.3,0.1-0.5,0.6-0.7,0.9c-0.7,0.9-1.3-0.2-1.8-0.8c-0.3-0.3-0.7-0.6-0.9-1s-0.1-0.8,0-1.3c0.1-0.4,0-0.8-0.3-1.2
			c-0.2-0.4-0.9-0.6-1.1-1c-0.1-0.3,0.3-0.5,0.2-0.9s-0.3-0.6,0.3-0.7c0-0.3-0.3-0.7-0.5-1c-0.5-0.5-0.3,0-0.6,0.3
			c-0.1,0.1-0.4,0.2-0.5,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.7-0.1-1.5-0.4-1.9-1.1c-0.3-0.5,0,0-0.6-0.1c-0.6,0-0.2,0.3-0.3,0.4
			c-0.1,0-0.6-0.1-0.6-0.1s-0.3-0.5-0.3-0.7s0.2-0.3,0.2-0.5c0-0.1-0.3-0.3-0.3-0.3s-0.2-0.5-0.1-0.9c0.1-0.4,0.3-0.1,0.4-0.3
			c0-0.2-0.1-0.1-0.6-0.4c-0.4-0.3-0.2-0.5,0-0.8c0.1-0.3,0.4-0.1,0.4-0.5s-0.3-0.2-0.5-0.5c-0.1-0.2-0.7-1.4-0.9-2.1
			s-2.8-9.1-2.8-9.1s-0.8-2.3-1-3.1c-0.2-0.8-0.7-2.3-1.1-3.6c-0.4-1.4-0.4-1.3-0.4-1.3c0.1-0.2,0-0.3-0.1-0.4
			c-0.2-0.3-0.8-0.2-1.1-0.4c-0.3-0.2-0.5-0.5-0.8-0.7c-0.4-0.3-0.9-0.3-1.2-0.6c-0.2-0.2-0.1-0.3-0.3-0.5c-0.2-0.1-0.4-0.1-0.6-0.2
			c-0.2-0.1-0.3-0.2-0.5-0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.2-0.5-0.4-0.6
			c-0.2,0-0.5,0.2-0.6,0.2c-0.3,0.1-0.7,0-1,0.2c-0.5,0.3-0.3,0.6-0.2,1.1c0.1,0.7-0.4,0.3-0.8,0.5c-0.4,0.1-0.4,0.6-0.9,0.7
			c-0.2,0-0.4-0.1-0.6,0c-0.2,0.2-0.1,0.4-0.2,0.6c-0.2,0.5-0.6,0.1-0.9,0.4c-0.4,0.3-0.4,0.7-0.9,0.9c-0.5,0.2-0.8,0.3-1.2,0.7
			c-0.4,0.4-0.5,0.4-1.1,0.3c-0.4-0.1-0.8,0.1-1.1-0.1s-0.5-0.7-0.5-1.1c0-0.5-0.3-2.2-0.6-2.4c-0.2-0.2-2.3-0.1-2.3-0.1
			s-0.6,1.3-0.9,1.9c-0.5,1.3-0.8,2.7-1.2,4c-0.4,1.4-0.9,2.8-1.4,4.1c-0.6,1.8-1.4,3.4-2,5.3c-0.3,1.1-0.2,2.3-0.1,3.5
			c0.1,0.5,0.1,0.9,0.1,1.4c0,0.4,0.2,0.9,0,1.4c-0.1,0.2-0.4,0.2-0.5,0.4c-0.1,0.2,0.1,0.4,0.1,0.6c-0.1,0.3-0.1,0.2-0.3,0.3
			c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.2,0.1-0.2,0.5s0.3,0.6,0.3,0.9s-0.3,0.6-0.4,0.9c-0.2,0.5,0,0.5,0.2,0.8
			c0.1,0.2,0,0.4,0.1,0.5c0.1,0.2,0.3,0.2,0.4,0.3c0.4,0.3,0.2,0.3-0.1,0.6c-0.2,0.2-0.2,0.2-0.2,0.5s0.2,0.3,0.2,0.5
			c0.1,0.2-0.2,0.8,0.3,0.8c0,0.3-0.1,0.6,0,0.9c0.2,0.5,0.8,0.2,0.8,0.9c0,0.5-0.1,0.9-0.6,1.1c-0.6,0.3-0.3,0.5-0.6,1.1
			c-0.1,0.2-0.3,0.3-0.2,0.5c0.1,0.3,0.4,0.3,0.5,0.6c0.2,0.4-0.4,0.7-0.6,1c-0.2,0.2-0.2,0.5-0.5,0.6c-0.2,0.1-0.4,0.1-0.6,0.2
			c-0.2,0.2-0.2,0.5-0.3,0.8s-0.3,0.4-0.4,0.6c-0.3,0.4-0.1,0.9-0.4,1.3c-0.5,0.7-0.2,0.6,0.1,1.2c0.1,0.2,0.1,0.4,0.3,0.6
			c0.1,0.2,0.4,0.3,0.5,0.5c0.3,0.4,0.3,1.1-0.3,1.1c-0.5,0-0.8-0.8-1.3-0.6c-0.6,0.2-0.1,0.8,0,1.1c0.3,0.5,0,0.9,0,1.4
			c0,0.3,0.4,1.2-0.1,1.3c-0.4,0.1-0.7-0.8-0.8-1.1c-0.2-0.4-0.6-0.9-1-0.4c-0.1,0.2-0.1,0.5-0.4,0.6c-0.2,0.1-0.5,0-0.7,0.1
			c-0.1,0-0.1,0.1-0.2,0.1l9.8,30.7c0.5,1,0.5,2,0.6,2.8c0.2,0.8,0.4,1.3,1.5,1.7c0.8,0.3,1.2,0.5,1.4,0.9s0.1,0.8,0.3,1.5
			c0.1,0.5,1.4,1.4,2.2,1.8c0-0.1,0-0.2-0.1-0.3c-0.3-0.5-0.3-0.7,0.1-1.1c0.7-0.6,0.3-1.7,0.6-2.6c0.1-0.2,0.2-0.5,0.1-0.7
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.7,0.2-0.7,0.7-0.9s0.9-0.5,1.2-0.9c0.2-0.3,0.5-1.2,0.1-1.5c-0.2-0.1-0.4,0-0.6-0.2
			c-0.1-0.3,0.3-0.6,0.4-0.7c0.5-0.5,0-0.7,0.2-1.2c0.2-0.7,1-0.1,1.3-0.5c0.4-0.5-0.3-0.6-0.5-0.8c-0.3-0.2-0.4-0.7-0.4-1.1
			c0-0.6,0.4-0.9,0.6-1.4c0.2-0.4,0.3-0.8,0.7-1.1c0.5-0.3,0.9-0.3,1.1-1.1c0.6-0.2,0.7,0.2,0.6,0.7c-0.1,0.3-0.6,0.8-0.1,1.1
			c0.7,0.5,1-1.9,1.1-2.3c0.2,0.1,0.1,0.4,0.2,0.5c0.5,0.1,0.2,0.9,0.4,1.3c0.2,0.4,0.7,0.8,1,0.3c0.3-0.4,0.2-0.9-0.1-1.3
			c-0.1-0.2-0.7-1.1,0-1.1c0.1,0.3,0.1,1.1,0.7,1c0.4-0.1,0.2-0.7,0.1-1c-0.3-0.1-1.1-0.6-0.9-1c0.1-0.3,0.5-0.2,0.6-0.5
			s-0.2-0.6-0.2-0.8s0.2-0.7,0.4-0.6c0.3,0.1,0,0.5,0.2,0.7c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.3
			c0.4,0.2,0.2,0.9,0.6,0.9c0-0.2,0-0.4,0.1-0.6c0.5,0.1,0.8,0.5,0.9-0.3c0.1-0.4-0.2-1,0-1.4c0.1-0.2,0.3-0.2,0.4-0.4
			c0-0.1,0-0.4,0-0.5c0-0.4-0.3-0.9-0.1-1.3c0.1-0.2,0.3-0.3,0.5-0.2s0.2,0.2,0.2,0.5c0,0.2,0.2,1.2,0.5,1.2
			c0.5,0.1,0.1-0.9,0.8-0.6c0.4,0.1,0.2,0.7,0.5,0.9c0.5,0.4,0.5-0.5,0.5-0.8c0-0.6,0.1-0.7,0.7-0.8c0-0.5,0.1-1,0.1-1.5
			c0-0.4-0.3-0.8-0.2-1.3c0-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.5,0-0.7c0-0.5-0.1-1.2,0.2-1.6l0.2-0.4c0.4-0.7-0.1-1.4-0.5-1.9
			c-1.2-1.5,2.2-1.4,1.5-2.8c-0.2-0.3-0.5-0.7,0-0.9c0.4-0.2,0.6,0.2,0.7,0.5c0.1,0.4,0.2,0.8,0.2,1.2s-0.2,0.7-0.2,1
			c0.5,0.1,0.2,0.6,0.1,0.9c-0.1,0.3-0.1,1.1,0.2,1.2c0.4,0.1,0.6-0.7,0.8-0.9c0.3-0.2,0.5,0.1,0.7,0.4c0.5-0.1,0.7,0.2,1.1,0.1
			c0.5-0.1,0.7-0.2,0.7-0.8c0-0.1,0.1-0.4,0-0.5c-0.1-0.2-0.4-0.1-0.5-0.2c-0.4-0.3-0.2-0.4,0.1-0.7c0.4-0.4,0.4-0.5,0.5-1
			c0-0.3,0.1-1.1,0.4-1.3c0.7-0.5,0.2,1.5,0.9,1.2c0.2-0.1,0.4-0.6,0.5-0.8c0.1-0.6-0.1-0.5,0.6-0.6c0.2-0.4-0.6-0.9-0.1-1.2
			c0.2-0.1,0.9-0.1,1.2-0.1s0.8,0.2,1.1,0.3c0.4,0.3,0.4,0.6,0.5,1.1s0.5,0.4,0.8,0.8c0.4-0.4,1.5-0.9,0.9-1.6
			c-0.2-0.2-0.9-0.3-0.4-0.8c0.3-0.3,0.8,0,1.1,0.1c0.2-0.4,0.7-0.5,0.8-0.9c0-0.5-0.6-0.5-0.5-1c0.1-0.2,0.2-0.2,0.4-0.3
			s0.2-0.3,0.3-0.5c0.2-0.3,0.6-0.5,0.8,0c0.1,0.4,0,0.8,0.2,1.2c0.2-0.1,0.4,0,0.6-0.3c0.1-0.2,0-0.4,0.1-0.6
			c0.2-0.5,0.8-0.4,1.1-0.1c0.4-0.3,0.5-0.5,0.2-0.9c-0.2-0.2-0.4-0.2-0.3-0.5c0-0.1,0.3-0.4,0.4-0.5c0.4-0.3,0.5-0.2,0.8,0
			c0.3,0.3,0.6,0.4,0.8-0.1c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.2-0.4-0.2-0.5c0-0.4,0.4-0.4,0.2-0.8c-0.1-0.2-0.3-0.3-0.4-0.5
			c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.3-0.4-0.9,0-1.1c0.4-0.2,0.6,0.5,0.7,0.8c0.1,0.4,0.1,0.6,0.5,0.7c0,0.2-0.1,0.5,0,0.7
			s0.3,0,0.5,0c0.5,0.1,0,0.6,0.4,0.8c0.8,0.3,1.1-1,1.2-1.5c0.4,0.2,0.4-0.5,0.5-0.7s0.2-0.3,0.3-0.5s0-0.3,0.1-0.4
			c0.1-0.5,1.2-1.3,0.6-1.8C786.5,128,786.3,128.2,786.1,128.4z"/>
									<path fill="#DDDDDC" d="M745.2,172.6c-0.2-0.8-0.2-1.3-0.3-1.4c0-0.1-0.2-0.2-1-0.6c-1.3-0.5-1.8-1.4-1.9-2.2
			c-0.2-0.9-0.1-1.7-0.6-2.6l-9.6-29.8c-0.3,0.3-0.9,0.6-1.2,0.3c-0.5,0.7-0.7-0.3-1.2-0.2c-0.4,0.1-0.6,0.8-0.7,1.2
			c-0.1,0.3-0.1,0.5-0.1,0.8s-0.1,0.5-0.2,0.7c-0.1,0.5,0.3,1-0.2,1.3c-0.2,0.2-0.3,0-0.3,0.4c0,0.2,0.1,0.5,0.2,0.6l-0.1,0.3
			c0,0.7,0.8,1.5,0.8,2.4c0,0.2,0,0.4-0.1,0.7c-0.3,0.8-0.7,0.7-0.7,1.1c0,0,0,0,0,0.1s0.4,0.6,0.6,1.1c0,0.1,0,0.1,0,0.1
			c0,0.2-0.1,0.4-0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0.2,0.5,0.7,0.6,0.7,1.6c0,0.1,0,0.1,0,0.2c0,0.7-0.1,1.8-1.1,2.1
			c-0.3,0.9,0.1,3.1-1.6,3.6c-0.1,0-0.2,0-0.2,0c-0.6-0.1-1-0.5-1.1-0.4c0,0,0,0-0.1,0c-0.1,0.1-0.1,0.1-0.1,0.3s0,0.4,0,0.7
			c0,0.2,0,0.4-0.2,0.6c-0.3,0.4-0.8,0.6-0.9,0.7c0,0.1,0.1,0.2,0.3,0.4s0.3,0.3,0.6,0.4c0.3,0.2,0.6,0.5,0.6,1c0,0.1,0,0.2,0,0.3
			c0,0.7-0.5,1.1-0.6,1.4c-0.1,0.3-0.1,0.4-0.1,0.5s0.1,0.4,0.2,1c0,0.6,0.1,1.1,0.1,1.6c0,0.6-0.1,1.3-0.3,2
			c-0.2,0.6-0.3,1.2-0.4,1.8c-0.1,1-0.3,1.3-0.6,2c-0.1,0.4-0.2,0.9-0.2,1.4c0,0.9,0.1,1.8,0.3,2.6c0.1,0.6,0.2,1.3,0.4,1.8
			c0.2,0.6,0.3,0.9,0.3,1.2s-0.1,0.5-0.1,1c0,0.2,0,0.5,0,0.7c0,1,0.2,2,0.2,3.1c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.1,0.5-0.1,0.7
			c0,0.3,0.1,0.4,0.4,1.1c0.1,0.3,1,0.7,1.2,1.7l15.4-3.4c0,0,0.1-0.1,0.1-0.3s0-0.4,0.1-0.7c0.1-0.2,0.3-0.4,0.5-0.4
			c0.1,0,0.2,0,0.3,0.1c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.3,0.3-0.5,0.6-0.5c0.2,0,0.3,0.1,0.4,0.1l0,0
			c0-0.1,0-0.3,0.2-0.5c0.1-0.1,0.3-0.3,0.5-0.3h0.1c0-0.2,0.1-0.6,0.4-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0,0.4,0.1,0.5,0.1
			c0,0,0,0,0-0.1s0.2-0.6,0.6-0.6c0,0,0,0,0.1,0c0.3,0,0.6,0.1,0.8,0.2c0-0.1,0.1-0.1,0.1-0.2c0.3-0.5,0.2-0.8,0.3-1.4
			c0.1-0.5,0.3-0.8,0.4-1.3c0-0.1,0.1-0.2,0.1-0.2C747.1,174.6,745.5,173.7,745.2,172.6z"/>
									<path fill="#DDDDDC" d="M658.8,475.2c0.4,0.1,0.5-0.3,0.5-0.6c0.1-0.8-0.4-1.6-0.4-2.4c0-0.1,0.4-0.3,0.3-0.7
			c0-0.3-0.2-0.6-0.5-0.7c-0.7-0.1-0.9,1.1-1,1.6c-0.1,0.7,0.5,1,0.7,1.6C658.5,474.3,658.4,475.1,658.8,475.2z"/>
									<path fill="#DDDDDC" d="M660.9,472.9c-0.3-0.6-0.9-1.2-1-1.9c0,0.4,0.1,0.9,0.1,1.4c0.1,0.5,0.1,1.2,0.3,1.7l0.2,0.1
			C660.4,473.7,661.3,473.7,660.9,472.9z"/>
									<path fill="#DDDDDC" d="M669.5,177.2c0.2-0.1,0.3-0.2,0.3-0.5c0-0.7-0.9-0.1-0.9,0.3C668.9,177.3,669.2,177.3,669.5,177.2z" />
									<path fill="#DDDDDC" d="M745.9,179.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5-0.2-0.6-0.2c0,0,0,0,0,0.2s0,0.4-0.1,0.7l-0.1,0.3l-0.3-0.1
			c-0.1,0-0.2-0.1-0.2-0.1l0,0c-0.1,0,0.1,0.2-0.3,0.7c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.1c0,0,0,0,0,0.1l0,0
			c0,0.1,0.3,0.4,0.3,0.9c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.3s-0.4-0.1-0.4-0.1c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.4,0.9
			h-0.1l-26.2,5.8v12.3c2.9-0.6,20.3-3.9,27.6-6.4l0.3-0.1l0.1,0.3c0.2,0.4,0.3,0.8,0.5,1.1c0.2,0.4,0.6,1,0.7,1.7
			c0,0.5-0.3,0.6-0.2,0.6c0,0.1,0.1,0.4,0.1,0.8c0,0.3,0.1,0.5,0.2,0.8l0,0c0.2,0.1,0.4,0,0.6,0.1c0.4,0.2,0.8,0.4,1.1,0.7
			c0.6,0.7,0.4,1.3,0.4,2.1c0,0.3,0,0.6,0.1,0.9c0.1,0.2,0.5,0.5,0.7,0.2c0.1-0.2-0.2-0.6-0.2-0.9c0-0.4,0.1-0.8,0.5-0.5
			c0.3,0.2,0.3,0.7,0.4,0.9c0.1,0.3,0.2,0.5,0.3,0.8c0.3-0.2,1-0.6,1.2-0.9c0.2-0.4-0.1-0.8,0.1-1.2c0.2,0.1,0.6,0.5,0.8,0.4
			c0.3-0.1,0.2-0.7,0.3-1c0.2-0.4,0.6-0.4,0.8-0.7c0.3-0.3,0.1-0.6,0.5-0.9c0.2-0.2,0.5-0.3,0.7-0.5c0.4-0.3,0.2-0.3,0.2-0.7
			c0-0.3,0.2-0.5,0.4-0.6c0.4-0.3,0.3-0.1,0.6,0c0.5,0.2,1.4,0.1,1.2,0.9c-0.1,0.5-0.4,1.2-0.2,1.7c0.1,0.2,0.4,0.3,0.6,0.3
			c0.3-0.1,0.2-0.4,0.5-0.6c0.5-0.3,1.1,0.2,1-0.6c-0.1-0.4-0.1-0.3,0.2-0.6c0.2-0.2,0.3-0.4,0.5-0.6c0.6-0.4,1.2-0.4,1.9-0.4
			c0-0.7,0.2-0.8,0.9-0.8c0.7,0.1,0.7-0.4,1.3-0.7c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.2,0.4-0.5,0.9-0.4c0.3,0.1,0.8-0.1,1.2-0.1
			c0.2,0-0.2-1.7-0.7-2.4c-0.3-0.4-0.5-1.1-0.8-1.5c-0.7-0.8-1.1-2.8-3.3-2.5c0.1,0.1,0.9,0.4,1,0.5c0.6,0.5,0.5,0.6,1,1.2
			c0.5,0.5,1.2,1.6,1.3,2.3c0,0.7-0.8,1.3-1.3,1.5c-0.8,0.4-1.3,0.3-1.8,1.1s-1.2,0.7-2.1,0.5c-0.9-0.1-1.4-0.5-1.9-1.1
			s-0.8-1.4-1.4-2c-0.5-0.6-1.3-0.2-1.9-0.7c-0.7-0.6-0.5-1.5-0.7-2.3c-0.2-0.9-1-1.5-1.9-1.3c-0.1-0.3-0.6-0.9-0.9-1
			c-0.5-0.2-0.7,0.6-1.1,0.7c-0.9,0.4-1.3-1.3-1.5-1.8c-0.2-0.6,0.2-0.8,0.3-1.3c0-0.3-0.2-0.6,0-0.9c0.1-0.2,0.4-0.2,0.6-0.3
			c0.6-0.5-0.3-0.6-0.5-0.8c-0.6-0.5,0.5-0.8,0.8-0.9c0.2,0,0.5,0,0.6-0.1c0.2-0.2,0.1-0.2,0.2-0.5c0.2-0.6,0.9-0.6,1.2-1l0.1-0.1
			c0-0.2,0-0.5-0.3-0.6c0,0.1-0.1,0.1-0.1,0.2c-0.2,0-0.2-0.3-0.4-0.4c-0.2-0.1-0.4,0-0.6,0c-0.4-0.1-0.7-0.6-0.8-1
			c-0.3,0.1-0.4-0.1-0.5-0.3s-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3,0-0.6,0-0.7,0
			C746.4,178.6,746.3,179,745.9,179.1z"/>
									<path fill="#DDDDDC" d="M744.1,198.8v-0.1c0.1-0.5,0.3-0.5,0.2-0.6c0-0.3-0.4-0.9-0.6-1.4c-0.1-0.3-0.2-0.6-0.4-0.8
			c-1.3,0.4-2.8,0.9-4.5,1.3c0.4,1.5,2.3,7.8,2.6,9.7c0.1,0.5,0.1,0.9,0.1,1.2c0,0.4,0,0.9-0.2,1.3c0,0.1-0.1,0.1-0.1,0.2l0,0
			c0.9,0.1,1.2,0,1.8-0.7c0.2-0.3,0.4-0.4,0.7-0.6c0.3-0.2,0.6-0.6,0.9-0.8c0.2-0.2,0.6-0.3,0.8-0.6s0.2-0.6,0.2-0.9
			c0.1-0.7,0.8-1.3,0.2-2c-0.2-0.2-0.5-0.4-0.6-0.7s-0.1-0.7-0.1-1c-0.1-0.6-0.3-1.1-0.4-1.7c-0.1-0.1-0.1-0.4-0.2-0.5
			C744.3,199.7,744.1,199.3,744.1,198.8z"/>
									<path fill="#DDDDDC" d="M506.5,337.3c0,0.1-0.1,0.3-0.1,0.6c0,0.4-0.2,0.8-0.3,1.1l2.1-0.2l-0.3-0.5
			C507.2,338.2,506.8,337.5,506.5,337.3z"/>
									<path fill="#DDDDDC" d="M658,469.1l-0.1,0.1c-0.4,0-1,0.3-1,0.7c0,0.7,0.8,0.1,0.9,0.7C658.6,470.6,658.7,469.1,658,469.1z" />
									<path fill="#DDDDDC" d="M669.5,174.1v0.2c0-0.4-0.4-0.3-0.4,0.1c0.2,0.1,0.4,0.2,0.6,0.1C669.8,174.3,669.7,174.1,669.5,174.1z" />
									<path fill="#DDDDDC" d="M504.8,340.6l-0.3,0.2l-0.1-0.1c0,0.1,0,0.1,0,0.2c0,0.4-0.3,0.5-0.4,0.6s-0.1,0.1-0.1,0.2l0,0l0,0
			c0,0,0.1,0,0.1,0.1c0.1,0.1,0.4,0.3,0.4,0.7c0,0,0,0,0,0.1c0,0.3-0.2,0.7-0.6,0.6c-0.3,0-0.5-0.2-0.6-0.2c0,0.1,0.1,0.1,0.1,0.2
			c0.1,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.3-0.2,0.5c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.7-0.2-0.9-0.2
			c-0.1,0.1-0.1,0-0.1,0.1c0,0.2,0.5,0.9,0.7,1.3c0.2,0.3,0.3,0.6,0.3,1s-0.2,0.7-0.5,1c-0.6,0.6-0.7,0.6-0.7,0.9
			c0,0.1,0,0.3,0.1,0.6c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.8-0.1,1c0,0.3,0,0.2,0.2,0.3h0.2l0.1,0.2c0,0.1,0.1,0.3,0.1,0.4
			c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.5,0.2s-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1v0.1c-0.1,0.2,0.4,0.3,0.5,1c0,0,0,0,0,0.1
			c0,0.2-0.1,0.4-0.2,0.5c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4,0-0.5,0h-0.1c-0.2,0.1-0.1,0-0.2,0.1c0,0.1,0,0.4-0.3,0.7
			c-0.2,0.3-0.5,0.4-0.7,0.4c-0.3,0-0.4-0.1-0.5-0.1h-0.1c-0.2,0-0.3,0.1-0.3,0.1s0,0,0,0.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.3,0.4,0.5,0.4,0.8c-0.1,0.7-0.7,0.8-1,1c-0.4,0.2-0.6,0.4-0.6,0.5l0,0l0.1,0.8l-0.7-0.5
			c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.1-0.1-0.1l0,0c0,0-0.1,0.1-0.2,0.2c-0.1,0.2-0.1,0.3-0.1,0.4s0.1,0.3,0.3,0.8
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.1-0.1-0.2,0.2
			c0,0.1,0,0.1,0,0.2s0,0.1,0.1,0.2s0.3,0.2,0.5,0.4c0.3,0.3,0.4,0.7,0.4,1c0,0,0,0,0,0.1s0,0.2,0,0.4c0,0.2,0.6,0.9,0.7,1.6
			c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.7-0.2l-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3
			c0,0.2,0.1,0.5,0.2,0.7l0.4,0.7l-0.8-0.2H495c0,0,0,0-0.1,0l0,0c0,0.1-0.1,0.2-0.2,0.4c4.7-0.3,23.8-1.4,45-3
			c15.3-1.2,31.7-2.6,44.5-4.2v-0.3c0,0,0,0,0-0.1v-0.1c0-0.1,0-0.1,0-0.2s0-0.2-0.1-0.3l0,0c-0.2-0.4-0.3-0.5-0.3-0.7
			c0-0.1,0-0.1-0.1-0.6c-0.1-0.3-0.2-0.8-0.2-1.3c0-0.7,0.2-1.7,1.2-1.7c0.1,0,0.1,0,0.2,0c0.2-0.2,0.4-0.3,0.6-0.3
			c0.4,0,0.8,0.2,1.2,0.2c0.2,0,0.3,0,0.4-0.2c0.1-0.1,0.4-0.9,0.4-1.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.4-0.6
			c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.4,0.3-0.6,0.6-0.8c0.5-0.3,0.8-0.2,0.8-0.3c0.1-0.1,0,0,0-0.1c0,0,0-0.1,0-0.2s0-0.2,0.1-0.4
			c0.5-0.9,1.6-1.2,2.4-1.4c0.1-0.4,0.4-0.6,0.6-0.7c0.3-0.1,0.7-0.1,0.9-0.2c0.4-0.1,0.8-0.3,1.4-0.3l0,0c0.4,0,0.7,0.1,0.8,0.1
			s0.2,0,0.4-0.2c0.3-0.3,0.6-0.6,1-0.9c0.3-0.3,0.5-0.5,0.8-0.8c0.5-0.5,0.8-0.5,1.2-0.7s0.4-0.5,0.6-0.9c0.1-0.4,0.5-0.8,1.1-0.8
			c0.1-0.4,0.5-0.7,0.8-0.7c0.4,0,0.6,0.2,0.8,0.1c0.1,0,0.2,0,0.3-0.1s0.4-0.8,0.4-1c0-0.1,0-0.2,0-0.2c0-0.1,0,0-0.1-0.1
			s-0.3-0.3-0.3-0.6c0-0.1,0-0.2,0-0.2c0-0.7,0.6-1,1.1-1.1c0.1,0,0.2,0,0.3,0c0.6,0,0.8,0.4,1,0.4c0.1,0,0.1,0,0.2,0l0,0
			c0,0,0-0.2,0-0.4c0-0.4,0-0.9,0.4-1.4c0.4-0.4,0.7-0.3,0.8-0.5c0.2-0.2,0.1-0.7,0.7-1.1c0.3-0.2,0.6-0.4,0.9-0.4
			c0.2,0,0.5,0.2,0.5,0.3c0.1,0.2,0.1,0.3,0.1,0.5c0,0.3,0,0.6,0.1,0.7c0,0.1,0.1,0.1,0.4,0.1c0.4,0,1-0.3,1-0.5v-0.1
			c0-0.1-0.1-0.3-0.1-0.6c0-0.2,0-0.3,0.1-0.5c0.3-0.4,0.7-0.5,0.8-0.7c0.3-0.3,0.4-0.7,1.2-0.8c0.7-0.1,0.5,0.1,0.9-0.3
			c0.2-0.1,0.4-0.6,1-0.7c0.2,0,0.3,0.1,0.5,0.1c0.4,0.4,0.4,0.8,0.4,1c0.3,0,0.7-0.1,0.8-0.1c0.3-0.2,0.1,0,0.4-0.6
			c0.4-0.8,0.7-1.2,0.9-2c0.1-0.2,0-0.5,0.3-0.9c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0
			c0.6-0.5,0.3,0,0.5-0.7c0.2-0.5,0.3-0.9,0.8-0.9c0.6,0.1,0.7,0.5,0.8,0.4l0,0l0,0c0-0.2,0-0.4,0-0.6s0-0.4,0-0.5
			c0-0.2,0.2-0.4,0.2-0.4l0,0c0,0-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0-0.4c0-0.3,0.1-0.4,0.1-0.7c0-0.1,0-0.2,0.1-0.3h-0.1h-0.3l-0.1-0.2
			c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.3,0.1-0.5c-0.6,0.1-1.1,0.1-1.2,0.1c-8.8,2.9-51.9,6.1-51.9,6.1s-13.9,1.4-17,1.5
			c-3,0.1-20.4,1.9-20.4,1.9H528l-0.1-0.3v-0.1l-2.3-0.1c0.2,0.5,0.3,1.1,0.3,1.7c0,0.7-0.1,1.3-0.1,1.3l-0.1,0.3l-20.8,1.7
			C505.3,340.1,505.1,340.3,504.8,340.6z"/>
									<path fill="#DDDDDC" d="M543.5,161c0-0.2,0-0.3-0.1-0.3l0.1-0.1C543.2,160.6,543.1,161.1,543.5,161z" />
									<path fill="#DDDDDC" d="M529.2,168.3c0.3-0.2,0.2-0.9-0.1-0.6l0.1-0.1C528.9,167.7,528.8,168.6,529.2,168.3z" />
									<path fill="#DDDDDC" d="M529.7,167.6h0.3c0-0.1,0-0.2,0-0.3C529.8,167.4,529.8,167.4,529.7,167.6z" />
									<path fill="#DDDDDC" d="M530.4,166.3c-0.1,0.1-0.1,0.9,0,0.9c0.3,0,0.3-0.3,0.1-0.5c0.1,0,0.1,0,0.2,0
			C530.9,166.5,530.7,166.1,530.4,166.3z"/>
									<path fill="#DDDDDC" d="M527.9,169.8c-0.8-0.6-1.4,1-0.7,1c0.3,1,0.9,0,0.5-0.1C528.1,170.9,528.2,170,527.9,169.8z" />
									<path fill="#DDDDDC" d="M790.3,130c-0.2-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.1-0.6-0.2-0.8c-0.1-0.2-0.1-0.2-0.3-0.2s-0.1,0-0.2,0
			l-0.2,0.1c-0.5,0.6-0.8,1.5-0.6,2.3c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0,0.5,0.1,0.7c0.2,0.7,0.5,0,0.5-0.3c0.1-0.6,0.7-0.4,0.8-1
			c0.2,0,0.4,0.1,0.6,0.1C790.9,130.2,790.5,130.2,790.3,130z"/>
									<path fill="#DDDDDC" d="M670.1,176.8c0,0.3,0.3,0.4,0.5,0.1l-0.1-0.1c0.2-0.1,0.2-0.3,0.2-0.5
			C670.7,176.2,670.1,176.5,670.1,176.8z"/>
									<path fill="#DDDDDC" d="M544.8,160.3L544.8,160.3c-0.2,0.1-0.3,0.2-0.3,0.4c0.1,0,0.2,0,0.3,0
			C544.8,160.6,544.9,160.4,544.8,160.3z"/>
									<path fill="#DDDDDC" d="M544.2,159.7c-0.1,0.1-0.1,0.1-0.1,0.2l-0.1-0.2c-0.1,0.1-0.1,0.3-0.1,0.5
			C544.2,160.2,544.5,159.9,544.2,159.7z"/>
									<path fill="#DDDDDC" d="M543.9,165c0.4,0.1,0.8-0.4,0.8-0.8c0.9,0.6,0.8-0.7,0.8-1.1c-0.1-0.7-0.1-1-0.7-1.5l-0.1-0.1
			c-0.4-0.2-0.5,0.3-0.5,0.6c0,0.5,0,0.4-0.2,0.8C543.4,163.7,543.6,164.3,543.9,165z"/>
									<path fill="#DDDDDC" d="M545.7,160.9c0.3-0.4,0-0.7-0.4-0.4C544.8,160.9,545.3,161.5,545.7,160.9z" />
									<path fill="#DDDDDC" d="M37.6,340.1c0.1-0.3-0.1-0.2-0.1-0.2v0.1c-0.2-0.5-1.2-0.5-1.6,0c0.5,0.5,2.1,1.4,2,0.1
			C37.8,340.1,37.7,340.1,37.6,340.1z"/>
									<path fill="#DDDDDC" d="M543.1,162.3c0.1-0.2,0-0.6-0.3-0.6c-0.5,0-0.1,0.8-0.2,1C542.6,162.5,543,162.7,543.1,162.3z" />
									<path fill="#DDDDDC" d="M611.4,280.9L611.4,280.9h-0.2l0.5,0.5c0.1,0.1,0.2,0.3,0.3,0.4C612,281.3,611.8,280.9,611.4,280.9z" />
									<path fill="#DDDDDC" d="M638.6,356.4L638.6,356.4c0,0.1-0.3,0-0.3,0l0,0c0,0-0.1,0-0.2-0.1l0,0c-0.1-0.2-0.1-0.2-0.1-0.2V356
			c0.1-0.2,0.1-1.1,0.2-1.5l-2.5-2.5l-1.3,1.1l-0.2-0.1c0,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.6-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.4
			c0.1-0.1,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.2s-0.1-0.1-0.1-0.1l-18.4,1.8l-3.4,1.3l-0.5,0.2h-0.1c0,0,0,0-0.1,0
			c-0.5-0.1-1,0.9-1.7,1.4c-0.2,0.2-0.4,0.3-0.6,0.3c-0.4,0-0.4-0.1-0.6-0.1c-0.5,0-0.2,0-0.7,0.3c-0.7,0.5-1.5,0.8-2.2,1.2
			c-0.4,0.2-0.8,0.3-1,0.4c-0.1,0.1-0.2,0.1-0.2,0.1s0,0.1,0,0.2s0,0.2,0,0.3c-0.2,0.9-0.9,1.7-1.9,1.9c-0.1,0.2-0.1,0.6-0.3,1
			c-0.3,0.6-0.4,0.4-0.5,0.9c-0.1,0.4-0.3,1-0.3,1.2c0,0.1,0,0.2,0,0.2c0.1,0,0.4,0,0.7,0.1c0.8,0.2,1.6,1,2.1,1.5
			c0.4,0.4,0.6,0.7,0.8,0.8c0.3,0.2,0.7,0.2,1.2,0.5c0.3,0.2,0.7,0.6,0.8,0.6c0,0,0,0,0.1,0c0.1-0.1,0.3-0.5,1-0.6
			c0.2,0,0.4,0.1,0.6,0.2l0.2,0.1v0.2v0.1c0,0.5,0.1,0.3,0.6,0.8c0.4,0.3,0.9,0.8,1.2,1.4c0.3,0.7,0.4,1.1,0.8,1.7
			c0.3,0.4,0.5,0.8,0.7,1.3c0.1,0.3,0.1,0.7,0.1,0.7c0.1,0.1,0.1,0.1,0.1,0.1s0.3,0,0.5,0.1c0.9,0.4,1,1.1,1.3,1.5
			c0.6,0.7,1.4,1.1,2.3,1.6c0.8,0.4,2.3,0.7,2.9,1.8l0.4,0.8c0.7,0.1,0.9,0.8,1,1.1c0.2,0.5,0.3,0.7,0.4,0.6c0,0,0.1,0,0.2,0.1
			c0.1,0.1,0.1,0.1,0.1,0.1l0.1,0.1l0,0c0.2,0.1,0.3,0.2,0.4,0.2c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0
			c0.7,0.4,0.6,0.7,0.9,0.7h0.1h0.3l0.1,0.3c0.1,0.4,0.6,1.2,0.9,1.1c0,0,0.1,0,0.2,0l0.2-0.1l0.2,0.2c0.3,0.2,0.5,0.5,0.5,0.9
			c0,0.2,0,0.3-0.1,0.5c-0.1,0.3-0.2,0.5-0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.6,1.1,1.6,1.7,1.5h0.1h0.4v0.4c0,0.3,0,0.5,0,0.8
			c0.9,0.1,1.4,0.7,2.1,1c0.6,0.3,1.3,0.5,1.9,0.7c0.5,0.3,0.5,0.6,0.8,0.8c0.2,0.2,0.8,0,1.2,0.9c0.1,0.2,0.1,0.3,0.1,0.4
			c0,0.5-0.2,0.9-0.2,1.1l0,0c0,0,0.1,0,0.2,0h0.3l0.1,0.3c0.1,0.4,0.1,0.8,0.2,1c0.1,0.3,0.5,0.5,0.8,1.1c0.3,0.7,0.2,1.6,0.3,2.1
			c0.2,0.8,0.1,1,0.7,1.1c0.8,0.1,1.3-0.2,2.2,0.7c0.7,0.7,0.7,1.3,1,1.8s0.8,1.1,1.1,1.8c0.2,0.5,0.2,0.8,0.2,1.2
			c0,0.3,0,0.5-0.1,0.9c0,0.2-0.1,0.3-0.1,0.3s0,0,0.1,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.2,0.3,0.4,0.3,0.5
			s0,0.1,0,0.1c0.3,0.4,0.3,0.3,0.8,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0,0.2,0c0.3-0.1,0.5-0.2,0.9-0.2c0.1,0,0.2,0,0.3,0
			c0.3,0.1,0.6,0.2,0.8,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0-0.1,0-0.2,0.1-0.3c1.2,0.7,0.8-0.6,0.5-1.2c-0.2-0.4-0.2-0.3-0.1-0.7
			c0-0.1,0.2-0.2,0.2-0.3c0-0.2-0.2-0.3-0.2-0.5c0.1-0.4,0.4-0.4,0.4-0.9c0-0.2-0.1-0.9-0.2-1.1c-0.1-0.3-0.3-0.4-0.4-0.6
			c-0.1-0.3-0.1-0.6-0.2-0.9c-0.3-0.5-1.1-1.4-1-2c0.1,0,0.1,0,0.2,0c0,0.9,0.3,1,0.8,1.7c0.4,0.5,0.3,1.1,0.7,1.5s1.1,0.1,0.9,1
			c0,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.1,0.7-0.2,1.1c-0.1,0.5-0.5,1.4,0,1.8c0.2-0.3,0.5-0.8,0.6-1.1c0.1-0.3-0.1-1.3,0.4-1.3
			c0.2-0.5,1-0.7,1-1.2c0-0.4-0.8-1.2-1-1.5c0.6,0.2,0.7,0.6,1,1c0.4,0.5,0.5,0.5,1,0.2c0.3-0.2,2.5-2,1.4-2.2
			c-0.2,0.3-0.4,1-0.8,0.6c0.3-0.4,0.3-0.4,0.3-0.8c0-0.3-0.1-0.8-0.3-1.1c-0.3-0.3-2.1-0.5-1.6-1.4c0.2,0.1,0.4,0.3,0.6,0.4
			c0.3,0.2,0.3,0.1,0.6,0.2c0.5,0.1,1.1,0.3,1.6,0.2c0-0.2-0.1-1,0.2-1s0.4,0.8,0.7,1c0.3-0.6,0.9-1,1.5-1.3c0.5-0.4,0.6-0.9,1-1.2
			c0.3-0.3,0.4,0,0.8-0.1c0.4-0.1,0.4-0.4,0.6-0.7c0.4-0.4,0.8-0.4,1.2-0.8c0.2-0.2,0.2-0.4,0.4-0.5c0.3-0.4,0.4-0.3,0.9-0.3
			c0.1-0.3-0.2-0.6-0.4-0.8c-0.1-0.3-0.2-0.6-0.4-0.8c-0.4-0.5-1.1-1-1.2-1.7c0.6,0.2,0.5,1,1,1.2c0.2,0.1,0.5-0.1,0.8,0
			s0.4,0.5,0.7,0.5c0.9,0.2,1.2-1.9,1.8-2.3c0.4-0.3,0.8-0.3,1.1-0.9c0.4-0.6,0.3-0.8,0.1-1.5s0.2-0.9,0.8-1.1
			c0.7-0.2,0.8-0.7,1.4-1c0.5-0.2,0.9-0.2,1.2-0.8c0.4-0.7-0.2-0.6-0.3-1.2c0.3,0,1.5,0.6,1.5,0c0.1-0.3-0.5-0.3-0.5-0.6
			c0-0.5,0.4-0.2,0.6-0.1c0.2,0.2,0.2,0.6,0.5,0.6c0.5,0,0.3-0.4,0.3-0.7c0-0.3-0.1-0.4-0.1-0.7c0-0.3,0.1-0.5,0-0.7
			c-0.1,0-0.4,0.1-0.5,0s-0.2-0.1-0.4-0.2c0,0-0.2-0.2-0.3-0.2c-0.1-0.1-0.3,0-0.4-0.1c-0.5-0.3-0.8-1.4-0.3-1.8
			c0,0.4,0.4,1.7,0.9,1.1c0.2,0.2,0.5,0.7,0.8,0.6c0.2-1,0.3-2.1,0.4-3.1c0.2-1,0.7-1.6,1.2-2.4c0.5-0.7,0.7-1.5,1.1-2.2
			c0.5-1,0.9-1.7,1.7-2.4c0.8-0.8,1.7-1.4,2.6-2l-18.9-14.2C653.9,354.4,640.7,356.3,638.6,356.4z"/>
									<path fill="#DDDDDC" d="M569,283.6L569,283.6c0.8,0,1.4,0.4,1.8,0.5c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.4-0.2
			c0.2-0.1,0.3-0.5,0.9-0.6c0.1,0,0.3,0,0.4,0.1l0,0c0.3,0,0.9,0.2,1.3,1.2c0.2,0,0.5-0.1,0.8-0.1s0.5,0,0.8,0.2
			c0.4,0.3,0.4,0.8,0.4,1c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.3c0.2,0.3,0.1,0.2,0.3,0.2s0.6,0,1,0.3c0.7,0.7,0.4,1.8,0.6,2.1
			c0.2,0.2,0.1,0.2,0.2,0.2c0.1,0,0.4-0.1,0.8-0.2c0.3,0,0.6,0,0.8-0.1c0.1,0,0.6-0.2,0.9-0.2l0,0l0,0c0.8,0.1,1,0.6,1.4,0.5l0,0
			l0,0c0.2,0,0.5-0.1,0.9-0.2c0.2,0,0.5,0.1,0.7,0.3c0.6,0.6,0.6,1.4,0.9,1.4c0.1,0,0.2,0,0.4,0c0.3,0,0.7-0.1,1.1-0.1h0.1
			c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.4,0.2-0.5c0.4-0.4,0.9-0.4,1.3-0.4c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0c0.8,0,1.5,0.3,2.2,0.3h0.3
			v0.3c0,0.4,0.4,0.6,0.6,0.6c0.2,0,0.2,0,0.3-0.3v-0.3l0.3-0.1c0.1,0,0.2,0,0.2,0c0.6,0,1.1,0.4,1.3,0.4h0.1
			c0.3-0.1,0.7-1.1,0.9-1.5c0.2-0.5,0.5-1.1,1.2-1.4c0.3-0.1,0.6-0.1,0.8-0.2s0.5-0.3,0.9-0.5l0.4-0.2l0.1,0.5c0,0.1,0,0.2,0,0.2
			c0,0.5-0.3,0.9-0.3,1.1v0.1c0,0.1,0,0.2,0,0.2c0.1,0,0.3,0.1,0.5,0.3c0.4,0.6,0.2,1,0.4,1.1c0,0.1,0.5,0,1.1,0.2
			c0.6,0.3,1.2,1,1.6,1.3c0.4,0.4,0.8,0.8,1.2,1.3c0.3,0,0.8,0,0.8-0.1c0.2,0,0.1-0.2,0.5-0.7c0.8-0.6,1.2-0.2,1.5-0.5
			c0.4-0.3,0.4-0.5,0.4-0.9c0-0.2,0-0.5-0.1-0.7c0-0.1-0.1-0.6-0.1-0.9c0-0.2,0-0.3,0.2-0.5c0.1-0.1,0.3-0.1,0.4-0.1
			c0.2,0,0.4,0.1,0.6,0.2c0.1-0.3,0.1-0.6,0.2-1c0.1-0.3,0.2-0.4,0.2-0.4s0-0.1-0.1-0.3c0-0.2-0.7-1.1-0.7-1.8
			c0-0.2,0.1-0.5,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0-0.8,0-1.5,0.7-2.2c0.6-0.6,1-1.3,1.4-2.2l0.7,0.2c0,0.1,0,0.1,0,0.2
			c0,0.2,0,0.3,0.1,0.5c0.7,0.2,1.1,0.7,1.1,1.4c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.3-0.5v-0.1l-0.1-1l0,0v-0.1c0-0.1,0-0.1,0-0.2
			c0-0.4,0.2-0.8,0.2-0.9l0,0c0,0,0,0-0.2,0l-1.1-0.2l1-0.5c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.5,0,0.6,0l0,0c0-0.1,0-0.2-0.1-0.4
			c0-0.2-0.4-0.4-0.4-1c0-0.1,0-0.1,0-0.1c0-0.6,0.3-0.9,0.3-1.2l0.1-0.4l0.4,0.1c0.1,0,0.1,0,0.2,0c0-0.2,0-0.4,0.1-0.7
			c0.1-0.4,0.6-0.5,0.8-0.5c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.4
			c0-0.3,0.1-0.6,0.5-0.9c0.6-0.4,1.3-0.4,1.2-0.5l0.4-0.8l0.2,0.9c0,0.2,0.1,0.3,0.1,0.3l0,0c0,0,0.2-0.1,0.4-0.3
			c0.2-0.2,0.4-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.7-0.5c0.2,0,0.3,0.1,0.3,0.1s0.1,0,0.2,0c0.1,0,0.9-0.6,1.1-0.7
			c0.1,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.5,0.3-0.9,0.7-1.2c0.6-0.4,1.5-0.9,1.4-1.3V267l-0.1-0.2
			l0.2-0.2c0.2-0.2,0.3-0.3,0.5-0.5l0.1-0.1l0.1-0.1c0.1,0,0.2,0,0.2,0l0,0l0,0l0,0v-0.1c0.2-0.8,0.6-1.1,0.5-1.3
			c0-0.1,0-0.1-0.1-0.3l0,0c-0.1-0.1-0.3,0-0.5-0.3c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.3,0.1-0.4s0-0.1,0-0.1c0-0.2,0.1-0.4,0.2-0.4
			c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.1,0-0.1c0.2-0.9,0.9-1.2,0.9-1.7c0-0.1,0-0.2,0-0.4c0-0.5-0.1-1.1-0.1-1.5s-0.2-0.8-0.2-1.3
			c0-0.2,0-0.4,0.1-0.7c0.2-0.4,0.4-0.7,0.4-0.9v-0.1c0-0.2-0.2-0.4-0.3-0.9c0-0.1,0-0.1,0-0.1c0.1-0.9,0.8-1.3,0.8-1.6V253
			c0-0.3-0.1-0.7-0.2-0.9c0,0-0.1-0.2-0.3-0.4s-0.3-0.2-0.4-0.5c0-0.1,0-0.2,0-0.2c0.1-0.8,0.7-1.1,0.6-1.2c0,0,0-0.1-0.1-0.1l0,0
			c0,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.1-0.6-0.4c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.4h-0.1v-0.3c0.1-1,1.1-1.3,1.6-1.5
			l-3.8-23.7c-0.7,0.4-1.5,0.7-2.3,1c-0.4,0.1-0.7,0.2-1.1,0.5c-0.5,0.4-0.9,0.8-1.4,1c-0.8,0.3-1.4,0.5-1.9,1.2
			c-0.4,0.5-0.5,0.5-1,0.4c-0.4-0.1-0.7,0.3-1,0.5c-0.4,0.3-0.8,0.5-1.1,0.9s-0.4,0.8-0.7,1.2c-0.6,0.7-1.3,1.2-2,1.8
			c-0.6,0.6-0.9,1.5-1.6,2c-0.3,0.2-0.5,0.5-0.8,0.6c-0.4,0.1-0.8-0.1-1.2,0s-0.8,0.4-1.2,0.3c-0.5-0.1-1.2-0.3-1.7-0.3
			c-0.6,0-0.9,0.7-1.2,1.1c-0.3,0.5-0.7,0.8-1.3,1c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.8,0.3s-0.5-0.1-0.8,0.1
			c-0.3,0.2-0.2,0.6-0.5,0.7c-0.4,0.2-1.1,0.2-1.5,0c-0.5-0.2-0.8-0.8-1.3-1c-0.4-0.2-1.1,0.1-1.5,0.1c0-0.2,0.2-0.6,0.1-0.7
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1-0.2-0.2-0.2c-0.1-0.1-0.3,0-0.4,0c-0.4-0.1-0.2-0.2-0.4-0.4c-0.5-0.4-1.3,1.1-1.8,1.1
			c-0.8,0.2-1.5-0.7-2.1-1.1c-0.2-0.2-0.8-0.5-1-0.6c-0.1,0-0.4,0.1-0.5,0.1c-0.3,0-0.3,0-0.5-0.1c-0.3-0.2-0.5-0.5-0.9-0.6
			s-0.7,0.3-1.1,0.2c-0.3-0.6-0.7-0.2-1.2-0.3c-0.3,0-0.4-0.2-0.4-0.3l-17.9,2.5L569,283.6z"/>
									<path fill="#DDDDDC" d="M643.7,269.1c0.1,0.7,0.1,1.4,0.2,2c0.2-0.2,0.3-0.5,0.4-0.8c0.1,0-0.1-0.3,0.3-0.7
			c0.1-0.1,0.3-0.2,0.5-0.2s0.3,0,0.3,0l0,0c0.5-0.1,0.4-0.6,0.9-1.2c0.7-0.6,0.9-0.3,1.1-0.8c0.1-0.2,0.2-0.8,0.4-1.3
			c0.1-0.5,0.4-1.1,1.1-1.1h0.1c0.6,0.1,0.8,0.4,0.9,0.4h0.1l0,0l0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.3-0.5,0.4-0.5
			c0.5-0.7,0.7-2.3,1.7-3l0.3-0.2l0.2,0.4c0.2,0.4,0.4,0.8,0.5,0.9c0,0,0,0,0.1,0s0.4-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0
			c0.6,0.3,0.5,0.5,0.7,0.4c0,0,0,0,0.1,0c0,0,0.1-0.1,0.4-0.3c0.1-0.1,0.1-0.1,0.2,0l0,0c0.1,0,0.3,0,0.4,0.1
			c0.3-0.7,0.5-1.8,1.5-1.9c0,0,0,0,0.1,0c0.4,0,0.6,0.2,0.6,0.2s0.1,0,0.2-0.1c0.1,0,0.6-0.8,0.6-0.8l0.1-0.2h0.2
			c0,0,0.2-0.1,0.3-0.5c0-0.1,0.1-0.3,0.2-0.4c-0.9,0.2-1.7,0.3-2.6,0.5c-5.3,1-10.5,2-15,2.8c0,1.5,0.4,2.8,0.5,4.4
			C643.3,267.3,643.5,268.2,643.7,269.1z"/>
									<path fill="#DDDDDC" d="M624,248.1v0.3c0,0.4-0.2,0.6-0.2,0.7l0,0l0,0c0,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.3
			c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.7-0.6,1.1-0.6,1.2l0,0c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.3,0.3,0.8,0.3,1.2
			c0,0.1,0,0.1,0,0.2c-0.2,0.8-0.8,1.1-0.8,1.5l0,0v0.1c0,0.2,0.2,0.4,0.3,1c0,0.1,0,0.2,0,0.3c-0.1,0.5-0.4,0.7-0.5,0.9
			c0,0.2-0.1,0.3-0.1,0.4c0,0.3,0.1,0.7,0.2,1.2c0,0.4,0.1,1,0.1,1.6c0,0.2,0,0.3,0,0.5c-0.2,0.8-0.8,1.2-0.9,1.6l0,0
			c0,0.1,0.2,0.2,0.2,0.4l0,0v0.1c0,0.3-0.2,0.4-0.2,0.5l0,0c0,0.1-0.1,0.2-0.1,0.3c0.1,0,0.2,0.1,0.3,0.3c0.1,0.2,0.2,0.5,0.2,0.7
			c-0.1,0.7-0.5,0.9-0.5,1.4l0.1,0.1l0,0l0,0l0,0l-0.8,0.9l-0.1-0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.1,0,0.2
			c-0.1,1.1-1.3,1.5-1.7,1.8c-0.5,0.4-0.5,0.4-0.5,0.6c0,0,0,0,0,0.1c0.1,0,0.4,0.2,0.4,0.5c0,0.1,0,0.1,0,0.1
			c-0.2,0.6-0.7,0.5-0.7,0.5c-0.2,0.1-0.9,0.7-1.3,0.8c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.2,0-0.3,0c0,0-0.1,0.1-0.1,0.2
			c-0.3,0.4-0.7,1.3-1.5,1.4c-0.3,0-0.5-0.2-0.6-0.4c-0.4,0.2-0.8,0.1-0.9,0.3c-0.2,0.2-0.2,0.2-0.2,0.3c0,0.1,0,0.2,0,0.4
			c0,0.2,0,0.4-0.2,0.6c-0.2,0.4-0.6,0.5-0.9,0.5c-0.1,0-0.2,0-0.2,0h-0.1c0,0,0,0-0.1,0.1c-0.1,0.2,0.1,0.4-0.2,0.9
			c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0-0.1,0-0.1,0c-0.1,0.3-0.2,0.5-0.2,0.7l0,0c0,0.2,0.2,0.3,0.4,0.9c0.1,0.2,0.1,0.4,0.1,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.1-0.4,0.1-0.5,0.1h-0.1v0.1c0,0.3-0.1,0.5-0.1,0.7c0.7,0.2,1,1,1,1.6c0,0.3,0,0.6-0.1,0.8l-0.1,0.3
			l-0.3-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.3-0.5,0.4-0.8,0.4s-0.5-0.1-0.8-0.2l-0.2-0.1v-0.2
			c-0.2-1.1,0.2-0.6-0.8-1.1l-0.2-0.1l0,0c-0.3,0.5-0.6,0.9-1,1.3c-0.6,0.6-0.4,0.9-0.5,2.1v0.4h-0.4h-0.1c-0.2,0-0.3,0-0.3,0
			s0,0,0,0.1c0,0.3,0.5,1.2,0.7,1.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.4-0.2,0.7c-0.2,0.4-0.2,0.9-0.4,1.5l-0.2,0.4l-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.2,0.1,0.5,0.1,0.6c0,0.3,0.1,0.6,0.1,0.8c0,0.5-0.2,1-0.7,1.4c-0.8,0.6-1.2,0.2-1.5,0.5
			c-0.1,0-0.1,0.4-0.6,0.8c-0.3,0.1-0.6,0.1-0.9,0.1c0,0.4-0.2,0.7-0.2,0.9c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.5,0.2,0.9
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.4-0.1,0.9-0.1,1.2c0,0.3,0,0.5,0.1,0.7c0.2,0.3,0.3,0.3,0.7,0.4c0.3,0.1,0.9,0.4,1,1
			c0.1,0.7,0,0.4,0.3,0.9s0.3,0.7,0.4,1.2c0.1,0.6,0.7,0.8,1.2,1.7c0.2,0.5,0.2,1,0.3,1.1c0.1,0.3,0.4,0.2,0.9,0.5s1.3,0.7,1.8,1.2
			c0.6,0.6,1,1.2,1.7,1.4c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.6,0.4,0.8,0.5s0.2,0,0.5,0.2
			s0.3,0.4,0.4,0.7c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.1,0.7v0.1c0.7,0.4,0.9,1.1,1.2,1.3c0.2,0.2,0.7,0.2,1.2,0.6
			c0.5,0.5,0.5,0.7,0.9,0.8c0.2,0.1,0.9,0.2,1.1,0.2l0,0c0.3,0,0.7-0.2,1.2-0.2l0,0c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5-0.1
			s0.3-0.6,0.9-0.9c0.4-0.2,0.7-0.2,0.7-0.2s0,0,0.3-0.3c0.3-0.4,0.6-0.7,1-0.7c0.8,0.1,1,0.7,1.6,0.9c0.1,0,0.1,0,0.2,0
			c0.4,0,1-0.4,1.6-0.7c0.3-0.1,0.7-0.4,1-0.5c0.5-0.1,0.9-0.1,1-0.2c0.7-0.3,0.8-1.7,2.1-2.2c1.1-0.4,1.3-0.3,2-1.1
			c0.3-0.4,0.7-0.6,1.1-0.6c0.6,0,0.9,0.4,1.1,0.9c0.6-0.6,1-1.2,2-1.5c0.1-0.4,0.2-0.9,0.2-1.2l-0.4-0.1l0.2-0.4
			c0.3-0.6,0.8-0.8,0.9-1V303c0-0.1-0.1-0.3-0.5-0.3v-0.3l-0.4-0.1c0-0.1,0-0.1,0-0.1l0,0c0.3-0.6,0.4-1.1,0.5-1.8
			c0.1-0.3-0.1-1,0.4-1.6c0.7-0.6,1.1-0.4,1.2-0.8c0,0,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.5c0.2-0.5,0.4-0.7,0.5-0.9
			c0.2-0.5,0.3-1.1,0.7-1.7c0.2-0.3,0.4-0.6,0.5-0.8l0,0c0-0.1-0.1-0.3-0.2-0.7v-0.1c0.1-1,1-1.4,1-1.8c0.2-0.7,0.2-0.9,0.7-1.6
			c0.3-0.4,0.4-1,0.7-1.7c0.3-0.6,0.5-1.2,0.5-1.7c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.3-0.8-0.3-1.3c0-0.3,0.1-0.6,0.2-0.9l0.2-0.3
			l0.3,0.1c1,0.5,1.7,1.6,2.4,2.1c0.5,0.4,0.8,0.5,1.2,0.5c0.2,0,0.5,0,0.9-0.1c0.5-0.1,0.5-0.1,0.7-0.6c0.1-0.2,0-0.6,0.2-1.1
			c0.2-0.4,0.5-0.5,0.5-0.6c0.4-0.7,0-1.9,0.6-3.2c0.7-1.2,1.1-1.7,1.1-2.9v-0.1V277h0.3c0.1,0,0.1,0,0.2,0c0.7,0,1.1,0.6,1.4,1
			c0.2,0.3,0.3,0.4,0.4,0.5c0.4-1,0.7-2.1,1.4-3.1c0.6-0.7,1.5-0.8,1.5-1.2c0-0.1,0-0.3,0-0.5s0-0.5,0-0.8c0-0.2,0-0.5,0.1-0.7
			l0.1-0.3h0.3l0,0c0.5,0,0.6-0.1,0.7-0.2c0.1-0.1,0.2-0.5,0.3-1c0.2-0.7,0.4-1.4,0.4-2.1c-0.3-0.2-0.5-0.5-0.5-0.8
			c0-0.1,0-0.2,0-0.3c0.1-0.3,0.3-0.5,0.5-0.5c0.2-0.1,0.2-0.1,0.2-0.1c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.1-0.8-0.3-1.1l-0.5-1l1,0.5
			c3.5,1.8,5.1,2.8,5.8,3.2c0.5,0.3,1,0.8,1.4,0.9c0.2-1.1,0.4-2.5,0.4-3.6c0-0.1,0-0.7-0.1-0.8c0-0.1,0-0.2,0-0.2l0,0
			c-0.1,0-0.1-0.1-0.2-0.2c-0.5-0.5-0.6-0.9-0.6-1.4c-0.2,0-0.3,0-0.4-0.1c-0.2-0.1-0.5-0.3-0.4-0.6c0-0.2,0.1-0.3,0.1-0.5
			c-0.3,0.1-0.8,0.6-1.4,0.6c-0.3,0-0.6-0.2-0.8-0.6c-0.1,0-0.1,0-0.2,0c-0.6,0-0.9-0.5-1-0.8c-0.1-0.4-0.2-0.6-0.4-0.7
			s-0.3-0.2-0.4-0.1c0,0-0.1,0.1-0.3,0.5c-0.1,0.3-0.2,0.8-0.7,0.9c-0.2,0.4-0.5,0.8-0.8,1c-0.2,0.2-0.5,0.3-0.7,0.3
			c-0.4,0-0.5-0.2-0.7-0.2h-0.1l0,0c-0.3,0.1-0.7,1-0.9,1.8l-0.1,0.3h-0.3c-0.2,0-0.5-0.1-0.5-0.2c0,0.1-0.2,0.2-0.6,0.3H655
			c-0.7,0-0.8-0.5-1-0.5c0,0,0,0-0.1,0s-0.4,0.1-0.7,0.1c-0.1,0-0.2,0-0.4-0.1c-0.4-0.2-0.6-0.5-0.7-0.8c-0.5,0.6-0.7,1.7-1.2,2.5
			c-0.2,0.3-0.6,1-1.1,1.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.6-0.1-0.8-0.4-0.9-0.4l0,0c-0.2,0-0.3,0.1-0.4,0.6c-0.1,0.4-0.2,1-0.4,1.4
			c-0.5,0.9-1.1,0.7-1.3,1c-0.3,0.1-0.2,1-1.1,1.4c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0-0.3,0l0,0c0,0.1,0,0.2-0.1,0.4
			c-0.2,0.3-0.4,1.2-1.3,1.3H643l-0.1-0.2c-0.3-0.8-0.3-1.8-0.4-2.5c-0.1-0.9-0.3-1.8-0.4-2.7c-0.1-1.5-0.4-2.7-0.5-4.3
			c-8.2,1.5-14,2.7-14,2.7l-0.4,0.1l-2.8-17.4C624.5,247.7,624.2,247.9,624,248.1L624,248.1z"/>
									<path fill="#DDDDDC" d="M716.1,220.1V220v-0.3h0.3h0.1c0.1-1.1,0.8-1.4,1.3-1.9c0.1-0.1,0.2-0.3,0.2-0.5c0-0.6-0.5-1.6-0.6-2.4
			c-0.8-4.3-1.5-8.6-2.3-12.9v-13c0,0-2.8-12.9-3.2-14.6l-0.3,0.1c-0.1-0.4-0.1-0.8-0.2-1c-0.1-0.2-0.1-0.2-0.4-0.3l0,0
			c-0.2,0.1-0.7,0.5-1.3,0.6h-0.4v-0.3c0-0.5-0.1-1.1-0.2-1.7c0,0,0,0,0-0.1c0-0.4,0.2-1,0.1-1.1l0,0c0-0.4-0.5-0.6-0.8-1.3
			c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.5,0.2-0.8,0.2-1.1c0-0.1,0-0.1,0-0.2c-0.1-0.8-1.4-1.2-1.5-2.7c0,0,0-0.1,0-0.2
			c0.1-0.8,0.5-1.3,0.6-1.9c0-0.1,0-0.2,0-0.3s0-0.3,0-0.5s0-0.5,0.2-0.8c-0.6-0.6-1.6-1.3-1.6-2.3c0-0.4,0.2-0.9,0.7-1.2
			c0-0.1,0-0.1,0-0.2c0-0.7-0.7-0.9-1.1-1.9c-0.3-0.9-0.3-1.9-0.6-2.6c-0.2-0.5-0.2-0.8-0.3-1.2c-0.1-0.3-0.3-0.7-0.4-1.2
			c-0.1-0.2-0.1-0.5-0.1-0.8s0-0.7,0-1c0-0.2,0-0.5-0.1-0.7c-1.1,0.3-2.1,0.5-3.2,0.8c-3,0.7-5.9,1.7-8.9,2.5l-1.4,0.3
			c-0.4,0-0.6,0.1-1,0.1c-0.3,0-0.5-0.1-0.7-0.1c-0.5,0-1.1,0.1-1.5,0.4c-0.5,0.3-0.5,0.6-1.1,0.4c-0.5-0.2-0.8,0-1.1,0.4
			c-0.3,0.3-0.3,0.7-0.7,0.9c-0.4,0.3-0.8,0.5-1.3,0.8c-0.4,0.3-0.9,0.3-1.3,0.6c-0.4,0.4-0.7,0.8-1.1,1.1c-0.4,0.3-0.6,0.7-0.9,1.1
			c-0.4,0.5-0.9,1-1.2,1.5l-0.2,0.4c-0.3,0.6-0.7,1.1-1.1,1.7c-0.3,0.4-0.7,0.7-0.9,1.2c-0.1,0.3,0,0.5-0.1,0.8s-0.4,0.4-0.6,0.5
			c-0.2,0.2-0.2,0.4-0.4,0.7c-0.1,0.2-0.4,0.3-0.4,0.5c0,0.4,0.6,0.3,0.4,0.7c-0.1,0.2-0.5,0.4-0.7,0.4c0.1,0,0.1,0.1,0.2,0.1
			c-0.2,0.3-0.5,0.5-0.8,0.7c-0.1,0.1-0.4,0.2-0.5,0.3c0,0.2,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.3s-0.2,0.4-0.3,0.5
			c-0.2,0.3-0.3,0.4-0.6,0.6c-0.5,0.3-0.6,0.7-1,1.1c-0.3,0.3-0.6,0.4-0.9,0.6c-0.3,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-1.3,0.4-1.3,1
			c0,0.3,0.3,0.4,0.2,0.7c-0.1,0.2-0.7,0.3-0.6,0.6c0.1,0.5,1.3,0.6,1.6,1c0.1,0.2-0.1,0.3,0,0.5c0.1,0.4,0.4,0.2,0.6,0
			c0.2-0.2,0.4-0.5,0.4-0.7c-0.1-0.6-0.4-0.1-0.7,0c0,0,0.3-0.6,0.5-0.7c0.3-0.1,0.7,0.1,0.8,0.4c0.2,0.6-0.5,0.8-0.5,1.3
			c0.1,0.6,1,0.5,1,0.9c0,0.2-0.8,1.1-1,1.1c-0.3,0-0.4-0.3-0.7,0c-0.2,0.2-0.3,0.4-0.1,0.6c0.1,0.2,0.5,0.2,0.6,0.4
			c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0,0.5,0.1,0.8c0,0.3,0.1,0.5,0.2,0.8
			c0.1,0.4,0.2,1.2,0,1.5c-0.3,0.3-1.1,0.5-1.5,0.5c-0.5,0.1-1.2,0.3-1.4,0.9c-0.2,0.6,0,1-0.9,0.8c-0.1,0.2-0.1,0.5-0.2,0.7
			c-0.2,0.4-0.6,0.7-0.8,1.1c-0.3,0.5-0.5,1-1,1.2s-0.7,0.3-1.1,0.7c-0.4,0.4-0.8,0.5-1.3,0.6c-0.4,0.1-0.8,0.4-1.3,0.4
			s-1-0.1-1.5,0s-0.9,0.4-1.4,0.6c-0.5,0.2-1,0.2-1.5,0.3c-0.6,0.1-1.1,0.3-1.6,0.6c-0.3,0.2-0.8,0.6-1.2,0.6s-0.6-0.5-0.9-0.8
			c-0.3-0.2-0.8-0.3-1.2-0.3c-0.9-0.1-1.8-0.5-2.7-0.4c-0.8,0.1-1.7,0.3-2.5,0.4c-0.4,0-0.8,0-1.2,0s-0.8,0.2-1.2,0.4
			s-0.5,0.3-1,0.2c-0.4-0.1-0.8,0-1.2,0c-0.5,0.1-0.8,0.1-1.2,0.3c-0.3,0.2-0.7,0.3-1,0.4c-0.7,0.4-1.4,0.9-2.1,1.1
			c-0.7,0.3-1.5,0.7-2.1,1.1c-0.3,0.2-0.5,0.5-0.7,0.7c-0.4,0.4-0.3,0.5-0.2,1c0.1,0.4-0.1,0.7-0.1,1.1c0.1,0.3,0.4,0.6,0.6,0.7
			c0.1,0.1,0.3,0,0.4,0.2c0.1,0.1-0.1,0.3-0.1,0.5c0.1,0.3,0.5-0.1,0.8-0.1s1,0.1,0.8,0.5c-0.1,0.3-0.8,0.3-1.1,0.4
			c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0,0.3,0.1,0.4c0.2,0.3,0.5,0.1,0.7,0.3c0,0.2,0,0.3,0,0.5c0.1,0.8,0.8,1.1,1.2,1.7
			c0.4,0.6,0.3,1.4,0.1,2c-0.4,0.8-1.2,1.1-1.5,1.9c-0.4,1.1-0.9,1.7-1.8,2.5c-1.5,1.3-3,2.7-4.4,4.1c-0.8,0.8-1.6,1.4-2.5,2.1
			c0.1,0.8,0.7,3.8,0.9,4.8l58.6-11.5l0.1,0.4v-0.4c0.2,0,0.4,0,0.5,0c0.2,0,0.5,0,0.7,0h0.3v0.3c0,0.1,0,0.2,0,0.3
			c0,0.3-0.1,0.6-0.1,0.7c0,0.1,0,0.1,0.2,0.2c0.1,0,0.4,0,0.6,0.2c0.3,0.3,0.3,0.5,0.3,0.6c0,0,0.1-0.1,0.3-0.3s0.4-0.5,0.8-0.5
			h0.1c0.8,0.2,1,0.9,1,1.5c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0h0.4v0.4c0,0.4,0.3,0.5,0.7,1c0.2,0.3,0.3,0.6,0.3,0.8s0,0.3,0,0.5l0,0
			c0,0.5,0.6,0.9,0.8,1.9c0.2,0,0.3-0.1,0.6-0.1c0,0,0,0,0.1,0h0.2l0.1,0.2c0.2,0.6,0.7,1,1.2,1h0.1c0.1,0,0.3,0,0.4,0
			c0.9,0,1.6,0.4,2.2,1.1l0.3,0.2c0,0,10.9,3.7,12.1,3.9c0.6,0.1,0.9,0.6,0.9,1c-0.1,0.8-0.6,1.5-0.6,2.4c0,0.1,0,0.1,0,0.1
			c0.4-0.3,0.8-0.4,1.1-0.9c0.2-0.5,0.3-1,0.5-1.5c0.1-0.2,0.2-0.4,0.3-0.6c-0.3-0.4-0.3-1.5-0.3-1.7
			C716.6,221.2,716.1,220.9,716.1,220.1z"/>
									<path fill="#DDDDDC" d="M638.4,355.7L638.4,355.7L638.4,355.7L638.4,355.7L638.4,355.7L638.4,355.7L638.4,355.7z" />
									<path fill="#DDDDDC" d="M620.4,325.6c-0.1,0.2-0.3,0.3-0.4,0.4c0.2,0.1,0.4,0.3,0.4,0.6c0,0.2-0.1,0.4-0.1,0.5
			c-0.1,0.3-0.1,0.5-0.1,0.6s0,0.1,0,0.2l0,0c0.1,0,0.2,0.2,0.2,0.5l0,0c-0.1,0.5-0.3,0.6-0.3,0.6c0,0.1,0,0.3,0,0.4
			c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3v0.2l-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0-0.7-0.2-0.8-0.3c0,0.1-0.1,0.2-0.1,0.3
			c-0.2,0.7-0.4,0.8-0.8,1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.6c-0.2,0.9-0.6,1.4-0.9,2.2
			c-0.1,0.3-0.2,0.7-0.7,0.9c-0.3,0.1-0.9,0.2-1.3,0.2c-0.1,0-0.1,0-0.1,0h-0.2l-0.1-0.2c-0.1-0.3-0.1-0.6-0.1-0.7
			c0-0.2,0-0.2,0-0.2h-0.1c0,0-0.3,0.2-0.6,0.5c-0.5,0.4-0.8,0.3-1.2,0.4c-0.5,0.1-0.2,0.1-0.8,0.6c-0.3,0.3-0.7,0.5-0.8,0.5v0.1
			c0,0.1,0.1,0.3,0.1,0.6c0,0.1,0,0.2-0.1,0.4c-0.3,0.6-1,0.9-1.7,0.9c-0.4,0-0.9-0.1-1-0.6c-0.1-0.4-0.1-0.8-0.2-1
			c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2-0.2,0.4-0.5,0.9c-0.4,0.5-0.7,0.5-0.8,0.6c-0.2,0.2-0.1,0.9-0.3,1.6c-0.1,0.2-0.4,0.4-0.7,0.4
			c-0.1,0-0.2,0-0.3,0c-0.6-0.2-0.7-0.5-0.8-0.4h-0.1c-0.3,0.1-0.5,0.3-0.5,0.4l0,0c0,0.2,0,0.1,0.1,0.2s0.3,0.3,0.3,0.6
			c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.3,1-0.6,1.4c-0.3,0.3-0.6,0.4-0.8,0.4c-0.4,0-0.7-0.2-0.8-0.2l0,0c0,0-0.1,0.2-0.1,0.4l-0.1,0.3
			h-0.3h-0.1c-0.5,0-0.5,0.1-0.7,0.4c-0.1,0.3-0.3,0.9-0.9,1.2c-0.6,0.3-0.7,0.3-1.1,0.6c-0.3,0.3-0.5,0.5-0.9,0.8
			c-0.3,0.3-0.6,0.6-1,0.9c-0.3,0.2-0.6,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.8-0.1l0,0l0,0c-0.3,0-0.8,0.2-1.2,0.3
			c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0.1-0.2,0.1-0.2,0.4v0.3l-0.3,0.1c-0.8,0.1-2,0.5-2.2,1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.2
			s0,0.4-0.2,0.6c-0.4,0.4-0.9,0.3-0.9,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l0,0c0,0,0,0.1,0.1,0.3c0.1,0.3,0.4,0.4,0.5,1
			c0,0.2,0,0.3-0.1,0.5c-0.1,0.3-0.3,1-0.5,1.3c-0.3,0.4-0.7,0.5-1,0.5c-0.5,0-1-0.2-1.2-0.2l0,0c0,0,0,0,0,0.1l-0.1,0.4l-0.4-0.1
			c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.2,0.1-0.3,0.2c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.4,0.1,0.8,0.2,1.1c0.2,0.5,0.2,0.7,0.2,0.7
			s0,0,0.2,0.3c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1l0.1-0.1l0.1,0.9l0.1,0.4c7.3-0.9,13.4-1.8,17.4-2.8c0.1-0.2,0.3-0.3,0.5-0.4
			c0.4-0.2,0.7-0.2,1-0.4c0.8-0.4,1.6-0.7,2.2-1.1c0.3-0.2,0.5-0.5,1.1-0.5c0,0,0,0,0.1,0c0.3,0,0.5,0.1,0.5,0.1s0.1,0,0.2-0.1
			c0.4-0.2,1-1.5,2.1-1.6c0,0,0,0,0.1,0l0.3-0.2l3.5-1.3l19-1.9l0.1,0.2c0,0,0,0,0.2,0.4c0.1,0.2,0.2,0.2,0.2,0.5
			c0,0.2-0.1,0.4-0.3,0.5c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1l1.5-1.3l3.3,3.2v0.2c0,0,0,0.8-0.1,1.4
			c2.6-0.2,16.7-2.3,16.7-2.3h0.2c0,0,17.2,13,19.4,14.6c0.3-0.2,0.4-0.4,0.7-0.6s0.7-0.1,0.9-0.3c0.3-0.2,0.4-0.4,0.7-0.7
			c0.1,0.8,1.2,0,1.6-0.1c0.3-0.1,0.6-0.1,0.8-0.2c0.2,0,0.3,0.1,0.4,0c0.1,0,0.2-0.2,0.3-0.2c0.3-0.1,0.5,0,0.8,0s0.6-0.1,0.8-0.2
			c0.4,0,0.5,0.2,0.8-0.2c0.1-0.2,0.1-0.6,0.2-0.9c0.1,0.1,0.2,0,0.3,0.2c0.4-0.3,0.3-1.7,0.3-2.1s0.1-0.8-0.3-1.1
			c-0.3-0.3-0.8-0.1-0.6-0.8c0.4-0.1,0.4,0.3,0.8,0.4c0.1,0,0.7-0.1,0.8-0.1c-0.1-0.4-0.4-0.5-0.3-0.9c0.1-0.3,0.4-0.5,0.5-0.8
			c0.3-0.7,0.6-1.4,1.1-2c0.3-0.5,0.6-1.1,1-1.5c0.2-0.2,0.5-0.3,0.7-0.5s0.3-0.5,0.5-0.8c0.4-0.6,1.1-1.2,1.6-1.7
			c0.2-0.1,0.5-0.3,0.6-0.5c0.3-0.5-0.2-0.3-0.3-0.7c-0.3-1.1,0.9,0,1.1,0c0.7,0.1,0.7-1,1-1.3c0.4-0.4,0.8-0.8,1.2-1.3
			c0.6-0.7,0.4-0.7,0.1-1.5c0.5-0.1,0.7,0.7,0.8,1.1c0.8,0.2,1.6-0.7,2.2-1.1c0.6-0.4,1.5-0.9,2.2-1.1c0.2-0.1,0.4-0.1,0.6-0.2
			c0.1-0.1,0.1-0.4,0.2-0.5c0.5-0.3,0.5,0.3,0.7,0.7c0.3,0.6,1,0.5,0.9-0.2c-0.1-0.4-0.5-0.3-0.5-0.9c0.1-0.5,0.6-0.8,1-0.4
			c0.2,0.3,0.2,1,0.3,1.3c0.6,0.2,0.7-0.3,0.8-0.7c0.1-0.7,0.3-0.4,0.7-0.9c-0.1-0.3-0.1-0.6,0.1-0.8c0.1-0.1,0.3,0,0.4-0.1
			c0.1-0.1-0.1-0.2,0-0.3c0.2-0.5-0.1-0.4,0.4-0.5c0.3-0.1,0.8,0.1,0.7-0.4c-0.5,0.2-0.7-0.9,0.1-0.7c0-0.1,0.1-0.2,0.1-0.3
			c0.3,0,0.5-0.1,0.7-0.3c-0.4-0.4-0.4-0.7-0.4-1.3c-0.3,0.1-0.8,0-1,0.3s0.1,0.7-0.1,0.9c-0.3,0.4-1.3-0.3-1.5-0.6
			c-0.2-0.3,0.1-0.7-0.5-0.5c-0.5,0.1-0.2,0.3-0.3,0.6c0,1-0.4,0.5-1.1,0.8c-0.3,0.1-0.3,0.5-0.5,0.6c-0.4,0.2-0.4-0.2-0.8-0.2
			c-1,0-0.4,1-0.7,1.3c-0.3,0.4-1.1-0.3-1.4-0.3c-0.5-0.1-1.4,0.2-1.9,0c-0.3-0.1-0.3-0.4-0.6-0.6c-0.2-0.1-0.5-0.2-0.6-0.4
			c-0.6-0.4-1.3-0.8-1.4-1.6c0.5,0,1,0.7,1.5,1c0.6,0.3,1.2,0.3,1.8,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.4,0.2,0.4,0,0.7-0.2
			c0.2-0.1,0.6-0.1,0.8-0.3c0.3-0.2,0.1-0.6,0.6-0.5c0.2-0.5,0.4-0.5,0.7-0.9c0.5-0.5,0.2-0.5,0-0.9c0.7,0.2,0.5-0.8,0.1-0.9
			c-0.5-0.1-0.8,0.8-1.3,0.4c0.1-0.5,0.5-0.5,0.8-0.8c0.3-0.4,0.2-0.8,0.8-1c0-0.1,0-0.1-0.1-0.1c0.4,0,0.9,0.2,1-0.2
			c0.1-0.2-0.2-0.8-0.2-1c-0.1-0.2-0.1-0.6-0.5-0.6s-0.4,0.5-0.8,0.5c-0.3,0-0.4-0.6-0.7-0.8c-0.6-0.3-0.5,0.3-0.9,0.4
			c-0.6,0.1-0.3-0.5-0.6-0.8c-0.2-0.2-0.8,0-1.1-0.1c-0.4,0-0.7-0.2-1.1-0.1c-0.6,0.1-0.5,0-1.1-0.2c-0.4-0.2-0.7-0.2-1.1-0.4
			c-0.2-0.1-0.5-0.3-0.7-0.5c0.2,0.1,0.4,0.1,0.6,0.2c0.4,0.2,0.5,0.3,0.8,0.4c0.3,0,0.7,0.1,1,0.1c0.5,0,0.5-0.2,1-0.5
			c0,0.9,0.8,0.8,1.6,0.6c0.3-0.1,0.4-0.1,0.7-0.1s0.5,0.2,0.9,0.1c0.1,0,0.6-0.3,0.7-0.5c0.2-0.5-0.5-0.5-0.6-0.9
			c-0.1-0.5,0.4-0.3,0.7-0.1c0.2,0.1,0.3,0.5,0.4,0.7c0.3,0.4,0.2,0.3,0.6,0.4c0.3,0.1,0.6,0.2,0.9,0.1c0-0.3,0-0.6,0.1-0.8
			c0.4-0.1,0.3,0.2,0.5,0.4c0,0.1,0.2,0.3,0.2,0.3c0.1,0.1,0.3-0.1,0.3-0.1c0.3,0.1,0.2,0,0.5,0.4c0.2,0.2,0.3,0.5,0.6,0.5
			c0.1-0.4,0.1-1,0.7-1c0.6,0.1,0.4,0.7,1,0.4c0.2-0.1,0.2-0.3,0.4-0.4s0.5,0,0.7-0.2c0.4-0.4-0.1-0.7,0-1.2c0.4,0,0.6,0,0.8-0.3
			c0-0.1,0-0.6,0.1-0.8c0.2-0.8,0.5-0.8,1-1.3c0.5-0.6-0.3-0.8-0.1-1.5c0.3-0.8,1.2,0.5,1.4-0.3c0.6,0.1,0.8-0.3,0.7-0.8
			s-0.8-0.7-0.6-1.2c0.2,0,0.5,0.3,0.7,0.3c0.5-0.1,0.1-0.3,0.1-0.5c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.5,0-0.7-0.1-1.2
			c-0.1-0.4-0.4-0.8-0.3-1.2c0.4,0.1,0.4,0.6,0.8,0.7c0.6,0.1,0.5-0.6,0.1-0.7c0-0.1-0.1-0.2-0.1-0.3v0.1c-0.2,0-1.1-1.1-1.1-0.1
			c-0.2,0-0.4,0-0.6,0c-0.1-0.2-0.1-0.4-0.1-0.6c-0.4,0.1-0.9-0.4-1.5-0.4c-1.1,0.1-0.1,0.6-0.3,1.3c-0.2,0-0.6-0.3-0.8-0.1
			c-0.2,0.2,0,0.7,0,0.8c0,0.4,0,0.8-0.1,1.1c-0.1,0.4-0.5,0.7-0.4,1c0.7,0.2,1.4,2.7,0.6,2.5c-0.2-1.3-0.8-2.3-1-3.5
			c-0.1-0.6,0-1.3-0.1-1.9c-0.1-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.6-0.6c-0.3,0.1-0.5,0.2-0.8,0.3c-0.2,0.1-0.2,0.4-0.4,0.5
			s-0.2-0.1-0.4-0.1c-0.6,0.1-1.5,0.3-1.4,1.1c-0.2,0.1-0.5,0.3-0.8,0.3c-0.3-0.1-0.2-0.5-0.4-0.7c-0.5-0.4-0.7,0.2-1,0.5
			c-0.7,0.7-1.5,0.7-2.3,1c-0.4,0.2-0.8,0.6-1.3,0.4c-0.7-0.2,0-0.5-0.1-1c-0.1-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.4-0.9-0.6-1.4
			c-0.2-0.6-0.2-0.9-0.3-1.5c-0.1-0.5-0.4-0.8-0.3-1.3c0.7,0.6,0.7,1.4,1,2.3c0.2,0.4,0.4,1.1,0.7,1.3c0.4,0.3,0.7,0.1,1.1,0.2
			c0.4,0.2,0.5,0.7,0.9,0.8c0.5,0.1,0.7-0.3,1-0.5c0.4-0.3,0.5-0.4,0.6-0.8c0.2-0.6,0-0.4,0.6-0.7c0.5-0.2,0.6-0.4,0-0.6
			c-0.5-0.2-1,0-1.1-0.7c0.6,0.2,1.2,0.2,1.7,0.5c0.5,0.2,1.4,0.4,1.8,0c-0.2-0.2-1.3-0.5-1.1-0.9c0.2-0.3,0.9,0.1,1.1,0.2
			c0.5,0.2,0.4,0.2,0.8,0c0.5-0.2,1-0.1,1.4-0.5c-0.2-0.4-0.3-0.8-0.7-1.1c-0.4-0.3-1.7-0.3-1.5-0.9c0.8,0.1,1.4,0.1,2,0.7
			c0.5,0.5,1.3,0.5,2,0.3c0.1-0.5-0.7-0.8-0.5-1.4c0.3,0.8,1.1,1.1,1.6,1.7c0.3,0.4,0.7,1.5,1.4,1.3c0.1-0.6-0.7-1.2-1-1.6
			c-0.2-0.3-0.3-0.7-0.6-0.9c-0.2-0.3-0.6-0.3-0.6-0.7c0.1,0,0.1,0,0.2,0c-0.2-0.4-0.2-1-0.6-1.3c-0.4-0.3-0.8-0.2-1.1-0.6
			c-0.4-0.6-0.1-1-0.8-1.3c-0.2-0.1-0.5-0.1-0.7-0.4c-0.1-0.3,0.1-0.5,0.2-0.8c-0.7-0.2-0.4-1.2,0.2-0.4c0.2,0.2,0.1,0.3,0.4,0.4
			c0.2,0,0.5-0.1,0.7-0.1c0-0.3-0.1-0.7-0.3-0.9c-0.2-0.1-0.5,0-0.6-0.1c-4.9,1.8-68.6,14-72.3,14.2
			C628.7,324.9,623.4,325.4,620.4,325.6z M702.8,330.6c0.3-0.1,0.7,0,0.9-0.1c0.4-0.2,0.4-0.3,0.6-0.7c0.4-0.5,0.3-0.4,0.9-0.2
			c0.3,0.1,0.7,0.1,0.7,0.5v0.2c0.1,0,0.2,0,0.1,0.2c-0.3,0-0.4,0.3-0.3,0.6c-0.2-0.1-0.4-0.1-0.6,0V331c0,0.3,0,0.5,0,0.7
			c-0.2,0-0.3,0-0.5-0.2c-0.1,0.3-0.4,0.4-0.7,0.3c-0.4-0.1-0.2-0.3-0.7-0.3c-0.3,0-0.6,0.3-0.8-0.1
			C702.3,330.9,702.6,330.7,702.8,330.6z M701.6,328.2l0.1,0.1c0.4,0.1,0.5,0.7,0.2,0.9c-0.3,0.2-0.8-0.2-0.5-0.5
			C701.2,328.6,701.3,328.2,701.6,328.2z M700,329.4c-0.4,0.1-0.5-0.5-0.1-0.6C700.3,328.8,700.4,329.3,700,329.4z M698.1,326.4
			c0.3,0.1,0.5,0.3,0.9,0.2c0.1-0.4,0.3-0.7,0.8-0.4l0.2,0.2c0.4,0.1,0.4,0.6,0.2,0.9c-0.3,0.3-0.5,0-0.8,0
			C699,327.2,697.6,327.3,698.1,326.4z"/>
									<path fill="#DDDDDC" d="M529.5,283c-0.3,0.4-0.5,0.7-0.7,1.2c-0.4,0.4-0.8,0.7-0.8,0.9l0,0l0,0c0,0.1,0.4,0.3,0.7,0.9
			c0,0.1,0.1,0.2,0.1,0.4c0,0.4-0.3,0.6-0.4,0.7c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.1-0.1,0.2c0.1,0,0.2,0.1,0.4,0.2
			c0.5,0.2,0.8,0.6,0.7,0.9l0,0c0,0.1,0,0.2,0,0.4c0.1,0.6,0.8,1.2,0.8,2.2c0,0.2,0,0.4-0.1,0.6s-0.1,0.3-0.1,0.4l0,0
			c0.1,0.1,0.2,0.2,0.4,0.4c0.2,0.3,0.7,0.7,0.7,1.3c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.3,0.5-0.4,0.6l0,0c0,0.2,0.2,0.6,0.2,1.1
			c0,0.3-0.2,0.7-0.5,0.9c-0.5,0.2-1.2,0.3-1.1,0.4v0.1c-0.1,0.1,0.3,0.1,0.5,0.8c0,0.1,0,0.2-0.1,0.3c-0.2,0.4-0.5,0.5-0.7,0.7
			c-0.1,0.1-0.1,0.1-0.2,0.1c0.2,0.1,0.3,0.2,0.3,0.5c0,0.4-0.3,0.5-0.5,0.7c-0.2,0.1-0.4,0.3-0.5,0.3c-0.3,0.1-0.5,0.2-0.5,0.2
			c0,0.1,0,0.2,0.1,0.5c0,0.1,0,0.3,0,0.4c-0.1,1.2-1.2,1.8-1.1,2.5c0,0.1,0,0.2,0.1,0.4l0.1,0.4l-0.4,0.1c-0.1,0-0.2,0-0.3,0
			c-0.3,0-0.6-0.1-0.7-0.2c0,0.1,0,0.1,0,0.3l0,0c0,0.1,0.1,0.3,0.1,0.5c0.1,0.1,0.1,0.2,0.2,0.2l0,0h0.6l-0.3,0.6
			c-0.3,0.6-0.6,1.1-0.9,1.6c-0.2,0.4-0.3,0.7-0.3,1s0,0.6,0,1v0.1l0,0c0,0.6-0.2,1.1-0.2,1.5h-0.1h0.1c0,0.4,0.3,0.8,0.5,1.4
			c0.1,0,0.2,0,0.3,0c-0.1-0.3-0.3-0.7-0.3-1.1c0-0.2,0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.4,0.7-0.4c0.7,0.1,1.2,0.6,1.4,0.6l0,0
			c0.1,0,0.3-0.1,0.5-0.3c0.2-0.2,0.5-0.5,1-0.5c0.2,0,0.3,0,0.5,0.1c0.5,0.3,0.5,0.7,0.6,0.9c0.1,0.2,0.1,0.2,0.1,0.2
			c0.1-0.5,0.3-0.6,0.3-0.7c0,0,0,0,0-0.1c0-0.2-0.2-0.4-0.4-1c0-0.1,0-0.2,0-0.3c0-0.3,0-1,0.8-1.1h0.1c0.5,0.1,0.6,0.4,0.6,0.6
			c0.1,0.2,0.1,0.4,0.1,0.4c0.2,0.3,1.1,0.4,1.7,0.4l0,0c0.2,0,0.3-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.2,1,0.6,1.3,0.9
			c0.7,0.5,0.7,0.7,1.3,0.7c0.1,0,0.2,0,0.3,0c0.6-0.1,0.5-0.1,0.5-0.1c0-0.1,0-0.5,0.3-1c0.1-0.3,0.3-0.6,0.6-0.6
			c0.2,0,0.2,0,0.2,0h0.1c0.3-0.1,0.4-0.4,0.8-0.9c0.2-0.3,0.6-1,1.3-1.1c0,0,0.1,0,0.2,0c0.3,0,0.6,0.2,0.7,0.4
			c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.1,0.4l0,0c0,0,0,0,0.1,0l0,0c0.1,0,0.3-0.2,0.7-0.2c0.2,0,0.5,0.1,0.7,0.4
			c0.3,0.5,0.2,1,0.2,1.2c0,0,0.1,0,0.2-0.1c0-0.2,0-0.5,0.2-0.9c0.2-0.2,0.2-0.4,0.6-0.4c0.1,0,0.2,0,0.2,0s0,0,0.1,0
			c0.5-0.2,0.3-0.1,0.4-0.3c0-0.1,0-0.3-0.1-0.6c0-0.2-0.1-0.5-0.1-0.8c0-0.4,0-0.9,0.3-1.3c0.3-0.2,0.5-0.2,0.6-0.3
			c0-0.1-0.2-0.4-0.2-0.8c0-0.1,0-0.2,0-0.2l0.1-0.2h0.2h0.1c0.2,0,0.5,0,0.7,0s0.3,0,0.3,0l0,0c0.1-0.1-0.3-0.4-0.4-1
			c0,0,0-0.1,0-0.2l0.1-0.3l0.3,0.1c0.7,0.2,1.6,0.4,1.7,1.5c0.2,1-0.1,0.7,0.4,1.2c0.5,0.4,0.8,0.8,1.2,0.8c0,0,0,0,0.1,0
			c0.3,0,0.5-0.1,0.8-0.1s0.6,0.2,0.8,0.5c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.3,0.5-0.6,1-0.7c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.3,0-0.7-0.1-1c0-0.2-0.1-0.4-0.1-0.6s0-0.4,0.2-0.6c0.1-0.2,0.4-0.3,0.6-0.3c0-0.1,0-0.2,0-0.4v-0.1c0-0.4,0-1,0.6-1.4
			c0.4-0.3,0.8-0.3,1-0.3h0.1h0.1c0,0,0,0,0.1-0.3c0-0.1,0-0.1,0-0.3c0-0.2,0-0.4,0-0.7c0-0.4,0.1-0.9,0.5-1.2
			c0.2-0.2,0.5-0.2,0.6-0.2s0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1-0.2,0.2-0.6c0.1-0.2,0.2-0.7,0.5-1c0.5-0.3,0.9-0.2,0.8-0.3
			c0.1-0.1,0.1-0.2,0.1-0.4c0-0.3-0.1-0.6-0.1-1c0,0,0,0,0-0.1c0-0.3,0-0.5,0-0.7s0-0.2-0.2-0.5c-0.2-0.2-0.3-0.5-0.3-0.7
			c0.1-1,1.3-1.2,2.2-1.2c0.5,0,1,0.1,1.4,0.4c0.2,0.2,0.2,0.4,0.2,0.5c0,0,0,0,0-0.1s0.1-0.3,0.1-0.5l0.1-0.7l0.5,0.5
			c0.3,0.3,0.5,0.6,0.6,0.7c0,0,0.1-0.1,0.2-0.3c0.6-1.1,1.3-1.9,2.8-2.2c0.2,0,0.3-0.1,0.4-0.1l0,0l0,0c0-0.1,0-0.2,0-0.4v-0.3
			l0.3-0.1c0.5-0.1,0.6-0.3,0.6-0.4s0-0.1,0-0.2c-0.1-0.3-0.2-0.3-0.4-0.3s-0.6,0.1-1-0.3c-0.1-0.1-0.2-0.3-0.2-0.4
			c0-0.5,0.3-0.7,0.3-0.8l0,0c0,0-0.1-0.2-0.3-0.3c-0.2-0.2-0.4-0.4-0.4-0.8v-0.1c0.1-0.4,0.5-0.6,0.8-0.6c0.1,0,0.2,0,0.3,0
			s0.1,0,0.1,0s0,0,0-0.1s0-0.2,0-0.3c0-0.2,0-0.5,0.2-0.8c0-0.1,0.1-0.1,0.1-0.1L562,235l-27,2.4c-0.2,0.2-0.5,0.5-0.8,0.7
			c-1.3,1.1-2.5,2.1-4,2.7c-1-0.3-1.7,0.7-2.7,0.4c-0.4-0.1-0.6-0.5-0.9-0.7c-0.3-0.2-0.7-0.3-1.1-0.5c-0.1,0-0.1-0.1-0.2-0.1
			l3.7,42.8L529.5,283z"/>
									<path fill="#DDDDDC" d="M563.3,235.4l18.1-2.5l0,0c0.1-0.4,0.2-0.9,0.3-1.3c0.2-0.5,0.6-0.7,0.7-1.3c0.1-0.6,0.1-0.6,0.6-0.8
			c0.6-0.3,0-0.6,0.2-1.1c0.1-0.3,0.5-0.3,0.7-0.6s0.2-0.7,0.3-1c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.4-0.2-0.9-0.2-1.3c0-0.4,0.2-0.8,0.2-1.2c0-0.5-0.3-0.8-0.1-1.3c0.2-0.4,1.2-1,1.7-1s0.7,0.1,1-0.3
			c0.2-0.2,0.4-0.6,0.4-0.9c0-0.5-0.5-0.8-0.2-1.3c0.1-0.4,0.4-0.6,0.3-1c-0.2-0.5-0.5-0.7-0.3-1.4c0.1-0.4,0.4-1,0.8-1.3
			c-0.1-0.3,0.2-0.8,0.5-1c0.5-0.4,1.3-0.4,1.9-0.2c0.4,0.2,1.6,1.6,1.6,0.4c0-0.5-0.2-0.7-0.1-1.2c0.1-0.4,0-0.7,0-1.1
			c0-0.8,0.1-1.7,0.1-2.5c0-0.5,0.3-1.1,0.2-1.6s-0.6-0.7-0.9-1c-0.6-0.9-0.7-2-0.9-3c-0.3-1.5-0.8-3-1.4-4.4
			c-0.6-1.4-0.8-2.8-1.2-4.3c-0.2-0.7-0.2-1.4-0.6-2c-0.4-0.7-0.7-1.4-1-2.1c-0.2-0.6-0.6-1-1.1-1.4c-0.3-0.3-0.5-0.3-0.9-0.3
			c-0.2,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3-0.1-0.6,0.3-1,0.4c-0.8,0.3-0.8,0.4-0.9,1.2c-0.2-0.3-0.5-0.3-0.8-0.2
			c-0.4,0.1-0.4,0.6-0.8,0.7c-0.3,0.1-0.5-0.2-0.8-0.2c-0.4,0-0.5,0.4-0.5,0.7c-0.2,0.6-0.2,1.4-0.7,1.8c-0.7,0.5-0.2,1-0.5,1.7
			c-0.1,0.3-0.4,0.5-0.6,0.7c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,0.2-0.2,0-0.3,0.1c-0.3,0.2-0.4,0.6-0.7,0.9c-0.4,0.5-0.6,0.3-1.1,0.5
			c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.2-0.5-0.1c0,0-0.4,0.5-0.4,0.3
			c0.1,0.4,0.1,1.1-0.1,1.4c0-0.3-0.3-0.6-0.3-0.9c0-0.3,0.3-0.5,0.3-0.8s-0.4-0.5-0.6-0.7c-0.2-0.2-0.4-0.5-0.5-0.8
			c-0.2-0.6-0.3-1.3-0.2-1.9c0.1-0.7,0-1.2,0.3-1.9c0.3-0.6-0.2-1.2,0.4-1.8c0.3-0.3,0.6-0.5,0.9-0.5c0.3,0,0.7,0.1,1-0.1
			c0.2-0.2,0.3-0.5,0.5-0.7s0.5-0.2,0.7-0.6c0.1-0.3,0-0.7,0-1s0.1-0.6,0.1-1s0-0.7,0.1-1s0.2-0.5,0.3-0.8c0-0.2-0.1-0.7,0.2-0.7
			c0.3-0.1,0.5,0.5,0.6,0.6c0.2-0.3,0.6-0.1,0.8-0.6c0.1-0.2,0-0.4,0.2-0.5c0.1-0.1,0.4,0.1,0.5-0.1c0.1-0.2-0.1-0.8,0-1.1
			c0-0.4,0.1-0.8,0.1-1.2c0-0.8-0.1-1.6-0.2-2.4c-0.1-0.8-0.2-1.6-0.2-2.3c0-0.4,0-0.7,0-1.1c0-0.3,0.1-0.4-0.1-0.6
			c0-0.1-0.3-0.4-0.3-0.4c-0.2-0.2-0.5-0.9-0.5-1.3c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.4,0-0.6-0.1
			c-0.3-0.2-0.1-0.7-0.4-1.1c-0.2-0.3-0.7-1.2,0-1.3c0.3-0.1,1.1,0.4,1-0.2c0.2,0.4,0.5,0,0.5-0.3s-0.2-0.2-0.3-0.4s0-0.4,0-0.6
			c0-0.4-0.1-0.5-0.4-0.7c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2,0-0.3-0.1s-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.2
			c-0.2-0.3,0-1.2-0.5-1.3c-0.3-0.1-0.5,0.2-0.8,0c-0.2-0.2-0.3-0.3-0.6-0.4c-0.3-0.1-0.6,0-0.9-0.1c-0.2-0.1-0.3-0.3-0.6-0.4
			c-0.3-0.1-0.5,0-0.8,0c-0.2,0-0.5-0.1-0.7-0.2c-0.4,0-0.6,0-0.8-0.4c-0.1-0.3-0.2-0.6-0.5-0.7c-0.4-0.2-1.1-0.3-1.6-0.2
			c-0.4,0.1-1.2,0.2-1.6-0.2c-0.3-0.3-0.4-0.7-0.6-1c-0.3-0.3-0.6-0.2-1-0.3c-0.6-0.1-0.5-0.7-1.1-0.8c-0.7-0.1-2-0.3-1.4,0.8
			c0.3,0.5,0.6,1.2,1.2,1.4c0.4,0.2,0.8,0.2,1.1,0.6c0.3,0.4,0.2,0.8-0.4,0.6c-0.4-0.1-0.4-0.6-0.6-0.9c-0.2-0.3-0.6-0.3-0.8-0.6
			s-0.2-0.6-0.4-0.9c-0.2-0.3-0.5-0.4-0.6-0.8c0,0.3-0.1,0.5-0.1,0.7c-0.1,0.3,0,0.5,0,0.8c-0.1,0.6-0.7,0.9-0.9,1.4
			c-0.1,0.2,0.1,0.7-0.2,0.8c-0.4,0.2-0.4-0.4-0.3-0.6c0-0.2,0.1-0.6,0.2-0.8c0.1-0.3,0.4-0.4,0.4-0.7s-0.3-0.4,0-0.8
			c0.2-0.2,0.4-0.3,0.7-0.4c-0.2-0.3-0.3-0.7-0.7-0.9s-0.8-0.2-1.2-0.3c-0.5-0.2-0.6-0.4-0.9-0.8c-0.2-0.2-0.6-0.7-0.7-0.2
			c-0.5,0.3-1-0.2-1.5,0c-0.7,0.2-0.9,0.5-1.2,1s-0.7,0.5-0.9,0.9c-0.1,0.3,0,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.3
			c-1.1,0.8-0.4,1.5,0.2,2.3c0.2,0.3,0.4,0.8,0.7,1c0.4,0.2,1.1-0.2,1.4,0.1c0.6,0.6-0.3,0.7-0.5,0.8c-0.4,0.2-0.2,0-0.3,0.3
			c-0.1,0.2,0.1,0.5-0.1,0.8c-0.3-0.2-0.3-0.5-0.6-0.7c0,0-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.6-0.2c-0.2,0-0.7,0.3-0.9,0.4
			c-0.3,0.1-0.8,0.3-0.8,0.7c0.4,0.1,0.4,0.5,0.7,0.6s0.7-0.3,1.1-0.1c0.2,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.4,0,0.6,0
			c0.5,0.1,0.7,0.4,0.4,0.9c-0.6,0.2-1.6-1.3-2.1-0.7v0.1c-0.2-0.1-0.5-0.1-0.7-0.3s-0.1-0.7-0.5-0.6c-0.4,0.5-1.1,1.1-1,1.7
			c0,0.3,0.3,0.6,0.4,0.9c0,0.4-0.2,0.7-0.2,1.1c0,0.4,0.2,0.6,0.5,0.9c0.3,0.4,0.2,0.4,0.2,0.8c0.1,0.3,0.3,0.5,0.4,0.8
			c0,0.3-0.3,0.6-0.2,1c0.4,0,0.6,0.2,0.5,0.6c-0.2-0.2-0.6-0.2-0.6,0.2c0,0.3,0.3,0.3,0.4,0.5c0.1,0.1,0.6,0.5,0.3,0.8
			c-0.3,0.2-0.5-0.4-0.6-0.6c-0.2,0.1-0.1,0.4-0.1,0.7c-0.3-0.3-0.6-0.4-0.7-0.7c-0.1-0.3,0.1-0.8,0.2-1.1c0.2-0.5,0.6-2.2-0.2-2.4
			c0,0.5-0.1,0.8-0.2,1.3c-0.1,0.5,0.2,0.8,0.2,1.2c0,0.6-0.7,1-0.9,1.5c0,0.1-0.4,1.9-0.7,1.5c-0.2-0.3-0.1-0.6-0.1-1
			c0.1-0.5,0.1-0.4,0-0.9c-0.1-0.7,0.7-1.6,0.1-2.1c-0.2,0.8-0.6,1.4-0.6,2.2c0,0.5,0.2,2.2-0.8,1.9c-0.1-0.4,0.1-0.8,0.1-1.2
			c0-0.5-0.2-0.6-0.3-0.9c-0.2-0.7,0.4-1.3,0.3-2.1c-0.1,0.2-0.2,0.5-0.5,0.5c-0.3-1.1,0.7-1.4,0.6-2.5c-0.1-0.4-0.3-0.5-0.1-1
			c0.3-0.5,0.6-0.5,0.4-1.2c-0.3,0.1-0.4,0.4-0.6,0.5c-0.4,0.3-0.3,0.1-0.5,0.5c-0.1,0.4-0.2,1-0.5,1.4c-0.1,0.2-0.4,0.3-0.5,0.5
			c-0.2,0.4,0.1,0.3,0.2,0.6c0.2,0.6,0.1,1.2,0.2,1.8c0.1,0.6,0.7,1.1,0.1,1.7c-1.1-0.1,0.1-3-0.8-3.3c-0.2,0.6-0.1,0.9-0.7,1.3
			c-0.6,0.4-0.7,0.2-1.2-0.3c-0.1,0-0.2,0.1-0.2,0.1c0,0.2,0,0.5-0.2,0.7c-0.5,0.1-1.1,0.2-1.1,0.8c0.2,0,0.6-0.4,0.7-0.3
			c0.3,0,0.4,0.5,0.3,0.8c-0.4,0.8-0.8-0.2-1.2-0.1c-0.5,0.1-0.4,1-0.3,1.4c0.2,0.6,0.5,1-0.2,1.4c-0.4,0.2-1.3-0.1-1.4,0.4
			c0.3,0.1,0.3,0.3,0.6,0.5c0.2,0.1,0.7,0,0.8,0.3c0,0.2-0.7,0.4-0.9,0.3c-0.4-0.1-0.5-0.5-0.9-0.4c-0.1,0.4,0.2,0.3,0.2,0.5
			c0.1,0.2,0,0.4,0.1,0.7c0.2,0.7,0.2,0.7,0.1,1.4c-0.2,0.9,0.3,1.7,0.2,2.6c-0.1,0.7-0.3,1.7-0.6,2.3c-0.2,0.3-0.6,0.7-0.7,1
			c0,0.2,0.1,0.4,0,0.7c0,0.1-0.2,0.3-0.2,0.4c-0.2,0.5-0.3,1-0.6,1.4s-0.7,0.7-0.9,1.2c0.2,0.4,0.4,0.7,0.6,1.1
			c1,1.8,1.2,3.2,0.5,5.2c-0.6,1.8-0.5,3,0.5,4.6c1.8,2.9,3.6,6,4.5,9.3c0.5,1.7,0.7,3.3,0.6,5.2c-0.1,1.7,0.2,3.4-0.2,5
			c-0.3,1.6-1.3,3.1-1.9,4.6c-0.7,1.6-1.2,3.2-1.8,4.9c-0.4,1-0.8,1.8-1.5,2.6l26.9-2.4L563.3,235.4z M550.7,171.9
			c-0.1-0.2-0.2-0.6-0.3-1C550.8,171.2,551.3,171.6,550.7,171.9z"/>
									<path fill="#DDDDDC" d="M714.4,225.8c-0.1-0.8,0.6-1.4,0.6-2.1c0-0.2,0-0.2-0.3-0.3c-1.4-0.3-12.2-3.9-12.3-4l-0.5-0.3l0,0
			c-0.2,0-0.6,0.3-0.7,0.5c-0.2,0.2-0.2,0.2-0.2,0.2s0.1,0.2,0.1,0.4c0,0,0,0,0,0.1c-0.1,1-0.5,1.4-0.5,2.1c0,0.8-0.1,1.6-0.8,2.2
			c-0.2,0.2-0.4,0.3-0.5,0.3s0,0-0.1,0.2c0,0,0,0.1,0,0.3s0,0.5,0,0.7c0,0,0,0,0,0.1v0.3l-0.3,0.1c-0.3,0.1-0.5,0.2-0.8,0.4
			c0.4,0.5,0.7,1.2,0.9,1.7c0.1,0.3,0.2,0.5,0.2,0.8c0,0.2,0,0.3-0.1,0.5c-0.1,0.4-0.3,0.5-0.3,0.5c0,0.1,0.1,0.2,0.1,0.5v0.1
			c0,0.4-0.1,0.6-0.3,1c-0.1,0.2-0.3,0.7-0.3,0.8l0,0c0,0.3,0.3,0.5,0.5,1.1c0.1,0.3,0.1,0.5,0.1,0.7c0.2-0.1,0.3-0.2,0.6-0.2
			c0.4,0,0.6,0.3,0.8,0.6c0.4,0.8,0.2,0.8,0.9,1.2c0.3,0.2,1,0.1,1.6,0.6c0.4,0.5,0.2,1,0.3,1.1s0.2,0.2,0.6,0.4
			c0.3,0.2,0.4,0.5,0.5,0.5c0.1,0.1,0.5,0,0.9,0.3s0.4,0.7,0.5,0.7c0,0.1,0.3,0,0.7,0.3c0.3,0.2,0.5,0.5,0.5,0.8
			c-0.1,0.7-0.7,0.9-1.2,1.1c-1,0.4-0.6,0-1,0.9c-0.2,0.7-0.5,1.3-0.8,2c-0.3,0.6-0.7,1.3-1.2,1.6c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.1,0.1-0.3,0.4-0.5,0.5c-0.1,0.1-0.3,0.2-0.4,0.4c-0.4,0.5-1,0.6-1.6,0.8c-0.4,0.1-0.8,0.4-1.1,0.5c-0.6,0.3-0.4,0.7-0.7,1.1
			c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.2,0,0.5,0,0.7c-0.1,0.5-0.1,0.8,0,1.4c0,0.2,0,0.5,0.1,0.7s0.4,0.3,0.5,0.5c0.2,0.4,0,1,0.1,1.4
			c0,0.5,0.2,0.8,0.7,1c0.4,0.2,1,0.1,1.1,0.6c0.2,0,0.7,0.1,0.9,0.2c0.2,0.2,0.2,0.5,0.4,0.6c0.1,0.1,0.2,0.1,0.4,0.2
			c0.1,0.1,0.1,0.3,0.3,0.4s0.8-0.3,0.7,0.1c0.2,0,0.4,0.2,0.6,0.2c0.3,0.1,0.4,0,0.6,0c0.6,0,0.2,0.4,0.6,0.8
			c0.2,0.2,0.6,0.2,0.8,0.2c0.5-0.1,0.3-0.3,0.6-0.6c0.4-0.3,0.4,0.2,0.7,0.4c0.2,0.2,0.6,0.1,0.9,0.2c0.3,0.1,1,0.4,1,0.8
			c0,0.2-0.2,0.3-0.2,0.5s0,0.3,0.1,0.5c0.1,0.4,0,0.7-0.1,1.1s0,0.8-0.1,1.2c0.3,0.1,0.8-0.5,0.9,0c0.5,0.1,0.7-0.5,0.8-0.9
			c0.2-0.4,0.5-0.4,0.6-0.9s0.2-0.6,0.5-0.9c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.2-0.2-0.4c-0.2-0.4,0-0.4,0.2-0.8
			c0.1-0.3,0.2-0.6,0.4-0.9c0.3-0.5,0.6-1.5,0.2-1.9c-0.2-0.2-0.3,0-0.3-0.4c0-0.1,0.1-0.3,0.2-0.4c0.3-0.4,0.5-0.1,0.8-0.2
			c0-0.1,0-0.3,0.1-0.4c0.2,0,0.3-0.2,0.5-0.3c0.3-0.1,0.4,0.1,0.4-0.3c0-0.2-0.1-0.3,0-0.5c0-0.2,0.1-0.3,0.1-0.5
			c-0.1-0.3-0.2-0.9,0.1-1.1c0.1,0,1.1-0.3,0.4-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.2-0.3-0.6-0.1-0.8
			c0.3-0.3,0.8,0.3,1.1-0.2c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.3,0.4-0.6c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.3,0.4-0.5,0.5-0.8
			c0.1-0.2,0.5-1.4-0.1-1.3c0-0.5,0.2-1.1,0.1-1.6c-0.1-0.5-0.2-0.9-0.1-1.3c0.1-0.5,0.2-0.7,0.1-1.2c-0.2-0.5,0-1-0.2-1.4
			c-0.1-0.3-0.4-0.8,0.1-1c0.1-0.4,0-0.7,0.1-1.1c0.1-0.4,0.3-0.9,0.3-1.3l0.1-0.5c0-0.3,0-0.6,0-0.9c-0.3-0.3,0-0.7,0.1-1.1
			c0.1-0.4-0.3-0.5-0.4-0.9c-0.2-0.3,0-0.5-0.5-0.6c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0-0.9,0c-0.3,0-0.6,0.1-0.9,0
			c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.1-0.4-0.2-0.3-0.5c0.2-0.5,0.6-0.8,0.7-1.4c0.1-0.3,0.3-0.4,0.4-0.7c0.1-0.3-0.1-0.2-0.3-0.4
			c-0.4-0.4-0.2-1.1,0.1-1.5c0.4-0.5-0.1-1,0.7-1.3c0.4-0.2,0.5-0.1,0.7,0.2c0.2,0.2,0.3,0.5,0.5,0.1c0.1-0.2,0-0.5,0-0.8
			s0.1-0.6,0.1-0.9c-0.1-0.1-0.1-0.1-0.1-0.2C714.5,226,714.5,225.9,714.4,225.8z"/>
									<path fill="#DDDDDC" d="M740.6,209.1c0.1-0.3,0.2-0.7,0.2-1s0-0.7-0.1-1.1c-0.2-1.8-2.1-8.2-2.6-9.6c-8.4,2.2-20.1,4.4-22.2,4.8
			c0.7,4.2,1.5,8.3,2.2,12.5c0.1,0.7,0.6,1.6,0.6,2.5c0,0.4-0.1,0.7-0.4,1c-0.8,0.8-1.1,0.6-1.1,1.7v0.3h-0.3
			c0.1,0.2,0.3,0.4,0.5,0.9c0.1,0.4,0.1,0.8,0.2,1.2v0.1c0.4-0.4,0.9-0.7,1.3-1.2c0.3-0.4,0.6-0.8,0.9-1.1c0.3-0.3,0.7-0.5,1-0.8
			c0.4-0.4,0.6-0.9,1-1.2c0.3-0.3,0.7-0.4,1-0.6c0.8-0.5,1.2-1.3,2-1.7c0.4-0.2,0.9-0.3,1.1-0.7c0.1-0.4,0-1,0.4-1.2
			c0.3-0.2,0.6,0.1,0.9,0.1c0.3,0.1,0.7,0.2,1,0.2c0.8-0.1,1.6-0.6,2.4-0.8c0.4-0.1,0.8-0.4,1.2-0.6c0.4-0.2,0.9-0.4,1.3-0.6
			c0.3-0.2,0.4-0.4,0.7-0.6c0.4-0.1,0.8-0.3,1.2-0.3h0.6c0.7-0.4,1-1.1,1.3-1.7c0.4-1.1,0.6,0,1.3,0.1c0.3,0,0.5,0.1,0.8,0.2
			c0.4,0.1,0.5,0,0.7-0.4c0.2-0.3,0.3-0.5,0.7-0.3c0.1,0,0.1,0,0.2,0.1C740.6,209.2,740.6,209.2,740.6,209.1
			C740.6,209.2,740.6,209.2,740.6,209.1z"/>
									<path fill="#DDDDDC" d="M591.4,327.7c0.3-0.2,0.5-0.2,0.7-0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.4,1-0.7
			c0.9-0.4,1.8-0.4,2.3-0.8c0.1-0.1,0.3-0.5,0.7-0.8c0.2-0.1,0.4-0.1,0.5-0.1s0.2,0,0.2,0l0,0l0,0c0,0,0,0,0.1-0.1
			c0.2-0.4,0.2-0.6,0.3-1s0.5-0.7,1.1-0.7c0.5-0.7,1.4-0.6,1.6-0.9c0.4-0.3,0.3-0.3,0.4-0.6c0-0.1,0-0.3,0-0.5c0-0.1,0-0.3,0-0.5
			c0.1-0.6,0.6-0.9,1.1-1.3s0.8-0.9,0.8-1c0,0,0-0.1-0.1-0.1l-0.2-0.1l0.1-0.2c0.3-1.2,1.6-1.6,2.1-2.1c0.8-1,1.6-0.9,2.4-1.4
			c0.5-0.3,1.1-1,1.6-1.6c1.3-1.3,2.5-2.5,3.6-3.9c-0.3-0.2-0.6-0.4-0.8-0.5c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0s-0.3,0-0.5-0.1
			c-0.9-0.3-1.4-1.1-1.9-1.5c-0.4-0.4-1.1-0.7-1.7-1.1c-0.3-0.2-0.8-0.1-1.2-0.8c-0.2-0.5-0.2-0.9-0.3-1.1c-0.3-0.6-1-0.8-1.3-1.9
			c-0.1-0.6,0-0.5-0.3-1c-0.4-0.5-0.3-0.7-0.4-1.1c-0.1-0.4-0.2-0.4-0.5-0.5s-0.8-0.2-1.1-0.7c-0.2-0.3-0.2-0.7-0.2-1
			c0-0.4,0.1-0.9,0.1-1.2c0-0.2,0-0.3,0-0.4c-0.1-0.3-0.2-0.6-0.2-1.1c0-0.1,0-0.2,0-0.2c0.1-0.4,0.2-0.7,0.2-0.9l0,0
			c-0.3-0.6-0.7-0.9-1.2-1.3s-1-1-1.5-1.3c0,0-0.1,0-0.2,0c-0.2,0-0.7,0-1-0.3c-0.4-0.6-0.3-1-0.4-1.1c-0.1-0.1-0.1-0.1-0.3-0.2
			c-0.3-0.1-0.4-0.5-0.3-0.8l0,0c0-0.2,0.1-0.4,0.1-0.6c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.6,0.1-0.8,0.2c-0.5,0.2-0.6,0.4-0.8,1
			c-0.2,0.4-0.4,1.5-1.2,1.9c-0.1,0.1-0.3,0.1-0.4,0.1c-0.5,0-0.9-0.3-1.1-0.4c-0.1,0.4-0.5,0.7-0.9,0.7c-0.5,0-1.1-0.4-1.3-1
			c-0.7-0.1-1.3-0.3-1.9-0.3c-0.1,0-0.1,0-0.2,0c-0.2,0-0.7,0-1,0.1c-0.1,0-0.1,0-0.1,0l0,0c0,0.1,0.2,0.4,0.2,0.9l0,0v0.5l-0.5-0.2
			c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-0.7,0.1-1.1,0.1c-0.2,0-0.4,0-0.6-0.1c-0.9-0.4-0.8-1.4-1.1-1.5c-0.1-0.1-0.2-0.1-0.3-0.1
			s-0.4,0.1-0.9,0.2H582l0,0l0,0c-0.7-0.1-1.1-0.5-1.4-0.5l0,0l0,0c-0.1,0-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.7,0.1
			c-0.3,0-0.5,0.2-0.9,0.2c-0.3,0-0.7-0.2-0.8-0.6c-0.3-0.7-0.2-1.7-0.4-1.9c-0.1-0.1-0.2-0.1-0.5-0.1s-0.7-0.2-0.9-0.7
			c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.1,0-0.2c0-0.3,0-0.4-0.1-0.5c-0.1,0-0.2-0.1-0.4-0.1s-0.6,0.1-0.9,0.1c0,0-0.1,0-0.2,0h-0.3
			l-0.1-0.2c-0.3-1-0.6-0.9-0.6-0.9h-0.1l-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.1s-0.1,0.2-0.6,0.5c-0.3,0.1-0.5,0.3-0.8,0.3
			c-0.2,0-0.3,0-0.5-0.1c-0.6-0.1-1.2-0.5-1.6-0.5c-0.2,0-0.2,0-0.4,0.2c-0.1,0,0.1,0.6-0.3,1.2c-0.2,0.2-0.5,0.2-0.6,0.2
			s-0.2,0-0.3,0l0,0l0,0c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.4,0.8l0,0l0,0c0,0.4-0.2,0.6-0.2,0.7c0,0,0.2,0,0.5,0.1
			c0.3,0,0.9,0.2,1,0.8c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.4,0.9-0.8,1.1c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.6-0.4,0.7
			c-0.2,0.1-0.4,0.1-0.7,0.1c-1.2,0.2-1.7,0.8-2.3,1.8c-0.2,0.4-0.4,0.6-0.8,0.7c-0.3,0-0.5-0.2-0.6-0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.1,0-0.1,0-0.2,0c-0.4,0-0.6-0.3-0.6-0.5c-0.1-0.2-0.1-0.2-0.1-0.2c-0.1-0.1-0.5-0.2-0.9-0.2s-0.8,0.1-1.1,0.2
			s-0.4,0.3-0.4,0.3s0,0.1,0.1,0.2c0.3,0.3,0.4,0.7,0.4,1c0,0.2,0,0.4,0,0.7c0,0,0,0,0,0.1c0,0.2,0.1,0.6,0.1,1
			c0,0.3-0.1,0.7-0.4,0.9c-0.5,0.3-0.8,0.2-0.8,0.3c-0.1,0-0.2,0.5-0.4,0.7c-0.1,0.1,0,0.5-0.4,0.9c-0.2,0.2-0.5,0.2-0.6,0.2
			s-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.2,0,0.4,0,0.7c0,0.1,0,0.3,0,0.4c-0.1,0.6-0.5,0.9-0.9,0.9
			c-0.3,0-0.5,0-0.7,0.1c-0.2,0.2-0.2,0.4-0.3,0.8v0.1c0,0.3,0,0.6-0.1,0.9l-0.1,0.3h-0.3c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0
			s0,0,0,0.1s0,0.3,0.1,0.5c0,0.3,0.1,0.7,0.1,1.1s0,0.8-0.2,1.2l-0.1,0.2h-0.3c0,0,0,0-0.1,0c-0.3,0-0.4,0.1-0.6,0.4
			c-0.2,0.3-0.4,0.7-1,0.8h-0.2l-0.1-0.2c-0.3-0.5-0.4-0.4-0.4-0.4c-0.1,0-0.3,0.1-0.8,0.1H551c-0.8,0-1.3-0.6-1.7-0.9
			c-0.7-0.6-0.5-1-0.6-1.6c-0.1-0.5-0.2-0.6-0.5-0.7c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.3,0.3-0.6,0.3-0.9,0.3
			c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.3,0.1,0.6c0,0,0,0.1,0,0.2c-0.2,0.5-0.6,0.5-0.7,0.5l0,0c-0.1,0.1-0.1,0.4-0.1,0.8
			c0,0.3,0,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.7c0,0.4-0.3,0.8-0.8,0.9c-0.1,0-0.2,0.1-0.3,0.1h-0.1c0,0,0,0,0,0.1
			c-0.1,0,0,0.4-0.1,0.8l-0.1,0.2l-0.2,0.1c-0.3,0.1-0.5,0.2-0.7,0.2s-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.3-0.1-0.5
			c0-0.3,0-0.6-0.1-0.6c-0.1-0.1-0.1-0.1-0.1-0.1s-0.3,0.2-0.7,0.2h-0.1c-0.3,0-0.6-0.2-0.6-0.4c-0.1-0.2-0.1-0.3-0.2-0.4
			c-0.1-0.2-0.1-0.4-0.2-0.5l0,0h-0.1c-0.1-0.1-0.6,0.5-0.8,0.8c-0.3,0.4-0.4,0.9-1.1,1.2c-0.1,0.1-0.3,0.1-0.4,0.1
			c-0.1,0-0.1,0-0.1,0s0,0.1-0.1,0.1c-0.2,0.4-0.1,0.6-0.2,0.9c-0.1,0.4-0.5,0.6-1.1,0.6c-0.1,0-0.2,0-0.3,0c-0.9,0-1.1-0.5-1.7-0.8
			c-0.4-0.3-0.9-0.7-1.2-0.8c-0.2-0.1-0.2-0.1-0.3-0.1s-0.3,0.1-0.6,0.1l0,0c-0.6,0-1.6,0-2.3-0.6c-0.2-0.3-0.3-0.6-0.3-0.7
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0.3,0.3,0.5,0.4,1.1c0,0,0,0,0,0.1c-0.1,0.5-0.3,0.7-0.3,0.8v0.1l0.2,0.4l-0.5,0.1
			c-0.1,0-0.3,0-0.4,0c-0.6,0-0.9-0.5-0.9-0.8c-0.1-0.3-0.2-0.5-0.3-0.6c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.5,0.3
			c-0.2,0.2-0.5,0.5-1,0.5c0,0,0,0-0.1,0c-0.7-0.1-1.2-0.6-1.4-0.6c0,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.3c0,0.4,0.3,0.9,0.4,1.3
			l0.2,0.5h-0.5c-0.1,0-0.2,0-0.4,0c-0.1,0-0.3,0-0.4,0c0,0.3-0.2,0.5-0.4,0.6s-0.3,0.2-0.5,0.4c-0.3,0.4-0.7,1.1-0.9,1.6
			c0,0.1,0,0.2,0,0.2c-0.1,0.5,0.7,1.1,0.8,2c0,0.3-0.2,0.7-0.5,0.9c-0.6,0.5-1.4,0.6-1.9,0.9s-1,1.2-1.9,1.5c-0.1,0-0.1,0-0.2,0
			c-0.4,0-0.8-0.2-0.9-0.2c-0.1,0-0.1,0-0.2,0.2v0.1c0,0.5,0.3,1.4,0.4,1.9c0.1,0.6,1.1,1.8,1.1,3c0,0.3-0.1,0.7-0.4,1
			c-0.3,0.3-0.6,0.5-1,0.5c-0.5,0-0.9-0.2-1.2-0.5c-0.8-0.7-0.7-0.8-1.6-0.8l0,0l0,0l0,0l0,0c-1.2,0-1.3-0.5-2.1-0.9
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.9,0.2-1.3,0.3c-1.4,0.5-1.2,2.1-2.8,3.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.6,0.6,0.6,1.1
			c0,0.2,0,0.4-0.1,0.6c-0.3,0.5-0.6,0.9-0.6,1l0,0c0,0,0.1,0.1,0.3,0.2l0.1,0.1l0.1,0.2c0.1,0.2,0.1,0.4,0.1,0.6
			c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.2,0.2-0.4,0.2c0.1,0.3,0.6,0.7,0.6,1.4c0,0.2,0,0.4-0.1,0.6c-0.5,1-1,0.9-1.1,1.7l-0.1,0.8
			l17.4-1.4c0-0.2,0.1-0.5,0.1-0.9c0-0.7-0.2-1.5-0.5-1.8l-0.8-0.7l4.6,0.1l0.1,0.3v0.1c2.4-0.2,17.2-1.8,20.1-1.8
			c3-0.1,16.9-1.5,16.9-1.5s10.8-0.8,22.7-2c0.3,0,0.7-0.1,1-0.1C590.4,328.8,590.7,328.1,591.4,327.7z"/>
									<path fill="#DDDDDC" d="M703.2,331.4c0.5,0,0.3,0.2,0.7,0.3c0.3,0.1,0.5,0,0.7-0.3c0.1,0.2,0.3,0.2,0.5,0.2c0-0.2,0-0.5,0-0.7v0.1
			c0.2-0.1,0.4-0.1,0.6,0c0-0.3,0.1-0.5,0.3-0.6c0.1-0.2,0-0.2-0.1-0.2V330c0-0.4-0.4-0.4-0.7-0.5c-0.5-0.1-0.5-0.3-0.9,0.2
			c-0.2,0.3-0.2,0.5-0.6,0.7c-0.3,0.1-0.7,0-0.9,0.1s-0.6,0.3-0.4,0.7C702.6,331.7,702.9,331.4,703.2,331.4z"/>
									<path fill="#DDDDDC" d="M699.9,328.8c-0.4,0.1-0.3,0.7,0.1,0.6C700.4,329.3,700.3,328.8,699.9,328.8z" />
									<path fill="#DDDDDC" d="M699.4,327.2c0.3,0,0.5,0.4,0.8,0c0.2-0.3,0.2-0.8-0.2-0.9l-0.2-0.2c-0.5-0.3-0.7,0-0.8,0.4
			c-0.4,0.1-0.6-0.1-0.9-0.2C697.6,327.3,699,327.2,699.4,327.2z"/>
									<path fill="#DDDDDC" d="M506.8,456.8c-0.5-0.1-0.7,0.3-1.1,0.3c-0.7,0.1-1-0.9-1.8-0.8c-0.2-0.9-0.2-1.4-1.2-1.5
			c-0.3,0-0.6,0.1-0.9,0s-0.6-0.4-1-0.4c-1,0-0.8,1.1-1.3,1.6c-0.2,0.2-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.4,0-0.5,0.1c-0.2,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.5,0.2-0.9,0.4c0.1-0.5,0.7-1.8,0-2.1c-1.1-0.4-2.5,2.7-0.9,2.5
			c0.1,0.5-0.6,1.2,0,1.4c0.7,0.2,0.7-0.9,1.3-0.9c0.9-0.1,0.3,0.8,0.6,1.2c0.2,0.2,0.6,0,0.8,0.1c0.3,0.1,0.4,0.3,0.6,0.4
			c0.3-0.2,0.5-0.8,0.8-0.6c0.4,0.2,0.1,0.7,0.6,0.9c0.4,0.1,0.9,0,1.3,0.1c0.7,0.3,1.8,0.5,2.6,0.1c0.6-0.3,1.1-1,1.5-1.5
			c0.5-0.7,1-1.4,1.6-2C507,457.1,507.3,456.9,506.8,456.8z"/>
									<path fill="#DDDDDC" d="M701.9,329.3c0.3-0.2,0.2-0.9-0.2-0.9l-0.1-0.1c-0.4-0.1-0.5,0.4-0.2,0.6
			C701.2,329.1,701.6,329.5,701.9,329.3z"/>
									<path fill="#DDDDDC" d="M550.4,170.9c0.1,0.3,0.2,0.7,0.3,1C551.3,171.6,550.8,171.2,550.4,170.9z" />
									<path fill="#DDDDDC" d="M519.8,223.5c0.2-0.9,0-1.7,0.1-2.5l0.1-0.2c0.2-0.1,0.1-0.4,0.1-0.7c-1-0.3-1.2-2.6-1.5-3.4
			c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.2-0.4-0.3-0.7c-0.2-0.6,0.1-1.2,0-1.9c-0.1-0.6-0.4-0.9-0.3-1.5c0.1-0.6,0.1-1.2,0.2-1.8
			c0.1-1.2,0.2-2.3,0.5-3.4c0.3-1.1,0.9-2.2,1-3.4c0-1.3-0.7-2.4-0.5-3.6c0.1-0.6,0.2-1.2,0.3-1.8c0.2-0.6,0.5-1.1,0.7-1.7
			c0.2-0.6-0.1-1.1,0.4-1.7c0.3-0.3,0.9-0.5,1.1-0.9c0.3-0.5-0.2-1.1-0.4-1.6c-0.2-0.6-0.1-1.2-0.1-1.8s0.1-1,0-1.6
			c-0.3-1.1,0.6-1.8,1-2.7c0.5-1.1,0.7-2.2,1.1-3.3c0.4-1,1-1.9,1.3-3.1c0.3-1.2,0.6-2.3,1.2-3.4c0.4-0.8,1.3-2.6,0.9-3.5
			c-0.4-0.8-1.4,1.1-1.7,1.5c-0.7,1-1.4,1.8-1.7,3c-0.3,1-0.3,2.4-1.3,3c-1.1,0.6-2.4,0.7-3.1,1.8c-0.6,1-0.5,2.1-1.5,2.9
			c-1,0.9-1.9,1.3-2.4,2.6c-0.1,0-0.2,0-0.3,0c0-0.4,0.3-1.4-0.1-1.5c-0.1-0.5,0.1-1.1,0.2-1.6c0.1-0.6,0.3-1.4,0.5-2
			c0.2-0.5,0.7-0.8,1-1.3c0.4-0.6,0.3-1.1,0.2-1.8c0.5-0.1,2.1-0.6,2.3-1c0,0-0.1-0.4-0.1-0.5c0-0.2,0.3-0.4,0.4-0.6
			c0-0.1,0-0.1,0-0.2c-0.4-0.3-1.1-0.8-1.1-1c-0.4,0-0.7-0.3-0.8-0.6c0-0.3,0-0.5-0.1-0.7c0-0.3-0.2-0.6-0.2-1.1
			c0-0.1,0-0.3,0.1-0.4c0.2-0.7,0.7-1.3,0.7-1.7c0-0.1,0-0.2-0.1-0.3l0,0c0,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.5,0.5-1,0.7
			c-0.3,0.1-0.3,0.2-0.7,0.3c-0.5-0.1-0.4-0.4-0.6-0.6c-0.2-0.3-0.3-0.9-0.3-1.4v-0.1c0.1-0.6,0.4-0.9,0.4-1l0,0c0,0,0,0-0.2-0.1
			s-0.5-0.3-0.5-0.7c0-0.1,0-0.1,0-0.2v-0.2l0.2-0.1c0.2,0,0.5-0.2,0.6-0.4c0,0-0.1,0-0.2-0.1l-0.3-0.1l0.1-0.3
			c0.1-0.4,0.3-0.6,0.4-0.8c-0.4-0.2-0.6-0.4-0.9-0.7c-0.5-0.4-0.1-0.3-1-0.4h-0.3v-0.3c-0.1-0.5-0.2-0.7-0.4-0.8l0,0
			c0,0-0.1,0-0.2,0.1c-0.1,0-0.3,0.1-0.5,0.1c0,0,0,0-0.1,0c-0.3,0-0.7-0.3-0.9-0.3c-0.4-0.2-0.8-0.5-0.8-0.9c0-0.2,0-0.4,0.1-0.6
			c0.1-0.3,0.1-0.5,0.1-0.6c0,0,0-0.1-0.2-0.2c-0.2-0.2-1-0.2-1.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.1l0,0c-0.1,0-0.3,0-0.5,0
			c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.5-0.3-0.5-0.3l0,0l0,0c0,0-0.2,0.1-0.5,0.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.4-0.5-0.5-0.6
			s0-0.1-0.2-0.1c0,0,0,0-0.1,0c0,0-0.3,0.1-0.5,0.3s-0.4,0.5-0.9,0.5c-0.3,0-0.5-0.2-0.6-0.5c-0.1,0.1-0.3,0.1-0.5,0.1
			c-0.1,0-0.2,0-0.4,0c-0.5-0.1-0.7-0.3-0.8-0.3h-0.1l0,0l0,0l0,0l-0.1-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.5-0.2-1.1-0.3-1.5-0.6
			c-0.2-0.2-0.4-0.3-0.6-0.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.5-0.1-1-0.2-1.5-0.3c-1.2-0.3-2.3-0.5-3.5-0.8c-1.4-0.3-2.7-0.6-4.1-0.9
			c-1.1-0.2-2.3-0.5-3.4-0.7c-0.5-0.1-1-0.2-1.4-0.3l-0.1-0.2c-0.2-0.6-0.4-1.2-0.6-1.8c-0.4-0.8-0.3-0.4-1.2-1
			c-0.5-0.4-0.3-0.5-0.5-0.5c0,0-0.1,0-0.2,0c-0.2,0-0.7-0.1-1-0.3l0,0c-0.6,0.1-1.7-0.4-2.3-0.6c-0.4-0.1-0.8-0.1-1.1-0.4
			c-0.2-0.2-0.3-0.7-0.6-0.8c-0.6-0.2-0.4,0.2-0.7,0.5c-0.5,0.4-1.2,0.5-1.7,0.8c-0.4-0.8,0-1.1,0.3-1.8c0.4-0.9-0.3-0.7-0.3-1.4
			c0-0.4,0.4-0.5,0.5-0.9c0.1-0.3,0.1-0.5,0.1-0.8c0.1-0.6,0.7-1,0.3-1.6c-0.4,0.1-0.7,0.3-1.1,0.4c-1.5,0.6-3,1.1-4.5,1.6
			c-1.6,0.6-3.1,1.5-4.7,2c-1.3,0.5-3.7,1.2-5.1,0.4c-0.5-0.3-0.6-0.7-0.4-1.2c-0.3,0.3-0.6,0.7-0.8,0.9c-0.1,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.2-0.1,0.6-0.6,0.6c-0.1,0-0.2,0-0.3-0.1v0.1c0,0.2,0.1,0.5,0.1,0.7l0.1,0.4H457c-0.2,0-0.5,0-0.7-0.1v0.1
			c0,0.2,0.1,0.5,0.1,0.8c0,0.2,0,0.4,0,0.6c0,0.5-0.1,1-0.1,1.4c-0.1,0.9-0.2,1.8-0.2,2.8l0,0l0,0c0,0.1,0,0.2,0,0.3
			c0,0.7,0,1.5,0,2.2c0,0.6,0.2,1.4,0.2,2.1c0,0.2,0,0.5-0.1,0.7l-0.1,0.5l-0.4-0.2c-0.1,0-0.3-0.1-0.3-0.1l0,0l0,0
			c0,0,0,0.2-0.1,0.4l0,0c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.2h-0.1c-0.1,0.1-0.3,0.2-0.4,0.2l0,0c0,0.1-0.1,0.4-0.4,0.6
			c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6-0.1-0.6-0.1l0,0c-0.3,0.1-0.3,0.1-0.4,0.3s-0.1,0.5-0.2,0.9l-0.1,0.2h-0.2c0,0,0,0-0.1,0
			c-0.3,0-0.5-0.1-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.7-0.1,1.2c-0.1,1.3-1,1.7-1.5,2.4
			c-0.1,0.2-0.2,0.6-0.2,1c0,0.5,0.2,0.9,0.4,1.1l0,0c0,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0.1c0.3,0.1,0.9,0.2,1,0.8
			c0,0.1,0,0.2-0.1,0.3c0.5,0.2,1.3,0.5,1.3,1.4c0,0,0,0,0,0.1c-0.1,1.2-1.2,1.5-1.2,1.9v0.1c0,0.1,0.1,0.3,0.1,0.6
			c0,0.1,0,0.2-0.1,0.4c-0.2,0.5-0.5,0.7-0.5,0.9l0,0c0,0.2,0.4,0.5,0.5,1.1c0,0.1,0,0.2,0,0.3c0,0.5-0.2,0.8-0.3,1.1
			c0,0.1,0,0.1,0,0.2c0,0.5,0.4,1.1,0.5,2c0,0.4,0,0.8,0,1.3c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.8-0.1,1.1s0.1,0.5,0.2,0.7
			c0.1,0.1,0.5,0.1,0.9,0.4c0.4,0.3,0.6,0.7,0.9,1c0.5,0.5,1.1,1.3,1.6,1.6c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.7-0.1,1.1-0.1
			c0.3,0,0.6,0.1,0.8,0.3c0.5,0.5,0.3,1.1,0.4,1.1c0.1,0.1,0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0
			c1.5,0.3,1.4,1.6,2.2,1.7c0.9,0.2,1.4,0.2,2.1,1.1c0.5,0.7,0.6,1.5,1,1.9c1,1.2,2.7,1.5,4,2.7c0.7,0.7,0.7,1.2,1.5,1.3
			c1.1,0.2,1.3,0.8,1.9,1.4c0.2,0.2,0.6,0.4,0.6,1.1l0,0c0,0.6-0.4,1-0.3,1.2c0,0,0,0,0,0.1c0.1,0.3,0.2,0.3,0.3,0.5
			c0.1,0.1,0.3,0.4,0.3,0.8c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.1,0.5-0.1,0.7l0.1,0.1l0.1,0.3c0.3,0.2,0.4,0.5,0.4,0.7
			c0,0.4-0.2,0.7-0.2,0.8v0.1c0,0.1,0,0.1,0,0.2c0.1,0,0.3,0.1,0.5,0.1l0.2,0.1v0.2v0.1c0,0.7-0.5,1.2-0.5,1.3l0,0
			c0,0.2,0.5,0.2,1,0.7c0.4,0.6,0.4,1,0.7,1.2c0.3,0.3,0.9,0.5,0.9,1.3c0,0.1,0,0.2,0,0.3c-0.1,0.7-0.5,1.1-0.9,1.3
			c-0.3,0.3-0.5,0.4-0.5,0.7c0,0.1,0,0.1,0,0.3c0.1,0.4,0.5,0.7,0.6,1.4c0,0.2,0,0.3,0,0.5s0,0.4,0,0.6c0,0.2,0,0.3,0.1,0.5
			c0.2,0.8,0.9,1.7,1.1,2.7c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.5-0.1,0.6c0,0.2,0,0.1,0.1,0.2h0.1c0.1,0,0.4-0.2,0.8-0.2
			c0.2,0,0.3,0,0.5,0.1c0.7,0.4,0.9,0.8,1.4,0.9c0.6,0.1,0.9,0.1,1.4,0.5l39.8-1.2C519.8,223.7,519.8,223.6,519.8,223.5z"/>
									<path fill="#DDDDDC" d="M529.5,293.3c-0.3-0.4-0.5-0.4-0.6-0.9c0-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.3,0.1-0.4
			c0-0.6-0.6-1.1-0.8-2.1c0-0.2,0-0.4,0-0.5l0,0v-0.1c0,0-0.1-0.1-0.3-0.2c-0.2-0.2-0.7-0.1-0.8-0.7c0-0.3,0.1-0.5,0.3-0.7
			c0.3-0.4,0.7-0.5,0.7-0.5l0,0c0,0,0-0.1,0-0.2c0.1-0.1-0.7-0.3-0.8-1.1c0,0,0,0,0-0.1c0.1-0.7,0.9-1,0.9-1.2
			c0.2-0.3,0.4-0.7,0.7-1.1c0,0-3.7-39.7-3.8-43.5c-0.2-0.4-0.4-0.7-0.7-1.1c-0.4-0.8-0.5-1.7-0.9-2.5c-0.3-0.5-0.6-1-0.8-1.6
			c-0.1-0.4-0.1-0.9-0.2-1.3c-0.9,0.1-1.6-1-2-1.7c-0.8-1.7-0.4-3.4-0.4-5.2c0-0.3,0-0.6,0-1l-38.7,1c0.2,0.2,0.3,0.5,0.3,0.7
			c0,0.5-0.2,0.8-0.2,1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.3,0.1c0.2,0.1,0.6,0.1,0.9,0.5c0.6,0.8,0.2,0.9,1,1.2c1.2,0.5,1.5,1.4,1.5,2.3
			c0,0.2,0,0.5,0,0.8c1.5,0.4,3.3,1.2,3.6,3.1c0.1,0.6,0,0.6,0.2,1.1c0.1,0.3,0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.2,0.7
			c-0.5,0.8-0.8,1-0.7,1.1c0,0,0,0.2,0.2,0.5s0.2,0.6,0.2,0.8c0,0.9-0.8,1.3-1.6,1.3c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.8
			c0,0.1,0,0.2,0,0.3v0.4H487h-0.1c-0.5,0-0.4,0.1-0.6,0.3c-0.1,0.3-0.2,0.8-0.5,1.2c-0.2,0.3-0.6,0.7-1,0.9
			c-0.2,0.1-0.3,0.1-0.5,0.2c-0.5-0.1-0.4-0.2-0.6-0.2h-0.1c-0.3,0-0.2,0.1-0.4,0.4c-0.1,0.3-0.5,0.9-1.2,0.9l0,0
			c-0.6,0-1-0.3-1.3-0.3c0,0,0,0-0.1,0s-0.1,0-0.1,0s-0.1,0.2-0.2,0.4c-0.4,0.5-0.8,0.7-1.5,0.6l0,0c-0.5,0-0.9-0.3-1.1-0.2
			c-0.1,0-0.1,0-0.1,0l0,0c0,0-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.5-0.1,1.1-0.4,1.5c-0.2,0.3-0.3,0.6-0.3,0.7
			c0,0.2,0.1,0.4,0.4,0.7c0.3,0.4,0.9,0.6,1.4,1.3s0.5,1.4,0.6,2.1c0.1,0.4,0.2,0.6,0.2,0.9c0,0.4-0.2,0.8-0.6,1.2
			c-0.6,0.5-1.2,0.8-1.3,1.3v0.1c0,0.4,0.4,0.7,0.5,1.5c0,0.2,0,0.4-0.1,0.6c-0.2,0.5-0.6,1.7-1.1,2.2c-0.3,0.2-0.6,0.3-0.8,0.3
			c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0.1c-0.5,0.2-1.2,0.8-1.2,1.2c0,0,0,0,0,0.1c-0.1,0.1,0.4,0.3,0.6,1v0.1
			c-0.1,0.6-0.4,0.8-0.4,0.9l0,0c0,0.1,0.2,0.3,0.2,0.7c0,0,0,0,0,0.1c0,0.3-0.3,0.6-0.5,0.7s-0.3,0.1-0.4,0.2
			c-0.3,0.2-0.3,0.3-0.3,0.4s0.2,0.5,0.2,1.2l0,0c0,0.9-0.5,1.5-0.5,2.1c0,0.1,0,0.1,0,0.2c0.1,0.7,0.3,1.5,0.5,2.2
			c0,0.4,0.9,0.9,0.9,1.8c0,0.1,0,0.1,0,0.1c-0.2,0.5-0.5,0.7-0.6,0.8l0,0c-0.1,0.2,0.4,0.3,0.9,0.7c0.7,0.7,0.5,1.7,0.7,2.3
			c0.2,0.8,0.7,0.7,1.5,1.5c0.6,0.7,0.7,1,1,1c0.1,0,0.3,0,0.5-0.1l0.3-0.1l0.1,0.3c0.2,0.5,0.1,1,0.3,1.2c0.1,0.1,0.1,0.2,0.2,0.2
			c0,0,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3,0.1c0.7,0.2,1,0.8,1.3,0.9c0.2,0.2,0.8,0.1,1.2,0.5c0.4,0.6,0.3,1,0.4,1
			c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.5,0.1,0.8,0.4c0.7,0.8,0.8,2.1,0.8,3.2c0,0.1,0,0.2,0,0.3c0.2,0.1,0.3,0.1,0.4,0.3
			c0.1,0.2,0.2,0.4,0.2,0.6s0,0.4-0.1,0.6c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0.1,0.5,0.1,0.7c0.2,0.6,0,0.4,0.4,0.9
			c0.1,0.2,0.6,0.7,0.9,0.9h0.1v-0.1c0.1-0.3,0.2-0.4,0.3-0.4c0,0,0-0.1,0.1-0.2l0.1-0.1l0,0c0-0.1,0-0.2,0.1-0.3
			c0.2-0.3,0.4-0.4,0.5-0.5c0.3-0.2,0.6-0.1,0.7-0.2c0.2,0,0.3,0,0.5,0c0.6,0,1.2,0.2,1.7,0.5s0.6,0.6,0.9,0.6c0,0,0.1,0,0.3,0
			c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0.3s0.4,0.6,0.4,1c0,0.9-0.7,1.6-0.9,2.1c-0.1,0.2-0.1,0.2-0.1,0.4c0,0.1,0,0.2,0,0.4
			c0,0.1,0,0.2,0,0.4c-0.1,0.6-0.3,0.7-0.3,0.9v0.1c0.1,0.4,0.2,0.2,0.3,0.7c0,0.3-0.2,0.4-0.3,0.7c-0.4,0.4-0.9,0.8-0.9,1v0.1
			c0,0.2,0.2,0.5,0.3,1v0.1c-0.1,0.7-0.8,1-0.9,1.3c-0.3,0.5-0.5,0.9-0.5,1.3c0,0.3,0.1,0.5,0.3,0.8c0.2,0.2,0.6,0.4,0.9,0.8
			c0.5,0.5,0.5,1,0.7,1.4c0.2,0.5,1.1,1.2,1.6,1.4c0.2,0.1,0.7,0,1.3,0.3c0.3,0.2,0.5,0.5,0.8,0.6c0.6,0.4,0.7,0.7,1,1.3
			c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0,0.3,0,0.5-0.1c0.2,0,0.3,0,0.4,0c0.4,0,0.7,0.3,0.9,0.6c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0.1,0,0.2,0
			s0.2,0,0.2,0c0.8,0.1,1.8,0.8,2.2,1.6c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0,0.4c0,0,0,0,0.1,0l0,0c0.1,0,0.3-0.1,0.7-0.1
			c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.6c0,0.4-0.2,0.7-0.1,0.8l0,0c-0.1,0.1,0.5,0.1,0.8,0.8c0,0.1,0,0.2,0,0.3
			c-0.1,0.7-0.6,0.9-0.5,1v0.1v0.1c0.1,0,0.3,0.1,0.5,0.2c0.6,0.3,0.9,0.9,0.9,1.5c0,0.4-0.1,0.7-0.3,1.1c-0.3,0.5-1,1.1-0.9,1.2
			c0,0,0,0,0.2,0.2c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0.2,0,0.6,0.1,0.8,0.5c0.1,0.2,0.1,0.3,0.1,0.4c0,0.4-0.2,0.7-0.2,0.8l0,0
			c0,0,0,0.1,0.2,0.1s0.6,0,0.8,0.3c0.2,0.2,0.2,0.4,0.2,0.6s0,0.5,0,0.6s0,0.1,0,0.1c0.1,0.2,0.2,0.2,0.3,0.3l0,0
			c0.1-0.1,0.2-0.1,0.3-0.1c0.6,0,1.2,0.4,1.6,0.6l0,0l0.1-0.1c1.4-0.6,1.1-2.4,3-3.2c0.5-0.2,1-0.4,1.6-0.4c0.3,0,0.6,0.1,0.9,0.2
			c1,0.6,0.8,0.8,1.7,0.8c0,0,0,0,0.1,0c0,0,0,0,0.1,0c1,0,1.4,0.4,2,1c0.2,0.2,0.5,0.3,0.7,0.3s0.3,0,0.5-0.2
			c0.1-0.2,0.2-0.3,0.2-0.5c0-0.7-0.9-1.9-1.1-2.8c-0.1-0.4-0.4-1.3-0.4-2c0-0.1,0-0.3,0.1-0.4c0.1-0.4,0.5-0.7,0.9-0.7
			s0.7,0.2,0.9,0.2c0,0,0,0,0.1,0c0.4,0,1-0.9,1.7-1.4c0.7-0.4,1.4-0.5,1.8-0.8c0.2-0.2,0.2-0.3,0.2-0.4c0.1-0.3-0.7-1-0.8-2
			c0-0.2,0-0.4,0.1-0.5c0.2-0.5,0.6-1.3,1-1.7c0.2-0.3,0.5-0.4,0.6-0.5s0.1,0,0.1-0.1c0,0,0-0.1,0-0.2c-0.1-0.5-0.6-1-0.6-1.8
			c0,0,0,0,0-0.1c0-0.6,0.2-1.1,0.2-1.6l0,0l0,0c0-0.4,0-0.7,0-1c0-0.4,0.1-0.9,0.4-1.4c0.2-0.4,0.5-0.8,0.7-1.1
			c-0.4-0.3-0.6-0.8-0.6-1.2c0-0.1,0-0.1,0-0.1c0-0.5,0.3-0.9,0.7-0.9c0.2,0,0.4,0.1,0.5,0.1V304c0.1-1.3,1.2-1.8,1.1-2.5
			c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.1-0.4-0.1-0.7c0-0.2,0.2-0.5,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0.2-0.1,0.3-0.2l0,0l-0.1-0.3
			c0-0.1,0-0.2,0-0.3c0-0.5,0.4-0.8,0.6-0.9c0.3-0.1,0.4-0.2,0.4-0.3l0,0c0,0,0,0-0.1-0.1s-0.3-0.3-0.3-0.7c0-0.1,0-0.2,0.1-0.3
			c0.4-0.8,1.4-0.7,1.4-0.8c0.2-0.1,0.2-0.1,0.2-0.2c0-0.2-0.2-0.5-0.2-1.1c0-0.1,0-0.2,0-0.3c0.1-0.3,0.2-0.2,0.2-0.3
			c0.1-0.1,0.1-0.1,0.2-0.2l0,0c0-0.1,0-0.1,0-0.1C530,293.9,529.8,293.6,529.5,293.3z"/>
									<path fill="#DDDDDC" d="M693.5,249.2c2.1-1.2,4.8-0.3,4.6,0.1l-0.1,0.3c0.4-0.6,0.8-1,1.6-1.2c1-0.3,1.7-0.8,2.4-1.4
			c0.2-0.2,0.3-0.4,0.4-0.6c0,0,0-0.1,0.1-0.1c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.2-0.6
			c0.2-0.5,0.6-1.1,0.7-1.7c0.1-0.9,0.6-1.2,1.4-1.4c0.2-0.1,0.4-0.2,0.5-0.2c0.1-0.1,0.1-0.1,0.1-0.1s0-0.1-0.2-0.2
			c0,0-0.3,0-0.7-0.3c-0.3-0.4-0.4-0.6-0.4-0.6c-0.1-0.1-0.4,0-0.9-0.2c-0.3-0.2-0.5-0.5-0.6-0.6s-0.4-0.2-0.7-0.6
			c-0.3-0.5-0.2-1-0.2-1c-0.1-0.1-0.8-0.2-1.4-0.5c-0.8-0.4-0.9-0.9-1.2-1.5c-0.1-0.1-0.1-0.2-0.1-0.2s-0.1,0-0.2,0.1
			c-0.1,0.1-0.3,0.2-0.5,0.3l-0.4,0.2l-0.1-0.5c-0.1-0.5-0.1-0.9-0.2-1.2c-0.1-0.3-0.5-0.6-0.6-1.3v-0.1c0-0.4,0.3-0.9,0.4-1.1
			c0.3-0.6,0.2-0.3,0.2-0.7v-0.1v-0.1c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.1,0-0.1c0.1-0.5,0.3-0.6,0.3-0.6c0-0.2,0.1-0.3,0.1-0.4
			c0-0.1,0-0.3-0.1-0.5c-0.3-0.5-0.7-1.4-1.1-1.9l-0.3-0.4l0.5-0.2c0.3-0.1,0.6-0.3,1-0.5c0-0.2,0-0.3,0-0.5s0-0.4,0.1-0.6
			c0.1-0.3,0.3-0.5,0.5-0.5c0.2-0.1,0.2-0.1,0.3-0.2c0.5-0.5,0.6-0.9,0.6-1.7c0-1,0.5-1.5,0.5-2.1v-0.1c0-0.2,0-0.2-0.1-0.4
			c0-0.2,0.1-0.4,0.3-0.7c0.2-0.2,0.4-0.4,0.7-0.6c-0.3-0.2-0.7-0.3-1.1-0.4c-0.1,0-0.2,0-0.3,0s-0.1,0-0.2,0
			c-0.7,0-1.5-0.4-1.8-1.2c-0.1,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0h-0.3v-0.3c0-0.8-0.7-1.2-0.9-2.2c0-0.1,0-0.1,0-0.1
			c0-0.2,0-0.4,0-0.5s0-0.2-0.2-0.4c-0.2-0.3-0.6-0.5-0.8-1c-0.1,0-0.2,0-0.3,0l-0.4,0.1V212c0-0.1,0-0.1,0-0.1c0-0.2,0-0.5-0.1-0.7
			s-0.2-0.3-0.3-0.3c0,0-0.1,0.1-0.3,0.3s-0.4,0.5-0.8,0.5c-0.1,0-0.2,0-0.3-0.1c-0.5-0.2-0.4-0.6-0.4-0.7c-0.1,0,0,0-0.2,0
			c-0.1,0-0.2,0-0.4-0.1c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.2,0-0.4,0.1-0.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0L631,220.9l-0.1-0.4
			c0,0-0.6-3.2-0.9-4.8c-0.6,0.5-1.2,1.1-1.8,1.6c-0.7,0.5-1.3,1.2-1.9,1.7c-0.9,0.6-1.7,1.2-2.6,1.9l-0.1,0.1
			c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.3-0.6,0.4-1,0.6c-0.3,0.1-0.5,0.4-0.7,0.6l6.7,41.4c4.5-0.9,50-9.5,62.9-11.9
			C691.5,250.8,692.4,249.8,693.5,249.2z"/>
									<path fill="#DDDDDC" d="M708.5,270.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.3-0.3-0.6,0.1-0.9-0.1c-0.3-0.2-0.3-0.5-0.2-0.8
			c0-0.1,0.2-0.4,0.3-0.4c0-0.1,0-0.3,0.1-0.4c0.1-0.6,0.4-1,0-1.6c-0.1-0.2-0.4-0.5-0.7-0.5c0,0.1,0,0.2,0,0.4
			c-0.2-0.1-0.4-0.2-0.6-0.3c-0.8-0.4-1.3-0.9-1.9-1.5c-0.4-0.4-0.8-0.8-1-1.3c-0.2-0.4-0.3-0.9-0.7-1.2c-0.4-0.3-0.9-0.3-1.2-0.7
			c-0.3-0.4-0.1-1-0.1-1.4c0-1-0.6-1.7-1.3-2.4c-0.6-0.5-0.7-1.3-1.4-1.6c-0.5-0.2-0.9-0.4-1-0.9s0.1-1-0.2-1.4
			c-0.2-0.4-0.7-0.7-0.7-1.1c-0.1-0.5,0.3-0.8,0.5-1.2s0-0.7,0-1.1c0-0.4,0.2-0.6,0.3-0.9c-0.4-0.5-2.1-0.8-3.5,0
			c-0.7,0.4-1.1,1-1.3,1.8c0.3-0.1,0.6-0.1,0.9-0.2l0.3-0.1l6.5,22.7l8.4-1.6c-0.1-0.3-0.1-0.5-0.1-0.8
			C708.8,271.6,709.1,270.3,708.5,270.2z"/>
									<path fill="#DDDDDC" d="M678.7,271.8c-0.4-0.7-1.2-1.1-2.2-1.3c-0.5-0.1-1-0.1-1.4-0.1c-1.1,0-2,0-3.2-1.4c-0.3-0.4-0.1-0.8,0-1.1
			c0.1-0.4,0.2-0.6,0.1-0.7c-0.2-0.3-0.7-0.5-1.1-0.8c-0.2-0.1-0.4-0.2-0.6-0.3c-0.8-0.5-1.6-0.8-2.4-1.1c-0.1,1.2-0.2,2.5-0.4,3.5
			l-0.1,0.3h-0.3c-0.9-0.1-1.5-0.8-2-1.1c-0.7-0.4-2.1-1.3-5-2.8c0,0.2,0.1,0.4,0.1,0.5c0,0.6-0.2,1.2-0.7,1.7
			c-0.2,0.2-0.4,0.2-0.5,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0.1,0.2,0.2,0.2l0.3,0.1l-0.1,0.3c0,0.8-0.2,1.7-0.4,2.4
			c-0.1,0.5-0.2,0.8-0.4,1.2c-0.2,0.3-0.6,0.5-1.1,0.5c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.8c0,0.2,0,0.5-0.1,0.7
			c-0.4,0.9-1.4,1.1-1.6,1.4c-0.7,1-0.9,2.1-1.4,3.3l-0.1,0.2h-0.2c0,0,0,0-0.1,0c-0.7,0-0.9-0.6-1.2-1c-0.2-0.3-0.4-0.5-0.5-0.6
			c-0.1,1.3-0.7,2-1.2,3c-0.5,0.8-0.1,2-0.6,3.1c-0.2,0.3-0.4,0.5-0.5,0.6c-0.1,0.2,0,0.6-0.2,1c-0.2,0.6-0.6,1-1.3,1.1
			c-0.4,0.1-0.7,0.1-1,0.1c-0.6,0-1.1-0.2-1.6-0.6c-0.7-0.5-1.4-1.4-2-1.8v0.1c0,0.3,0.2,0.6,0.3,1.2c0,0.1,0,0.3,0,0.4
			c0,0.8-0.4,1.4-0.6,1.9c-0.2,0.6-0.3,1.3-0.8,1.9c-0.4,0.6-0.3,0.6-0.6,1.3c-0.3,0.8-1,1.2-1,1.7l0,0l0,0c0,0.1,0.1,0.3,0.2,0.7
			v0.1c-0.1,0.5-0.4,0.9-0.6,1.1c-0.3,0.5-0.4,0.9-0.6,1.6c-0.2,0.4-0.4,0.6-0.5,0.9c0,0,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2
			c0,0.2,0,0.4-0.1,0.6c-0.4,0.8-1.1,0.6-1.3,1c-0.2,0.2-0.2,0.7-0.2,1.2c-0.1,0.6-0.2,1.1-0.4,1.6c0.4,0.2,0.7,0.5,0.7,0.9
			c0,0.1,0,0.3-0.1,0.4c-0.2,0.3-0.4,0.5-0.6,0.7v0.2c0,0.1,0,0.2,0,0.3c0,0.6-0.2,1.3-0.2,1.7v0.2l-0.2,0.1c-1,0.2-1.4,1-2.3,1.8
			l-0.5,0.4l-0.1-0.6c-0.1-0.7-0.4-0.9-0.6-0.9c-0.1,0-0.3,0.1-0.6,0.4c-0.8,0.9-1.3,0.9-2.2,1.3c-0.9,0.3-0.9,1.5-2,2.2
			c-0.5,0.2-0.9,0.2-1.2,0.3c-0.3,0.1-0.6,0.3-1,0.4c-0.5,0.2-1.1,0.8-1.9,0.8c-0.1,0-0.3,0-0.4-0.1c-0.8-0.3-1.3-0.9-1.4-0.8
			c0,0-0.2,0-0.4,0.4s-0.4,0.6-0.7,0.6c-0.2,0.1-0.3,0-0.6,0.1s-0.3,0.4-0.8,0.8c-0.3,0.2-0.6,0.3-0.9,0.3c-0.2,0-0.4,0-0.6,0l0,0
			l0,0l0,0c-0.3,0-0.7,0.2-1.2,0.2c0,0,0,0-0.1,0c-0.3,0-1-0.2-1.3-0.2c-0.7-0.2-0.8-0.7-1.2-0.9c-0.2-0.2-0.6-0.2-1.1-0.5
			c-0.7-0.5-0.8-1.1-1.3-1.4l-0.1-0.1v-0.1c0-0.2-0.1-0.3-0.1-0.5s0-0.4,0-0.5c-1.1,1.4-2.2,2.6-3.5,3.8c-0.5,0.5-1,1.3-1.8,1.7
			c-1,0.6-1.6,0.5-2.2,1.2c-0.6,0.7-1.6,1.1-1.9,1.7c0.1,0.2,0.2,0.3,0.2,0.5c0,0.7-0.6,1.1-1,1.5c-0.5,0.4-0.9,0.9-0.9,1
			s0,0.2,0,0.3c0,0.2,0,0.3,0,0.5c0,0.4-0.1,0.9-0.7,1.2c-0.7,0.4-1.4,0.4-1.4,0.7l-0.1,0.2h-0.2c-0.7,0.1-0.6,0.1-0.6,0.2
			c-0.1,0.2,0,0.6-0.4,1.2c-0.2,0.3-0.5,0.5-0.8,0.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0.4-0.6,0.8
			c-0.8,0.6-1.8,0.5-2.4,0.9c-0.3,0.2-0.6,0.6-1.2,0.8c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.2,0-0.3,0.1
			c-0.2,0.2-0.5,0.4-0.7,0.8c11.3-1.1,23-2.6,26.9-3.9l0,0l0,0c0,0,9.4-0.9,13.1-1.1c3.6-0.2,61.5-11.2,72.1-13.8
			c-0.5-0.2,0-0.6,0.1-0.9c0.2-0.6-0.2-0.7-0.5-1.2c-0.2-0.4-0.1-1-0.3-1.4s-0.6-0.5-0.7-0.9c-0.1-0.4,0.3-0.9-0.1-1.3
			c-0.4-0.5-0.7,0-1.1,0c-0.3,0-0.4-0.2-0.6-0.2c-0.2,0-0.3,0.1-0.4,0.1c-0.4,0-0.8,0.2-1.1-0.2c-0.3-0.3-0.2-0.8-0.9-0.5
			s-0.2,0.5-0.2,1c-0.1-0.1-0.3,0-0.4,0c-0.2,0.7,0.7,0.6,0.4,1.3c-0.3,0.6-0.9,0.2-1.5,0.3c0,0.2-0.6,1.3-0.9,0.7
			c-0.2-0.4,0.6-0.7,0-1.2c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.3-0.4-0.6-0.1-1-0.4c-0.5-0.3-0.1-0.9-0.8-1.1
			c-0.5-0.2-0.6-0.1-0.9-0.6c-0.2-0.3-0.4-0.9-0.8-0.9c-0.3,0-0.3,0.3-0.6,0.3c-0.2,0-0.2-0.1-0.4-0.2c-0.4-0.2-0.7-0.4-1.1-0.4
			c-0.5,0-0.8,0.3-1.3,0.2c0.1-0.8-0.2-0.6-0.8-0.7s-0.4-0.6-0.9-0.9c-0.4-0.3-0.8,0-1.3,0.1s-1,0.1-1.5,0.2
			c-0.4,0.1-0.9,0.3-1.3,0.2c-0.1,0-1.1-0.5-0.8-0.7c0.2-0.1,0.9,0.6,1.1,0.6c0.5,0.1,0.9-0.4,1.3-0.5c0.6-0.1,1.2-0.1,1.7-0.2
			s0.9-0.3,1.4-0.3c0,1.4,1.1,0.5,2,0.7c0.8,0.1,0.5,1.1,1.4,0.8c0.9-0.4,1.4-0.5,1.9,0.6c0.3,0.7,1,1,1.8,1.1
			c0.6,0,1.5-0.4,1.8,0.6h0.2c0.2,0.3,0.8,0.6,1.2,0.2c-0.1-0.4-0.4-0.9-0.7-1.3c-0.1-0.2-0.3-0.5-0.5-0.7c-0.3-0.3-0.2-0.2-0.7-0.2
			c-0.2-0.5,0-1.1-0.7-1.2c-0.3,0-0.5,0.2-0.8,0.1c-0.4-0.1-0.4-0.4-0.7-0.7c-0.4-0.3-0.8-0.2-1.3-0.4c-0.4-0.2-0.8-0.5-1.2-0.6
			c-0.9-0.5-1.4-1.4-2.3-1.9c-2-1.1-4.4-1.4-6.4-2.8c0.3,0.1,0.6,0.4,0.9,0.5c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.2,0.9,0.3
			c0.5,0.1,0.8,0,1.2,0.2c0.6,0.3,1.2,0.5,1.8,0.8c0.7,0.3,1.1,0.8,1.9,1c-0.2-0.5-0.4-0.7-0.8-1c-0.4-0.3-0.7-0.6-1.1-0.9
			c-0.2-0.2-0.5-0.2-0.7-0.4c-0.2-0.2-0.3-0.6-0.5-0.8c-0.5-0.6-1.3-0.7-1.8-1.2c0.8,0.1,1.5,0.8,2,1.3c0.6,0.5,1.3,1,1.8,1.6
			c0.4,0.4,0.5,0.7,1.1,0.9c0.4,0.2,0.5,0.2,0.8,0.5c0.4,0.5,0.8,1.4,1.4,1.7c0.3,0.2,0.7-0.2,1-0.2c0.2,0,0.5,0.3,0.7,0.5
			c0.3-0.5,0.4-1.2,0.4-1.7c-0.7-0.1-0.3-0.7,0.1-0.8c0.7-0.3,0.7,0.3,0.9,0.8c0.9-0.4,0.3,0.7,1,0.9c0.7,0.2,0.6-1,0.6-1.3
			c-0.1-0.4-0.1-0.6-0.1-1c0-0.7-0.2-0.6-0.7-0.6s-0.8-0.3-1.2-0.5c0.1-0.3,0.6-0.6,0.5-0.9c-0.1-0.2-0.6-0.3-0.8-0.3
			c-0.1,0-0.3,0.1-0.5,0.1c-0.1,0-0.3-0.2-0.5-0.2c-0.4,0-0.7,0.2-1.1,0.1c-0.7-0.1-0.7-0.7-1.2-1.2c-0.3-0.3-0.6-0.2-0.8-0.4
			c-0.2-0.2-0.1-0.6-0.4-0.7c-0.4-0.2-0.5,0-0.8-0.3s-0.3-0.4-0.7-0.5c-0.5-0.2-0.5,0-0.9-0.5c-0.2-0.3-0.5-0.6-0.8-0.8
			c-0.5-0.4-1-0.6-1.5-1c-0.7-0.5-0.1-1.3-0.6-1.7c-0.5-0.5-1.5,0.1-2-0.3c-0.2-0.2-0.1-0.5-0.5-0.7c-0.3-0.2-0.7-0.1-1.1-0.3
			c0.5-0.4,1.1-0.3,1.4,0.1c0.3,0.4,0.1,0.3,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0.1,0.9,0,1.1,0.6c0.1,0.2,0,0.4,0.2,0.6
			s0.4,0.2,0.6,0.4c0.1,0.1,0.1,0.3,0.2,0.3c0,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.3,0.4,0.4c0.4,0.3,0.7,0.4,1,0.8
			c0.4,0.4,0.8,0.8,1.3,1c0.2,0.1,0.5,0,0.7,0.1s0.3,0.2,0.5,0.3c0.3,0.1,0.4,0,0.7,0.3c0.2,0.2,0.3,0.5,0.4,0.8
			c0.2,0.5,0.2,1,0.8,0.9c-0.2-0.4-0.2-0.8,0-1.2c0.5,0,0.5,1.5,1,0.4c0.4,0.3,0.6,0.9,1.1,1.1c0-0.3,0-0.8,0.4-0.9
			c-0.1-0.3,0-0.5-0.1-0.8s-0.3-0.2-0.3-0.5c0.5,0.1,0.5-0.3,0.5-0.6c0-0.2-0.2-0.3-0.2-0.5s0.3-0.3,0.3-0.5s-0.3-0.3-0.3-0.4
			c0-0.2,0.3-0.3,0.3-0.4c0-0.2-0.1-0.7-0.3-0.9c-0.5-0.6-1.2-0.4-1.9-0.7c-0.3-0.1-0.5-0.6-0.7-0.7c-0.4-0.1-0.5,0.4-0.9,0.4
			c0.1-0.4-0.1-0.4-0.3-0.7c-0.1-0.1-0.1-0.4-0.2-0.5c-0.1-0.1-0.4-0.1-0.4-0.1c-0.2-0.2-0.1-0.4-0.2-0.7c0-0.1-0.5-0.3-0.6-0.4
			c-0.6-0.4-1-0.3-1.3,0.4c-0.5,1-0.4-0.2-1-0.3c-0.1,0.2-0.7,0.4-0.9,0.3c-0.5,0-0.3-0.4-0.7-0.5c-0.2,0-0.3,0.2-0.4,0.2
			c-0.2-0.1-0.2-0.1-0.4-0.2c0,0-0.2-0.2-0.2-0.3c-0.1-0.1-0.3,0-0.4-0.1c-0.2-0.2-0.1-0.6-0.3-0.8c-0.4-0.3-0.6,0-0.7-0.6
			c-0.1-0.4,0.2-0.6-0.2-1c-0.2-0.2-0.5-0.4-0.7-0.3c0,0.1,0.1,0.3,0.1,0.4c-0.5-0.1-0.5,0.3-0.8,0.6c-0.3,0.4-0.7,0.6-1.2,0.7
			c-0.3,0-0.8,0-1-0.1c-0.4-0.2-0.2-0.5-0.4-0.9c-0.2-0.8-0.6-1.2-0.5-2.1c0.1-0.8,0.4-1.6,1-2.2c0.3-0.3,0.5-0.3,0.6-0.7
			c0,0,0,0,0-0.1l-0.1-0.1C679.2,274.1,679.3,272.7,678.7,271.8z"/>
									<path fill="#DDDDDC" d="M709.2,274.1c0-0.2-0.1-0.4-0.2-0.6l-9.1,1.7l-6.6-22.7c-4.2,0.8-17.8,3.3-31.6,5.9
			c0.5,0.3,0.6,0.8,0.7,1.1c0.1,0.3,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.4-0.1l0.3-0.1l0.1,0.3c0.1,0.4,0.2,0.3,0.2,0.4
			c0.2,0.1,0.8-0.6,1.6-0.6c0.2,0,0.5,0.1,0.7,0.3l0.2,0.2l-0.1,0.2c-0.1,0.2-0.2,0.3-0.2,0.4c0.1,0,0.3,0,0.5,0h0.3v0.3
			c0.1,0.8,0,0.8,0.5,1.3c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.2,0.3,0.2,0.6c0,0.2,0.1,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
			c0.9,0.3,1.7,0.6,2.6,1.2c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.3,1.1,0.5,1.4,1c0.4,0.5,0.2,1.1,0,1.5c0,0.1-0.1,0.3-0.1,0.4
			c0.9,1.1,1.5,1.1,2.6,1.1c0.4,0,0.9,0,1.5,0.1c1.3,0.2,2.2,0.7,2.8,1.6c0.7,1.1,0.7,2.8,0,4.7l-0.2-0.1c0,0.1,0,0.3,0,0.4
			c-0.1,0.6-0.6,0.7-1,1.1s-0.3,0.6-0.3,1.1s-0.2,0.9-0.1,1.4c0.1,0.6,0.3,0.8,0.8,0.9c0.7,0.1,0.7-0.2,1.2-0.5
			c0.3-0.3,0.6-0.2,0.9-0.5c0.3-0.4,0.3-0.8,0.9-0.4c0.4,0.2,0.3,0.6,0.6,0.9s0.6,0.3,1,0.3c0,0.2,0,0.5,0.1,0.7c1,0.1,0.6-0.3,1-1
			c0.3,0.1,0.3,0.5,0.4,0.7c0.1,0.1,0.5,0.3,0.6,0.3c0.3,0.2,0.8,0.5,0.7-0.2c0.8-0.2,1.7-0.3,0.6,0.6c0.3-0.1,0.6,0,0.8-0.3
			c0.4,0.4,0.9,0.3,1.3,0.6c0.4,0.3,0.7,1.1,1.1,0.2c0.3,0.1,0.3,0.5,0.5,0.7c0.3,0.3,0.6,0.3,1,0.2c-0.2-0.3-0.8-0.4-0.8-0.9
			c0.7,0.3,1.1,1,1.9,1.2c0.1-0.6-0.7-1.9-1-2.4c-0.5-0.7-1.1-0.9-1.8-1.3c-0.7-0.5-0.5-1.2-1.5-1.3c-0.8-0.1-1.3-0.7-1.8-1.3
			c-0.5-0.7-0.4-1.6-1.1-2.3c0.7-0.2,1,1,1.2,1.5c0.3,0.6,0.5,1.2,1.2,1.5c0.3,0.1,0.6,0,0.9,0.1c0.3,0.1,0.4,0.5,0.6,0.7
			c0.2,0.3,0.9,1.2,1.3,0.7c0.2-0.3,0.1-1.5-0.1-1.8c-0.5,0-1-0.1-1.3-0.4c-0.5-0.4-0.4-0.8-0.4-1.3c-0.1-0.8-0.4-0.7-0.8-1.3
			c-0.3-0.4,0.1-0.9-0.6-1c0-0.9-0.1-1.3-0.8-1.9c0.2-0.3,0.7-1.9-0.2-1.7c0-0.4,0-0.7-0.1-1.1c-0.1-0.5-0.2-0.5,0.1-1
			c0.3-0.3,0.7-0.5,0.4-1c-0.1-0.3-0.7-0.3-0.8-0.6c-0.2-0.4,0.3-0.4,0.5-0.6c0.4-0.3,0.4-0.2,0.2-0.7c-0.1-0.3-0.4-0.7-0.6-0.9
			c-0.3-0.3-0.7-0.2-0.5-0.8c0.9,0.5,1.1-0.9,0.7-1.5c-0.2-0.4-0.4-0.2-0.2-0.7c0.1-0.4,0.5-0.8,0.9-0.5c0.1-0.4,0.2-0.8,0.5-1.1
			c0.1-0.1,0.4-0.4,0.6-0.4c0.2,0,0.2,0.2,0.4,0.2c0.7,0.2,1.1-0.3,1.4-1c0.2-0.4,0.4-0.7,0.3-1.2c0-0.4-0.2-0.6-0.1-1
			c0.1-0.4,0.7-1.7,1.3-1.4c0.7,0.3-0.1,1.5,0,2c0.3-0.2,1-1.8,1.4-1.7c0.6,0.2-0.5,1.4-0.7,1.6c-0.3,0.4-0.4,0.7-0.8,1
			c-0.4,0.3-0.3,0.4-0.5,0.9c-0.2,0.7-0.5,1.2-1.1,1.7c-0.7,0.5-0.7,1.1-0.5,1.9c0.1-0.1,0.2-0.2,0.3-0.3c-0.2,0.8-0.5,1.2-0.2,2
			c0.1,0.3,0.6,1.3,0.8,0.4c0.1-0.5-0.5-1.2,0.4-1.5c0.4-0.2,0.9,0.2,1.1,0.6c-0.3,0-0.2,0.1-0.3,0.3c0.8,0.7-0.1,1.5-0.4,2.2
			c-0.2,0.5-0.2,0.4,0.1,0.8c0.4,0.5,0.2,0.4,0,0.9c-0.3,0.6,0,0.5,0.4,1c0.4,0.4,0.2,0.7-0.3,0.8c-0.3,0.1-0.9-0.1-0.9,0.5
			c0,0.5,0.6,0.5,0.9,0.5c0.6,0.2,0.4,0.3,0.7,0.6c0.1,0.1,0.4,0.1,0.5,0.3c0.1,0.1,0,0.5,0,0.7c0.2,0,0.3-0.3,0.4-0.3
			c0.4-0.1,0.2,0.1,0.4,0.3c0.4,0.3,0.4,0.7,0.6,1.1c1.3,0,0-2.1,0.7-2.6c-0.1,0.1,0,0.3-0.1,0.5c0.9,0.3,1.1,2.2,0.2,2.6
			c-0.4,0.2-1,0-1.5,0.1c-0.2,0-0.3,0.2-0.6,0.1c-0.2-0.1-0.3-0.4-0.5-0.4c-0.5-0.1-0.6,0.2-0.5,0.6c0,0.2,0.2,0.4,0.2,0.6
			s-0.2,0.4-0.2,0.6c-0.1,0.5,0.3,0.7,0.2,1.2s-0.6,0.5-0.3,1.1c0.2,0.4,0.7,0.5,1,0.5c0.7-0.1,0.6-0.3,1.1,0.3
			c0.5,0.7,1.8,0.9,2.1,0c0.2-0.5,0.1-0.8,0.8-0.5c0.5,0.2,0.4,0.6,0.8,0.9c0.3,0.3,0.8,0.1,1.1,0.4c0.4,0.4,0.2,0.8-0.2,1.1
			c-0.4,0.3-0.9,0.3-1.2,0.7c-0.4,0.6,0.1,0.6,0.4,0.5c0.2-0.1,0.3-0.2,0.5-0.2s0.3,0.2,0.5,0.2c0.4,0,0.6-0.6,1.1-0.3
			c0,0.2-0.1,0.5-0.1,0.7c-0.4,0-0.8,0.4-0.7,0.8c0.4,0.1,1.2-0.9,1.3,0c0,0.7-0.8,0.4-0.8,0.9c-0.1,0.3,0.3,0.3,0.3,0.6
			c0,0.2-0.2,0.4-0.3,0.6c-0.2,1,0.3,0.3,0.7,0.3c0.3,0.6,0.3-0.1,0.5-0.3c0.1-0.2,0.5-0.4,0.6-0.6c0.4-0.3,1.3-0.9,1.6-0.2
			c1-0.4,2-0.6,3.3-0.7c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.4,0-0.7,0.1-1.1s0.3-0.5,0.6-0.8c0.1-0.2,0.5-0.6,0.5-0.8
			c0.1-0.4-0.2-0.7-0.2-1.2s0.8-1.6,1.1-0.6C708.9,276.9,709.4,275.3,709.2,274.1z"/>
									<path fill="#DDDDDC" d="M702.6,285.6c0.1,0.3-0.4,0.6-0.6,0.7c0.7,0.2,0.6,0.3,0.1,0.8c-0.5,0.6-0.2,0.1-0.9,0.1
			c-1,0-0.1,1.4-0.2,1.9c0,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.4,0,0.6c-0.2,0.4-0.7,0.1-0.8,0.5c-0.1,0.3,0.4,0.6,0.4,0.9
			c0,0.2-0.2,0.7-0.3,0.9c-0.1,0.2-0.5,0.5-0.4,0.9c0.1,0.3,0.4,0.5,0.5,0.8c-0.2,0-0.5,0-0.6,0.1c0.4,0.4,0.7,0,0.7,0.7
			c0,0.3,0,1-0.4,1.1c0.3,0.4,0.4,0.6,0.3,1.1c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.4,0.5,0.6,0.7,0.8c-0.8-0.1,0,1,0.2,1.1
			c0.2,0.2,0.3,0.2,0.4,0.5c0.1,0.3-0.1,0.6,0.2,0.8c0.8-0.8,0-2.9,0.2-4c0.1-0.6,0.6-0.9,0.8-1.4c0.1-0.3-0.1-0.7-0.1-1
			c0.1-0.3,0.3-0.7,0.5-0.9c0.2-0.2,0.7-0.4,0.7-0.6c0.1-0.2-0.2-0.4-0.2-0.7c0.1-0.4,0.4-0.3,0.5-0.6l0.2-0.7
			c0.1-0.6,0.1-1,0.3-1.6s0.5-1.1,0.6-1.6c0.1-0.6,0.4-0.9,0.4-1.5c0-0.5-0.1-1.6,0.6-1.6c0-0.1,0.1-0.2,0.1-0.4
			c-1.6,0.1-2.6,0.4-4,1.2C701.9,285.5,702.5,285.2,702.6,285.6z"/>
								</g>
								<path fill="#DDDDDC" d="M527.6,367.9c-0.3-0.2-0.9-0.7-1.7-1.4c-17,1.2-30.2,1.9-31.6,2c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.1,0.4,0.4,0.8,0.9c0.2,0.2,0.3,0.4,0.3,0.7c-0.1,1-1.2,1.1-1.7,1.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.5-0.1-0.7-0.3
		c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.4,0.5,1,0.5,1.7c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.5,0.2-0.7,0.2
		c-0.3,0-0.6,0-0.8,0l0,0c0,0.1,0,0.1,0.1,0.2c0.2,0.4,0.7,0.4,0.9,1.2c0,0.4-0.3,0.6-0.7,0.9c-0.2,0.1-0.3,0.2-0.4,0.2
		c0,0.1,0,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5s0,0.4,0.1,0.6c0.1,0.3,0.1,0.4,0.1,0.7c0,0.2-0.1,0.4-0.2,0.7c-0.1,0.4,0,0.7-0.1,1.3
		c0,0.4-0.3,0.7-0.6,0.8c-0.2,0.1-0.3,0.1-0.6,0.2c-0.2,0.2-0.4,0.5-0.5,1l-0.4,1.1l-0.3-1.1c0-0.2-0.3-0.3-0.3-0.3l0,0
		c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2c-0.1,0.3-0.3,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3l-0.1,0.2h-0.2
		c0,0-0.6,0.4-0.8,0.7c0,0.1-0.1,0.1-0.1,0.1l0,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.7,0.1,0.8,0.7c0,0.2-0.1,0.4-0.3,0.6
		c-0.2,0.2-0.5,0.3-0.7,0.3c-0.4,0-0.8-0.2-1-0.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.3,0.3,0.9,0.6,1.1l0.2,0.2l-0.1,0.2
		c-0.3,0.4-0.6,0.4-1.1,0.7c-0.4,0.2-0.7,0.4-0.9,0.5c0.1,0.1,0.3,0.1,0.6,0.2h0.2l0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.8-0.7,1.4-0.6,1.6
		c0,0,0,0.1,0.4,0.4l0.4,0.2L484,392c-0.3,0.4-0.7,0.5-1,0.5c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
		c0,0.2,0.2,0.5,0.6,0.6l0.3,0.1v0.3c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.2-0.7,0.2c-0.5,0-0.9-0.2-1.3-0.4
		c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0.2,0.7,0.7,1.1,1.4c0.1,0.2,0.1,0.4,0.1,0.5c0,0.6-0.3,0.8-0.3,1.1c0.8,0.2,1.7,0.5,1.8,1.6
		c0,0.2,0,0.4-0.1,0.6c-0.3,0.6-0.8,0.9-0.7,1.1v0.1c0.1,0.2,0.1,0.2,0.1,0.2s0.1,0,0.3-0.1l0.4-0.3l0.2,0.5
		c0.1,0.3,0.2,0.8,0.2,1.3c0,0.4-0.1,0.9-0.3,1.2c-0.3,0.4-0.6,0.4-0.9,0.5s-0.3,0.2-0.3,0.2l0,0c0,0.1,0,0.1,0.3,0.3
		c0.2,0.1,0.7,0.4,0.7,1l0,0c0,0.6-0.4,1.1-0.7,1.4c0.9,0.3,1.4,1.3,1.5,2.2v0.5H484c-0.3,0.6-0.8,1.2-0.7,1.5l0,0c0,0,0,0,0.2,0
		h0.4l-0.1,0.4c-0.1,0.4-0.3,0.8-0.3,1c0,0,0,0,0,0.1s0,0.2,0.1,0.3c0.1,0,0.4,0.1,0.6,0.4c0.6,0.7,0.6,1.3,0.7,2.1
		c0.3,0.1,0.5,0.2,0.6,0.2h0.4v0.4c0,0.1,0,0.1,0,0.2c0,0.5,0.1,0.5,0.2,0.6s0.5,0.3,0.8,0.7c0.2,0.3,0.3,0.6,0.3,0.8
		c0,0.7-0.5,1.3-1.2,1.5c0,0.4,0,0.9,0,1.4v0.4l-0.4-0.1c-0.5-0.1-0.8-0.4-1.1-0.6c-0.2,0.2-0.4,0.5-0.5,0.7
		c-0.1,0.1-0.1,0.1-0.1,0.2l0,0c0,0.1,0,0.2,0,0.2l0,0c0.1,0,0.5,0,0.9,0.4c0.3,0.3,0.5,0.7,0.4,1c0,0.7-0.5,1.3-1.2,1.3
		c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2-0.1,0.4c-0.2,0.4-0.5,0.5-0.8,0.5c-0.2,0-0.3,0.1-0.3,0.1
		c-0.4,0.4-0.5,0.7-0.5,1.1c0,0.3,0,0.6,0,1.1c0,0.1,0,0.3,0,0.4v0.4l-0.4-0.1h-0.1c-0.8,0-1.1,0.4-1.1,1.1c0,0.3,0.1,0.6,0.3,1
		l0.2,0.2l-0.2,0.2c-0.5,0.5-1.2,0.5-1.8,0.7c-0.6,0.1-0.7,0.2-0.7,0.4c0,0.1,0,0.3,0.2,0.5c0.1,0.2,0.2,0.2,0.3,0.3
		c0.1,0.1,0.4,0.4,0.4,0.7c0,0.2,0,0.3-0.1,0.5c-0.3,0.7-0.7,0.6-0.7,0.8l0,0l0,0l0,0l0,0c0,0,0.1,0.2,0.2,0.3
		c0.1,0.2,0.3,0.3,0.3,0.6c0,0,0,0,0,0.1c-0.1,0.5-0.6,0.7-0.9,0.7c-0.1,0-0.2,0-0.2,0c-0.2,0-0.2,0-0.3,0.1c0,0.1-0.1,0.1-0.1,0.2
		c-0.1,0.2,0.4,0.6,0.5,1.3c0,0,0,0,0,0.1c-0.1,0.8-0.7,1.2-0.8,1.6c0,0.1,0,0.2,0,0.4c0,0.4,0.2,0.8,0.3,1.4l0.1,0.4l-0.4,0.1
		c0,0-0.1,0.1-0.2,0.4c0,0.2,0,0.4-0.1,0.6l0.1,0.1l30.7-1.8l-0.1,0.5l-0.2,0.9l0,0c0,0.1,0,0.1,0,0.2s0,0.3,0,0.5
		c0,0.1,0,0.3-0.1,0.4c-0.2,0.4-0.5,0.7-0.6,0.9c-0.1,0.2-0.1,0.3-0.2,0.4c0,0.1,0,0.3,0,0.5c0,0.1,0,0.2,0,0.4
		c-0.2,0.7-0.6,0.7-0.5,1.1v0.1c0,0.3,0.1,1.3,0.2,1.6l0,0c0.1,0.1,0.1,0.1,0.3,0.1c0.3,0,0.7,0.3,0.8,0.8c0,0.1,0,0.3,0,0.4
		s0,0.2,0,0.2c0,0.1,0,0.1,0,0.1c0.1,0.1,0-0.1,0.6,0.2c0.3,0.2,0.4,0.5,0.5,0.5c0,0.1,0.6,0,0.8,0.7c0,0.1,0,0.2,0,0.3
		c0,0.5-0.3,0.8-0.2,0.9c0,0,0,0.1,0.1,0.2c0,0.1,0.4,0.1,0.8,0.7c0.1,0.3,0.2,0.8,0.2,0.9c0.1,0.3,0.4,1.3,0.6,1.7
		c0.6-0.1,1.3-0.2,1.2-0.7c0.2,0.1,0.4,0.2,0.6,0.3c0.6-0.7,1.3-1.5,1.9-2.2c-0.1-0.2-0.3-0.4-0.3-0.6c0.4-0.4,0.6,0,1,0.1
		c0.5,0.2,0.5,0.1,0.9-0.1c0.9-0.4,2-0.3,3-0.7c0.9-0.4,1.3-1.1,2.4-0.9c0.7,0.1,1,0.8,1.7,1c0.5,0.1,1.7,0.4,2.2,0.2
		c0.4-0.2,0.5-0.7,0.8-0.8c0.6-0.3,0.4,0.2,0.7,0.6c0.3,0.5,0.3,0.2,0.7,0.1c0.3-0.1,0.8,0.1,1,0c0.6-0.3,0.5-1.1,1-1.5l-3.7-27.5
		L527.6,367.9z"/>
								<path fill="#0B446C" d="M545.4,449.6c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.1-0.1-0.3-0.1-0.5s-0.1-0.4-0.1-0.7c0-0.3,0.1-0.7-0.1-1
		c-0.2-0.2-0.7-0.2-1-0.2h-0.3l-0.1-0.3c-0.1-0.4-0.1-0.8-0.3-1.1c-0.2-0.4-0.6-0.5-1-0.5s-1.1-0.4-1.1-0.8c-0.1-0.3,0-0.2-0.2-0.3
		c-0.2-0.1-0.2-0.2-0.2-0.3c-0.1-0.5-0.1-1.2,0-1.7c0.1-0.6,0.5-1.2,1.1-1.5s1.2-0.3,1.8-0.3c1.3-0.1,2.7-0.2,4-0.3
		c7.3-0.6,14.6-1.5,22-2.2c4.3-0.4,8.2-0.8,12.4-1.2c-0.2-0.4-0.2-0.8-0.4-1c-0.1-0.2-0.6-0.4-0.9-0.8c-0.2-0.3-0.2-0.5-0.2-0.7
		c0-0.4,0.1-0.6,0.2-1c0-0.1,0-0.1,0-0.2c0-0.5-0.2-0.7-0.3-1.4c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0-0.6,0-0.9c0-0.4,0-0.7-0.1-1l0,0
		c-0.1,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0-0.2c0-0.5,0.3-0.8,0.3-0.9c0-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.3,0-0.6c-0.1-1-1-1.1-1-2.4
		c0-0.1,0-0.2,0-0.2c0-0.9,0.1-2.1,0.3-3c0.2-0.7,0.4-1.2,0.4-1.7c0-0.1,0-0.1,0-0.2c0-0.3-0.4-0.6-0.4-1.2c0-0.1,0-0.1,0-0.1
		c0.1-0.9,1-1.7,1.5-2.2c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.1-0.6,0.1-0.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.3-0.6-0.4
		s-0.8-0.4-0.8-0.9c0-0.1,0-0.1,0-0.2c0-0.5,0.4-0.8,0.6-1c0.3-0.2,0.4-0.3,0.4-0.5c0-0.3-0.5-0.3-1-0.8c-0.9-0.9-1.6-2.3-1.9-3.3
		c-0.3-1.1-1.1-2.1-1.5-3.4l0.3-0.1c-1.8-4.6-10-34.9-10.8-38c-12.7,1.2-26.3,2.2-38.1,3c0.7,0.7,1.3,1,1.3,1l0.2,0.1l-1.7,57.1
		l3.6,27.4c0.5,0.1,1,0.4,1.4,0.5c0.3,0.1,0.8,0.2,1.1,0.3c0.3,0.2,0.4,0.6,0.8,0.5c0.1-0.6,0.6-1.2,0.6-1.8
		c0.1-0.8-0.5-1.4-0.3-2.2c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.3,0-0.7,0-1c0.1-0.7,0.2-1.5,0.3-2.2c0-0.5-0.3-2.2,0.3-2.4
		c0.1,0.8,0,1.5,0.1,2.3c0.4-0.1,0-0.6,0.2-1c0.5,0.1,0.6,0.7,0.5,1.2c0.3-0.3,0.4-0.4,0.6-0.8c0.1-0.3,0.1-0.7,0.1-1.1
		c0.1-0.8,0.2-1.4,0.1-2.3c0.5,0.6,0.3,1.6,0.2,2.4c0,0.3-0.2,0.7-0.3,1c-0.1,0.4,0.4,1.1-0.1,1.1c0,0.4-0.1,0.9,0.1,1.2
		c0.1,0.3,0.6,0.4,0.8,0.7c0.4,0.6-0.3,2,0.2,2.5l0.2,1.1l0,0c0.2,0.5,0.3,0.8,0.9,1c-0.1,0.3-0.2,0.5-0.1,0.8
		c0.4,0.1,1.4,0.2,1.3,0.8c-0.1,0.6-1.5,0.2-1.3,1c0.7-0.2,1.6-0.5,2.3-0.6c0.4,0,2,0.2,2-0.5c0-0.3-0.6-0.4-0.5-0.8
		c0.1-0.6,0.4-0.2,0.8-0.2c0.6,0.1,0.3-0.1,0.6-0.5c0.3-0.3,0.4-0.2,0.8-0.4c0.1-0.1,0.2-0.3,0.3-0.4s0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.3,0.3-0.5c0-0.2,0.1-0.4,0.1-0.4C545.5,450.1,545.4,449.9,545.4,449.6z"/>
								<g id="red_pins_complect">
									<g>
										<path fill="#DE1E25" d="M1057.3,44.4c-56.5,6.8-106.9,28.9-147.9,62.2L569.5,380.3c-5.2,3.9-7.8,10.4-6.9,17.4
				s5.3,12.6,10.8,15.3l395.4,185.6c47.7,22.7,101.9,32.3,158.4,25.5c159.7-19.1,274.4-164.5,255.2-324.6
				C1362.7,139.5,1217.4,25.2,1057.3,44.4z M1124.5,594.2C980.3,611.5,849,508.3,831.8,364.1c-17.3-144.2,85.9-275.5,230.1-292.7
				s275.5,85.9,292.7,230.1C1371.9,445.7,1268.8,576.9,1124.5,594.2z"/>
									</g>
								</g>
								<path fill="#DE1E25" d="M1196.3,107.4h-183.6c-0.7,6.7-5.1,12-10.5,12.9v39.6c5.5,0.9,9.8,6.4,10.5,13.2h183.6
		c0.3-3.3,1.5-6.3,3.5-8.7s4.5-3.9,7.2-4.3v-39.7C1201.5,119.5,1197.1,114.1,1196.3,107.4z"/>
							</g>
							<path fill="#DE1E25" d="M1194.5,113.4h-177.3c-0.7,6.5-4.9,11.6-10.2,12.4V164c5.3,0.9,9.5,6.2,10.1,12.7h177.4
	c0.3-3.2,1.5-6.1,3.4-8.4c1.9-2.3,4.3-3.8,6.9-4.2v-38.3C1199.5,125,1195.2,119.9,1194.5,113.4z"/>
							<g>
								<path fill="#FFFFFF" d="M1038.3,146.6c0.1,0,0.2,0,0.3,0.1l0.9,1c-0.7,0.8-1.5,1.4-2.4,1.8s-2.1,0.7-3.5,0.7
		c-1.2,0-2.3-0.2-3.2-0.6c-1-0.4-1.8-1-2.5-1.7s-1.2-1.6-1.6-2.7s-0.6-2.2-0.6-3.4s0.2-2.4,0.6-3.4s1-1.9,1.7-2.7
		c0.7-0.7,1.6-1.3,2.6-1.7s2.1-0.6,3.4-0.6c1.2,0,2.2,0.2,3.1,0.6c0.9,0.4,1.7,0.9,2.3,1.5l-0.7,1c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.7-0.3
		c-0.3-0.1-0.6-0.2-1-0.3s-0.8-0.1-1.3-0.1c-0.9,0-1.7,0.2-2.4,0.5s-1.4,0.7-1.9,1.3s-0.9,1.2-1.2,2c-0.3,0.8-0.4,1.7-0.4,2.7
		s0.1,1.9,0.4,2.7s0.7,1.5,1.2,2s1.1,1,1.8,1.3s1.4,0.4,2.2,0.4c0.5,0,0.9,0,1.3-0.1s0.8-0.1,1.1-0.3s0.6-0.3,0.9-0.5
		s0.6-0.4,0.9-0.7C1038,146.7,1038.1,146.6,1038.3,146.6z"/>
								<path fill="#FFFFFF" d="M1042.1,150v-16.9h2v6.8c0.5-0.5,1-0.9,1.6-1.3s1.3-0.5,2.1-0.5c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.2,0.9
		c0.3,0.4,0.6,0.8,0.7,1.4s0.3,1.1,0.3,1.8v7.4h-2v-7.4c0-0.9-0.2-1.6-0.6-2c-0.4-0.5-1-0.7-1.8-0.7c-0.6,0-1.2,0.1-1.7,0.4
		s-1,0.7-1.4,1.2v8.5h-2.1L1042.1,150L1042.1,150z"/>
								<path fill="#FFFFFF" d="M1059.5,138.2c0.8,0,1.6,0.1,2.3,0.4s1.3,0.7,1.7,1.2s0.8,1.1,1.1,1.9c0.3,0.7,0.4,1.6,0.4,2.5
		s-0.1,1.7-0.4,2.5s-0.6,1.4-1.1,1.9s-1.1,0.9-1.7,1.2s-1.4,0.4-2.3,0.4c-0.8,0-1.6-0.1-2.3-0.4s-1.3-0.7-1.7-1.2s-0.9-1.1-1.1-1.9
		s-0.4-1.6-0.4-2.5s0.1-1.7,0.4-2.5c0.3-0.7,0.6-1.4,1.1-1.9s1.1-0.9,1.7-1.2S1058.6,138.2,1059.5,138.2z M1059.5,148.6
		c1.1,0,2-0.4,2.6-1.2s0.8-1.8,0.8-3.2s-0.3-2.5-0.8-3.2c-0.6-0.8-1.4-1.2-2.6-1.2c-0.6,0-1.1,0.1-1.5,0.3s-0.8,0.5-1.1,0.9
		c-0.3,0.4-0.5,0.8-0.6,1.4c-0.1,0.5-0.2,1.2-0.2,1.9s0.1,1.3,0.2,1.8s0.4,1,0.6,1.4c0.3,0.4,0.6,0.7,1.1,0.9
		S1058.9,148.6,1059.5,148.6z"/>
								<path fill="#FFFFFF" d="M1072.2,138.2c0.8,0,1.6,0.1,2.3,0.4s1.3,0.7,1.7,1.2s0.8,1.1,1.1,1.9c0.3,0.7,0.4,1.6,0.4,2.5
		s-0.1,1.7-0.4,2.5s-0.6,1.4-1.1,1.9s-1.1,0.9-1.7,1.2s-1.4,0.4-2.3,0.4c-0.8,0-1.6-0.1-2.3-0.4s-1.3-0.7-1.7-1.2s-0.9-1.1-1.1-1.9
		s-0.4-1.6-0.4-2.5s0.1-1.7,0.4-2.5c0.3-0.7,0.6-1.4,1.1-1.9s1.1-0.9,1.7-1.2S1071.4,138.2,1072.2,138.2z M1072.2,148.6
		c1.1,0,2-0.4,2.6-1.2s0.8-1.8,0.8-3.2s-0.3-2.5-0.8-3.2c-0.6-0.8-1.4-1.2-2.6-1.2c-0.6,0-1.1,0.1-1.5,0.3s-0.8,0.5-1.1,0.9
		c-0.3,0.4-0.5,0.8-0.6,1.4c-0.1,0.5-0.2,1.2-0.2,1.9s0.1,1.3,0.2,1.8s0.4,1,0.6,1.4c0.3,0.4,0.6,0.7,1.1,0.9
		S1071.7,148.6,1072.2,148.6z"/>
								<path fill="#FFFFFF" d="M1086.9,140.3c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.5-0.3s-0.5-0.2-0.8-0.3
		s-0.6-0.1-1-0.1c-0.3,0-0.7,0-0.9,0.1c-0.3,0.1-0.5,0.2-0.7,0.4s-0.3,0.3-0.4,0.5s-0.2,0.4-0.2,0.7s0.1,0.5,0.3,0.7
		s0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.3,1,0.4s0.7,0.2,1.1,0.4c0.4,0.1,0.7,0.3,1.1,0.4c0.4,0.2,0.7,0.3,1,0.6c0.3,0.2,0.5,0.5,0.7,0.8
		s0.3,0.7,0.3,1.2s-0.1,1-0.3,1.5c-0.2,0.5-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.4,0.8c-0.5,0.2-1.2,0.3-1.9,0.3
		c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1l0.5-0.8c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.3,0.1,0.4,0.2
		c0.2,0.1,0.3,0.2,0.6,0.4s0.5,0.2,0.8,0.4s0.7,0.2,1.2,0.2c0.4,0,0.7-0.1,1-0.2s0.5-0.2,0.7-0.4s0.3-0.4,0.4-0.6
		c0.1-0.2,0.1-0.5,0.1-0.7c0-0.3-0.1-0.6-0.3-0.8s-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.3-1-0.4c-0.4-0.1-0.7-0.2-1.1-0.3
		s-0.7-0.3-1.1-0.4c-0.4-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.5-0.5-0.7-0.9s-0.3-0.8-0.3-1.3c0-0.4,0.1-0.9,0.3-1.3
		c0.2-0.4,0.5-0.8,0.8-1.1c0.4-0.3,0.8-0.6,1.3-0.7s1.1-0.3,1.8-0.3c0.8,0,1.5,0.1,2.1,0.4c0.6,0.2,1.1,0.6,1.6,1L1086.9,140.3z"/>
								<path fill="#FFFFFF" d="M1094.8,138.2c0.7,0,1.3,0.1,1.9,0.3s1.1,0.6,1.5,1s0.8,1,1,1.6s0.4,1.4,0.4,2.2c0,0.3,0,0.5-0.1,0.6
		s-0.2,0.2-0.4,0.2h-7.7c0,0.7,0.1,1.4,0.3,1.9c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.6,1.1,0.8s0.9,0.3,1.5,0.3c0.5,0,1-0.1,1.3-0.2
		c0.4-0.1,0.7-0.2,1-0.4c0.3-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.3-0.2,0.5-0.2s0.3,0.1,0.4,0.2l0.6,0.7c-0.3,0.3-0.6,0.6-0.9,0.8
		c-0.4,0.2-0.7,0.4-1.1,0.6s-0.8,0.3-1.2,0.3c-0.4,0.1-0.9,0.1-1.3,0.1c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.3-0.7-1.8-1.2
		s-0.9-1.2-1.2-1.9c-0.3-0.8-0.4-1.7-0.4-2.7c0-0.8,0.1-1.6,0.4-2.3c0.2-0.7,0.6-1.3,1.1-1.8s1-0.9,1.7-1.2
		C1093.2,138.4,1094,138.2,1094.8,138.2z M1094.9,139.7c-1,0-1.8,0.3-2.3,0.9c-0.6,0.6-0.9,1.4-1.1,2.4h6.3c0-0.5-0.1-0.9-0.2-1.3
		s-0.3-0.7-0.6-1s-0.6-0.5-0.9-0.7C1095.8,139.8,1095.3,139.7,1094.9,139.7z"/>
								<path fill="#FFFFFF" d="M1109.2,148.1h7.1v1.9h-9.3v-16.4h2.2L1109.2,148.1L1109.2,148.1z" />
								<path fill="#FFFFFF" d="M1122.7,138.2c0.8,0,1.6,0.1,2.3,0.4s1.3,0.7,1.7,1.2s0.8,1.1,1.1,1.9c0.3,0.7,0.4,1.6,0.4,2.5
		s-0.1,1.7-0.4,2.5s-0.6,1.4-1.1,1.9s-1.1,0.9-1.7,1.2s-1.4,0.4-2.3,0.4c-0.8,0-1.6-0.1-2.3-0.4s-1.3-0.7-1.7-1.2s-0.9-1.1-1.1-1.9
		s-0.4-1.6-0.4-2.5s0.1-1.7,0.4-2.5c0.3-0.7,0.6-1.4,1.1-1.9s1.1-0.9,1.7-1.2S1121.9,138.2,1122.7,138.2z M1122.7,148.6
		c1.1,0,2-0.4,2.6-1.2s0.8-1.8,0.8-3.2s-0.3-2.5-0.8-3.2c-0.6-0.8-1.4-1.2-2.6-1.2c-0.6,0-1.1,0.1-1.5,0.3s-0.8,0.5-1.1,0.9
		c-0.3,0.4-0.5,0.8-0.6,1.4c-0.1,0.5-0.2,1.2-0.2,1.9s0.1,1.3,0.2,1.8s0.4,1,0.6,1.4c0.3,0.4,0.6,0.7,1.1,0.9
		S1122.1,148.6,1122.7,148.6z"/>
								<path fill="#FFFFFF" d="M1138.7,140.5c-0.1,0.1-0.1,0.1-0.2,0.2s-0.1,0.1-0.3,0.1c-0.1,0-0.2,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.5-0.3
		s-0.5-0.2-0.8-0.3s-0.7-0.1-1.1-0.1c-0.6,0-1.1,0.1-1.5,0.3s-0.8,0.5-1.1,0.9s-0.5,0.8-0.7,1.4c-0.1,0.5-0.2,1.1-0.2,1.8
		s0.1,1.3,0.2,1.9c0.2,0.5,0.4,1,0.7,1.4c0.3,0.4,0.6,0.7,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3s0.9-0.1,1.2-0.2c0.3-0.1,0.6-0.2,0.8-0.4
		s0.4-0.3,0.5-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2l0.6,0.7c-0.5,0.6-1.1,1.1-1.9,1.4c-0.8,0.3-1.6,0.4-2.4,0.4
		c-0.7,0-1.4-0.1-2-0.4c-0.6-0.3-1.2-0.7-1.6-1.2c-0.5-0.5-0.8-1.1-1.1-1.9c-0.3-0.7-0.4-1.6-0.4-2.5s0.1-1.7,0.4-2.4
		c0.2-0.7,0.6-1.4,1.1-1.9s1-0.9,1.7-1.2s1.5-0.4,2.3-0.4s1.5,0.1,2.2,0.4c0.6,0.3,1.2,0.6,1.7,1.1L1138.7,140.5z"/>
								<path fill="#FFFFFF" d="M1150,150h-0.9c-0.2,0-0.4,0-0.5-0.1s-0.2-0.2-0.2-0.4l-0.2-1.1c-0.3,0.3-0.6,0.5-0.9,0.7s-0.6,0.4-0.9,0.6
		c-0.3,0.1-0.7,0.3-1,0.3c-0.4,0.1-0.8,0.1-1.2,0.1c-0.5,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1-0.6s-0.5-0.6-0.7-1s-0.3-0.8-0.3-1.4
		c0-0.5,0.1-0.9,0.4-1.3s0.7-0.8,1.2-1.1s1.3-0.6,2.2-0.8s2-0.3,3.3-0.3v-0.9c0-0.9-0.2-1.6-0.6-2.1s-1-0.7-1.7-0.7
		c-0.5,0-0.9,0.1-1.3,0.2s-0.6,0.3-0.9,0.4c-0.2,0.2-0.5,0.3-0.6,0.4s-0.4,0.2-0.5,0.2s-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.3l-0.4-0.7
		c0.6-0.6,1.3-1.1,2.1-1.4c0.7-0.3,1.6-0.5,2.5-0.5c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.3,0.9c0.3,0.4,0.6,0.8,0.8,1.4s0.3,1.1,0.3,1.8
		v7.7L1150,150L1150,150z M1144.7,148.8c0.4,0,0.7,0,1-0.1s0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.5-0.4,0.7-0.7v-2.4
		c-0.9,0-1.7,0.1-2.4,0.2s-1.2,0.3-1.6,0.5s-0.7,0.4-0.9,0.7c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.3,0,0.6,0.1,0.8s0.2,0.4,0.4,0.5
		s0.4,0.2,0.6,0.3C1144.2,148.7,1144.4,148.8,1144.7,148.8z"/>
								<path fill="#FFFFFF" d="M1156.6,150.2c-0.9,0-1.6-0.3-2.1-0.8s-0.7-1.2-0.7-2.2v-7.1h-1.4c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.3
		v-0.8l1.9-0.2l0.5-3.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h1v4h3.3v1.5h-3.3v7c0,0.5,0.1,0.9,0.4,1.1
		c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.6-0.1s0.3-0.1,0.4-0.2s0.2-0.1,0.3-0.2s0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.3,0.2l0.6,1
		c-0.4,0.3-0.8,0.6-1.3,0.8C1157.6,150.1,1157.1,150.2,1156.6,150.2z"/>
								<path fill="#FFFFFF" d="M1164.4,134.7c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5s-0.3,0.2-0.5,0.3s-0.4,0.1-0.6,0.1
		s-0.4,0-0.6-0.1s-0.3-0.2-0.5-0.3s-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6s0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.1,0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5
		C1164.3,134.4,1164.4,134.6,1164.4,134.7z M1163.9,138.4V150h-2v-11.6H1163.9z"/>
								<path fill="#FFFFFF" d="M1172.2,138.2c0.8,0,1.6,0.1,2.3,0.4s1.3,0.7,1.7,1.2s0.8,1.1,1.1,1.9c0.3,0.7,0.4,1.6,0.4,2.5
		s-0.1,1.7-0.4,2.5s-0.6,1.4-1.1,1.9s-1.1,0.9-1.7,1.2s-1.4,0.4-2.3,0.4c-0.8,0-1.6-0.1-2.3-0.4s-1.3-0.7-1.7-1.2s-0.9-1.1-1.1-1.9
		s-0.4-1.6-0.4-2.5s0.1-1.7,0.4-2.5c0.3-0.7,0.6-1.4,1.1-1.9s1.1-0.9,1.7-1.2S1171.4,138.2,1172.2,138.2z M1172.2,148.6
		c1.1,0,2-0.4,2.6-1.2s0.8-1.8,0.8-3.2s-0.3-2.5-0.8-3.2c-0.6-0.8-1.4-1.2-2.6-1.2c-0.6,0-1.1,0.1-1.5,0.3s-0.8,0.5-1.1,0.9
		c-0.3,0.4-0.5,0.8-0.6,1.4c-0.1,0.5-0.2,1.2-0.2,1.9s0.1,1.3,0.2,1.8s0.4,1,0.6,1.4c0.3,0.4,0.6,0.7,1.1,0.9
		S1171.6,148.6,1172.2,148.6z"/>
								<path fill="#FFFFFF" d="M1180.2,150v-11.6h1.2c0.3,0,0.5,0.1,0.5,0.4l0.2,1.3c0.5-0.6,1.1-1,1.7-1.4c0.6-0.3,1.3-0.5,2.2-0.5
		c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.7,1.4s0.3,1.1,0.3,1.8v7.4h-2v-7.4c0-0.9-0.2-1.6-0.6-2
		c-0.4-0.5-1-0.7-1.8-0.7c-0.6,0-1.2,0.1-1.7,0.4s-1,0.7-1.4,1.2v8.5L1180.2,150L1180.2,150z"/>
							</g>
							<a href='/locations/6'>
								<text transform="matrix(1 0 0 1 1021 329)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Prattville</text>
							</a>
							<a href="/locations/4">
								<text transform="matrix(1 0 0 1 1011 371)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Montgomery</text>
							</a>
							<a href="/locations/4">
								<text transform="matrix(1 0 0 1 1011 392.6)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">(Fairview)</text>
							</a>
							<a href="/locations/2">
								<text transform="matrix(1 0 0 1 1119 287)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Millbrook</text>
							</a>
							<a href="/locations/5">
								<text transform="matrix(1 0 0 1 1188 332)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Phenix</text>
							</a>
							<a href="/locations/5">
								<text transform="matrix(1 0 0 1 1188 353.6)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">City</text>
							</a>
							<a href="/locations/3">
								<text transform="matrix(1 0 0 1 1155 388)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Montgomery</text>
							</a>
							<a href="locations/3">
								<text transform="matrix(1 0 0 1 1155 409.6)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">(Dalraida)</text>
							</a>
							{/* <a href="/locations/1">
								<text transform="matrix(1 0 0 1 1011 460)" fill="#03436C" font-family="'Europa-Bold'" font-size="18px" text-decoration=" underline">Greenville</text>
							</a> */}
						</svg>
						{/* <div className="col-md-2 millbrook ">
              <a href="/locations/2" className="locationLink">Millbrook</a>

            </div>


            <div className="col-md-2  prattville">
              <a href="/locations/6" className="locationLink">Prattville</a>

            </div>


            <div className="col-md-2 phenix">
              <a href="/locations/5" className="locationLink">Phenix</a>

            </div>


            <div className="col-md-2 fairview">
              <a href="/locations/4" className="locationLink">Montgomery (Fairview)</a>
            </div>


            <div className="col-md-2 dalraida">
              <a href="/locations/3" className="locationLink">Montgomery (Dalraida)</a>

            </div>


            <div className="col-md-2 greenville">
              <a href="/locations/1" className="locationLink">Greenville</a>
            </div> */}

					</div>
				</div>
			</div>
		</>
	);
}

export default MapComponent;