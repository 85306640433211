// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// const PromotionComponent = () => {

//   const [welcome, setWelcome] = useState(null);

//   useEffect(() => {
//     fetch(`http://localhost:1337/api/homepage-promotion-text`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);
//   console.log(welcome)

//   const [promotions, setPromotions] = useState([]);

//   useEffect(() => {
//     fetch(`http://localhost:1337/api/locations?populate=*`)
//       .then((res) => res.json())
//       .then((locations) => {
//         const allPromotions = locations.data.flatMap(location => location.attributes.promotions);
//         setPromotions
//           (allPromotions);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);

//   return (
//     <div className="container">
//       <div>{welcome && welcome.data.attributes ?
//                         <p>{welcome.data.attributes.homepagePromotionTitle}</p> :
//                         <p>Loading...</p>}
//                       </div>
//       <div id="carouselPromotions" className="carousel slide">
//         <div className="carousel-inner">
//           {promotions.map((promotion, index) => (
//             <div key={promotion.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
//               <div className="card mb-3">
//                 <div className="row g-0">
//                   <div className="col-md-6">
//                     <img className="img-fluid" src={promotion.promotionImageLink} alt="..." />
//                   </div>
//                   <div className="col-md-6">
//                     <div className="card-body">
//                       <h5 className="card-title">{promotion.promotionTitle}</h5>
//                       <p className="card-text">{promotion.promotionDescription}</p>
//                       <Link to={`/promotions/${promotion.id}`} className="btn btn-primary">Open</Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <button className="carousel-control-prev" type="button" data-bs-target="#carouselPromotions" data-bs-slide="prev">
//           <span className="carousel-control-prev-icon" ></span>
//           <span >Previous</span>
//         </button>
//         <button className="carousel-control-next" type="button" data-bs-target="#carouselPromotions" data-bs-slide="next">
//           <span className="carousel-control-next-icon" ></span>
//           <span >Next</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default PromotionComponent;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as EmptySign } from '../public/images/empty_sign.svg';
import { ReactComponent as AttentionSign } from '../public/images/attention.svg';
import { config } from '../components/Constants'
const URL = config.url;


const PromotionComponent = () => {
  const [welcome, setWelcome] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/homepage-promotion-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  const [promotions, setPromotions] = useState([]);

  // useEffect(() => {
  //   fetch(`${URL}/api/locations?populate=*`)
  //     .then((res) => res.json())
  //     .then((locations) => {
  //       const allPromotions = locations.data.flatMap(location => location.attributes.promotions);

  //       // Filter duplicates
  //       const uniquePromotions = allPromotions.reduce((acc, current) => {
  //         const x = acc.find(item => item.promotionTitle === current.promotionTitle);
  //         if (!x) {
  //           return acc.concat([current]);
  //         } else {
  //           return acc;
  //         }
  //       }, []);

  //       setPromotions(uniquePromotions);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, []);
  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        const allPromotions = locations.data.flatMap(location => location.attributes.promotions);
  
        // Filter duplicates
        const uniquePromotions = allPromotions.reduce((acc, current) => {
          const x = acc.find(item => item.promotionTitle === current.promotionTitle);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        
        // Sort promotions by newest (using id for ordering)
        const sortedPromotions = uniquePromotions.sort((a, b) => b.id - a.id);
  
        setPromotions(sortedPromotions);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);  
  return (
    <div className="container d-none d-sm-block	d-sm-none d-md-block promoComponent">
      <div id="carouselPromotions" className="carousel slide ">
        <div className="carousel-inner">
          {promotions.map((promotion, index) => (
            <div key={promotion.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <div className="card promo promo-mobile">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card-body text-center">
                      <AttentionSign className="attentionSign mb-4" />
                      <h5 className="card-title text-uppercase promoTitle mb-4">{promotion.promotionTitle}</h5>
                      <p className="card-text text-capitalize mb-4 promotionText">{promotion.homepagePromotionBlurb}</p>
                      <a href={`/promotions`} className="btn learnHow">learn how
                      </a>
                    </div>
                  </div>
                  <div className="col-md-9 text-center emptySignDiv emptySignDiv-mobile">
                    <img src={promotion.promotionImageLink} alt="promo add that scrolls" className="img-fluid promoImg-mobile" />

                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
        <div classname="row">
          <div classname="col-sm-6">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselPromotions" data-bs-slide="prev">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
              </svg>
            </button>
          </div>
          
          <div classname="col-sm-6">
            <button className="carousel-control-next" type="button" data-bs-target="#carouselPromotions" data-bs-slide="next">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};


export default PromotionComponent;
