import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { ReactComponent as CareersSign } from '../public/images/careers_sign.svg';
import { ReactComponent as GreyFields } from '../public/images/grey_field.svg'
import { config } from '../components/Constants'
import axios from 'axios';
const URL = config.url;
//const URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:1337';

const Careers = () => {

  const [welcome, setWelcome] = useState();


  useEffect(() => {
    fetch(`${URL}/api/careers-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);



  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    resume: '',
  });

  const [submitted, setSubmitted] = useState()

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormState({ ...formState, resume: e.target.files[0] });
  };
  console.log(formState)


  const uploadFile = async (file) => {
    const data = new FormData();
    data.append('files', file);
    const response = await fetch(`${URL}/api/upload`, {
      method: 'POST',
      body: data,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedFile;
    if (formState.resume) {
      uploadedFile = await uploadFile(formState.resume);
    }
    
    console.log(uploadedFile[0].url)
    console.log(formState)
    const data = {
      name: formState.name,
      phone: formState.phone,
      email: formState.email,
      message: formState.message,
      resume: uploadedFile[0] // using uploadedFile[0].id here because Strapi responds with an array of uploaded files
    };

    axios({
      method: 'post',
      url: `${URL}/api/career-forms?populate=*`,
      data: {
        data: data
      },
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`
      }
    })
       .then(console.log(data.resume))
      .then(setSubmitted(true))
      .catch(function (error) {
        if (error.response) {
          console.log('data error', error.response.data)
          console.log('status error', error.response.status)
          console.log('headers error', error.response.headers)
        } else if (error.request) {
          console.log('request error', error.request)
        } else {
          console.log('Error message', error.message)
        }
        console.log('config error', error.config)
      })

  }
  if (submitted) {
    return <div>Thanks! We&apos;ll be in touch shortly.</div>
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <CareersSign className="aboutSign " />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="mainTitle text-uppercase mt-5">{welcome.data.attributes.careersTitle}</p> :
              <p>Loading...</p>}
            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="contactText">{welcome.data.attributes.careersText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <GreyFields className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="careersBackground">
        <div className="container">

          <div className="contact-form">
            <form className="careers" onSubmit={handleSubmit}>
              <div className="row g-5 mb-5">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    value={formState.name}
                    onChange={handleChange}
                    className="form-control inputStyling"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="email"
                    value={formState.email}
                    onChange={handleChange}
                    className="form-control inputStyling"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>

              <div className="row g-5 mb-0 mb-lg-5 mb-3">
                <div className="col-md-6">
                  <input
                    type="tel"
                    name="phone"
                    value={formState.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    className="form-control inputStyling"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="file"
                    name="resume"
                    onChange={handleFileChange}

                    accept=".pdf,.doc,.docx"

                    className="form-control inputStyling"
                  />
                  <div>
                    <p>Add .pdf, .doc, or .docx</p>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-md-12 mb-5">
                  <textarea
                    name="message"
                    value={formState.message}
                    onChange={handleChange}
                    cols="150"
                    placeholder="Message"
                    className="form-control inputStyling"
                  ></textarea>
                </div>
              </div>

              <div className="row">

                <div className='col-md-12 text-center'>
                  <button type="submit" className="btn btn-danger inputStyling px-5 mb-5">Submit</button>

                </div>
              </div>
            </form>

          </div>

        </div>

      </div>

    </>
  );
};

export default Careers;