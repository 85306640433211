import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Services from "./Services/Services";
import ServiceInfo from "./ServiceInfo/ServiceInfo";
import About from "./About/About";
import Personnel from "./Personnel/Personnel";
import StaffInfo from "./StaffInfo/StaffInfo";
import "./App.css";
import Careers from "./Careers/Careers";
import History from "./History/History";
import Experience from "./Experience/Experience";
import PrescriptionRefill from "./PrescriptionRefill/PrescriptionRefill";
import PrescriptionTransfer from "./PrescriptionRefill/PrescriptionTransfer";
import PrescriptionRefillInfo from "./PrescriptionRefillInfo/PrescriptionRefillInfo";
import Promotions from "./Promotions/Promotions";
import PromotionInfo from "./PromotionInfo/PromotionInfo";
import Gallery from "./Gallery/Gallery";
import PrescriptionRefillList from "./PrescriptionRefillList/PrescriptionRefillList";
import LocationsList from "./LocationsList/LocationsList";
import Location from "./Location/Location";

// import Axios from 'axios';
// import ReCAPTCHA from 'react-google-recaptcha';
// import { useState } from "react";

const App = () => {
//   const [SuccessMsg, setSuccessMsg] = useState("")
//   const [ErrorMsg, setErrorMsg] = useState("")
//   const [valid_token, setValidToken] = useState([]);
// const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
// const SECRET_KEY = process.env.REACT_APP_reCAPTCHA_SECRET_KEY;

  
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/1" element={<PrescriptionRefillList />} />
          <Route path="/services/:id" element={<ServiceInfo />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/experience" element={<Experience />} />
          <Route path="/locations" element={<LocationsList />} />
          <Route path="/locations/:id" element={<Location />} />
          <Route path="/about/history" element={<History />} />
          <Route path="/about/personnel" element={<Personnel />} />
          <Route path="/about/personnel/:id" element={<StaffInfo />} />
          <Route path="/prescription-refill" element={<PrescriptionRefill />} />
          <Route path="/prescription-transfer" element={<PrescriptionTransfer />} />
          <Route
            path="/prescription-refill/:id"
            element={<PrescriptionRefillInfo />}
          />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/promotions/:id" element={<PromotionInfo />} />
          <Route path="/gallery/" element={<Gallery />} />
          <Route path="*" element={<h1>404 Page Not Found</h1>} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;