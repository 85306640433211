import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import prescriptionRefillData from '../PrescriptionRefill/PrescriptionRefillData';
import { ReactComponent as ServicesSign } from '../public/images/services_sign_grey.svg';
import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
import { ReactComponent as ServicesSinglePlank } from '../public/images/aboutSinglePlank.svg'
import { ReactComponent as RxIcon } from '../public/images/rxIcon.svg'
import { config } from '../components/Constants'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
const URL = config.url;


const PrescriptionRefillList = () => {
  const [prescriptionRefillDataState] = useState(prescriptionRefillData);
  const [welcome, setWelcome] = useState();
  const [service, setService] = useState();

  useEffect(() => {
    fetch(`${URL}/api/services-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/services/1`)
      .then((res) => res.json())
      .then((response) => {
        // Now we assume that the response object has a similar structure to the one in your Services component
        setService(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(service)

  // SERVICES PRESCRIPTION PLANK
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <ServicesSign className="serviceSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="mainTitle text-uppercase mt-5">{welcome.data.attributes.servicesTitle}</p> :
              <p>Loading...</p>}
            {welcome && welcome.data.attributes ?
              <p>{welcome.data.attributes.servicesText}</p> :
              <p>Loading...</p>}
            <Link to={`/prescription-refill`} className="text-uppercase refillLink pe-3" target="_blank">
              <p className="btn btn-danger">quick refill</p>
            </Link>
            <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
              <p className="btn btn-danger">transfer</p>
            </Link>
          </div>
        </div>
      </div>
      <FenceBackground className="d-none d-sm-block d-sm-none d-md-block" />
      <div className="refillBackground ">
        <div className="container servicesWrapper">
          <ServicesSinglePlank className="bioPlank d-none d-sm-block d-sm-none d-md-block script-refill-plank" />
          <div className="bioContent prescriptionRefillData ps-lg-5 script-refill-content ">
            <div className="row pt-lg-5">
              <div className='col-md-12 text-end pe-3  pt-5'>
                <Link to={`/services`} className='text-uppercase close refill-close'>x</Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <RxIcon className="py-5 text-center rxIcon" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {service && service.data.attributes ?
                  <h4 className="text-uppercase text-start singleServiceTitle">{service.data.attributes.serviceTitle}</h4> : null}

              </div>
              <div className="col-md-12">
                {service && service.data.attributes ?
                  <ReactMarkdown className="singleServiceText text-start py-lg-5">{service.data.attributes.serviceDescription}</ReactMarkdown> : null}
              </div>

            </div>
            <div className="row">
              <div className="col-md-6">

                <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
                  <p className="btn btn-danger">quick refill</p>
                </Link>
              </div>
              <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
                <p className="btn btn-danger">transfer</p>
              </Link>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <h4 className="text-uppercase text-start singleServiceTitle">choose a location for refills/transfers</h4>
              </div>
            </div>
            <div className="row">
              {prescriptionRefillDataState.map((item) => (
                <div key={item.id} className="col-sm-6 col-md-4 py-lg-5">
                  <Link to={`/prescription-refill/${item.id}`} className="servicesRefillLinks">{item.name}</Link>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionRefillList;
