import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { config } from '../components/Constants'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutPlanks } from '../public/images/AboutPageLinks.svg'

const URL = config.url;

const About = () => {
  const [welcome, setWelcome] = useState('');

  useEffect(() => {
    fetch(`${URL}/api/about-text`)

      .then((res) => res.json())
      .then((res) => JSON.stringify(res))
      .then((res) => JSON.parse(res))
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign" />
          </div>
          <div className='col-md-6 mt-5'>

            <div className="mainTitle text-uppercase mt-5">{welcome && welcome.data.attributes ?
              <ReactMarkdown>{welcome.data.attributes.aboutTitle}</ReactMarkdown> : null}</div>

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="aboutBackground mt-3 mt-lg-0">

        <div className="container pt-3 pt-lg-0">
          <AboutPlanks className="aboutPlanks img-fluid d-none d-md-block" />
          <div className="aboutPlanksContent d-md-none">

            {/* <div className="row aboutRow first-about-row">
              <div className=" col-md-10 forOpenBtns text-lg-end">
                <span className="test-center text-uppercase" >history</span><Link to="/about/history" className="d-none d-md-inline-block openBtnAbout firstOpen">open</Link>
                <span className="d-md-none">
                  <Link to="/about/history" className="text-decoration-none">open</Link>
                </span>
              </div>
            </div>

            <div className="row  aboutRow">
              <div className="col-md-10  forOpenBtns text-lg-end">
                <span className="text-center text-uppercase">experience</span>
                <Link to="/about/experience" className="d-none d-md-inline-block openBtnAbout">open</Link>
                <span className="d-md-none">
                  <Link to="/about/experience" className="text-decoration-none">open</Link>
                </span>
              </div>
            </div>

            <div className="row  aboutRow">
              <div className="col-md-10  forOpenBtns text-lg-end">
                <span className="text-center text-uppercase">personnel</span>
                <Link to="/about/personnel" className="d-none d-md-inline-block openBtnAbout">open</Link>
                <span className="d-md-none">
                  <Link to="/about/personnel" className="text-decoration-none">open</Link>
                </span>
              </div>
            </div>

            <div className="row  aboutRow">
              <div className="col-md-10  forOpenBtns text-lg-end">
                <span className="text-start text-center text-uppercase">personnel</span>
                <Link to="/locations" className="d-none d-md-inline-block openBtnAbout">open</Link>
                <span className="d-md-none">
                  <Link to="/about/locations" className="text-decoration-none">open</Link>
                </span>
              </div>
            </div>

            <div className="row  aboutRow">
              <div className="col-md-10  forOpenBtns text-lg-end">
                <span className="text-center text-uppercase">galllery</span>
                <Link to="/gallery" className="d-none d-md-inline-block openBtnAbout">open</Link>
                <span className="d-md-none">
                  <Link to="/gallery" className="text-decoration-none">open</Link>
                </span>
              </div>
            </div> */}
            <div className="row mx-1 aboutRow first-about-row">
              <div className="col-md-10 d-flex justify-content-center justify-content-md-end forOpenBtns text-lg-end">
                {/* The title span is always visible and clickable on mobile */}
                <span className="text-uppercase d-md-none">
                  <Link to="/about/history" className="text-decoration-none about-mobile-links">history</Link>
                </span>

                {/* On larger screens, show the title without the link */}
                <span className="text-uppercase d-none d-md-inline">history</span>

                {/* The button is only visible on screens 990px and above */}
                <Link to="/about/history" className="openBtnAbout firstOpen d-none d-md-inline-block">open</Link>
              </div>
            </div>

            <div className="row mx-1 aboutRow">
              <div className="col-md-10 d-flex justify-content-center justify-content-md-end forOpenBtns text-lg-end">
                <span className="text-uppercase d-md-none">
                  <Link to="/about/experience" className="text-decoration-none about-mobile-links">experience</Link>
                </span>
                <span className="text-uppercase d-none d-md-inline">experience</span>
                <Link to="/about/experience" className="openBtnAbout d-none d-md-inline-block">open</Link>
              </div>
            </div>

            <div className="row mx-1 aboutRow">
              <div className="col-md-10 d-flex justify-content-center justify-content-md-end forOpenBtns text-lg-end">
                <span className="text-uppercase d-md-none">
                  <Link to="/about/personnel" className="text-decoration-none about-mobile-links">personnel</Link>
                </span>
                <span className="text-uppercase d-none d-md-inline">personnel</span>
                <Link to="/about/personnel" className="openBtnAbout d-none d-md-inline-block">open</Link>
              </div>
            </div>

            <div className="row mx-1 aboutRow">
              <div className="col-md-10 d-flex justify-content-center justify-content-md-end forOpenBtns text-lg-end">
                <span className="text-uppercase d-md-none">
                  <Link to="/locations" className="text-decoration-none about-mobile-links">locations</Link>
                </span>
                <span className="text-uppercase d-none d-md-inline">personnel</span>
                <Link to="/locations" className="openBtnAbout d-none d-md-inline-block">open</Link>
              </div>
            </div>

            <div className="row mx-1 aboutRow">
              <div className="col-md-10 d-flex justify-content-center justify-content-md-end forOpenBtns text-lg-end">
                <span className="text-uppercase d-md-none">
                  <Link to="/gallery" className="text-decoration-none about-mobile-links">gallery</Link>
                </span>
                <span className="text-uppercase d-none d-md-inline">gallery</span>
                <Link to="/gallery" className="openBtnAbout d-none d-md-inline-block">open</Link>
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  );
};

export default About;


