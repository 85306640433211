import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as AboutSinglePlank } from '../public/images/experiencePlank.svg'
import { config } from '../components/Constants'

const URL = config.url;

const Experience = () => {
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/experience`)

      .then((res) => res.json())
      .then((experienceData) => {
        setExperience(experienceData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
// a comment to push
  const [welcome, setWelcome] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/about-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign" />
          </div>
          <div className='col-md-6 mt-5 mb-3 mb-md-0'>
            {welcome && welcome.data.attributes ?
              <h3 className="historyTitle text-uppercase mt-5">{welcome.data.attributes.aboutTitle}</h3> : null}

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="experienceBackground">
        <AboutSinglePlank className="experiencePlank d-none d-sm-block d-sm-none d-md-block" />
        <div className="container">
          <div className="experienceContent">
            <div className="row pt-0 pt-md-5">
              <div className='col-md-9 text-end py-0 py-lg-5'>
                <Link to={`/about`} className='close text-uppercase expCloseBtn'>X</Link>
              </div>
            </div>
            <div className="row pt-0 pt-md-5">
              <div className='col-md-9 expTitleDiv d-xs-block d-sm-block d-md-none'>
                <h3 className="expTitle text-uppercase">experience/advantage</h3>
              </div>
            </div>
            <div className="row py-0 py-md-5 my-0 my-md-5">
              <div className="singleAboutText col-md-12 py-0 py-md-5">
                {experience && experience.data.attributes ?
                  <ReactMarkdown className="experienceDesc py-0 py-md-5 my-0 my-md-5">{experience.data.attributes.experienceDescription}</ReactMarkdown> :
                  <p>Loading...</p>}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
