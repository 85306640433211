
import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { useParams, Link } from "react-router-dom";
import { config } from '../components/Constants'
const URL = config.url;


const PromotionInfo = () => {
  const [promotion, setPromotion] = useState(null);
  const { id } = useParams();
  const [welcome, setWelcome] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/promotions-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        // Flatten all promotions from all locations into one array
        const allPromotions = locations.data.flatMap(location => location.attributes.promotions);

        // Find the selected promotion by id
        const selectedPromotion = allPromotions.find(promo => promo.id === +id);

        // Set the found promotion
        setPromotion(selectedPromotion);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [id]);
  console.log(welcome)


  if (!promotion) {
    return (
      <div className="container">
        <div className="welcome-box">
          <h2 className="home-icon">Welcome</h2>
          <h3>Our Promise</h3>
          <div>{welcome && welcome.data.attributes ?
            <p>{welcome.data.attributes.promotionsText}</p> :
            <p>Loading...</p>}
          </div>
        </div>
        <div className="card mb-3">
          <div className="row g-0">
            <div className="col-md-6">
              <img src="https://placehold.co/400x200" className="d-block w-20" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h5 className="card-title">""</h5>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (

    <div className="container">
      <div className="welcome-box">
        <h2 className="home-icon">Welcome</h2>
        <h3>Our Promise</h3>
        <div>{welcome && welcome.data.attributes ?
          <p>{welcome.data.attributes.promotionsText}</p> :
          <p>Loading...</p>}
        </div>
      </div>
      <div className="card mb-3">
        <div className="row g-0">
          <div className="col-md-6">
          <img src={promotion.promotionImageLink} className="img-fluid d-block w-20" alt="..." />
          </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">{promotion.promotionTitle}</h5>
              <p className="card-text">{promotion.promotionDescription}</p>
              <Link to={`/promotions`}>Close</Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PromotionInfo;