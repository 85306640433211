import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Link, useParams } from "react-router-dom";
import { ReactComponent as LocationsSinglePlank } from '../public/images/locationSinglePlank.svg';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg';
import { ReactComponent as LocationsSign } from '../public/images/locations_sign.svg';
import { ReactComponent as LeftQuote } from '../public/images/quote_left.svg';
import { ReactComponent as RightQuote } from '../public/images/quote_right.svg';
import { config } from '../components/Constants'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const URL = config.url;
// to push

const Location = () => {
    const [location, setLocation] = useState(null);
    const { id } = useParams();
    const [welcome, setWelcome] = useState(null);

    useEffect(() => {
        fetch(`${URL}/api/locations-text`)
            .then((res) => res.json())
            .then((welcomeData) => {
                setWelcome(welcomeData);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);
    console.log(welcome)

    useEffect(() => {
        fetch(`${URL}/api/locations/${id}?populate=*`)
            .then((res) => res.json())
            .then((data) => {
                setLocation(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, [id]);
    console.log(location);
    // const [showBioModal, setShowBioModal] = useState(false);

    if (!location) {
        return <div></div>;
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-md-3'>
                        {/* <img src={AboutSign} alt="" className="serviceSign d-none d-sm-block d-sm-none d-md-block"  /> */}
                        <LocationsSign className="locationsSign" />
                    </div>
                    <div className='col-md-6 mt-5'>
                        {welcome && welcome.data.attributes ?
                            <div>
                                <p className="mainTitle">{welcome.data.attributes.locationsPageTitle}</p>
                                <ReactMarkdown className="singleAboutText">{welcome.data.attributes.locationsPageDescription}</ReactMarkdown>
                            </div> :
                            <p>Loading...</p>}
                    </div>
                </div>
            </div>
            <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

            <div className="locationsBackground pb-5">
                <div className="container ">
                    {/* <LocationsSinglePlank className="locationsSinglePlank d-none d-sm-block d-sm-none " /> */}
                    <div className="locationsContent mt-3 mt-md-0">
                        <div className="row ">
                            <div className='col-md-11 text-end py-lg-5 '>
                                <Link to={`/locations`} className='text-uppercase close location-close'>X</Link>
                            </div>
                        </div>
                        <div key={location.id}>
                            <div className="row">
                                <div className="col-md-12 text-center my-5">
                                    <h2 className="text-uppercase aboutTitles">{location.data.attributes.locationName}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 text-center">
                                    <div className='row'>
                                        {location.data.attributes.people.map((person, personIndex) => (
                                            <div className="locationPersonnel" key={person.id} >
                                                <div className="text-center">
                                                    <img src={person.personImageLink} className="locationManager" alt={person.personName} />
                                                    <h5 className="locationsSingleTitles">{person.personName}</h5>
                                                    <p className="locationsSingleTitles">{person.personRole}</p>
                                                    <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target={`#staticBackdrop${person.id}`}>
                                                        BIO
                                                    </button>
                                                     <div class="modal fade" id={`staticBackdrop${person.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content modalBioContent">
                                                                {person ? (
                                                                    <div className="row">
                                                                        <img src={person.personImageLink} className="managerModal" alt={person.personName} />
                                                                        <h5 className="modalBioTitles text-center">{person.personName}</h5>
                                                                        <p className="modalBioTitles text-center">{person.personRole}</p>
                                                                        {person.personQuote && <p className="modalBioTitles text-start" >Quote: <span className="bioData">{person.personQuote}</span></p>}
                                                                        {person.personEducation && <p className="modalBioTitles text-start">Education: <span className="bioData">{person.personEducation}</span></p>}
                                                                        {person.personYOE && <p className="modalBioTitles text-start">Years of Experience: <span className="bioData">{person.personYOE}</span></p>}
                                                                        {person.personLicenses && <p className="modalBioTitles text-start">Licenses: <span className="bioData">{person.personLicenses}</span> </p>}
                                                                        {person.personAssoc && <p className="modalBioTitles text-start">Member Associations/Organizations: <span className="bioData">{person.personAssoc}</span></p>}
                                                                        {person.personAwards && <p className="modalBioTitles text-start">Awards/Recognitions: <span className="bioData">{person.personAwards}</span></p>}
                                                                        {person.personVolunteer && <p className="modalBioTitles text-start">Local Volunteerism: <span className="bioData">{person.personVolunteer}</span></p>}
                                                                        {person.personBio && <p className="modalBioTitles text-start">About: <span className="bioData">{person.personBio}</span></p>}

                                                                    </div>

                                                                ) : (
                                                                    <div>Loading...</div>
                                                                )}

                                                                <div class="modal-footer">
                                                                    <button type="button" className="btn closeModal" data-bs-dismiss="modal">Close</button>
                                                                </div>
                                                            </div>
                                                        </div> 

                                                                </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-5 text-center text-md-start">

                                    <Link to={`/prescription-refill`} className="text-uppercase refillLink d-block mx-auto" target="_blank">
                                        <p className="btn btn-danger">quick refill</p>
                                    </Link>
                                    <Link to={`/prescription-refill`} className="text-uppercase refillLink d-block mx-auto" target="_blank">
                                        <p className="btn btn-danger">transfer</p>
                                    </Link>
                                    <p className="text-uppercase locationTitle">address</p>
                                    <ReactMarkdown className="text-capitalize locationDetails addressHours">{location.data.attributes.locationAddress}</ReactMarkdown>



                                    <div className="row">
                                        <p className="text-uppercase locationTitle">phone</p>
                                        <p className="text-capitalize locationDetails ">{location.data.attributes.locationPhone}</p>
                                    </div>

                                    <div className="row">
                                        <p className="text-uppercase mainTitle">operating hours</p>
                                        <ReactMarkdown className="locationDetails addressHours">{location.data.attributes.operatingHours}</ReactMarkdown>
                                    </div>

                                    {/* <div className="row">
                                        <p className="text-uppercase locationTitle">email</p>
                                        <p className="text-capitalize locationDetails ">{location.data.attributes.locationEmail}</p>
                                    </div> */}
                                </div>
                            </div>

                            <div className="row my-5">
                                <p className="text-md-start ms-md-5 text-center text-uppercase mainTitle">{location.data.attributes.locationName} promotions</p>
                                <div id={`carouselPromotions${location.id}`} className="carousel slide col-md-12 ">
                                    <div className="carousel-inner">
                                        {location.data.attributes.promotions.sort((a, b) => b.id - a.id).map((promotion, promoIndex) => (
                                            <Link to={promotion.promotionURL} key={promotion.id}>
                                                <div className={`carousel-item ${promoIndex === 0 ? 'active' : ''}`}>
                                                    <div className="locationPromo">
                                                        <div className="card mb-3 text-center">
                                                            <img src={promotion.promotionImageLink} className="img-fluid card-img-top" alt="promo" />
                                                            <div className="card-body locationPromoCardBody">
                                                                <h5 className="card-title promoPageLittleTitle text-center">{promotion.promotionTitle}</h5>
                                                                <ReactMarkdown className="card-text text-start">{promotion.promotionDescription}</ReactMarkdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev ms-3 ms-md-5" type="button" data-bs-target={`#carouselPromotions${location.id}`} data-bs-slide="prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                        </svg>
                                    </button>
                                    <button className="carousel-control-next me-3 me-md-5" type="button" data-bs-target={`#carouselPromotions${location.id}`} data-bs-slide="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div className="row my-5 pt-5">
                                <p className=" text-uppercase text-center text-md-start ms-md-5 mainTitle">testimonials</p>
                                <div id={`carouselTestimonials${location.id}`} className="carousel slide">
                                    <div className="carousel-inner">
                                        {[...location.data.attributes.testimonials].reverse().map((testimonial, testIndex) => (
                                            <div key={testimonial.id} className={`carousel-item ${testIndex === 0 ? 'active' : ''}`}>
                                                <div className="row mb-5">
                                                    <div className="col-md-3">
                                                        <LeftQuote className="leftQuote" />
                                                    </div>
                                                    <div className="col-md-5 text-center">
                                                        <p className="testimonialText">{testimonial.testimonialText}</p>
                                                    </div>
                                                    <div className="col-md-3 text-end">
                                                        <RightQuote className="rightQuote" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-9 text-end">
                                                        <p className="testimonialAuthor text-uppercase">{testimonial.testimonialAuthor}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev ms-3 ms-md-5" type="button" data-bs-target={`#carouselTestimonials${location.id}`} data-bs-slide="prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                        </svg>
                                    </button>
                                    <button className="carousel-control-next me-3 me-md-5" type="button" data-bs-target={`#carouselTestimonials${location.id}`} data-bs-slide="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className='row my-5 pt-5'>
                                <div className="col-md-12">
                                    <p className="mainTitle text-uppercase text-center text-md-start ms-md-5 ">gallery</p>
                                    {location.data.attributes.galleries && (
                                        <Carousel
                                            className="carousel slide text-center"
                                            showArrows={true}
                                            infiniteLoop={true}
                                            showThumbs={false}
                                            showStatus={false}
                                            showIndicators={false}
                                            swipeable={true}
                                        // renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        //     hasPrev && (
                                        //         <button type="button" onClick={onClickHandler} title={label} className="carousel-button carousel-button-prev">
                                        //             Prev
                                        //         </button>
                                        //     )
                                        // }
                                        // renderArrowNext={(onClickHandler, hasNext, label) =>
                                        //     hasNext && (
                                        //         <button type="button" onClick={onClickHandler} title={label} className="carousel-button carousel-button-next">
                                        //             Next
                                        //         </button>
                                        //     )
                                        // }
                                        >
                                            {Array(Math.ceil(location.data.attributes.galleries.length / 4)).fill().map((_, index) => {
                                                const galleries = location.data.attributes.galleries.slice(index * 4, (index + 1) * 4);
                                                return (
                                                    <div key={index} className="carousel-slide gallery">
                                                        {galleries.map((gallery) => (
                                                            <div key={gallery.id} className="col-md-3 mb-3 text-center galleryCard">
                                                                <img src={gallery.galleryImageOrVideoLink} className="img-fluid carousel-image galImage" alt="gallery" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            })}
                                        </Carousel>
                                    )}
                                </div>
                            </div>


                            <div className="map-component row">
                                <p className="mainTitle text-center text-md-start ms-md-5  text-uppercase">Directions</p>
                                <div className="col-md-12 text-center">

                                    <iframe class="img-responsive img-fluid" src={location.data.attributes.mapLink} width="600" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Location;
