import React, { useState, useEffect } from "react";
import { ReactComponent as NewsSign } from '../public/images/news_sign.svg';
import { ReactComponent as RedReadMore } from '../public/images/red_read_more.svg';
import { config } from '../components/Constants'
import axios from "axios";
const URL = config.url;


const NewsComponent = () => {
  const [newsStories, setNewsStories] = useState([]);
  const [email, setEmail] = useState("");
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [submitted, setSubmitted] = useState()

  useEffect(() => {
    fetch(`${URL}/api/news-stories?populate=*`)
      .then((res) => res.json())
      .then((newsData) => {
        // Sort the stories by the 'createdAt' attribute in descending order
        const sortedNews = newsData.data.sort((a, b) =>
          new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
        );
        setNewsStories(sortedNews);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(newsStories)

  const handleSubmit = async (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: `${URL}/api/email-sign-ups`,  
      data: {
        data: { email }
      },
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`
      }
    })
      .then(setSubmitted(true))
      .catch(function (error) {
        if (error.response) {
          console.log('data error', error.response.data)
          console.log('status error', error.response.status)
          console.log('headers error', error.response.headers)
        } else if (error.request) {
          console.log('request error', error.request)
        } else {
          console.log('Error message', error.message)
        }
        console.log('config error', error.config)
      })

  }
  if (submitted) {
    return <div>Thanks! We&apos;ll be in touch shortly.</div>
  }


  const handlePrev = () => {
    setCurrentStoryIndex(
      (currentStoryIndex - 1 + newsStories.length) % newsStories.length
    );
  };

  const handleNext = () => {
    setCurrentStoryIndex((currentStoryIndex + 1) % newsStories.length);
  };

  return (
    <>
      <div className="container-fluid newsComponent">
        <div className='row mb-5 pb-5 mt-5 news-row'>
          <div className='col-md-3'>
            <NewsSign className="newsSign " />

          </div>

          <div className="col-md-2 mt-5 ">
            <div className="email-signup">
              <p className='signUp'>sign-up</p>
              <p className="today">today</p>
              <p>to receive notifications</p>
            </div>
          </div>
          <div className="col-md-6 newsForm mt-5">
            <form onSubmit={handleSubmit} >
              <input
                className="me-5 form-control formInput d-inline"
                cols="15"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
                required
              />
              <button className="btn btn-danger px-5 text-end" type="submit">
                submit
              </button>
            </form>

          </div>

        </div>
      </div>

      <div className="container d-none d-sm-block d-sm-none d-md-block">
        {newsStories.length > 0 ? (
          <div className="row">
            <div className="col-md-3 d-inline">
              <img src={newsStories[currentStoryIndex].attributes.newsImageLink} alt="News" className="img-fluid newsImg" />
            </div>
            <div className="col-md-9 d-inline newsCardDiv">
              <p className="text-uppercase newsTitle1">what's now</p>
              <p className="text-uppercase newsTitle2">{newsStories[currentStoryIndex].attributes.newsTitle}</p>
              <p className="newsText">{newsStories[currentStoryIndex].attributes.newsDescription}</p>
              <div className="text-end">
                <a href={newsStories[currentStoryIndex].attributes.newsURL} target="_blank" rel="noopener noreferrer">
                  <RedReadMore className="redReadMore" />
                </a>

              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">

                <p onClick={handlePrev}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                  </svg>
                </p>
              </div>
              <div className='col-md-6 text-start'>
                <p onClick={handleNext}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                  </svg>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )
        }
      </div>
    </>
  );
};

export default NewsComponent;