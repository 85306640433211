import React from 'react';
import ReactMarkdown from 'react-markdown'
import { ReactComponent as RefillMap } from '../public/images/refillMap.svg';
import '../public/styles/style.css';
import { useState, useEffect } from 'react';
import {ReactComponent as RefillSign} from '../public/images/refill_sign.svg';
import { config } from '../components/Constants'
const URL = config.url;


const PrescriptionRefill = () => {
  const [welcome, setWelcome] = useState([]);

  useEffect(() => {
    fetch(`${URL}/api/prescription-refill-page`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)
  return (
    <>
      <div className="row my-5">
        <div className="col-md-3">
        <RefillSign className="refillSign" />
        </div>
        <div className="col-md-8">{welcome.data && welcome.data.attributes ?
          <div>
            <p className="mainTitle">{welcome.data.attributes.prescriptionRefillHeader}</p>
            <ReactMarkdown className="singleAboutText">{welcome.data.attributes.prescriptionRefillText}</ReactMarkdown>
          </div> :
          <p>Loading...</p>}
        </div>

      </div>
      <div className="refillMapDiv">
         {/*<h2 className="text-uppercase text-center mb-5">click <a href="#transfer" className="text-decoration-none text-danger">here</a> to transfer a prescription</h2>
         <iframe src="https://jonesdrugs.mysecurescripts.com/quick-refill" width="100%" height="900px" frameborder="0" allowFullScreen="" title="secure scripts embed"></iframe>
         <iframe src="https://jonesdrugs.mysecurescripts.com/transfer-prescriptions" width="100%" height="1700px" frameborder="0" allowFullScreen="" title="secure scripts embed" id="transfer"></iframe>
         <RefillMap className="refillMap d-none d-sm-block d-sm-none d-md-block" /> */}
        {/* <div className="refillLocations d-none d-sm-block d-sm-none d-md-block"> 


          <div className="col-md-2 refillMillbrook">
            <a href="/locations/4" className="refillLink">Millbrook</a>
          </div>
          <div className="col-md-2  refillPrattville">
            <a href="/locations/3" className="refillLink">Prattville</a>
          </div>
          <div className="col-md-2 refillPhenix">
            <a href="/locations/6" className="refillLink">Phenix</a>
          </div>
          <div className="col-md-2 refillFairview">
            <a href="/locations/1" className="refillLink">Montgomery</a>
          </div>
          <div className="col-md-2 refillDalraida">
            <a href="/locations/2" className="refillLink">Montgomery(Dalraida)</a>
          </div>
          <div className="col-md-2 refillGreenville">
            <a href="/locations/5" className="refillLink">Greenville</a>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default PrescriptionRefill;
