const prescriptionRefillData = [
  {
    id: 1,
    name: 'Montgomery(Fairview)',
    coordinates: { x: 1, y: 1 },
    refillPolicy: 'Refill Policy for Montgomery(Fairview)...',
    pageUrl: '/prescription-refill/1',
  },
  {
    id: 2,
    name: 'Montgomery(Dalraida)',
    coordinates: { x: 314, y: 279 },
    refillPolicy: 'Refill Policy for Montgomery(Dalraida)...',
    pageUrl: '/prescription-refill/2',
  },
  {
    id: 3,
    name: 'Prattville',
    coordinates: { x: 314, y: 279 },
    refillPolicy: 'Refill Policy for Prattville...',
    pageUrl: '/prescription-refill/3',
  },
  {
    id: 4,
    name: 'Millbrook',
    coordinates: { x: 314, y: 279 },
    refillPolicy: 'Refill Policy for Millbrook...',
    pageUrl: '/prescription-refill/4',
  },
  {
    id: 5,
    name: 'Greenville',
    coordinates: { x: 314, y: 279 },
    refillPolicy: 'Refill Policy for Greenville...',
    pageUrl: '/prescription-refill/5',
  },
  {
    id: 6,
    name: 'Phenix City',
    coordinates: { x: 314, y: 279 },
    refillPolicy: 'Refill Policy for Phenix City...',
    pageUrl: '/prescription-refill/6',
  },
  
  
  // Add more locations as needed
];

export default prescriptionRefillData;
