import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { ReactComponent as LocationsPlanks } from '../public/images/LocationsListLinks.svg';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg';
import { ReactComponent as LocationsSign } from '../public/images/locations_sign.svg';
import { config } from '../components/Constants'
const URL = config.url;


const LocationsList = () => {
  const [locations, setLocations] = useState([]);
  const [welcome, setWelcome] = useState();

  useEffect(() => {
    fetch(`${URL}/api/locations-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  useEffect(() => {
    fetch(`${URL}/api/locations`)
      .then((res) => res.json())
      .then((locations) => {
        setLocations(locations);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(locations.data);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            {/* <img src={AboutSign} alt="" className="serviceSign d-none d-sm-block d-sm-none d-md-block"  /> */}
            <LocationsSign className="locationsSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="mainTitle text-uppercase mt-5">{welcome.data.attributes.locationsPageTitle}</p> :
              <p>Loading...</p>}
            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.locationsPageDescription}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="locationsPlanksBackground">

        <div className="container">
          <LocationsPlanks className="aboutPlanks d-none d-md-block" />
          <div className="locationsPlanksContent d-xs-block d-sm-block d-md-none">

             {/* <div className="row mb-lg-3 locationsRow">
              <div className='col-md-12  col-lg-8'>
                <span className="text-uppercase" >Montgomery (Fairview)</span>
                <Link to="/locations/4" className="openBtn">open</Link>
              </div>
            </div>

            <div className="row mb-lg-2 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <span className="text-uppercase" >Montgomery (Dalraida)</span>
                <Link to="/locations/3" className="openBtn">open</Link>
              </div>
            </div>

            <div className="row mb-lg-3 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <span className="text-uppercase" >Prattville</span>
                <Link to="/locations/6" className="openBtn">open</Link>
              </div>
            </div>

            <div className="row mb-lg-3 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <span className="text-uppercase" >Millbrook</span>
                <Link to="/locations/2" className="openBtn">open</Link>
              </div>
            </div>

            <div className="row mb-lg-5 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <span className="text-uppercase" >Greenville</span>
                <Link to="/locations/1" className="openBtn">open</Link>
              </div>
            </div>

            <div className="row mb-lg-5 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <span className=" text-uppercase" >Phenix City</span>
                <Link to="/locations/5" className="openBtn">open</Link>
              </div>
            </div> */}
            <div className="row mx-1 mb-lg-3 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/4" className="text-uppercase location-link">Montgomery (Fairview)</Link>
              </div>
            </div>

            <div className="row mx-1 mb-lg-2 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/3" className="text-uppercase location-link">Montgomery (Dalraida)</Link>
              </div>
            </div>

            <div className="row mx-1 mb-lg-3 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/6" className="text-uppercase location-link">Prattville</Link>
              </div>
            </div>

            <div className="row mx-1 mb-lg-3 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/2" className="text-uppercase location-link">Millbrook</Link>
              </div>
            </div>

            {/* <div className="row mb-lg-5 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/1" className="text-uppercase location-link">Greenville</Link>
              </div>
            </div> */}

            <div className="row mx-1 mb-lg-5 locationsRow">
              <div className='col-md-12 col-lg-8'>
                <Link to="/locations/5" className="text-uppercase location-link">Phenix City</Link>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="about-links">




      </div>
    </>
  );
};

export default LocationsList;
