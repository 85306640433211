import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ReactComponent as ServicesSign } from '../public/images/services_sign_grey.svg';
import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
import { ReactComponent as ServicesSinglePlank } from '../public/images/aboutSinglePlank.svg'
import { config } from '../components/Constants'
const URL = config.url;


const ServiceInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState();
  const [welcome, setWelcome] = useState();

  useEffect(() => {
    fetch(`${URL}/api/services-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/services/${id}`)
      .then((res) => res.json())
      .then((response) => {
        // Now we assume that the response object has a similar structure to the one in your Services component
        setService(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [id]);


  // for pushing one more time
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <ServicesSign className="serviceSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="mainTitle">{welcome.data.attributes.servicesTitle}</p> :
              <p>Loading...</p>}
            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.servicesText}</ReactMarkdown> :
              <p>Loading...</p>}
            <Link to={`/prescription-refill`} className="text-uppercase refillLink pe-3" target="_blank">
              <p className="btn btn-danger">quick refill</p>
            </Link>
            <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
              <p className="btn btn-danger">transfer</p>
            </Link>
          </div>
        </div>
      </div>
      <FenceBackground className="d-none d-sm-block d-sm-none d-md-block" />
      <div className="bioBackground ">

        <div className="container servicesWrapper">
          <ServicesSinglePlank className=" d-none d-sm-block d-sm-none d-md-block singleServicePlank" />
          <div className="bioContent ms-5 singleServiceContent ms-5">
            <div className="row pt-lg-5">
              <div className='col-md-12 text-end pe-3  pt-5'>
                <Link to={`/services`} className='text-uppercase close refill-close'>x</Link>
              </div>
            </div>


            <div className="row ms-0 ms-md-5">
              <div className="col-md-12">
              {service ? (
                <>

                  <div className="row my-5 ms-0 ms-md-5">
                    <div className="col-md-12 ">
                      <h4 className="text-uppercase text-start singleServiceTitle">{service.attributes.serviceName}</h4>
                    </div>
                    <div className="col-md-10">
                      <ReactMarkdown className="singleServiceText text-start py-lg-5">{service.attributes.serviceDescription}</ReactMarkdown>
                    </div>

                  </div>
                </>
              ) : (
                <p>Loading...</p>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default ServiceInfo;

