// import React, { useState, useEffect } from 'react';
// import '../public/styles/style.css'
// import { Link } from 'react-router-dom';
// import { config } from '../components/Constants'
// const URL = config.url;


// const ServicesComponent = () => {
//   const [services, setServices] = useState([]);
//   const [currentServices, setCurrentServices] = useState([]);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     fetch(`${URL}/api/services`)
//       .then((res) => res.json())
//       .then((services) => {
//         setServices(services);
//         setCurrentServices(services.slice(0, 4));
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);
//   console.log(services)

//   const nextServices = () => {
//     let nextIndex = (currentIndex + 1) % services.length;
//     setCurrentIndex(nextIndex);
//     setCurrentServices(services.slice(nextIndex, nextIndex + 2));
//   }

//   const prevServices = () => {
//     let prevIndex = ((currentIndex - 1) + services.length) % services.length;
//     setCurrentIndex(prevIndex);
//     setCurrentServices(services.slice(prevIndex, prevIndex + 2));
//   }

//   return (
//     <div className="container">
//       <div className="row mb-5">
//         {currentServices && currentServices.length > 0 && currentServices.map((service) => (
//           <div key={service.id} className="col-md-3">
//             <a href={service.attributes.serviceURL} className="iconLink">
//               <img src={service.attributes.serviceIconLink} alt="" className="img-fluid homeServiceIcons" />
//             </a>
//           </div>
//         ))}
//       </div>

//       <div className="row mb-5">
//         <div className="col-md-6 text-end  homeArrows">
//           <p onClick={prevServices}>
//             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
//               <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
//             </svg>
//           </p>
//         </div>
//         <div className="col-md-6 text-start homeArrows">
//           <p onClick={nextServices}> 
//             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
//               <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
//             </svg>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServicesComponent;

import React, { useState, useEffect } from 'react';
import '../public/styles/style.css'
import { Link } from 'react-router-dom';
import { config } from '../components/Constants'
const URL = config.url;

const ServicesComponent = () => {
  const [services, setServices] = useState('');
  const [currentServices, setCurrentServices] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [welcome, setWelcome] = useState('');

  useEffect(() => {
    fetch(`${URL}/api/welcome`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/homepage-services-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
console.log(welcome)
  useEffect(() => {
    fetch(`${URL}/api/services`)
      .then((res) => res.json())
      .then((services) => {
        const orderedServices = services.data.sort((a, b) => a.id - b.id);
        setServices(orderedServices);
        setCurrentServices(orderedServices.slice(0, 4));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  console.log(services)

  const nextServices = () => {
    let nextIndex = (currentIndex + 1) % services.length;
    setCurrentIndex(nextIndex);
    let newServices = services.slice(nextIndex, nextIndex + 4);
    if (newServices.length < 4) {
      newServices = newServices.concat(services.slice(0, 4 - newServices.length));
    }
    setCurrentServices(newServices);
  }
  
  const prevServices = () => {
    let prevIndex = ((currentIndex - 1) + services.length) % services.length;
    setCurrentIndex(prevIndex);
    let newServices = services.slice(prevIndex - 3, prevIndex + 1).reverse();
    if (newServices.length < 4) {
      newServices = newServices.concat(services.slice(services.length - (4 - newServices.length), services.length).reverse());
    }
    setCurrentServices(newServices);
  }

  return (
    <div className="container">
      
      <div className="row my-5">
        {currentServices && currentServices.length > 0 && currentServices.map((service) => (
          <div key={service.id} className="col-sm-6 col-md-3 service-card">
            <a href={service.attributes.serviceURL} className="homeServiceIcons">
              <img src={service.attributes.serviceIconLink} alt="" className="img-fluid homeServiceIcons" />
            </a>
          </div>
        ))}
      </div>

      <div className="row my-5">
        <div className="col-sm-6 text-end service-arrow-prev">
          <p onClick={prevServices}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>
          </p>
        </div>
        <div className="col-sm-6 text-start service-arrow-next">
          <p onClick={nextServices}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#02395C" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesComponent;

// github push puposes