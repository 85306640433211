// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// const WelcomeComponent = () => {
//   const [welcome, setWelcome] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetch(`http://localhost:1337/api/welcome`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);

//   useEffect(() => {
//     fetch(`http://localhost:1337/api/homepage-services-text`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);
//   console.log(welcome)

//   return (
//     <div className="welcome-box">
//       <div>{welcome && welcome.data.attributes ?
//         <div><p>{welcome.data.attributes.homepageServicesTitle}</p><p>{welcome.data.attributes.homepageServicesText}</p></div> :
//         <p>Loading...</p>}
//       </div>
//       <h2 className="home-icon">Welcome</h2>
//       <h3>Our Promise</h3>
//       <div>{welcome && welcome.data.attributes ?
//         <div>
//           <p>{welcome.data.attributes.welcomeTitle}</p>
//           <p>{welcome.data.attributes.welcomeDescription}</p>
//         </div> :
//         <p>Loading...</p>}</div>
//       <button className="my-button" onClick={() => navigate("/about")}>
//         Learn More
//       </button>
//     </div>
//   );
// };

// export default WelcomeComponent;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "../public/styles/style.css";
// import Bushes from '../public/images/picture_frame.png';
// import { ReactComponent as WelcomeSign } from '../public/images/welcome_sign.svg';
// import { ReactComponent as ServicesSign } from '../public/images/servicesSign.svg';
// import { ReactComponent as RedLearnMore } from '../public/images/red_learn_more.svg';
// import { config } from '../components/Constants'
// const URL = config.url;


// const WelcomeComponent = () => {
//   const [welcome, setWelcome] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetch(`${URL}/api/welcome`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);
// console.log(welcome)
//   return (
//     <>
//       <div className="container-fluid">

//         <div className="row">

//           <div className='col-md-3'>
//             <WelcomeSign className="welcomeSign d-none d-sm-block d-sm-none d-md-block" />

//           </div>
//           <div className='col-md-6 mt-5'>
//             <h3 className="ourPromiseH2 text-uppercase mt-5">our promise</h3>
//             <div className="ourPromiseText">{welcome && welcome.data.attributes ?
//               <p>{welcome.data.attributes.welcomeDescription}</p> :
//               <p className="text-capitalize">loading...</p>}</div>

//           </div>
//           <div className="col-md-3 mt-5">
//             <a href="/about">
//               <RedLearnMore className="redLearnMore mt-5" />

//             </a>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-md-11 d-none d-sm-block	d-sm-none d-md-block d-md-none d-lg-block text-end">
//             <img src={Bushes} alt="red frame" className="" />
//           </div>
//         </div>
//         <div className="row serviceSignDiv">
//           <div className="col-md-3">
//             <ServicesSign className="serviceSign d-none d-sm-block d-sm-none d-md-block" />
//           </div>
//           <div className="col-md-9 mt-5">
//             <h3 className="text-uppercase mt-5">fast & easy</h3>
//             <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro aut amet tenetur doloribus quod velit temporibus ipsum iste molestiae, autem cupiditate sit sunt dolore ipsam. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum incidunt ex quibusdam est dolorem nulla et at non inventore dolores!</p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default WelcomeComponent;

import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
// import Bushes from '../public/images/picture_frame.png';

import { ReactComponent as ServicesSign } from '../public/images/servicesSign.svg';
import { ReactComponent as RedLearnMore } from '../public/images/red_learn_more.svg';
import { ReactComponent as WelcomeSign } from '../public/images/welcome_sign.svg';
// import { ReactComponent as MobileSign } from '../public/images/mobile_sign.svg'
import { config } from '../components/Constants'
const URL = config.url;


const WelcomeComponent = () => {
  const [welcomeText, setWelcomeText] = useState('');
  const [services, setServicesWelcome] = useState('');
  // const navigate = useNavigate();

  useEffect(() => {
    fetch(`${URL}/api/welcome`)
      .then((res) => res.json())
      .then((data) => {
        setWelcomeText(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcomeText)



  useEffect(() => {
    fetch(`${URL}/api/homepage-services-text`)
      .then((res) => res.json())
      .then((data2) => {
        setServicesWelcome(data2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(services)

  return (
    <>
      <div className="container-fluid">

        <div className="row">

          <div className='col-md-3 mt-5'>
            <WelcomeSign className="welcomeSign" />
            {/* <div className="mobileSignDiv">
              <MobileSign className="mobileSign img-fluid d-block d-sm-none d-sm-block d-md-none" />
            </div>
              <p className="mobileSignText text-center">welcome</p> */}
          </div>
          <div className='col-md-6 mt-5'>
            {welcomeText && welcomeText.data.attributes ?
              <h3 className="mainTitle text-uppercase mt-md-5">{welcomeText.data.attributes.welcomeTitle}</h3> : null}

            <div className="singleAboutText">{welcomeText && welcomeText.data.attributes ?
              <ReactMarkdown>{welcomeText.data.attributes.welcomeDescription}</ReactMarkdown> :
              <p className="text-capitalize">loading...</p>}</div>
          </div>
          <div className="col-md-3 mb-4">
            <Link to='/about' className="aboutLink">
              <RedLearnMore className="redLearnMore mt-md-5" />
            </Link>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-11 d-none d-sm-block	d-sm-none d-md-block d-md-none d-lg-block text-end">
            <img src={Bushes} alt="red frame" className="" />
          </div>
        </div> */}
        <div className="row serviceSignDiv">
          <div className="col-md-3">
            <ServicesSign className="serviceSign" />
          </div>
          <div className="col-md-9 mt-lg-5 ">
            {services && services.data.attributes ?
              <h3 className="mainTitle text-uppercase mt-5">{services.data.attributes.homepageServicesTitle}</h3> : null}
            <div className="singleAboutText">
              {services && services.data.attributes ?
                <ReactMarkdown className="singleAboutText">{services.data.attributes.homepageServicesText}</ReactMarkdown> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// for push
export default WelcomeComponent
