import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as AboutSinglePlank } from '../public/images/historyPlank.svg'
import { ReactComponent as HistoryTimeline } from '../public/images/timeline1.svg'
import { config } from '../components/Constants'
const URL = config.url;

const History = () => {
  const [histories, setHistories] = useState([]);
  const navigate = useNavigate();
  const [welcome, setWelcome] = useState([]);

  useEffect(() => {
    fetch(`${URL}/api/histories`)
      .then((res) => res.json())
      .then((histories) => {
        setHistories(histories);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(histories)
  useEffect(() => {
    fetch(`${URL}/api/about-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome.data && welcome.data.attributes ?
              <p className="mainTitle">{welcome.data.attributes.aboutTitle}</p> :
              <p>Loading...</p>}
            {welcome.data && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="historyBackground">
        <AboutSinglePlank className="historyPlank img-fluid d-none d-sm-block d-sm-none d-md-block" />
        <div className="container">
          <div className="historyContent">
            <div className="row pt-0 pt-md-5">
              <div className='col-md-10 text-end py-3 py-md-5'>
                <Link to={`/about`} className='close text-uppercase histCloseBtn'>X</Link>
              </div>

              <div className='row pt-0 pt-md-5'>
                <div className="historyTimeline mt-0 mt-lg-0 mt-3 mt-lg-0">
                  <HistoryTimeline className="img-fluid  pt-0 pt-md-5 pt-lg-1" />
                </div>

                {/* {
                  histories.data &&
                  histories.data.length > 0 &&
                  histories.data.map((history) => (
                    <div className="row">
                      <div className="col-md-12">
                        <p className="historyTimelineContent">{history.attributes.historyTitle}</p>

                      </div>
                    </div>
                  ))} */}

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default History;