import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CareersSign } from '../public/images/careers_sign.svg'
import { ReactComponent as RedJoinUs } from '../public/images/red_join_us.svg';
import { config } from '../components/Constants'
const URL = config.url;
//     const navigate = useNavigate();


//     return (
//         <div className="careers-info">
//           <h2 className="title">CAREERS</h2>
//           <h2>Join the Jones Drugs Team</h2>
//           <p>All Kinds of Career Information</p>
//           <button className="my-button" onClick={() => navigate('/careers')}>Join Us</button>
//           <div className="col-md-6">
//                     <div className="card-body">
//                       <div>{welcome && welcome.data.attributes ?
//                         <p>{welcome.data.attributes.homepageCareersText}</p> :
//                         <p>Loading...</p>}
//                       </div>
//                     </div>
//                   </div>
//         </div>
//       );
//     };
const CareersComponent = () => {
  const navigate = useNavigate();
  const [welcome, setWelcome] = useState();
  useEffect(() => {
    fetch(`${URL}/api/homepage-careers-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)
  return (
    <>
      <div className="container-fluid py-5">
        <div className='row'>
          <div className="col-md-3">
            <CareersSign className="careersSign" />
          </div>

          <div className="col-md-9 mt-5">
            {welcome && welcome.data.attributes ?
              <p className="text-uppercase careersTitle mt-5">{welcome.data.attributes.homepageCareersTitle}</p> : null}
            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.homepageCareersText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 text-end">
            <RedJoinUs onClick={() => navigate('/careers')} className="redJoinUs" />
          </div>
        </div>
      </div>

    </>
  );
};
export default CareersComponent

