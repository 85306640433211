import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import prescriptionRefillData from '../PrescriptionRefill/PrescriptionRefillData';
import { config } from '../components/Constants'
const URL = config.url;


const PrescriptionRefillInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [welcome, setWelcome] = useState(null);

  useEffect(() => {
    fetch(`${URL}/api/services-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  useEffect(() => {
    const fetchedLocation = prescriptionRefillData.find(location => location.id === parseInt(id));
    setLocation(fetchedLocation);
  }, [id]);

  if (!location) {
    return <div>Loading...</div>;
  }

  return (
    <div className="location-info">
      <h1 className='title'>Prescription Refill</h1>
      <div className="welcome-box">
        <h2 className="home-icon">Get Your Refills Here!</h2>
        <div>{welcome && welcome.data.attributes ?
          <ReactMarkdown>{welcome.data.attributes.servicesText}</ReactMarkdown> :
          <p>Loading...</p>}
        </div>
      </div>
      <h2>{location.name}</h2>
      <p>{location.refillPolicy}</p>
      <button onClick={() => navigate('/services/1')}>Close</button>
    </div>
  );
}
export default PrescriptionRefillInfo;