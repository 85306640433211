import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../public/styles/style.css'
import { ReactComponent as Refill } from '../public/images/refill_icon.svg'
import { ReactComponent as ReadMoreRed } from '../public/images/read_more_red.svg';
import Logo from '../public/images/jonesLogo.png';
import { ReactComponent as MobileLogo } from '../public/images/jonesLogo.svg';
import HomeBanner from '../public/images/Web Banner - Jones Drugs.mp4';
import AboutBanner from '../public/images/about.jpg';
import CareerBanner from '../public/images/careers.jpg';
import ContactBanner from '../public/images/contact.jpg';
import PromoBanner from '../public/images/promotions.jpg';
import LocationsBanner from '../public/images/locations.jpg';
import RefillBanner from '../public/images/refills.jpg';
import ServicesBanner from '../public/images/woman_services.jpg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const firstPath = location.pathname.split('/')[1];
  const locations = ['Montgomery', 'Prattville', 'Millbrook', 'Phenix City'];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  let background;
  let title;
  // let headerSubText;

  switch (firstPath) {
    case 'about':
      title = 'About';
      background = AboutBanner
      break;
    case 'gallery':
      title = 'Gallery';
      background = AboutBanner
      break;
    case 'services':
      title = 'Services';
      background = ServicesBanner
      break;
    case 'prescription-refill':
      title = 'Prescription Refill'
      background = RefillBanner
      break;
    case 'prescription-transfer':
      title = 'Prescription Transfer'
      background = RefillBanner
      break;
    case 'careers':
      title = 'Careers'
      background = CareerBanner
      break;
    case 'contact':
      title = 'Contact Us'
      background = ContactBanner
      break;
    case 'locations':
      title = 'Locations'
      background = LocationsBanner
      break;
    case 'promotions':
      title = 'Promotions'
      background = PromoBanner
      break;
    default:
      background = HomeBanner
      title = 'Home';
  }

  // console.log(background)

  if (background === '/static/media/Web Banner - Jones Drugs.e08ce0eac47f3d9bc1f6.mp4') {
    return (
      <>
        <video className="video d-none d-sm-block d-sm-none d-md-block" autoPlay loop playsInline muted>
          <source src={background} type="video/mp4" />
        </video>

        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="mobileVersion py-5">
                <a href="/">
                  <MobileLogo className="" />
                </a>
              </div>
              <div classname="col-md-12">
                <div className="hamburger-menu test-menu py-3">
                  <button className="btn btn-danger" data-bs-toggle="dropdown" >
                    <span className="hamburger-line d-block"></span>
                    <span className="hamburger-line d-block"></span>
                    <span className="hamburger-line d-block"></span>
                  </button>
                  <ul className="dropdown-menu hamburger-links">
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/">home</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/promotions">promotions</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/about">about</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/locations">locations</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/services">services</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/contact">contact</Link></button></li>
                    <li><button className="dropdown-item text-uppercase" type="button"><Link to="/careers">careers</Link></button></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="hide-on-mobile">
              <div className="row">
                <div className="col-md-6 mb-5">
                  <a href="/">
                    <img src={Logo} alt="jones logo" className="logo img-fluid d-none d-sm-block d-sm-none d-md-block mb-lg-5" />
                  </a>
                </div>
                <div className="col-md-6 headerIcons text-end px-5 d-none d-sm-block d-sm-none d-md-block">
                  <Link to='/prescription-refill' className="refillText" target='_blank'>
                    <Refill className="refill" />
                    <p className=" text-uppercase">prescription <span className="d-block">refill</span></p>
                  </Link>
                </div>
              </div>


              <div className="row">
                <div className="col-md-6 ms-5">
                  <h2 className="slogan text-capitalize">personal service</h2>
                  <h2 className="slogan text-capitalize">from your family pharmacy</h2>

                </div>

                <div className="row">
                  <div className="col-md-4">
                    <ul className="locations">
                      {locations.map((location, index) => (
                        <li key={index}>{location}</li>
                      ))}
                    </ul>
                  </div>
                </div>

              </div>

              <div className="row ms-5">
                <div className="col-md-4">
                  <Link to="/locations">
                    <ReadMoreRed className="readMore pt-2 d-none d-sm-block d-sm-none d-md-block" />
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </header >
      </>
    )
  }
  return (
    <>
      <img src={background} alt="" className="img-fluid banner d-none d-sm-block d-sm-none d-md-block" />

      <header >
        <div className="container-fluid">
          <div className="row">
            <div className="mobileVersion">
              <a href="/">
                <MobileLogo className="" />
              </a>
            </div>
            <div classname="col-md-12">
              <div className="hamburger-menu">
                <button className="btn btn-danger" data-bs-toggle="dropdown" >
                  <span className="hamburger-line d-block"></span>
                  <span className="hamburger-line d-block"></span>
                  <span className="hamburger-line d-block"></span>
                </button>
                <ul className="dropdown-menu hamburger-links">
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/">home</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/promotions">promotions</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/about">about</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/locations">locations</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/services">services</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/contact">contact</Link></button></li>
                  <li><button className="dropdown-item text-uppercase" type="button"><Link to="/careers">careers</Link></button></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row hide-on-mobile">
            <div className="col-md-6 mb-5">
              <a href="/">
                <img src={Logo} alt="jones logo" className="logo img-fluid d-none d-sm-block d-sm-none d-md-block mb-lg-5" />
              </a>
            </div>
            <div className="col-md-6 headerIcons text-center px-5 d-none d-sm-block d-sm-none d-md-block">
              <Link to='/prescription-refill' className="refillText" target='_blank'>
                <Refill className="refill" />
                <p className=" text-uppercase">prescription <span className="d-block">refill</span></p>
              </Link>
            </div>
          </div>


          <div className="row hide-on-mobile">
            <div className="col-md-6 ms-5">
              <h2 className="slogan text-capitalize">personal service</h2>
              <h2 className="slogan text-capitalize">from your family pharmacy</h2>

            </div>

            <div className="row">
              <div className="col-md-4">
                <ul className="locations">
                  {locations.map((location, index) => (
                    <li key={index}>{location}</li>
                  ))}
                </ul>
              </div>
            </div>

          </div>

          <div className="row ms-5 hide-on-mobile">
            <div className="col-md-4">
              <Link to="/locations">
                <ReadMoreRed className="readMore pt-2 d-none d-sm-block d-sm-none d-md-block" />
              </Link>
            </div>
          </div>

        </div>
      </header >
    </>
  );
}

export default Header;
