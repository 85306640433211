// import React, { useState, useEffect } from "react";

// const TestimonialsComponent = () => {
//   const [testimonials, setTestimonials] = useState([]);

//   useEffect(() => {
//     fetch(`http://localhost:1337/api/locations?populate=*`)
//       .then((res) => res.json())
//       .then((locations) => {
//         const allTestimonials = locations.data.flatMap(location => location.attributes.testimonials);
//         setTestimonials(allTestimonials);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);

//   return (
//     <div className="container">
//       <div id="carouselExampleCaptions" className="carousel slide">
//         <div className="carousel-inner">
//           {testimonials.map((testimonial, index) => (
//             <div key={testimonial.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
//               <div className="card mb-3">
//                 <div className="row g-0">
//                   <div className="col-md-6">
//                     <div className="card-body">
//                       <h5 className="card-title">{testimonial.testimonialText}</h5>
//                       <p className="card-text">{testimonial.testimonialAuthor}</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
//           <span className="carousel-control-prev-icon" ></span>
//           <span >Previous</span>
//         </button>
//         <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
//           <span className="carousel-control-next-icon" ></span>
//           <span >Next</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default TestimonialsComponent;

import React, { useState, useEffect } from "react";
import { ReactComponent as TestimonialsSign } from '../public/images/testimonials.svg';
import { ReactComponent as LeftQuote } from '../public/images/quote_left.svg';
import { ReactComponent as RightQuote } from '../public/images/quote_right.svg';
import { config } from '../components/Constants'
const URL = config.url;


const TestimonialsComponent = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        const allTestimonials = locations.data.flatMap(location => location.attributes.testimonials);
        
        const sortedTestimonials = allTestimonials.sort((a, b) => {
          return new Date(b.id) - new Date(a.id);
        });
  
        console.log(sortedTestimonials); // Log the sorted testimonials
        setTestimonials(sortedTestimonials);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  
console.log(testimonials)
  return (
    <div className="testimonials">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <TestimonialsSign className="testimonialsSign" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">

            <div id="carouselExampleCaptions" className="carousel slide">
              <div className="carousel-inner">
                {testimonials.map((testimonial, index) => (
                  <div key={testimonial.id} className={`carousel-item test-carousel-item ${index === 0 ? 'active' : ''}`}>
                    <div className="row mb-5">
                      <div className="col-md-3">
                        <LeftQuote className="leftQuote text-end" />
                      </div>
                      <div className="col-md-5">
                        <p className="testimonialText">{testimonial.testimonialText}</p>
                      </div>
                      <div className="col-md-4 text-end">
                        <RightQuote className="rightQuote" />
                      </div>
                    </div>
                    <div className="row signature">
                      <div className="col-md-9 col-sm-12 col-xs-12 text-end">
                        <p className="testimonialAuthor text-uppercase">{testimonial.testimonialAuthor}</p>
                        <p className="testimonialText text-capitalize">{testimonial.testimonialLocation}</p>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
              <div className="row mt-5 carousel-button-row">
                <div className="col-md-6 text-end mt-5">
                  <button className="carousel-control-prev mt-5" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#02395C" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                  </button>
                </div>

                <div className="col-md-6 text-start mt-5">
                  <button className="carousel-control-next mt-5" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#02395C" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                    </svg>
                  </button>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TestimonialsComponent;