import React from 'react';
import ReactMarkdown from 'react-markdown'
import { ReactComponent as RefillMap } from '../public/images/refillMap.svg';
import '../public/styles/style.css';
import { useState, useEffect } from 'react';
import { ReactComponent as RefillSign } from '../public/images/refill_sign.svg';
import { config } from '../components/Constants'
const URL = config.url;


const PrescriptionTransfer = () => {
    const [welcome, setWelcome] = useState([]);

    useEffect(() => {
        fetch(`${URL}/api/prescription-refill-page`)
            .then((res) => res.json())
            .then((welcomeData) => {
                setWelcome(welcomeData);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);
    console.log(welcome)
    return (
        <>
            <div className="row my-5">
                <div className="col-md-3">
                    <RefillSign className="refillSign" />
                </div>
                <div className="col-md-8">{welcome.data && welcome.data.attributes ?
                    <div>
                        <p className="mainTitle">{welcome.data.attributes.prescriptionRefillHeader}</p>
                        <ReactMarkdown className="singleAboutText">{welcome.data.attributes.prescriptionRefillText}</ReactMarkdown>
                    </div> :
                    <p>Loading...</p>}
                </div>

            </div>
            <div className="refillMapDiv">
                {/* <iframe src="https://jonesdrugs.mysecurescripts.com/quick-refill" width="100%" height="900px" frameborder="0" allowFullScreen="" title="secure scripts embed"></iframe> */}
                <iframe src="https://jonesdrugs.mysecurescripts.com/transfer-prescriptions" width="100%" height="1700px" frameborder="0" allowFullScreen="" title="secure scripts embed"></iframe>
          
            </div>
        </>
    );
}

export default PrescriptionTransfer;