import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { ReactComponent as PromoSign } from '../public/images/promo_sign.svg'
import { ReactComponent as CityBackground } from '../public/images/grey_city.svg'
import { config } from '../components/Constants'
const URL = config.url;

const Promotions = () => {
  const [locations, setLocations] = useState([]);
  const [welcome, setWelcome] = useState();
  const [currentPromoIndices, setCurrentPromoIndices] = useState({});

  useEffect(() => {
    fetch(`${URL}/api/promotions-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        setLocations(locations.data);
        const initialIndices = {};
        locations.data.forEach(location => {
          initialIndices[location.id] = 0;
        });
        setCurrentPromoIndices(initialIndices);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handlePrev = (locationId) => {
    setCurrentPromoIndices(prevIndices => {
      const locationPromotionsLength = locations.find(location => location.id === locationId).attributes.promotions.length;
      return {
        ...prevIndices,
        [locationId]: (prevIndices[locationId] - 1 + locationPromotionsLength) % locationPromotionsLength
      };
    });
  };

  const handleNext = (locationId) => {
    setCurrentPromoIndices(prevIndices => {
      const locationPromotionsLength = locations.find(location => location.id === locationId).attributes.promotions.length;
      return {
        ...prevIndices,
        [locationId]: (prevIndices[locationId] + 1) % locationPromotionsLength
      };
    });
  };

  return (
    <>
      <div className="container-fluid topSection">
        <div className="row">
          <div className='col-md-3'>
            <PromoSign className="promoSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="text-uppercase promoPageTitle mt-5">{welcome.data.attributes.promotionsTitle}</p> : null}

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="promoPageText">{welcome.data.attributes.promotionsText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>

      <CityBackground className="greyCityBackground d-none d-sm-block d-sm-none d-md-block" />

      <div className='promosTempBackground'>
        <div className="container promos">
          <div className="row ">
            {/* {locations && locations.length > 0 && locations.map((location, index) => (
              <div key={location.id} className={`col-12 col-md-6 ${index % 2 === 0 ? 'mb-5' : ''}`}>
                <h3 className="text-center locationName">{location.attributes.locationName}</h3>
                <div id={`carouselPromotions${location.id}`} className="carousel slide">
                  <div className="carousel-inner">
                    {location.attributes.promotions.sort((a, b) => b.id - a.id).map((promotion, promoIndex) => (
                      <div key={promotion.id} className={`carousel-item ${promoIndex === currentPromoIndices[location.id] ? 'active' : ''}`}>
                        <Link to={promotion.promotionURL} className="promoLinkStyle">
                          <div className="card mb-3 mb-lg-5 mt-lg-5">
                            <img src={promotion.promotionImageLink} className="img-fluid card-img-top" alt="promotion ad" />
                            <div className="promoCardBody">
                              <div className="card-body">
                                <h5 className="card-title promoPageLittleTitle text-center">{promotion.promotionTitle}</h5>
                                <ReactMarkdown className="card-text">{promotion.promotionDescription}</ReactMarkdown>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="row promotions-button-row">
                          <div className="col-6 text-end promotions-arrow-prev">
                            <p onClick={() => handlePrev(location.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                              </svg>
                            </p>
                          </div>
                          <div className='col-6 text-start promotions-arrow-next'>
                            <p onClick={() => handleNext(location.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))} */}

            {locations && locations.length > 0 && locations.map((location, index) => {
              const isDesktop = window.innerWidth > 768;
              const marginBottomClass = isDesktop && index % 2 === 0 ? 'mb-5' : '';

              return (
                <div key={location.id} className={`col-12 col-md-6 ${marginBottomClass}`}>
                  <h3 className="text-center locationName">{location.attributes.locationName}</h3>
                  <div id={`carouselPromotions${location.id}`} className="carousel slide">
                    <div className="carousel-inner">
                      {location.attributes.promotions.sort((a, b) => b.id - a.id).map((promotion, promoIndex) => (
                        <div key={promotion.id} className={`carousel-item ${promoIndex === currentPromoIndices[location.id] ? 'active' : ''}`}>
                          <Link to={promotion.promotionURL} className="promoLinkStyle">
                            <div className="card mb-3 mb-lg-5 mt-lg-5">
                              <img src={promotion.promotionImageLink} className="img-fluid card-img-top" alt="promotion ad" />
                              <div className="promoCardBody">
                                <div className="card-body">
                                  <h5 className="card-title promoPageLittleTitle text-center">{promotion.promotionTitle}</h5>
                                  <ReactMarkdown className="card-text">{promotion.promotionDescription}</ReactMarkdown>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="row promotions-button-row">
                            <div className="col-6 text-end promotions-arrow-prev">
                              <p onClick={() => handlePrev(location.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                </svg>
                              </p>
                            </div>
                            <div className='col-6 text-start promotions-arrow-next'>
                              <p onClick={() => handleNext(location.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>

    </>
  );
};

export default Promotions;