// // import React, { useState, useEffect } from "react";
// // import { useParams, useNavigate } from "react-router-dom";
// // import { Link } from 'react-router-dom';
// // import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
// // import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
// // import { ReactComponent as AboutSinglePlank } from '../public/images/aboutSinglePlank.svg'
// // import { config } from '../components/Constants'
// // const URL = config.url;
// // const StaffInfo = () => {
// //   const [person, setPerson] = useState([]);
// //   const [personLocation, setPersonLocation] = useState(null);
// //   const [allPeople, setAllPeople] = useState([]);
// //   const { id } = useParams();
// //   const navigate = useNavigate();
// //   const [welcome, setWelcome] = useState(null);


// //   useEffect(() => {
// //     fetch(`${URL}/api/about-text`)
// //       .then((res) => res.json())
// //       .then((welcomeData) => {
// //         setWelcome(welcomeData);
// //       })
// //       .catch((error) => {
// //         console.error("Error:", error);
// //       });
// //   }, []);

// //   useEffect(() => {
// //     fetch(`${URL}/api/locations?populate=*`)
// //       .then((res) => res.json())
// //       .then((locations) => {
// //         const people = locations.data.flatMap(location => location.attributes.people);
// //         setAllPeople(people);
// //         const selectedPerson = people.find(peep => peep.id === +id);
// //         setPerson(selectedPerson);

// //         // set the location for the selected person
// //         const selectedPersonLocation = locations.data.find(location => location.attributes.people.some(peep => peep.id === +id));
// //         setPersonLocation(selectedPersonLocation);

// //       })
// //       .catch((error) => {
// //         console.error("Error:", error);
// //       });
// //   }, [id]);

// //   const nextPerson = () => {
// //     const currentIndex = allPeople.findIndex(peep => peep.id === +id);
// //     let nextIndex = 0;
// //     if (currentIndex !== -1 && currentIndex < allPeople.length - 1) {
// //       nextIndex = currentIndex + 1;
// //     }
// //     navigate(`/about/personnel/${allPeople[nextIndex].id}`);
// //   };

// //   const prevPerson = () => {
// //     const currentIndex = allPeople.findIndex(peep => peep.id === +id);
// //     let prevIndex = allPeople.length - 1;
// //     if (currentIndex > 0) {
// //       prevIndex = currentIndex - 1;
// //     }
// //     navigate(`/about/personnel/${allPeople[prevIndex].id}`);
// //   };
// //   return (
// //     <>
// //       <div className="container-fluid">
// //         <div className="row">
// //           <div className='col-md-3'>
// //             <AboutSign className="aboutSign d-none d-sm-block d-sm-none d-md-block" />
// //           </div>
// //           <div className='col-md-6 mt-5'>
// //             {/* LINES BELOW NEED TO BE MADE DYNAMICALLY */}
// //             <h3 className="historyTitle text-uppercase mt-5">TITLE</h3>
// //             {welcome && welcome.data.attributes ?
// //               <p>{welcome.data.attributes.aboutText}</p> :
// //               <p>Loading...</p>}
// //           </div>
// //         </div>
// //       </div>
// //       <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

// //       <div className="personnelBackground">
// //           <AboutSinglePlank className="bioPlank d-none d-sm-block d-sm-none d-md-block" />
// //         <div className="container">
// //           <div className="personnelContent">
// //             <div className="row pt-5">
// //               <div className='col-md-9 text-end py-lg-5'>
// //                 <Link to={`/about`} className='close text-uppercase'>X</Link>
// //               </div>
// //             </div>
// //             {person ? (
// //               <div className="row bioContent">
// //                 <div className="col-md-12 text-center">
// //                   <img src={person.personImageLink} className="img-fluid personBioImg" alt="person bio" />
// //                 </div>
// //                 <div className='row'>
// //                   <div className="col-md-12 text-center">
// //                     <h5 className="personBioTitles">{person.personName}</h5>
// //                     <p className="personBioTitles">{person.personRole}</p>
// //                     {personLocation && <p className="personBioTitles">Location: <span className="bioData">{personLocation.attributes.locationName}</span></p>}
// //                     <p onClick={() => navigate("/about/personnel")} className="btn btn-danger text-uppercase">return to bios</p>
// //                     {person.personQuote && <p className="personBioTitles">Quote: <span className="bioData">{person.personQuote}</span></p>}
// //                     {person.personEducation && <p className="personBioTitles">Education: <span className="bioData">{person.personEducation}</span></p>}
// //                     {person.personYOE && <p className="personBioTitles">Years of Experience: <span className="bioData">{person.personYOE}</span></p>}
// //                     {person.personLicenses && <p className="personBioTitles">Licenses: <span className="bioData">{person.personLicenses}</span> </p>}
// //                     {person.personAssoc && <p className="personBioTitles">Member Associations/Organizations: <span className="bioData">{person.personAssoc}</span></p>}
// //                     {person.personAwards && <p className="personBioTitles">Awards/Recognitions: <span className="bioData">{person.personAwards}</span></p>}
// //                     {person.personVolunteer && <p className="personBioTitles">Local Volunteerism: <span className="bioData">{person.personVolunteer}</span></p>}
// //                     {person.personBio && <p className="personBioTitles">About: <span className="bioData">{person.personBio}</span></p>}

// //                   </div>
// //                   <div onClick={prevPerson} className="col-md-6 text-end arrows">
// //                     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
// //                       <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
// //                     </svg>
// //                     <p className="text-uppercase bioArrows">last bio</p>
// //                   </div>
// //                   <div onClick={nextPerson} className="col-md-6 arrows">
// //                     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
// //                       <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
// //                     </svg>
// //                     <p className="text-uppercase bioArrows">next bio</p>
// //                   </div>
// //                 </div>
// //               </div>
// //             ) : (
// //               <div>Loading...</div>
// //             )}
// //           </div>
// //         </div>
// //       </div>

// //     </>
// //   );
// // };

// // export default StaffInfo;

import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { useParams, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as AboutSinglePlank } from '../public/images/aboutSinglePlank.svg'
import { config } from '../components/Constants'
const URL = config.url;
const StaffInfo = () => {
  const [person, setPerson] = useState([]);
  const [personLocation, setPersonLocation] = useState('');
  const [allPeople, setAllPeople] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [welcome, setWelcome] = useState('');


  useEffect(() => {
    fetch(`${URL}/api/about-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        const people = locations.data.flatMap(location => location.attributes.people);
        setAllPeople(people);
        const selectedPerson = people.find(peep => peep.id === +id);
        setPerson(selectedPerson);

        // set the location for the selected person
        const selectedPersonLocation = locations.data.find(location => location.attributes.people.some(peep => peep.id === +id));
        setPersonLocation(selectedPersonLocation);

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [id]);

  const nextPerson = () => {
    const currentIndex = allPeople.findIndex(peep => peep.id === +id);
    let nextIndex = 0;
    if (currentIndex !== -1 && currentIndex < allPeople.length - 1) {
      nextIndex = currentIndex + 1;
    }
    navigate(`/about/personnel/${allPeople[nextIndex].id}`);
  };

  const prevPerson = () => {
    const currentIndex = allPeople.findIndex(peep => peep.id === +id);
    let prevIndex = allPeople.length - 1;
    if (currentIndex > 0) {
      prevIndex = currentIndex - 1;
    }
    navigate(`/about/personnel/${allPeople[prevIndex].id}`);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign d-none d-sm-block d-sm-none d-md-block" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome.data && welcome.data.attributes ?
              <h3 className="mainTitle text-uppercase mt-5">{welcome.data.attributes.aboutTitle}</h3> : null}

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>

      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="m-0 p-0 bioBackground">

        <div className="container-fluid m-0 p-0">
          <AboutSinglePlank className="bioPlank" />
          <div className="bioContent ps-0 pe-0">
            <div className="row pt-0 pt-md-5 pe-0 ps-0">
              <div className='col-md-9 text-end py-lg-5 ps-0 pe-0'>
                <Link to={`/about`} className=' text-uppercase close bio-close'>X</Link>
              </div>
            </div>
            {person ? (
              <div className="row m-0 p-0">
                <div className="col-md-12 text-center p-0">
                  <img src={person.personImageLink} className="img-fluid personBioImg" alt="person bio" />
                </div>
                <div className='row m-0 p-0'>
                  <div className="col-md-12 text-center">
                    <h5 className="personBioTitles">{person.personName}</h5>
                    <p className="personBioTitles">{person.personRole}</p>
                    {personLocation && <p className="personBioTitles">Location: <span className="bioData">{personLocation.attributes.locationName}</span></p>}
                    <p onClick={() => navigate("/about/personnel")} className="btn btn-danger text-uppercase">return to bios</p>
                    {person.personQuote && <p className="personBioTitles quote"><span className="bioData ">{person.personQuote}</span></p>}
                    {person.personEducation && <p className="personBioTitles">Education: <span className="bioData">{person.personEducation}</span></p>}
                    {person.personYOE && <p className="personBioTitles">Years of Experience: <span className="bioData">{person.personYOE}</span></p>}
                    {person.personLicenses && <p className="personBioTitles">Licenses: <span className="bioData">{person.personLicenses}</span> </p>}
                    {person.personAssoc && <p className="personBioTitles">Member Associations/Organizations: <span className="bioData">{person.personAssoc}</span></p>}
                    {person.personAwards && <p className="text-center personBioTitles">Awards/Recognitions: <span className="text-center bioData">{person.personAwards}</span></p>}
                    {person.personVolunteer && <p className="personBioTitles">Local Volunteerism: <span className="bioData">{person.personVolunteer}</span></p>}
                    {person.personBio && <p className="personBioTitles">About: <span className="bioData">{person.personBio}</span></p>}
                  </div>
                  <div className="row">
                    <div
                      onClick={prevPerson}
                      className="col-6 d-flex d-md-block text-start align-items-center arrows prev-bio-arrow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="red"
                        className="bi bi-arrow-left-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                      </svg>
                      <p className="text-uppercase bioArrows ms-2">last bio</p>
                    </div>
                    <div
                      onClick={nextPerson}
                      className="col-6 d-flex d-md-block text-end align-items-center arrows next-bio-arrow"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="red"
                        className="bi bi-arrow-right-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                      </svg>
                      <p className="text-uppercase bioArrows ms-2">next bio</p>
                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default StaffInfo;

