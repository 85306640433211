import React, { useState } from "react";
import axios from 'axios'
import { config } from './Constants'
const URL = config.url;
// const nodemailer = require("nodemailer");

const FraudModal = ({ onClose }) => {

    // const [formState, setFormState] = useState({
    //     complaintType: "",
    //     message: "",
    // });

    // const handleInputChange = (event) => {
    //     setFormState({ ...formState, [event.target.name]: event.target.value });
    // };

    const [complaintType, setComplaintType] = useState()
    const [message, setMessage] = useState()
    const [submitted, setSubmitted] = useState()
    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     const data = { ...formState };
    //     console.log(data);
    //     fetch(`${URL}/api/fraud-forms`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`
    //         },
    //         body: JSON.stringify({ 'data': data })
    //     })
    //         .then(console.log(`${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`))
    //         .then((response) => response.json())
    //         .then((data) => {
    //             alert('Your form has been submitted successfully.');
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             alert('There was an error submitting your email address. Please try again.');
    //         });

    // }

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios({
            method: 'post',
            url: `${URL}/api/fraud-forms`,
            data: {
                data: { complaintType, message }
            },
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`
            }
        })
            .then(setSubmitted(true))
            .catch(function (error) {
                if (error.response) {
                    console.log('data error', error.response.data)
                    console.log('status error', error.response.status)
                    console.log('headers error', error.response.headers)
                } else if (error.request) {
                    console.log('request error', error.request)
                } else {
                    console.log('Error message', error.message)
                }
                console.log('config error', error.config)
            })

    }

    if (submitted) {
        return <div>Thanks! We&apos;ll be in touch shortly.</div>
    }

    return (
        <>
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content " onClick={(e) => e.stopPropagation()}>
                    <p className="">If you would like to file a fraud notification or complaint of a waste or abuse violation, or a compliance complaint, this anonymous reporting is available 24 hours a day, 7 days a week.</p>
                    <form onSubmit={handleSubmit} className="my-5 px-5">
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <select
                                    name="complaintType"
                                    value={complaintType}
                                    onChange={(e) => setComplaintType(e.target.value)}
                                    className="form-control inputStyling text-uppercase"
                                >
                                    <option value="">Type of Complaint</option>
                                    <option value="fraud">fraud</option>
                                    <option value="waste">waste</option>
                                    <option value="abuse">abuse</option>
                                    <option value="compliance">compliance</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 my-3">
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Complaint"
                                    required
                                    rows="5"
                                    className="form-control inputStyling"
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col text-center">
                                <button className="btn submit-btn" type="submit">
                                    Submit
                                </button>

                            </div>
                        </div>
                    </form>
                    {/* <iframe width="540" height="305" src="https://58f4d0a1.sibforms.com/serve/MUIFABDg0YMF0xR667Wd96aKK7VNQhweqFNbqknt_7oblufQsB92qhpgI8-KHq2h08f53M8hooj5RUxkCIOKRV9CvxMNMPRCDCGSdFV7ttRm5hXRxWB6nai1WRUO17G8Twfk2o3TvR3bcinMvGVQOVUq9xRuY1RxWg01C2QHZAKat3HXmKNV4pt9iKke8SzHQ4JoubYKwdeJ3ylH" frameborder="0" scrolling="auto" allowfullscreen title="complaint" className="contactForm"></iframe> */}
                    <button onClick={onClose} className="modal-close-button">X</button>
                </div>
            </div>
        </>
    );

};

export default FraudModal;
