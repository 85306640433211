// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
// import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
// import { ReactComponent as AboutSinglePlank } from '../public/images/personnelPlank.svg'
// import { config } from '../components/Constants'
// const URL = config.url;



// const Personnel = () => {
//   const [people, setPeople] = useState([]);
//   const [welcome, setWelcome] = useState(null);

//   useEffect(() => {
//     fetch(`${URL}/api/about-text`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);
//   console.log(welcome)

//   useEffect(() => {
//     fetch(`${URL}/api/locations?populate=*`)
//       .then((res)=>res.json())
//       .then((locationsData) => {
//         const allPeople = locationsData.data.reduce((all, location) => [...all, ...location.attributes.people], []);
//         setPeople(allPeople);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className='col-md-3'>
//             <AboutSign className="aboutSign d-none d-sm-block d-sm-none d-md-block" />
//           </div>
//           <div className='col-md-6 mt-5'>
//             {welcome && welcome.data.attributes ?
//             <h3 className="mainTitle text-uppercase mt-5">{welcome.data.attributes.aboutTitle}</h3> : null}

//             {welcome && welcome.data.attributes ?
//               <p className="singleAboutText">{welcome.data.attributes.aboutText}</p> :
//               <p>Loading...</p>}
//           </div>
//         </div>
//       </div>
//       <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

//       <div className="personnelBackground">
//           <AboutSinglePlank className="personnelPlank d-none d-sm-block d-sm-none d-md-block" />
//         <div className="container">
//           <div className="personnelContent">
//             <div className="row pt-5">
//               <div className='col-md-9 text-end py-5'>
//                 <Link to={`/about`} className='close text-uppercase'>X</Link>
//               </div>
//             </div>
//             <div className="row personContent">
//               {people && people.length > 0 && people.map((person) => (
//                 <div key={person.id} className="col-md-6 text-center">
//                   <img src={person.personImageLink} className="img-fluid personnelImg my-4" alt="personnel" />
//                   <p  className="personnelTitles text-capitalize">{person.personName}</p>
//                   <p className="personnelTitles text-capitalize pb-3">{person.personRole}</p>
//                   <Link to={`/about/personnel/${person.id}`} className="bioLink " >view bio</Link>
//                 </div>

//               ))}
//             </div>
//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default Personnel;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'
import { ReactComponent as AboutSign } from '../public/images/aboutSign.svg';
import { ReactComponent as AboutSinglePlank } from '../public/images/personnelPlank.svg'
import { config } from '../components/Constants'
const URL = config.url;



const Personnel = () => {
  const [people, setPeople] = useState([]);
  const [welcome, setWelcome] = useState();

  useEffect(() => {
    fetch(`${URL}/api/about-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  console.log(welcome)

  //   useEffect(() => {
  //     fetch(`${URL}/api/locations?populate=*`)
  //       .then((res)=>res.json())
  //       .then((locationsData) => {
  //         const allPeople = locationsData.data.reduce((all, location) => [...all, ...location.attributes.people], []);
  //         setPeople(allPeople);
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error);
  //       });
  //   }, []);

  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locations) => {
        const allPeople = locations.data.flatMap(location => location.attributes.people);

        // Filter duplicates
        const uniquePeople = allPeople.reduce((acc, current) => {
          const x = acc.find(item => item.personName === current.personName);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setPeople(uniquePeople);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <AboutSign className="aboutSign " />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <h3 className="mainTitle text-uppercase mt-5">{welcome.data.attributes.aboutTitle}</h3> : null}

            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="singleAboutText">{welcome.data.attributes.aboutText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityAndFarmBackground className=" d-none d-sm-block d-sm-none d-md-block" />

      <div className="personnelBackground">
        <div className="plankCenter">
        </div>
        <div className="container">
          <AboutSinglePlank className="d-none d-sm-none d-md-none d-lg-block personnelPlank" />
          <div className="historyContent">
            <div className="row pt-0 pt-md-5">
              <div className='col-md-9 text-end py-3 py-md-5'>
                <Link to={`/about`} className='close text-uppercase persCloseBtn'>X</Link>
              </div>
            </div>
            <div className="row pt-0 pt-md-5">
              <div className='col-md-9 d-xs-block d-sm-block d-md-block d-lg-none personnelTitleDiv'>
                <h3 className="text-center text-uppercase personnelTitle">personnel</h3>
              </div>
            </div>
            <div className="row personnelContent">
              {people && people.length > 0 && people.map((person) => (
                <div key={person.id} className="col-md-6 text-center mb-4">
                  <img src={person.personImageLink} className="img-fluid personnelImg" alt="personnel" />
                  <p className="personnelTitles text-capitalize">{person.personName}</p>
                  <p className="personnelTitles text-capitalize pb-1">{person.personRole}</p>
                  <Link to={`/about/personnel/${person.id}`} className="bioLink " >view bio</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div >

    </>
  );
};
// trying one last ditch effort to push up
export default Personnel;
