// import React, { Component } from 'react';
// import ServicesComponent from '../ServicesComponent/ServicesComponent';
// import MapComponent from '../MapComponent/MapComponent';
// import WelcomeComponent from '../WelcomeComponent/WelcomeComponent';
// import NewsComponent from '../NewsComponent/NewsComponent';
// import LetsLearnComponent from '../LetsLearnComponent/LetsLearnComponent'
// import CareersComponent from '../CareersComponent/CareersComponent';
// import PromotionComponent from '../PromotionComponent/PromotionComponent';
// import TestimonialsComponent from '../TestimonialsComponent/TestimonialsComponent';
// class Home extends Component {
//   render() {
//     return (
//       <div className="home-page">
        
//         <WelcomeComponent />
//         <div>
//           <ServicesComponent />
//           <PromotionComponent />
//           <NewsComponent />
//           <LetsLearnComponent />
//           <TestimonialsComponent />
//           <MapComponent />
//           <CareersComponent />
//         </div>
        
//       </div>
//     );
//   }
// }

// export default Home;















// import React, { Component } from 'react';
// import ServicesComponent from '../ServicesComponent/ServicesComponent';
// import MapComponent from '../MapComponent/MapComponent';
// import WelcomeComponent from '../WelcomeComponent/WelcomeComponent';
// import NewsComponent from '../NewsComponent/NewsComponent';
// // import LetsLearnComponent from '../LetsLearnComponent/LetsLearnComponent'
// import CareersComponent from '../CareersComponent/CareersComponent';
// import PromotionComponent from '../PromotionComponent/PromotionComponent';
// import TestimonialsComponent from '../TestimonialsComponent/TestimonialsComponent';
// import '../public/styles/style.css';
// import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
// import { ReactComponent as CityBackground } from '../public/images/city_background.svg';
// import { ReactComponent as FarmBackground } from '../public/images/farm_background.svg';
// import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'


// class Home extends Component {
//   render() {
//     return (
//       <div className="home-page" >
//         <div className="fenceDiv">
//           <FenceBackground className="fenceBackground d-none d-sm-block d-sm-none d-md-block" />
//           <WelcomeComponent className="welcome" />
//           <div className="fenceBackgroundColor">
//             <ServicesComponent />
//             <PromotionComponent className=" d-none d-sm-block	d-sm-none d-md-block" />
//           </div>
//           <CityBackground className="cityBackground d-none d-sm-block d-sm-none d-md-block" />
//           <NewsComponent className=""/>
//         </div> 
//         {/* <LetsLearnComponent className="" /> */}
//         <FarmBackground className="farmBackground d-none d-sm-block d-sm-none d-md-block" />
//         <TestimonialsComponent className="testimonials" />
//         <MapComponent className="d-none homeMapComponent"/>
//         <CityAndFarmBackground  className="farmAndCityBackground d-none d-sm-block d-sm-none d-md-block" />

//         <div className="farmAndCityBackgroundColor d-none d-sm-block d-sm-none d-md-block">
//           <CareersComponent />

//         </div>

//       </div>
//     );
//   }
// }

// export default Home;



import React, { Component } from 'react';
import ServicesComponent from '../ServicesComponent/ServicesComponent';
import MapComponent from '../MapComponent/MapComponent';
import WelcomeComponent from '../WelcomeComponent/WelcomeComponent';
import NewsComponent from '../NewsComponent/NewsComponent';
import CareersComponent from '../CareersComponent/CareersComponent';
import PromotionComponent from '../PromotionComponent/PromotionComponent';
import TestimonialsComponent from '../TestimonialsComponent/TestimonialsComponent';
import '../public/styles/style.css';
import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
import { ReactComponent as CityBackground } from '../public/images/city_background.svg';
import { ReactComponent as FarmBackground } from '../public/images/farm_background.svg';
import { ReactComponent as CityAndFarmBackground } from '../public/images/city_and_farm_background.svg'


class Home extends Component {
  render() {
    return (
      <div className="home-page" >
        <div className="fenceDiv">
          <FenceBackground className="fenceBackground d-none d-sm-block d-sm-none d-md-block" />
          <WelcomeComponent className="welcome" />
          <div className="fenceBackgroundColor">
            <ServicesComponent />
            <PromotionComponent className=" d-none d-sm-block	d-sm-none d-md-block" />
          </div>
          <CityBackground className="cityBackground d-none d-sm-block d-sm-none d-md-block" />
          <NewsComponent className=""/>
        </div> 
        {/* <LetsLearnComponent className="" /> */}
        <FarmBackground className="farmBackground w-100 d-none d-sm-block d-sm-none d-md-block" />
        <TestimonialsComponent className="testimonials" />
        <MapComponent />
        <CityAndFarmBackground  className="farmAndCityBackground d-none d-sm-block d-sm-none d-md-block" />

        <div className="farmAndCityBackgroundColor d-none d-sm-block d-sm-none d-md-block">
          <CareersComponent />

        </div>

      </div>
    );
  }
}

export default Home;