
import React, { useState } from "react";

import ReactMarkdown from 'react-markdown'
import { useEffect } from "react";
import axios from "axios";
import { ReactComponent as CityFields } from '../public/images/city_fields.svg';
import { ReactComponent as ContactSign } from '../public/images/contact_sign.svg';
import { config } from '../components/Constants'
const URL = config.url;

const Form = () => {

  const [formState, setFormState] = useState({
    location: "",
    // recapcha: true
  });
  const [fullName, setFullName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [location, setLocation] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [submitted, setSubmitted] = useState()
  // const handleInputChange = (event) => {
  //   setFormState({ ...formState, [event.target.name]: event.target.value });
  // };
  // to test new key



  const handleLocationClick = (location) => {
    setFormState({ ...formState, location: location.attributes.locationName });
    setSelectedLocation(location);
  }
  useEffect(() => {
    fetch(`${URL}/api/locations?populate=*`)
      .then((res) => res.json())
      .then((locationsData) => {
        setLocations(locationsData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: `${URL}/api/contact-forms`,
      data: {
        data: { fullName, phone, email, message, location }
      },
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_STRAPI_ACCESS_TOKEN}`
      }
    })
      .then(setSubmitted(true))
      .catch(function (error) {
        if (error.response) {
          console.log('data error', error.response.data)
          console.log('status error', error.response.status)
          console.log('headers error', error.response.headers)
        } else if (error.request) {
          console.log('request error', error.request)
        } else {
          console.log('Error message', error.message)
        }
        console.log('config error', error.config)
      })

  }

  if (submitted) {
    return <div>Thanks! We&apos;ll be in touch shortly.</div>
  }

  return (
    <>
      <div className="contactBackground">
        <div className="container">

          <div className="contact-form">
            <form onSubmit={handleSubmit} className="contact-form-flex">
              <div className="row mb-5 g-5">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Name"
                    className="form-control inputStyling"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="form-control inputStyling"
                    required
                  />
                </div>
              </div>
              <div className="row g-5">
                <div className="col-md-6">
                  <input
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone"
                    className="form-control inputStyling"
                  />
                </div>
                <div className="col-md-6">
                  <select
                    name="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="form-control inputStyling text-uppercase"
                  >
                    <option value="">choose location</option>
                    <option value="millbrook">millbrook</option>
                    <option value="montgomery(fairview)">montgomery(fairview)</option>
                    <option value="montgomery(dalraida)">montgomery(dalraida)</option>
                    <option value="phenix city">phenix city</option>
                    <option value="prattville">prattville</option>
                  </select>

                </div>
              </div>


              <div className="row">
                <div className="col-md-12 my-5">
                  <textarea
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                    required
                    rows="5"
                    className="form-control inputStyling"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-center contact-form-checkbox">
                  <button type="submit" className="btn btn-danger inputStyling px-5">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div className="row">
            <div className="col-md-12">

            <p className="text-uppercase text-center text-md-start d-block d-md-block mt-3 mt-md-0 location">select a location:</p>
          </div>
             {/* <div className="col-md-12">
              <p className="text-uppercase location d-none d-sm-block">select a location:</p>
              <p className="text-uppercase d-block d-sm-none text-center mt-3 location ">select a location:</p>
            </div> */}
            {locations.data && locations.data.length > 0 && locations.data.map((location) => (
              <div className="col-md-2 text-center contactLocations">
                <p key={location.id}
                  onClick={() => handleLocationClick(location)}>{location.attributes.locationName}</p>
              </div>
            ))}

          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              {selectedLocation && (
                <iframe
                  title="Google Map"
                  src={selectedLocation.attributes.mapLink}
                  allowFullScreen
                  className="form-control"
                />
              )}
            </div>
          </div>
        </div>

      </div>
    </>
  )
};


const Contact = () => {


  useEffect(() => {
    fetch(`${URL}/api/contact-text`)
      .then((res) => res.json())
      .then((welcomeData) => {
        setWelcome(welcomeData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [welcome, setWelcome] = useState(null);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            {/* <img src={AboutSign} alt="" className="serviceSign d-none d-sm-block d-sm-none d-md-block"  /> */}
            <ContactSign className="contactSign" />
          </div>
          <div className='col-md-6 mt-5'>
            {welcome && welcome.data.attributes ?
              <p className="mainTitle text-uppercase mt-5">{welcome.data.attributes.contactTitle}</p> :
              <p>Loading...</p>}
            {welcome && welcome.data.attributes ?
              <ReactMarkdown className="contactText">{welcome.data.attributes.contactText}</ReactMarkdown> :
              <p>Loading...</p>}
          </div>
        </div>
      </div>
      <CityFields className=" d-none d-sm-block d-sm-none d-md-block" />
      <Form />
    </>
  );
};

export default Contact;