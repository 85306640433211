// import React, { useState, useEffect } from 'react';
// import ReactMarkdown from 'react-markdown';
// import { Link } from 'react-router-dom';
// import { ReactComponent as ServicesSign } from '../public/images/services_sign_grey.svg';
// import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
// import { ReactComponent as ServicesPlank } from '../public/images/ServicesPageLinks.svg';
// import { config } from '../components/Constants';
// const URL = config.url;

// const Services = () => {
//   const [services, setServices] = useState([]);
//   const [welcome, setWelcome] = useState();

//   useEffect(() => {
//     fetch(`${URL}/api/services-text`)
//       .then((res) => res.json())
//       .then((welcomeData) => {
//         setWelcome(welcomeData);
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   }, []);

//   useEffect(() => {
//     fetch(`${URL}/api/services`)
//       .then((res) => res.json())
//       .then((services) => {
//         setServices(services.data);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className='col-md-3'>
//             <ServicesSign className="serviceSign" />
//           </div>
//           <div className='col-md-6 mt-5'>
//             {welcome && welcome.data.attributes ?
//               <h3 className=" text-uppercase mt-5 mainTitle">{welcome.data.attributes.servicesTitle}</h3> :
//               <p>Loading...</p>}
//             {welcome && welcome.data.attributes ?
//               <ReactMarkdown className="singleAboutText">{welcome.data.attributes.servicesText}</ReactMarkdown> :
//               <p>Loading...</p>}
//             <Link to={`/prescription-refill`} className="text-uppercase refillLink pe-3" target="_blank">
//               <p className="btn btn-danger">quick refill</p>
//             </Link>
//             <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
//               <p className="btn btn-danger">transfer</p>
//             </Link>
//           </div>
//         </div>
//       </div>
      
//       <FenceBackground className="d-none d-md-block" />
      
//       <div className="servicesBackground mt-5 mt-md-0">
//         <div className="container">
//           <ServicesPlank className="d-none d-md-block servicesPlanks" />
          
//           <div className="servicesPlanksContent d-block d-md-none">
//             <div className="row my-3">
//               {services && services.length > 0 && services.map((service) => (
//                 <div key={service.id} className="col-12 mb-1">
//                   <Link to={service.attributes.serviceURL} className="text-decoration-none service-card">
//                     <div className="personnelTitleDiv mt-3">
//                       <p className="personnelTitle servicesLinkTitle">
//                         {service.attributes.serviceName}
//                       </p>
//                     </div>
//                   </Link>
//                 </div>
//               ))}
//             </div>
//           </div>

//         </div>
//       </div>
//     </>
//   );
// };

// export default Services;

import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ServicesSign } from '../public/images/services_sign_grey.svg';
import { ReactComponent as FenceBackground } from '../public/images/fence_background.svg';
import { ReactComponent as ServicesPlank } from '../public/images/ServicesPageLinks.svg';

const Services = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-3'>
            <ServicesSign className="serviceSign" />
          </div>
          <div className='col-md-6 mt-5'>
            <h3 className=" text-uppercase mt-5 mainTitle">Our Services</h3>
            <p className="singleAboutText">
              Here are some of the services we offer:
            </p>
            <Link to={`/prescription-refill`} className="text-uppercase refillLink pe-3" target="_blank">
              <p className="btn btn-danger">quick refill</p>
            </Link>
            <Link to={`/prescription-refill`} className="text-uppercase refillLink" target="_blank">
              <p className="btn btn-danger">transfer</p>
            </Link>
          </div>
        </div>
      </div>

      <FenceBackground className="d-none d-md-block" />

      <div className="servicesBackground mt-5 mt-md-0">
        <div className="container">
          <ServicesPlank className="d-none d-md-block servicesPlanks" />

          <div className="servicesPlanksContent d-block d-md-none">
            <div className="row my-3">

              {/* Service 1 */}
              <div className="col-12 mb-1">
                <Link to="/services/1" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      prescription refill/transfer</p>
                  </div>
                </Link>
              </div>

              {/* Service 2 */}
              <div className="col-12 mb-1">
                <Link to="/services/2" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      free delivery
                    </p>
                  </div>
                </Link>
              </div>

              {/* Service 3 */}
              <div className="col-12 mb-1">
                <Link to="/services/7" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      compounding
                    </p>
                  </div>
                </Link>
              </div>

              {/* Service 4 */}
              <div className="col-12 col-lg-8">
                <Link to="/services/3" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      flu & immunizations
                    </p>
                  </div>
                </Link>
              </div>

              {/* Service 5 */}
              <div className="col-12 mb-1">
                <Link to="/services/4" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      free annual blood glucose meter
                    </p>
                  </div>
                </Link>
              </div>

              {/* Service 6 */}
              <div className="col-12 mb-1">
                <Link to="/services/5" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      free medical consultation
                    </p>
                  </div>
                </Link>
              </div>

              {/* Service 7 */}
              <div className="col-12 mb-1">
                <Link to="/services/6" className="text-decoration-none service-card">
                  <div className="personnelTitleDiv mt-3">
                    <p className="text-uppercase personnelTitle">
                      insurance consultation
                    </p>
                  </div>
                </Link>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Services;